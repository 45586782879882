import React, { useRef } from 'react';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { exportComponentAsPNG } from 'react-component-export-image';
import { getExportFileName } from '../../services/FileService';
import { ExportIcon } from '../icons';
import { InnerHtml } from './InnerHtml';

export function ExportableToImage({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) {
  const exportableRef = useRef<HTMLDivElement>(null);
  return (
    <div className="relative">
      <ExportToImageButton
        refToExport={exportableRef}
        name={getExportFileName(name)}
      />
      <div ref={exportableRef} className="w-full">
        {children}
      </div>
    </div>
  );
}

function ExportToImageButton({
  refToExport,
  name,
}: {
  refToExport: React.RefObject<HTMLDivElement>;
  name: string;
}) {
  const modal = useModal();

  return (
    <button
      className="tertiary bg-transparent small absolute top-0 right-0 z-40"
      onClick={() =>
        modal.openModalWithComponent(
          <ExportToImageModal refToExport={refToExport} name={name} />,
          'Exporter en image',
          false,
          true,
          'w-2/3',
        )
      }
    >
      <ExportIcon className="w-4 h-4" />
    </button>
  );
}

export function ExportToImageModal({
  refToExport,
  name,
}: {
  refToExport: React.RefObject<HTMLDivElement>;
  name: string;
}) {
  const myRef = useRef<HTMLDivElement>(null);
  const modal = useModal();
  const toast = useToast();
  const startExport = () => {
    // modal.openModalWithComponent(component);
    exportComponentAsPNG(myRef, {
      fileName: `${name}`,
    })
      .then(() => {
        modal.closeModal();
      })
      .catch((err) => {
        console.log(err);
        toast.openToastWithError(err);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      <div ref={myRef}>
        <InnerHtml
          html={refToExport?.current?.innerHTML || ''}
          className="p-4 rounded-xl m-auto block max-w-5xl w-full"
        />
      </div>

      <div className="flex justify-end">
        <button
          className="primary purple"
          onClick={() => {
            startExport();
          }}
        >
          <ExportIcon className="w-4 h-4" />
          <span>Exporter</span>
        </button>
      </div>
    </div>
  );
}
