import React, { useState } from 'react';
import { useModal } from '../../../layout/Modal';
import { UserIcon, XIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { NumberInput } from '../../../generic/form/NumberEditor';
import {
  ProSurveyListScreenDocument,
  useImportExternalConsultationModal_DownloadTemplateLazyQuery,
  useImportExternalConsultationModalMutation,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useToast } from '../../../layout/Toast';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { GroupedStakeholderSegmentDropdown } from '../../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useGroupedStakeholderSegments } from '../../stakeholder/dropdown/useGroupedStakeholderSegments';

export const ImportExternalConsultationModal = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const [numberOfRespondent, setNumberOfRespondent] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [data, setData] = useState<string>('');
  const [segment, setSegment] = useState<string>('');
  const toast = useToast();
  const projectContext = useProjectContext();
  const [create] = useImportExternalConsultationModalMutation();
  const [getTemplateFile] =
    useImportExternalConsultationModal_DownloadTemplateLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const { availableItems } = useGroupedStakeholderSegments(
    projectContext?.enterprise?.id || '',
  );

  const downloadTemplate = () => {
    if (!projectContext?.enterprise?.referential?.id) {
      return;
    }

    getTemplateFile({
      variables: { referentialId: projectContext.enterprise.referential.id },
    })
      .then((response) => {
        const url = response.data?.downloadConsultationRawMaterialityTemplate;
        const link = document.createElement('a');
        link.href = `data:application/xls;base64,${url!}`;
        link.setAttribute('download', 'template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        toast.openToastWithError('Error downloading template');
      });
  };

  const submit = () => {
    if (!projectContext?.enterprise?.id) {
      return;
    }
    create({
      variables: {
        input: {
          company: {
            id: projectContext.enterprise.id,
          },
          name,
          data,
          numberOfRespondent,
          defaultSegment: { id: segment },
        },
      },
      refetchQueries: [
        {
          query: ProSurveyListScreenDocument,
          variables: {
            companyId: projectContext.enterprise.id,
          },
        },
      ],
      onCompleted: () => {
        modal.closeModal();
        toast.openToastWithMessage(t('toast:survey.externalImport.success'));
      },
      onError: (err) => {
        console.error(err);
        toast.openToastWithError(t('toast:survey.externalImport.error'));
      },
    });
  };

  const isFormComplete = () => {
    return name && data && segment;
  };

  return (
    <div className="flex flex-col">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">
          {t('consultationRawMateriality.import.modal.title')}
        </h4>
      </div>

      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          {/*<UploadZone file={file} setFile={setFile} />*/}
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('consultationRawMateriality.import.form.name')}
            </label>
            <input
              type="text"
              id="title"
              className="form-input-text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="title" className="form-input-label">
              {t('consultationRawMateriality.import.form.segment')}
            </label>
            <GroupedStakeholderSegmentDropdown
              multiple={false}
              selectedSegmentIds={[segment]}
              handleChange={(selectedItems) => setSegment(selectedItems[0].id)}
              availableItems={availableItems}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="title" className="form-input-label">
              {t('consultationRawMateriality.import.form.numberOfRespondent')}
            </label>
            <NumberInput
              icon={<UserIcon />}
              step={1}
              value={numberOfRespondent}
              setValue={setNumberOfRespondent}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-2">
              <label htmlFor="title" className="form-input-label">
                {t('consultationRawMateriality.import.form.data.label')}
              </label>
              <button
                className="tertiary small"
                onClick={() => downloadTemplate()}
              >
                {t(
                  'translation:consultationRawMateriality.import.form.file.downloadTemplate',
                )}
              </button>
            </div>
            <textarea
              className="form-input-text h-32"
              value={data}
              onChange={(e) => {
                setData(e.target.value);
              }}
            />
            <MessageBox type={MessageBoxType.Info}>
              <p className="font-semibold">
                {t('consultationRawMateriality.import.form.data.help')}
              </p>
            </MessageBox>
          </div>
        </div>
        <div className={'flex justify-end pt-5'}>
          <button
            className="primary purple"
            onClick={() => submit()}
            disabled={!isFormComplete()}
          >
            {t('consultationRawMateriality.import.form.submit')}
          </button>
        </div>
      </div>
    </div>
  );
};
