import {
  ChecklistPreview_TaskFragment,
  ChecklistPreview_TaskGroupFragment,
  TaskStatus,
  useChecklistPreviewQuery,
} from '../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../layout/Loader';
import { sortByNullablePosition } from '../../services/UtilsService';
import clsx from 'clsx';
import { ProgressBar } from '../generic/ProgressBar';

export function ChecklistPreview({ checklistId }: { checklistId: string }) {
  const checkListQuery = useChecklistPreviewQuery({
    variables: {
      id: checklistId,
    },
  });

  if (checkListQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!checkListQuery.data?.getChecklist) {
    return null;
  }

  return (
    <div className="space-y-4 max-w-4xl">
      {(checkListQuery.data?.getChecklist?.taskGroups || [])
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((taskGroup) => (
          <TaskGroup key={taskGroup.id} taskGroup={taskGroup} />
        ))}
    </div>
  );
}

function TaskGroup({
  taskGroup,
}: {
  taskGroup: ChecklistPreview_TaskGroupFragment;
}) {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <h5>{taskGroup.name}</h5>
      </div>
      {(taskGroup.tasks || [])
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
    </div>
  );
}

function TaskCard({ task }: { task: ChecklistPreview_TaskFragment }) {
  return (
    <div className="card p-4 flex flex-col gap-1 items-start shadow-sm">
      <div className="flex items-center gap-4 justify-between w-full">
        <div className="border-2 border-gray-300 rounded-lg w-6 h-6 bg-white"></div>
        <div className={clsx('grow font-semibold')}>{task.title}</div>
      </div>

      <div className="pl-10 w-full flex flex-col gap-2 justify-center">
        {task.status !== TaskStatus.Done && (
          <div className="text-gray-500 text-sm">{task.description}</div>
        )}
        {task.linkToSurvey && (
          <div className="flex items-center gap-8 text-gray-500">
            <div>Questionnaire</div>
            <ProgressBar value={0} total={100} />
          </div>
        )}
        {task.status !== TaskStatus.Done &&
          task.subtasks &&
          task.subtasks.length > 0 && (
            <div className="pl-0">
              <div className="flex flex-col gap-2">
                {(task.subtasks || []).map((subtask, index) => (
                  <div
                    key={`subtask_${index}`}
                    className="flex items-center gap-2"
                  >
                    <input
                      type="checkbox"
                      className="form-input-checkbox border-2 rounded-md w-5 h-5"
                      checked={false}
                      readOnly={true}
                    />
                    <div className="text-gray-500">{subtask.title}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
