import { DatapointTag_DatapointFragment } from '../../../../../graphql/cms/generated';
import {
  GapAnalysisDisclosureRequirement_DataPointFragment,
  GapAnalysisDisclosureRequirement_DisclosureRequirementFragment,
  GapAnalysisDisclosureRequirement_DrBySlugDocument,
  useGapAnalysisDisclosureRequirement_DefineDataPointMutation,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../../../layout/Toast';
import { useDisclosure } from '../DisclosureContext';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon, XIcon } from '../../../../icons';
import { Toggle, ToggleColors } from '../../../../generic/form/Toggle';
import { DataPointJustifyButton } from './DataPointJustifyButton';

export function DatapointAccordionItem({
  datapoint,
  reportDatapoint,
  disclosureRequirement,
}: {
  datapoint: DatapointTag_DatapointFragment;
  reportDatapoint?: GapAnalysisDisclosureRequirement_DataPointFragment;
  disclosureRequirement: GapAnalysisDisclosureRequirement_DisclosureRequirementFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    selectedDatapointSlug,
    selectDatapointSlug,
    highlightedDatapointsSlugs,
    setHighlightedDatapointsSlugs,
  } = useDisclosure();
  const [disabled, setDisabled] = useState(reportDatapoint?.disabled ?? true);
  const [isAvailable, setIsAvailable] = useState(
    reportDatapoint?.isAvailable ?? false,
  );
  const [availabilityNotice, setAvailabilityNotice] = useState(
    reportDatapoint?.availabilityNotice ?? '',
  );
  const [hasChanged, setHasChanged] = useState(false);

  const isExpanded = selectedDatapointSlug === datapoint.slug;
  const toggleExpanded = () => {
    const newValue =
      selectedDatapointSlug === datapoint.slug ? null : datapoint.slug;
    selectDatapointSlug(newValue ? newValue : null);
  };

  useEffect(() => {
    if (!hasChanged) {
      return;
    }

    const handler = setTimeout(() => {
      save({ disabled, isAvailable, availabilityNotice });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [availabilityNotice, isAvailable, disabled]);

  const [defineDataPoint, { loading }] =
    useGapAnalysisDisclosureRequirement_DefineDataPointMutation();
  const save = (
    data: Partial<GapAnalysisDisclosureRequirement_DataPointFragment>,
  ) => {
    if (datapoint.slug) {
      defineDataPoint({
        variables: {
          input: {
            disclosureRequirement: {
              id: disclosureRequirement.id,
            },
            referenceSlug: datapoint.slug,
            ...data,
          },
        },
        refetchQueries: [
          {
            query: GapAnalysisDisclosureRequirement_DrBySlugDocument,
            variables: {
              referenceSlug: disclosureRequirement.referenceSlug,
              reportId: disclosureRequirement.reportTopic.report.id,
            },
          },
        ],
        onCompleted: () => {
          toast.openToastWithMessage(
            t(
              'toast:gap_analysis.disclosure_requirement.datapoint.define.success',
            ),
          );
        },
      });
    }
  };

  return (
    <div
      className={clsx(
        'border border-gray-100 bg-white rounded-xl',
        highlightedDatapointsSlugs.includes(datapoint.slug || '') &&
          'border-gray-900',
      )}
      onMouseOver={() => setHighlightedDatapointsSlugs([datapoint.slug || ''])}
      onMouseOut={() => setHighlightedDatapointsSlugs([])}
    >
      <div className="flex items-center justify-between gap-2 p-4">
        <div
          className="flex items-center gap-2 cursor-pointer grow"
          onClick={() => toggleExpanded()}
        >
          <button className="unstyled" onClick={() => toggleExpanded()}>
            <ChevronDownIcon
              className={`w-4 h-4 ${isExpanded ? 'transform rotate-180' : ''}`}
            />
          </button>
          <div className="text-base font-bold" onClick={() => toggleExpanded()}>
            {datapoint.identifier}
          </div>
          <div className="flex items-center gap-2">
            {datapoint.voluntary && (
              <div className="tag blue">
                {t(
                  'disclosure_requirements.disclosure_requirement.datapoints.voluntary',
                )}
              </div>
            )}
          </div>
        </div>
        <Toggle
          state={!disabled}
          setState={() => {
            setDisabled(!disabled);
            setHasChanged(true);
          }}
        />
      </div>
      {isExpanded && (
        <div className="border-t border-gray-50 p-4 space-y-2">
          <div className="font-bold">{datapoint.name}</div>
          <div className="flex gap-2 text-sm">
            {datapoint.alternative && (
              <div className="tag purple">
                {t(
                  'disclosure_requirements.disclosure_requirement.datapoints.alternative',
                )}
              </div>
            )}
            {datapoint.conditional && (
              <span className="tag purple">
                {t(
                  'disclosure_requirements.disclosure_requirement.datapoints.conditional',
                )}
              </span>
            )}
          </div>
          <div className="flex flex-col items-center gap-2">
            <div className="flex flex-col gap-2 w-full">
              <div className="border-t-gray-100 border-t my-3" />
              {(!disabled && (
                <div className="flex flex-col gap-2">
                  <label className="flex gap-2 text-sm font-bold">
                    {t(
                      'gap_analysis.disclosure_requirement.datapoint.available',
                    )}
                    <Toggle
                      state={isAvailable}
                      setState={() => {
                        setIsAvailable(!isAvailable);
                        setHasChanged(true);
                      }}
                      color={ToggleColors.Green}
                      offIcon={<XIcon />}
                      onIcon={<CheckIcon />}
                    />
                  </label>
                  <label
                    className={clsx('flex flex-col gap-2  text-sm', {
                      'text-gray-300': !isAvailable,
                    })}
                  >
                    {t(
                      'gap_analysis.disclosure_requirement.datapoint.availabilityNotice.label',
                    )}{' '}
                    :
                    <input
                      disabled={!isAvailable}
                      type="text"
                      className="form-input-text"
                      value={availabilityNotice}
                      onChange={(e) => {
                        setAvailabilityNotice(e.target.value);
                        setHasChanged(true);
                      }}
                    />
                  </label>
                </div>
              )) || (
                <div className={'flex justify-center items-center gap-4'}>
                  <span className={'text-gray-500 text-xs'}>
                    {t(
                      'gap_analysis.disclosure_requirement.datapoint.disabled.justify',
                    )}
                  </span>
                  <DataPointJustifyButton
                    dataPoint={{
                      disabledReason: reportDatapoint?.disabledReason,
                      referenceSlug: datapoint.slug!,
                      reportDisclosureRequirement: {
                        id: disclosureRequirement.id,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
