import { LoaderFullscreen } from '../../../../components/layout/Loader';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { NavLink } from 'react-router-dom';
import { OpenIcon } from '../../../../components/icons';
import { DoubleMaterialityStaticMatrix } from '../../../../components/project/publications/doubleMateriality/DoubleMaterialityStaticMatrix';
import { MatrixProvider } from '../../../../components/project/publications/doubleMateriality/MatrixContext';
import { AppRoutes } from '../../../AppRoutes';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import {
  DOUBLE_MATERIALITY_MATRIX_TABS,
  useDoubleMaterialityMatrixTabs,
} from './useDoubleMaterialityMatrixTabs';
import { DoubleMaterialityHeader } from './DoubleMaterialityHeader';

export const DoubleMaterialityMatrixIroScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();

    if (projectContext?.isLoadingEnterprise) {
      return <LoaderFullscreen />;
    }

    return (
      <TabNavigationLayout<DOUBLE_MATERIALITY_MATRIX_TABS.IRO>
        tabs={useDoubleMaterialityMatrixTabs()}
        activeTab={DOUBLE_MATERIALITY_MATRIX_TABS.IRO}
        renderTabs={(tabsContent) => (
          <>
            <div className="py-12 px-16">
              <DoubleMaterialityHeader />
            </div>
            {tabsContent}
          </>
        )}
      >
        <PageTitle title={t('screen.double_materiality.title')} />
        <div className="bg-gray-50 shadow-inner">
          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityMatrixIroInner />
          </MatrixProvider>
        </div>
      </TabNavigationLayout>
    );
  },
  { requiredCompany: true },
);

export const DoubleMaterialityMatrixIroInner = () => {
  const { t } = useTranslation();

  return <div className="main-content py-16 space-y-4">
    <div className="flex items-center gap-4 justify-between">
      <h3>{t('screen.double_materiality.iro_matrix.title')}</h3>
      <NavLink
        to={AppRoutes.Iro}
        className="button unstyled small text-gray-500"
      >
        {t('screen.double_materiality.iro_matrix.gotoIroScreen')}
        <OpenIcon />
      </NavLink>
    </div>
    <DoubleMaterialityStaticMatrix />
  </div>
}

