import { FormEvent, useState } from 'react';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { sendPasswordResetEmail } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { Loader } from '../../components/generic/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';
import { NavLink } from 'react-router-dom';
import { ErrorIcon } from '../../components/icons';

export function PasswordResetScreen() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const firebaseAuth = useFirebaseAuth();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    sendPasswordResetEmail(firebaseAuth, email)
      .catch((err) => {
        console.error(err);
        setIsFormSubmitted(true);
        setIsLoading(false);
        setError('Compte inexistant');
      })
      .finally(() => {
        setIsFormSubmitted(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageTitle title="Mot de passe oublié" />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div>
          <NavLink to={'/'}>
            <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
          </NavLink>
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-xl">
          <div>
            <h1>Mot de passe oublié</h1>
            <p className="text-gray-500">
              Réinitialisez votre mot de passe en renseignant votre email
            </p>

            {isFormSubmitted ? (
              <MessageBox type={MessageBoxType.Info}>
                Un email vous a été envoyé
              </MessageBox>
            ) : (
              <div className="mt-8">
                <form
                  name="loginForm"
                  className="space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className="space-y-1">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError(null);
                      }}
                      required
                    />
                  </div>

                  <div className="flex justify-center">
                    {isLoading ? (
                      <Loader className="w-10 h-10 text-black" />
                    ) : (
                      <input
                        className="primary purple w-full"
                        type="submit"
                        value="Réinitialiser mon mot de passe"
                      />
                    )}
                  </div>

                  {error && (
                    <div className="p-4 flex gap-4 items-center bg-red-50 text-red-700">
                      <ErrorIcon className="shrink-0" />
                      {error}
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
