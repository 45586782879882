import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { ThemeIcon as ThemeIconComponent } from '../../../generic/icon/ThemeIcon';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronRightIcon, FileImport } from '../../../icons';
import {
  ProSurveyListScreen_ConsultationRawMaterialityFragment,
  ThemeIcon,
} from '../../../../graphql/generated';
import { IconTitle } from '../../../heading/iconTitle';
import { AppRoutes } from '../../../../screens/AppRoutes';

export const ConsultationRawMaterialitiesList = ({
  consultations,
}: {
  consultations: ProSurveyListScreen_ConsultationRawMaterialityFragment[];
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start gap-6 self-stretch">
      <IconTitle icon={<FileImport />} as="h2">
        {t('consultationRawMateriality.title')}
      </IconTitle>
      <table className="">
        <thead>
          <tr>
            <th className="text-left">
              {t('consultationRawMateriality.list.table.name')}
            </th>
            <th className=""></th>
          </tr>
        </thead>
        <tbody>
          {consultations.map((consultation) => {
            return (
              <ConsultationRawMaterialitiesRow
                consultation={consultation}
                key={consultation.id}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ConsultationRawMaterialitiesRow = ({
  consultation,
}: {
  consultation: ProSurveyListScreen_ConsultationRawMaterialityFragment;
}) => {
  const tooltipId = `survey-tooltip-${consultation.id}`;
  const { translateProperty } = useTranslation();

  return (
    <tr>
      <td>
        <NavLink
          to={generatePath(AppRoutes.ConsultationRawMaterialities, {
            consultationId: consultation.id,
          })}
        >
          <div className="flex items-center gap-2">
            <div
              data-tooltip-content={translateProperty(
                consultation.defaultSegment,
                'name',
              )}
              data-tooltip-id={tooltipId}
            >
              <Tooltip id={tooltipId} className="tooltip" />
              <ThemeIconComponent
                themeIcon={
                  consultation.defaultSegment?.group?.icon ||
                  ThemeIcon.Collaborators
                }
              />
            </div>
            <div className="font-title font-extrabold">{consultation.name}</div>
          </div>
        </NavLink>
      </td>
      <td>
        <div className="flex justify-end gap-2">
          <NavLink
            to={generatePath(AppRoutes.ConsultationRawMaterialities, {
              consultationId: consultation.id,
            })}
          >
            <button className="primary purple small">
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </NavLink>
        </div>
      </td>
    </tr>
  );
};
