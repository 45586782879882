import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../layout/Modal';
import { useToast } from '../../../layout/Toast';
import { CommentForm } from '../../../generic/form/commentForm/CommentForm';
import {
  DisclosureRequirementJustifyButtonFragment,
  useGapAnalysisJustifyDisabledReasonMutation,
} from '../../../../graphql/generated';
import { MessageFillIcon, MessageIcon } from '../../../icons';

export const DisclosureRequirementJustifyButton = ({
  disclosureRequirement: {
    disabledReason,
    referenceSlug,
    reportTopic: { id: topicId },
  },
}: {
  disclosureRequirement: DisclosureRequirementJustifyButtonFragment;
}) => {
  const { t } = useTranslation();
  const modal = useModal();
  const toast = useToast();
  const [justify] = useGapAnalysisJustifyDisabledReasonMutation();

  const openJustificationModal = () => {
    modal.openModalWithComponent(
      <CommentForm
        initialComment={disabledReason}
        mutate={(comment) =>
          justify({
            variables: {
              topicId,
              referenceSlug,
              disabledReason: comment,
            },
          })
            .then(() => {
              modal.closeModal();
              toast.openToastWithMessage(
                t('toast:gap_analysis.disclosure_requirement.justify.success'),
              );
            })
            .catch(() => {
              toast.openToastWithMessage(
                t('toast:gap_analysis.disclosure_requirement.justify.error'),
              );
            })
        }
        cta={t('global:save')}
      />,
      t('gap_analysis.disclosure_requirement.justify.modal_title'),
      false,
      true,
      'w-3/12',
    );
  };

  return (
    <button className="unstyled" onClick={() => openJustificationModal()}>
      {disabledReason ? (
        <MessageFillIcon className="w-4 h-4 text-purple-500" />
      ) : (
        <MessageIcon className="w-4 h-4 text-gray-300" />
      )}
    </button>
  );
};
