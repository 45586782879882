import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { DropIcon, DropLeftIcon } from '../../icons';
import { Float } from '@headlessui-float/react';

interface GradientDropdownItem {
  id: string | null; // null is used for the default (void) item
  name: string;
  strength: GradientDropdownStrength;
}

export enum GradientDropdownStrength {
  VeryLight = 'VeryLight',
  Light = 'Light',
  Medium = 'Medium',
  Strong = 'Strong',
  VeryStrong = 'VeryStrong',
}

export const GradientDropdown = <ItemType extends GradientDropdownItem>({
  items,
  selected,
  chooseItemId,
  extraPositionClassName,
}: {
  items: ItemType[];
  selected: ItemType | null;
  chooseItemId: (itemId: string | null) => void;
  extraPositionClassName?: string;
}) => {
  return (
    <Listbox
      value={selected}
      onChange={(item) => chooseItemId(item?.id || null)}
    >
      {({ open }) => (
        <Float portal>
          <ListboxButton className="tertiary p-0 text-sm bg-transparent">
            <div className="flex items-center gap-1">
              <div
                className={clsx(
                  'block truncate rounded-md p-1 text-sm font-semibold',
                  selected && getBgColorFromColorStrength(selected.strength),
                  selected && getTextColorFromColorStrength(selected.strength),
                )}
              >
                {selected?.name || '-'}
              </div>
              <div className="pointer-events-none flex items-center pr-2">
                <DropIcon
                  className="h-4 w-4 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions
              className={clsx(
                'absolute z-10 mt-2 min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg',
                'ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                extraPositionClassName,
              )}
            >
              {items.map((item) => (
                <DropdownItem key={item.id} item={item} />
              ))}
            </ListboxOptions>
          </Transition>
        </Float>
      )}
    </Listbox>
  );
};

const DropdownItem = <ItemType extends GradientDropdownItem>({
  item,
}: {
  item: ItemType;
}) => {
  return (
    <ListboxOption
      key={item.id}
      className={clsx(
        'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
        'data-[selected]:active data-[focus]:text-white data-[focus]:bg-gray-100',
      )}
      value={item}
    >
      {({ selected }) => (
        <>
          {selected ? (
            <div
              className={clsx(
                'icon absolute inset-y-0 left-0 flex items-center pr-4 text-white',
              )}
            >
              <DropLeftIcon
                className="h-4 w-4 text-gray-900"
                aria-hidden="true"
              />
            </div>
          ) : null}
          <div
            className={clsx(
              selected ? 'font-semibold' : 'font-normal',
              'ml-3 block truncate',
            )}
          >
            <div
              className={clsx(
                'block truncate rounded-md p-1 font-bold w-full',
                getBgColorFromColorStrength(item.strength),
                getTextColorFromColorStrength(item.strength),
              )}
            >
              {item?.name || '-'}
            </div>
          </div>
        </>
      )}
    </ListboxOption>
  );
};

export function getBgColorFromColorStrength(
  strength: GradientDropdownStrength,
) {
  switch (strength) {
    case GradientDropdownStrength.VeryLight:
      return 'bg-purple-50';
    case GradientDropdownStrength.Light:
      return 'bg-purple-100';
    case GradientDropdownStrength.Medium:
      return 'bg-purple-300';
    case GradientDropdownStrength.Strong:
      return 'bg-purple-500';
    case GradientDropdownStrength.VeryStrong:
      return 'bg-purple-900';
  }
}

export function getTextColorFromColorStrength(
  strength: GradientDropdownStrength,
) {
  switch (strength) {
    case GradientDropdownStrength.VeryLight:
      return 'text-purple-900';
    case GradientDropdownStrength.Light:
      return 'text-purple-900';
    case GradientDropdownStrength.Medium:
      return 'text-purple-900';
    case GradientDropdownStrength.Strong:
      return 'text-purple-50';
    case GradientDropdownStrength.VeryStrong:
      return 'text-purple-50';
  }
}
