import React from 'react';
import {
  ImpactLevel,
  MaterialityTypes,
  PriorityLevel,
  StakesDoubleMaterialitySeverityArray_AnswerFragment,
  StakesDoubleMaterialitySeverityArray_QuestionFragment,
  StakesDoubleMaterialitySeverityArray_StakeFragment,
} from '../../../../../graphql/generated';
import {
  MatrixArray,
  MatrixRowType,
  stakeAnswersToMatrixRows,
} from '../MatrixArray';

export function StakesDoubleMaterialitySeverityArray({
  stakes,
  question,
  answers,
}: {
  stakes: StakesDoubleMaterialitySeverityArray_StakeFragment[];
  question: StakesDoubleMaterialitySeverityArray_QuestionFragment;
  answers: StakesDoubleMaterialitySeverityArray_AnswerFragment[];
}) {
  // columns = list of impacts or priorities, depending on materiality type
  const columns: Array<PriorityLevel | ImpactLevel> =
    question.stakeOptions?.materialityType === MaterialityTypes.Financial
      ? [
          PriorityLevel.None,
          PriorityLevel.Low,
          PriorityLevel.Medium,
          PriorityLevel.High,
        ]
      : [
          ImpactLevel.None,
          ImpactLevel.Low,
          ImpactLevel.Medium,
          ImpactLevel.High,
        ];

  // Matrix = list of rows, each row is a stake
  const matrix: MatrixRowType[] = stakeAnswersToMatrixRows(
    answers || [],
    stakes,
    columns,
    question.stakeOptions?.materialityType || MaterialityTypes.Financial,
  );

  return (
    <MatrixArray
      title={question.stakeOptions?.materialityOptions?.impactLabel}
      matrix={matrix}
      columns={columns}
    />
  );
}
