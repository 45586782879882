import { useState } from 'react';
import { getAuth, getIdToken } from 'firebase/auth';
import { useToast } from '../components/layout/Toast';
import { useFirebaseApp } from '../providers/FirebaseProvider';
import { SurveyLanguage } from "../graphql/generated";

interface UseExportFileProps {
  path: string;
  fileName: string;
  errorToastMessage: string;
  language?: SurveyLanguage
}

export const enum ApiDownloadRoutes {
  ReferentialExport = '/referential/:referentialId/export',
  SurveyExport = '/survey/:surveyId/export/survey',
}

export const useDownloadFile = ({
  path,
  fileName,
  errorToastMessage,
  language = SurveyLanguage.Fr
}: UseExportFileProps) => {
  const toast = useToast();
  const firebaseApp = useFirebaseApp();
  const currentUser = getAuth(firebaseApp).currentUser;
  const [loading, setLoading] = useState(false);

  const downloadFile = () => {
    if (currentUser == null) return;
    getIdToken(currentUser)
      .then((token) => {
        const authorizationHeader = token ? `Bearer ${token}` : '';
        if (process.env.API_URL) {
          const apiUrl = process.env.API_URL;
          setLoading(true);
          fetch(`${apiUrl}${path}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              Authorization: authorizationHeader,
              'accept-language': language,
            },
          })
            .then((result) => {
              if (!result.ok) {
                throw Error(result.statusText);
              }
              return result.blob();
            })
            .then((blob) => {
              if (blob != null) {
                const filename = fileName;
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            })
            .catch((error) => {
              toast.openToastWithError(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          toast.openToastWithError(errorToastMessage);
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return { downloadFile, loading };
};
