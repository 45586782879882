import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

export const DoubleMaterialityHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="">{t('screen.double_materiality.title')}</h1>
      <p className="text-gray-500">
        {t('screen.double_materiality.description')}
      </p>
    </>
  );
};
