import React, { useState } from 'react';
import {
  GradientDropdown,
  GradientDropdownStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import {
  IroScalePicker_NegativeImpactFragment,
  IroScalePicker_PositiveImpactFragment,
  IroScopePicker_OpportunityFragment,
  IroScopePicker_RiskFragment,
  ScaleValue,
  useUpdateIroScaleMutation,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';
import { useIroPickerRefetchQueries } from './useRefetchQueries';

export type IroScaleItem = {
  id: ScaleValue | null;
  name: string;
  strength: GradientDropdownStrength;
};

export const scaleCriteriaChoices = (t: TFunction): IroScaleItem[] => {
  return [
    {
      id: ScaleValue.VeryHigh,
      name: t(`enum:iroCriteria.scale.${ScaleValue.VeryHigh}`),
      strength: GradientDropdownStrength.VeryStrong,
    },
    {
      id: ScaleValue.High,
      name: t(`enum:iroCriteria.scale.${ScaleValue.High}`),
      strength: GradientDropdownStrength.Strong,
    },
    {
      id: ScaleValue.Medium,
      name: t(`enum:iroCriteria.scale.${ScaleValue.Medium}`),
      strength: GradientDropdownStrength.Medium,
    },
    {
      id: ScaleValue.Low,
      name: t(`enum:iroCriteria.scale.${ScaleValue.Low}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: null,
      name: '-',
      strength: GradientDropdownStrength.VeryLight,
    },
  ];
};

export default function IroScalePicker({
  iro,
  extraPositionClassName,
}: {
  iro:
    | IroScopePicker_RiskFragment
    | IroScopePicker_OpportunityFragment
    | IroScalePicker_PositiveImpactFragment
    | IroScalePicker_NegativeImpactFragment;
  extraPositionClassName?: string;
}) {
  const { t } = useTranslation();
  const items = scaleCriteriaChoices(t);
  const initialScaleItem = items.find(
    (item) => item.id === iro.scaleCriteria?.value || null,
  );
  const [selected, setSelected] = useState(initialScaleItem || null);
  const [update] = useUpdateIroScaleMutation();
  const toast = useToast();
  const refetchQueries = useIroPickerRefetchQueries();

  const chooseItemId = (itemId: string | null) => {
    const item = items.find((item) => item.id === itemId) || null;
    setSelected(item);

    update({
      variables: {
        input: {
          id: iro.id,
          scale: item?.id ?? null,
        },
      },
      refetchQueries,
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteria.update.success', {
            criteriaName: t('iro.criteria.scale.title'),
          }),
        );
      })
      .catch(() => {
        toast.openToastWithError(t('toast:iro.criteria.scale.update.success'));
      });
  };
  return (
    <GradientDropdown
      {...{
        items,
        selected,
        chooseItemId,
        extraPositionClassName,
      }}
    />
  );
}
