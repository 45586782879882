import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { LoaderFullscreen } from '../../layout/Loader';
import clsx from 'clsx';
import { TooltipWrapper } from '../TooltipWrapper';
import { TextIcon } from '../../icons';

export default function RichTextEditorTextarea({
  value,
  onChange,
  onBlur,
  placeholder,
  inlined = false,
  disabled = false,
}: {
  value: string;
  onChange: (valueTransformed: string, valueWithHTML: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  inlined?: boolean;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState(true);

  const sanitizeAndChange = (content: string, html: string) => {
    // Clear string if it only contains <p><br></p> or <p><br/></p> or <p></p>
    if (
      html === '<p><br></p>' ||
      html === '<p><br/></p>' ||
      html === '<p></p>'
    ) {
      onChange('','');
    } else {
      onChange(content, html.trim());
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        // { indent: '-1' },
        // { indent: '+1' },
      ],
      // ['link', 'image'],
      // ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'p',
    'br',
    'align',
  ];

  if (loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div
      id="editorContainer"
      className="relative"
      onBlur={() => onBlur && onBlur()}
    >
      {!disabled && (
        <div className="absolute top-3 right-3 z-50 w-5 h-5 flex items-center justify-center cursor-help">
          <TooltipWrapper label="Mise en forme possible" place={'left'}>
            <TextIcon className="text-gray-300" />
          </TooltipWrapper>
        </div>
      )}
      <ReactQuill
        theme="bubble"
        className={clsx(
          'focus:border-0 p-0 text-base min-h-[10rem]',
          inlined ? 'form-input-text-inline' : 'form-input-text',
        )}
        readOnly={disabled}
        modules={modules}
        formats={formats}
        value={value}
        onChange={(
          text: string,
          delta: never,
          source: string,
          editor: ReactQuill.UnprivilegedEditor,
        ) => {
          if (source === 'user') {
            // Hacky way to handle only user changes
            // See https://github.com/zenoamaro/react-quill/issues/259
            sanitizeAndChange(text, editor.getText());
          }
        }}
        placeholder={placeholder}
        bounds="#editorContainer"
      />
    </div>
  );
}
