import React, { ReactNode } from 'react';
import clsx from 'clsx';

export enum ColorIconColor {
  Blue = 'blue',
  Gray = 'gray',
  Green = 'green',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow',
}

export function ColorIcon({
  color,
  icon,
  borderColor,
}: {
  color: ColorIconColor;
  icon: ReactNode;
  borderColor?: string;
}) {
  const colorClassName =
    Object.values(ColorIconColor).includes(color) &&
    `bg-${color}-50 text-${color}-900`;
  const borderColorClassName =
    borderColor && `border-2 border-${borderColor}-500`;

  return (
    <div
      className={clsx(
        'rounded-lg p-1 shrink-0 w-8 h-8 flex items-center justify-center',
        colorClassName,
        borderColorClassName,
      )}
    >
      {icon}
    </div>
  );
}
