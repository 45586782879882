import { SurveyQuestion_SurveyQuestionFragment } from '../../../../graphql/generated';
import React, { useState } from 'react';
import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';
import { ChevronDownIcon } from '../../../icons';
import clsx from 'clsx';
import { SurveyQuestionDatavizForStake } from './SurveyQuestionDatavizForStake';

export function SurveyQuestion({
  question,
  stakeId,
}: {
  question: SurveyQuestion_SurveyQuestionFragment;
  stakeId: string;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="bg-white p-4 border border-gray-100 rounded-xl space-y-8">
      <div
        className="flex items-center justify-between gap-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-4">
          <SurveyQuestionIcon questionType={question.type} />
          <div className="">
            <div className="font-title font-bold whitespace-pre-wrap">
              {question.title}
            </div>
          </div>
        </div>
        <ChevronDownIcon className={clsx(isExpanded && 'rotate-180')} />
      </div>
      {isExpanded && (
        <SurveyQuestionDatavizForStake question={question} stakeId={stakeId} />
      )}
    </div>
  );
}
