import { MaturityEvaluationRowFragment, useMaturityEvaluationTemplatesQuery } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../layout/Modal';
import React, { useState } from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import { SearchIcon, XIcon } from '../../../icons';
import { MaturityEvaluationTemplateList } from './MaturityEvaluationTemplateList';
import { SourceFilter, TemplatesSources } from './SourceFilter';

export  const MaturityEvaluationTemplates = ({
  setPreviewMaturityEvaluationTemplate,
}: {
  setPreviewMaturityEvaluationTemplate: (
    previewMaturityEvaluationTemplate: MaturityEvaluationRowFragment | null,
  ) => void;
}) => {
  const { t } = useTranslation();
  const { data, loading } = useMaturityEvaluationTemplatesQuery({
    fetchPolicy: 'network-only',
  });
  const modal = useModal();
  const [selectedSourceFilter, setSelectedSourceFilter] =
    useState<TemplatesSources>(TemplatesSources.GoodSteps);
  const [searchTextFilter, setSearchTextFilter] = useState('');

  const filteredMaturityEvaluationTemplates = (
    maturityEvaluationTemplates: MaturityEvaluationRowFragment[],
  ) => {
    let filteredMaturityEvaluationTemplates = maturityEvaluationTemplates;
    if (selectedSourceFilter === TemplatesSources.Own) {
      filteredMaturityEvaluationTemplates = filteredMaturityEvaluationTemplates.filter(
        (maturityEvaluationTemplate) => !maturityEvaluationTemplate.isTemplate,
      );
    }
    if (selectedSourceFilter === TemplatesSources.GoodSteps) {
      filteredMaturityEvaluationTemplates = filteredMaturityEvaluationTemplates.filter(
        (maturityEvaluationTemplate) => maturityEvaluationTemplate.isTemplate,
      );
    }
    if (searchTextFilter) {
      // Fetch maturityEvaluation having searchTextFilter in name or description
      filteredMaturityEvaluationTemplates = filteredMaturityEvaluationTemplates.filter(
        (maturityEvaluationTemplate) =>
          maturityEvaluationTemplate.name
            .toLowerCase()
            .includes(searchTextFilter.toLowerCase()) ||
          maturityEvaluationTemplate.description?.toLowerCase()
            .includes(searchTextFilter.toLowerCase()),
      );
    }
    return filteredMaturityEvaluationTemplates;
  };

  return loading ? (
    <LoaderFullscreen />
  ) : (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <h4 className="text-center grow">
          {t('maturity.templateModal.chooseTemplate')}
        </h4>
      </div>

      <div className="flex h-full">
        <div className="bg-gray-50 w-80 p-8 divide-y divide-gray-100 space-y-8">
          <div className="flex flex-col gap-1 pt-4">
            <h3 className="title-h5">{t('maturity.templateModal.sources')}</h3>
            <SourceFilter
              source={TemplatesSources.GoodSteps}
              selectedSourceFilter={selectedSourceFilter}
              setSelectedSourceFilter={setSelectedSourceFilter}
            />
            <SourceFilter
              source={TemplatesSources.Own}
              selectedSourceFilter={selectedSourceFilter}
              setSelectedSourceFilter={setSelectedSourceFilter}
            />
          </div>
        </div>
        <div className="grow p-8 overflow-scroll flex flex-col gap-8">
          <h1 className="max-w-3xl">
            {t('maturity.templateModal.inspirationMessage')}
          </h1>
          <div className="flex items-center justify-start gap-2 px-2 border border-gray-100 shadow-sm rounded-md">
            <SearchIcon className="text-gray-300" />
            <input
              placeholder={t('maturity.templateModal.searchPlaceholder')}
              className="border-0 w-full focus:border-0 focus:ring-0 focus:outline-none text-sm placeholder-gray-300 pl-0"
              type={'text'}
              value={searchTextFilter}
              onChange={(e) => setSearchTextFilter(e.target.value)}
            />
          </div>
          {data?.getMaturityEvaluationTemplates && (
            <MaturityEvaluationTemplateList
              maturityEvaluations={filteredMaturityEvaluationTemplates(data.getMaturityEvaluationTemplates)}
              setPreviewMaturityEvaluationTemplate={setPreviewMaturityEvaluationTemplate}
            />
          )}
        </div>
      </div>
    </div>
  );
}
