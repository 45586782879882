// TODO: find a way to extract Tailwind colors from config file
import { ThemeColor } from '../graphql/generated';

export const tailwindColors = {
  red: {
    900: '#BF4B32',
    500: '#F46031',
    300: '#FFAF9E',
    100: '#FFDFD8',
    50: '#FFEEEA',
  },
  orange: {
    100: '#FADFC5',
  },
  gray: {
    900: '#282A2C',
    500: '#6C738C',
    300: '#A6ADC6',
    100: '#E4E6F2',
    50: '#F6F9FF',
  },
  blue: {
    900: '#2B5DA8',
    500: '#3065CC',
    300: '#AED8FF',
    100: '#D7ECFF',
    50: '#ECF6FF',
  },
  blue2: {
    100: '#D7DDFF',
    900: '#3A499B',
  },
  brown: {
    100: '#E8D7D1',
    900: '#6E5249',
  },
  pink: {
    100: '#FFD8EF',
    900: '#793A60',
  },
  green: {
    900: '#015055',
    500: '#00A77F',
    300: '#93E7D3',
    100: '#DCF5EF',
    50: '#E8F9F5',
  },
  green2: {
    100: '#E5F2DA',
    300: '#D9EFB4',
  },
  yellow: {
    900: '#C78017',
    500: '#FFCB44',
    300: '#FFE9B0',
    100: '#FFF5DA',
    50: '#FFFAED',
  },
  purple: {
    900: '#5E4394',
    500: '#7559AD',
    300: '#E9DEFF',
    100: '#F2EDFC',
    50: '#F6F2FF',
  },
  superDark: '#121314',
};

export function mapThemeColorToHexColor(
  themeColor: ThemeColor | null | undefined,
  shade: number,
): string {
  switch (themeColor) {
    case ThemeColor.Blue: {
      const colorShades = tailwindColors['blue' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
    case ThemeColor.Green: {
      const colorShades =
        tailwindColors['green' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
    case ThemeColor.Purple: {
      const colorShades =
        tailwindColors['purple' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
    case ThemeColor.Red: {
      const colorShades = tailwindColors['red' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
    case ThemeColor.Yellow: {
      const colorShades =
        tailwindColors['yellow' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
    case ThemeColor.Gray:
    default: {
      const colorShades = tailwindColors['gray' as keyof typeof tailwindColors];
      return colorShades[shade as keyof typeof colorShades];
    }
  }
}

export function mapThemeColorToTailwindClass(
  themeColor: ThemeColor | null | undefined,
  shade: number,
  type: 'bg' | 'text',
): string {
  switch (themeColor) {
    case ThemeColor.Blue:
      return `${type}-blue-${shade}`;
    case ThemeColor.Green:
      return `${type}-green-${shade}`;
    case ThemeColor.Purple:
      return `${type}-purple-${shade}`;
    case ThemeColor.Red:
      return `${type}-red-${shade}`;
    case ThemeColor.Yellow:
      return `${type}-yellow-${shade}`;
    case ThemeColor.Gray:
    default:
      return `${type}-gray-${shade}`;
  }
}
