import React from 'react';
import { Loader } from '../generic/Loader';

export function LoaderFullscreen() {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Loader className="w-10 h-10" />
    </div>
  );
}
