import React, { useState } from 'react';
import {
  AddNewStakeholderButton_SurveyFragment,
  ManageStakeholderListDocument,
  useAddNewStakeholderButton_StakeholderCreateMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';
import { PlusIcon } from '../../icons';

export function AddNewStakeholderButton({
  survey,
}: {
  survey: AddNewStakeholderButton_SurveyFragment;
}) {
  const [createStakeholder] =
    useAddNewStakeholderButton_StakeholderCreateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const addNewStakeholder = () => {
    setIsLoading(true);
    createStakeholder({
      variables: {
        input: {
          stakeholderSurvey: {
            survey: {
              id: survey.id,
            },
            email: '',
            firstName: '',
            lastName: '',
          },
        },
      },
      refetchQueries: [
        {
          query: ManageStakeholderListDocument,
          variables: {
            id: survey.id,
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <button
      className="primary purple small"
      onClick={(event) => {
        event.preventDefault();
        addNewStakeholder();
      }}
    >
      <PlusIcon />
      <span>Ajouter</span>
    </button>
  );
}
