import { MaterialityTypes } from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { convertArrayToObject } from '../../../../../../services/FileService';
import { utils, writeFileXLSX } from 'xlsx';
import { DownloadIcon } from '../../../../../icons';
import React from 'react';
import { StakeQualityAverageType } from './types';

export const ExportMatrixContentToXlsButton = ({
  stakeQualityArray,
  materialityType,
}: {
  stakeQualityArray: StakeQualityAverageType[];
  materialityType: MaterialityTypes;
}) => {
  const { translateProperty } = useTranslation();
  const label =
    materialityType === MaterialityTypes.Financial
      ? 'Risque / Opportunité'
      : 'Impact +/-';
  const exportToXls = () => {
    const header = ['Enjeu', label];

    const rows = stakeQualityArray.map((stakeQuality) => {
      return [
        translateProperty(stakeQuality.stake, 'name'),
        stakeQuality.qualityAverage,
      ];
    });

    // Convert array to object to be able to use xlsx library
    const asAnObject = convertArrayToObject(rows, header);

    const worksheet = utils.json_to_sheet(asAnObject, {
      skipHeader: false,
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'qualité');
    writeFileXLSX(workbook, `Export tableau ${label}.xlsx`, {
      compression: true,
    });
  };

  return (
    <button className="tertiary" onClick={exportToXls}>
      <DownloadIcon />
    </button>
  );
};
