import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../../layout/Modal';
import { PlusIcon } from '../../../../icons';
import { StakeholderGroupForm } from '../form/StakeholderGroupForm';
import {
  CreateStakeholderGroupInput,
  GroupedStakeholderSegmentDropdownDocument,
  StakeholderListDocument,
  useCreateStakeholderGroupMutation,
} from '../../../../../graphql/generated';

type Props = {
  companyId: string;
};

export const AddStakeholderGroupButton = ({ companyId }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const [createStakeholderGroup] = useCreateStakeholderGroupMutation();

  const openCreateIroModal = () => {
    modal.openModalWithComponent(
      <StakeholderGroupForm<CreateStakeholderGroupInput>
        companyId={companyId}
        onSubmit={(input) =>
          createStakeholderGroup({
            variables: { input },
            refetchQueries: () => [
              {
                query: StakeholderListDocument,
                variables: {
                  companyId: companyId,
                },
              },
              {
                query: GroupedStakeholderSegmentDropdownDocument,
                variables: {
                  companyId: companyId,
                },
              },
            ],
          })
        }
        onClose={modal.closeModal}
      />,
      t('iro.create.modal.title'),
      false,
      false,
      undefined,
      'p-0',
    );
  };

  return (
    <button className="primary purple" onClick={openCreateIroModal}>
      <PlusIcon className="w-4 h-4" />
      {t('global:add')}
    </button>
  );
};
