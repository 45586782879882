import React from 'react';
import { BlocksRenderer as StrapiBlocksRenderer } from '@strapi/blocks-react-renderer';

export function BlocksRenderer({
  ...blocksRendererProps
}: React.ComponentProps<typeof StrapiBlocksRenderer>) {
  // Strapi and/or Strapi Blocks Renderer is not handling null content
  // Strapi GraphQL API returns "any" or nullable types
  // -> in any case, Strapi documentation is wrong, "any" should not be allowed here
  // Strapi Blocks Renderer expects RootNode[];

  // Make sure rest.content is not null
  if (!blocksRendererProps.content) {
    return null;
  }

  // Trim content to remove empty blocks
  // This is a workaround for Strapi GraphQL API returning empty blocks
  blocksRendererProps.content = blocksRendererProps.content.filter((block) => {
    return block.children.length === 1 && hasText(block.children[0]);
  });

  return <StrapiBlocksRenderer {...blocksRendererProps} />;
}

// Interface chunk from BlocksRenderer.tsx
interface TextInlineNode {
  type: 'text';
  text: string;
}

function hasText(obj: unknown) {
  return (
    (obj as unknown as TextInlineNode).text !== undefined &&
    (obj as unknown as TextInlineNode).text !== null &&
    (obj as unknown as TextInlineNode).text !== ''
  );
}
