import { useIntegrationsRouteConfig } from './useIntegrationsRouteConfig';
import { useAppRouteConfig } from './useAppRouteConfig';
import { useAdminRouteConfig } from './useAdminRouteConfig';
import { usePublicRouteConfig } from './usePublicRouteConfig';

export const useRouteConfig = () => {
  return [
    ...useIntegrationsRouteConfig(),
    ...useAppRouteConfig(),
    ...useAdminRouteConfig(),
    ...usePublicRouteConfig(),
  ];
};
