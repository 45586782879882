import React from 'react';
import {
  SurveyQuestionSummaryFieldsFragment,
  useQuestionPillarStakeMutation,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import StakeDropdown from '../../../../../form/StakeDropdown';
import PillarDropdown from '../../../../../form/PillarDropdown';

export function QuestionPillarStake({
  question,
  enableStakePicker,
  enablePillarPicker,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  enableStakePicker: boolean;
  enablePillarPicker: boolean;
}) {
  const toast = useToast();
  const [updateQuestionMutation] = useQuestionPillarStakeMutation();

  const [stakeId, setStakeId] = React.useState<string | null | undefined>(
    question.stake?.id,
  );
  const [pillarId, setPillarId] = React.useState<string | null | undefined>(
    question.pillar?.id,
  );

  const updateStakeId = (newStakeId: string | null) => {
    setStakeId(newStakeId);
    updateStakeOptions(newStakeId, pillarId);
  };

  const updatePillarId = (newPillarId: string | null) => {
    setPillarId(newPillarId);
    updateStakeOptions(stakeId, newPillarId);
  };

  const updateStakeOptions = (
    stakeId: string | null | undefined,
    pillarId: string | null | undefined,
  ) => {
    updateQuestionMutation({
      variables: {
        input: {
          id: question.id,
          stake: stakeId ? { id: stakeId } : null,
          pillar: pillarId ? { id: pillarId } : null,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-4">
      <h3>Paramètres</h3>
      <div className="grid grid-cols-2 gap-4">
        {enablePillarPicker && (
          <div>
            <label htmlFor="stake" className="form-input-label mb-2">
              Pilier associé
            </label>
            <PillarDropdown
              currentPillarId={pillarId}
              setPillarId={updatePillarId}
            />
          </div>
        )}
        {enableStakePicker && (
          <div>
            <label htmlFor="stake" className="form-input-label mb-2">
              Enjeu associé
            </label>
            <StakeDropdown
              currentStakeId={stakeId}
              setCurrentStakeId={updateStakeId}
              isRequired={false}
              pillarId={pillarId}
            />
          </div>
        )}
      </div>
    </div>
  );
}
