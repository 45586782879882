import binocularsIllustration from '../../assets/images/illustrations/binoculars.png';
import { NavLink } from 'react-router-dom';
import React from 'react';

export function NotFoundScreen() {
  return (
    <div className="p-8 flex flex-col gap-4 w-full items-center justify-center bg-blue-50 h-screen">
      <img src={binocularsIllustration} alt="" className="w-56 grayscale" />
      <h1>Oups ! Cette page n'existe pas !</h1>
      <p className="max-w-2xl text-center text-gray-500">
        La page que vous recherchez n'existe pas :'(
      </p>
      <NavLink to="/">
        <button className="primary">Retour à l'accueil</button>
      </NavLink>
    </div>
  );
}
