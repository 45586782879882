import React from 'react';
import { SurveyStatus, SurveyStatusTagFragment } from '../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export function SurveyStatusTag({
  survey,
}: {
  survey: SurveyStatusTagFragment;
}) {
  const { t } = useTranslation();
  if (survey.isTemplate) {
    return <div className="tag purple">{t('survey.statusTag.template')}</div>;
  }

  switch (survey.status) {
    case SurveyStatus.Created:
      return (
        <div className="tag gray">{t('survey.statusTag.unpublished')}</div>
      );
    case SurveyStatus.Ready:
    case SurveyStatus.Sent:
      return <div className="tag green">{t('survey.statusTag.published')}</div>;
    case SurveyStatus.Closed:
      return <div className="tag green">{t('survey.statusTag.closed')}</div>;
    default:
      return null;
  }
}
