import React from 'react';
import {
  ProbabilityLevel,
  StakesDoubleMaterialityProbabilityArray_AnswerFragment,
  StakesDoubleMaterialityProbabilityArray_QuestionFragment,
  StakesDoubleMaterialityProbabilityArray_StakeFragment,
} from '../../../../../graphql/generated';
import { getProbabilityLabel } from '../../../../../services/DiagnosticService';
import { MatrixArray, MatrixRowType } from '../MatrixArray';
import { useTranslation } from '@hooks/useTranslation';

export function StakesDoubleMaterialityProbabilityArray({
  stakes,
  question,
  answers,
}: {
  stakes: StakesDoubleMaterialityProbabilityArray_StakeFragment[];
  question: StakesDoubleMaterialityProbabilityArray_QuestionFragment;
  answers: StakesDoubleMaterialityProbabilityArray_AnswerFragment[];
}) {
  const { translateProperty } = useTranslation();
  // columns = list of impacts or priorities, depending on materiality type
  const columns: Array<ProbabilityLevel> = [
    ProbabilityLevel.Low,
    ProbabilityLevel.Medium,
    ProbabilityLevel.High,
    ProbabilityLevel.Certain,
  ];

  // Matrix = list of rows, each row is a stake
  const matrix: MatrixRowType[] =
    (answers || [])
      .flatMap((answer) => answer.stakes)
      .reduce((acc: MatrixRowType[], stakeAnswer) => {
        if (acc.find((row) => row.stakeId === stakeAnswer?.stakeId)) {
          // Increment value in the right cell
          const stakeId = stakeAnswer?.stakeId || '';
          const level = stakeAnswer?.probability;
          const row = acc.find((row) => row.stakeId === stakeId);
          const cell = row?.values.find((cell) => cell.key === level);
          if (cell) {
            cell.value++;
          }
          return acc;
        } else {
          const stake = stakes.find(
            (stake) => stake.id === stakeAnswer?.stakeId,
          );

          return [
            ...acc,
            {
              stakeId: stakeAnswer?.stakeId || '',
              label: translateProperty(stake, 'name'),
              values: columns.map((column) => {
                const label = getProbabilityLabel(column);
                const value = stakeAnswer?.probability === column ? 1 : 0;
                return {
                  key: column,
                  label,
                  value,
                };
              }),
            },
          ];
        }
      }, [])
      .toSorted((a, b) => a.label.localeCompare(b.label)) || [];

  return (
    <MatrixArray
      title={question.stakeOptions?.materialityOptions?.probabilityLabel}
      matrix={matrix}
      columns={columns}
    />
  );
}
