import React from 'react';
import clsx from 'clsx';
import { sanitizeTypeformString } from '../../../../services/SurveyService';
import { ExportableToImage } from '../../../generic/ExportableToImage';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';

type Scoring = {
  scores: {
    label: string;
    score: number;
  }[];
};

export function VerticalScoringChart({
  scores,
  title,
  isEnps,
  isThumbnail,
}: {
  scores: number[];
  title?: string;
  isEnps?: boolean;
  isThumbnail?: boolean;
}) {
  const projectContext = useProjectContext();
  const currentUser = useCurrentUser();

  const scoring: Scoring = {
    scores: scores.map((score, index) => ({
      label: (index + 1).toString(),
      score: score,
    })),
  };

  const sumOfAllScores = scoring.scores.reduce((a, b) => a + b.score, 0);

  if (isThumbnail) {
    return (
      <div>
        <div className="flex items-stretch gap-4 justify-evenly">
          {scoring.scores.map((score) => {
            const color = isEnps ? getEnpsColor(score.label) : null;
            return (
              <ScoreBar
                key={score.label}
                label={score.label}
                score={score.score}
                sumOfAllScores={sumOfAllScores}
                color={color}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <ExportableToImage name="Scoring">
        {title && (
          <div className="font-bold mb-4">
            {sanitizeTypeformString(title, projectContext, currentUser)}
          </div>
        )}
        <div className="flex items-stretch gap-4 justify-evenly">
          {scoring.scores.map((score) => {
            const color = isEnps ? getEnpsColor(score.label) : null;
            return (
              <ScoreBar
                key={score.label}
                label={score.label}
                score={score.score}
                sumOfAllScores={sumOfAllScores}
                color={color}
              />
            );
          })}
        </div>
      </ExportableToImage>
    );
  }
}

function ScoreBar({
  label,
  score,
  sumOfAllScores,
  color,
}: {
  label: string;
  score: number;
  sumOfAllScores: number;
  color?: string | null;
}) {
  const percent = score / sumOfAllScores;
  return (
    <div className="flex flex-col w-40 gap-2">
      <div className="flex items-center gap-0.5 justify-center">
        <div
          className={clsx('text-center text-sm', percent > 0 && 'font-bold')}
        >
          {Math.round(percent * 100)}%
        </div>
        <div className="text-xs text-gray-500">({score})</div>
      </div>
      <div className="h-56 bg-gray-100 rounded-lg flex items-end">
        <div
          className={clsx(
            'w-full rounded-lg',
            color ? `bg-${color}-500` : 'bg-purple-500',
          )}
          style={{ height: `${Math.min(100, percent * 100)}%` }}
        ></div>
      </div>
      <div className="text-gray-500 text-center text-sm">{label}</div>
    </div>
  );
}

function getEnpsColor(enpsLabel: string) {
  const enpsLabelAsInt = parseInt(enpsLabel);
  if (enpsLabelAsInt <= 6) {
    return 'red';
  } else if (enpsLabelAsInt >= 9) {
    return 'green';
  } else {
    return 'gray';
  }
}
