import { JoinCompanyProjectList_ProjectFragment } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

import { CompanyItem } from './CompanyItem';

export function CompaniesList({
  companies,
}: {
  companies: JoinCompanyProjectList_ProjectFragment[];
}) {
  const { t } = useTranslation();
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th>{t('translation:home.projects.archived.title')}</th>
          <th className="w-40"></th>
        </tr>
      </thead>
      <tbody>
        {companies.map((company) => (
          <CompanyItem company={company} key={company.id} />
        ))}
      </tbody>
    </table>
  );
}
