import {
  EditCatalystMenuFragment,
  MoveDirection,
  useCatalystDeleteMutation,
  useCatalystMoveMutation,
} from '../../../graphql/generated';
import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useModal } from '../../layout/Modal';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { DeleteIcon, DownIcon, MenuIcon, UpIcon } from '../../icons';

export function EditCatalystMenu({
  catalyst,
}: {
  catalyst: EditCatalystMenuFragment;
}) {
  const modal = useModal();
  const toast = useToast();
  const [moveCatalyst] = useCatalystMoveMutation();
  const [isMoving, setIsMoving] = useState(false);
  const move = (direction: MoveDirection) => {
    setIsMoving(true);
    moveCatalyst({
      variables: {
        input: {
          id: catalyst.id,
          roadmapId: catalyst.roadmap.id,
          direction: direction,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage("L'axe stratégique a bien été déplacé");
        modal.closeModal();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
        console.error(err);
      })
      .finally(() => setIsMoving(false));
  };

  const moveUp = () => {
    move(MoveDirection.Up);
  };
  const moveDown = () => {
    move(MoveDirection.Down);
  };
  const openConfirmationModal = () => {
    modal.openModalWithComponent(<DeleteCatalystModal catalyst={catalyst} />);
  };

  return isMoving ? (
    <Loader />
  ) : (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              'text-gray-900 hover:bg-gray-50 hover:text-gray-900 p-0 w-full flex group rounded-md inline-flex items-center text-base font-medium border-transparent hover:border-transparent',
            )}
          >
            <div className="flex items-center rounded-xl p-2 w-full gap-3">
              <MenuIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500 rotate-90" />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-50 absolute bg-white top-0 left-0 transform translate-y-6 -translate-x-1/3 mt-3 px-2 max-w-md sm:px-0 lg:max-w-3xl">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex flex-col items-start p-1">
                  <button
                    className="contextual-menu-item"
                    onClick={moveUp}
                    disabled={catalyst.position === 0}
                  >
                    <UpIcon />
                    <span>Monter</span>
                  </button>
                  <button className="contextual-menu-item" onClick={moveDown}>
                    <DownIcon />
                    <span>Descendre</span>
                  </button>
                  <button
                    className="contextual-menu-item"
                    onClick={openConfirmationModal}
                  >
                    <DeleteIcon />
                    <span>Supprimer</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function DeleteCatalystModal({
  catalyst,
}: {
  catalyst: { id: string, title: string };
}) {
  const modal = useModal();
  const toast = useToast();
  const [deleteCatalyst] = useCatalystDeleteMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteCurrentCatalyst = () => {
    setIsDeleting(true);
    deleteCatalyst({
      variables: {
        id: catalyst.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage("L'axe stratégique a bien été supprimé");
        modal.closeModal();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  return (
    <div className="max-w-2xl text-center flex flex-col gap-4">
      <h2>Supprimer l’axe stratégique “{catalyst.title}” ?</h2>
      <p>
        En supprimant cet axe,
        <strong>
          toutes les actions qu'il contient seront également supprimées
        </strong>
        . Cette action est irréversible
      </p>
      <div className="flex justify-center">
        <button className="primary purple" onClick={deleteCurrentCatalyst}>
          {isDeleting && <Loader />}
          <span>Supprimer</span>
        </button>
      </div>
    </div>
  );
}
