import React from 'react';
import { useModal } from '../../../layout/Modal';
import { NavLink } from 'react-router-dom';
import { SurveyResponseModal } from '../../../project/survey/SurveyResponseModal';
import { ContextualMenu } from '../../../generic/ContextualMenu';
import { DeleteStakeholderButton } from './DeleteStakeholderButton';
import { DeleteSurveyResponseButton } from './DeleteSurveyResponseButton';
import { AddContactToStakeholdersButton } from './AddContactToStakeholdersButton';
import { EditIcon, EyeIcon, MenuIcon, OpenIcon } from '../../../icons';
import { SurveyResponseContextualMenu_SurveyResponseFragment } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export function SurveyResponseContextualMenu({
  response,
  surveyId,
  stakeholderId,
}: {
  response: SurveyResponseContextualMenu_SurveyResponseFragment;
  surveyId: string;
  stakeholderId: string | null;
}) {
  const menuIcon = (
    <MenuIcon className="flex-shrink-0 h-3.5 w-3.5 text-gray-500 rotate-90" />
  );
  const { id: responseId, respondent } = response;

  return (
    <ContextualMenu button={menuIcon}>
      <div className="flex flex-col items-start p-1">
        {!stakeholderId && respondent?.email && (
          <AddContactToStakeholdersButton
            surveyId={surveyId}
            respondent={respondent as { email: string; name?: string }}
          />
        )}
        {responseId && (
          <>
            <DisplayResponseButton {...{ responseId }} />
            <EditResponseButton {...{ responseId, surveyId }} />
            <DeleteSurveyResponseButton {...{ responseId }} />
          </>
        )}
        {!responseId && stakeholderId && (
          <DeleteStakeholderButton
            stakeholderId={stakeholderId}
            surveyId={surveyId}
          />
        )}
      </div>
    </ContextualMenu>
  );
}

function EditResponseButton({
  surveyId,
  responseId,
}: {
  surveyId: string;
  responseId: string;
}) {
  const { t } = useTranslation();
  return (
    <NavLink
      className="button contextual-menu-item"
      to={`/survey/${surveyId}/response/${responseId}`}
      target={'_blank'}
    >
      <EditIcon />
      <div>{t('survey.responses.contextualMenu.edit')}</div>
      <OpenIcon />
    </NavLink>
  );
}

function DisplayResponseButton({ responseId }: { responseId: string }) {
  const modal = useModal();
  const { t } = useTranslation();
  const openResponseAnswersModal = () => {
    if (responseId) {
      modal.openModalWithComponent(
        <SurveyResponseModal responseId={responseId} />,
        null,
        true,
        false,
        'w-8/12',
      );
    }
  };

  return (
    <button className="contextual-menu-item" onClick={openResponseAnswersModal}>
      <EyeIcon />
      <div>{t('survey.responses.contextualMenu.see')}</div>
    </button>
  );
}
