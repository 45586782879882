import {
  CompanyNameFieldsFragment,
  EnterpriseNameFieldsFragment,
  SurveyAnswerType,
  SurveyQuestionType,
  SurveyResponse_QuestionableSurveyFragment,
  SurveyResponse_QuestionFragment,
  SurveyResponse_ResponseFragment,
  SurveyStatus,
  SurveySubmitAnswerInput,
  SurveySubmitRespondentInput,
  useCompanyNameQuery,
  useReplaceSurveyResponseMutation,
  useSubmitSurveyResponseMutation,
} from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import React, { useMemo, useRef, useState } from 'react';
import surveyIllustration from '../../../assets/images/illustrations/surveys.png';
import { ProgressBar, ProgressBarStyles } from '../../generic/ProgressBar';
import { QuestionRouter } from '../questions/QuestionRouter';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import {
  deleteSurveyAnswersToLocalStorage,
  getSurveyAnswersFromLocalStorage,
  storeSurveyAnswersToLocalStorage,
} from '../../../services/SurveyService';
import { ChevronRightIcon, DeleteIcon, LogoIcon } from '../../icons';
import { Trans } from 'react-i18next';
import { useTranslation } from '@hooks/useTranslation';
import { isEnterprise } from '../../../types/companies.types';

export const enum SURVEY_RESPONSE_MODE {
  NEW_RESPONSE = 'NEW_RESPONSE', // New response submission
  CONTINUE_STARTED_RESPONSE = 'CONTINUE_STARTED_RESPONSE', // Continue a response that has been started but not submitted
  EDIT_EXISTING_RESPONSE = 'EDIT_EXISTING_RESPONSE', // Edit an existing response
  PREVIEW = 'PREVIEW', // Preview survey (no persistence)
}

export function SurveyResponse({
  survey,
  initialResponseMode,
  response,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
  initialResponseMode: SURVEY_RESPONSE_MODE;
  response?: SurveyResponse_ResponseFragment;
}) {
  const { t } = useTranslation();

  const [responseMode, setReponseMode] =
    useState<SURVEY_RESPONSE_MODE>(initialResponseMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAnswerValid, setIsAnswerValid] = useState(false);
  // const [isFirstRender, setIsFirstRender] = useState(true);

  // questionId => answer object
  const [answers, setAnswers] = useState<{
    [key: string]: SurveySubmitAnswerInput;
  }>(getInitialAnswers(survey, responseMode, response));

  const [submitResponseMutation] = useSubmitSurveyResponseMutation();
  const [replaceResponseMutation] = useReplaceSurveyResponseMutation();
  const toast = useToast();
  const modal = useModal();
  const mainContentRef = useRef<HTMLElement>(null);

  // Memoise the result to avoid re-rendering
  const questions: SurveyResponse_QuestionFragment[] = useMemo(() => {
    return (
      (survey.questions || [])
        .filter((q) => !q.parent)
        .toSorted((a, b) => a.position - b.position)
        .flatMap((question) => {
          if (question.type === 'group') {
            return (
              (question.children || []).toSorted(
                (a, b) => a.position - b.position,
              ) || []
            );
          } else {
            return [question];
          }
        }) || []
    );
  }, [survey.questions]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const isFirstQuestion = currentQuestionIndex === 0;
  const isLastQuestion = currentQuestionIndex + 1 === questions.length;

  const saveToLocalstorage = (answersToSave: {
    [key: string]: SurveySubmitAnswerInput;
  }) => {
    storeSurveyAnswersToLocalStorage(survey.id, JSON.stringify(answersToSave));
  };

  const gotoNextQuestion = () => {
    saveToLocalstorage(answers);
    setIsAnswerValid(false);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: 0,
        left: 0,
        // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        behavior: 'instant' as ScrollBehavior,
      });
    }
  };

  const gotoPreviousQuestion = () => {
    setIsAnswerValid(true);
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const setAnswer = (answer: SurveySubmitAnswerInput | null) => {
    if (answer === null) {
      // Unset answer
      const copyOfAnswers = { ...answers };
      delete copyOfAnswers[questions[currentQuestionIndex].id];
      setAnswers(copyOfAnswers);
      saveToLocalstorage(copyOfAnswers);
    } else {
      const copyOfAnswers = { ...answers };
      copyOfAnswers[questions[currentQuestionIndex].id] = answer;
      setAnswers(copyOfAnswers);
      saveToLocalstorage(copyOfAnswers);
    }
  };

  const getRespondent = (answers: {
    [key: string]: SurveySubmitAnswerInput;
  }): SurveySubmitRespondentInput | undefined => {
    // Find first 'email' answer
    const emailAnswer = Object.values(answers).find(
      (answer) => answer.type === SurveyAnswerType.Email,
    );
    if (emailAnswer && emailAnswer.identity) {
      return {
        name: emailAnswer.identity.name,
        email: emailAnswer.identity.email,
        role: emailAnswer.identity.role,
        userAgent: navigator.userAgent,
      };
    }
    return undefined;
  };

  const callMutationPromise = () => {
    // Build answers array, also remove answers that are not in the current survey (survey may have been modified)
    const answersArray: SurveySubmitAnswerInput[] = Object.values(
      answers,
    ).filter((answer: SurveySubmitAnswerInput) =>
      questions.some((question) => question.id === answer.question.id),
    );

    if (
      responseMode === SURVEY_RESPONSE_MODE.EDIT_EXISTING_RESPONSE &&
      response?.id
    ) {
      return replaceResponseMutation({
        variables: {
          id: response.id,
          input: {
            survey: {
              id: survey.id,
            },
            answers: answersArray,
            respondent: getRespondent(answers),
          },
        },
      });
    } else {
      return submitResponseMutation({
        variables: {
          input: {
            survey: {
              id: survey.id,
            },
            answers: answersArray,
            respondent: getRespondent(answers),
          },
        },
      });
    }
  };

  const resetAnswer = () => {
    setAnswers({});
    deleteSurveyAnswersToLocalStorage(survey.id);
    setCurrentQuestionIndex(0);
    setReponseMode(SURVEY_RESPONSE_MODE.NEW_RESPONSE);
  };

  const submit = () => {
    if (responseMode === SURVEY_RESPONSE_MODE.PREVIEW) {
      setIsSubmitted(true);
    } else {
      setIsSubmitting(true);

      callMutationPromise()
        .then((res) => {
          setIsSubmitting(false);
          setIsSubmitted(true);
          deleteSurveyAnswersToLocalStorage(survey.id);
        })
        .catch((e) => {
          setIsSubmitting(false);
          toast.openToastWithError(e.message);
          modal.openModalWithComponent(
            <MessageBox type={MessageBoxType.Error}>
              <div className="space-y-4 max-w-xl">
                <p>
                  Une erreur est survenue lors de l'enregistrement de vos
                  réponses. Soit à cause d'un problème technique, soit parce que
                  le questionnaire a été modifié entre temps.
                </p>
                <p>
                  Veuillez rafraichir la page et recommencer.{' '}
                  <strong>Rassurez-vous, vos réponses ont été gardées.</strong>
                </p>
                <button
                  className="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Rafraichir
                </button>
              </div>
            </MessageBox>,
            'Erreur',
          );
        });
    }
  };

  const isCurrentQuestionValid = (): boolean => {
    if (responseMode === SURVEY_RESPONSE_MODE.PREVIEW) {
      return true;
    }
    if (questions[currentQuestionIndex].required) {
      return isAnswerValid;
    }
    return true;
  };

  // Edge cases

  if (survey.status === SurveyStatus.Closed) {
    return <ClosedSurvey survey={survey} />;
  }

  if (
    responseMode !== SURVEY_RESPONSE_MODE.PREVIEW &&
    survey.status === SurveyStatus.Created
  ) {
    return <UnpublishedSurvey survey={survey} />;
  }

  if (survey.questions?.length === 0) {
    return <EmptySurvey survey={survey} />;
  }

  return (
    <div className="h-[calc(100dvh)] w-full flex flex-col items-stretch">
      <Header survey={survey} currentQuestionIndex={currentQuestionIndex}>
        {responseMode === SURVEY_RESPONSE_MODE.PREVIEW && (
          <MessageBox type={MessageBoxType.Info}>
            <p>
              <Trans i18nKey="survey.response.preview_mode">
                <strong>Preview mode:</strong> your answers won't be saved.
              </Trans>
            </p>
          </MessageBox>
        )}
        {responseMode === SURVEY_RESPONSE_MODE.EDIT_EXISTING_RESPONSE && (
          <MessageBox type={MessageBoxType.Info}>
            {t('survey.response.edit_mode')}
          </MessageBox>
        )}
      </Header>
      <div
        className="grow bg-yellow-50 overflow-y-scroll w-full"
        ref={mainContentRef as React.RefObject<HTMLDivElement>}
      >
        {isSubmitted ? (
          <SuccessMessage />
        ) : isSubmitting ? (
          <LoaderFullscreen />
        ) : (
          <QuestionRouter
            key={questions[currentQuestionIndex].id}
            question={questions[currentQuestionIndex]}
            answer={answers[questions[currentQuestionIndex].id]}
            setAnswer={setAnswer}
            setIsAnswerValid={setIsAnswerValid}
          />
        )}
      </div>
      {!isSubmitted && !isSubmitting && (
        <div className="bg-white px-4 py-2 lg:p-4 shadow-2xl shadow-gray-900 z-10 flex justify-center items-center gap-4">
          <div className="w-full">
            {responseMode ===
              SURVEY_RESPONSE_MODE.CONTINUE_STARTED_RESPONSE && (
              <button className="tertiary small" onClick={resetAnswer}>
                <DeleteIcon />
                Réinitialiser les réponses
              </button>
            )}
          </div>
          <div className="flex items-center justify-center gap-4 shrink-0">
            {!isFirstQuestion && (
              <button
                className="secondary"
                onClick={() => gotoPreviousQuestion()}
              >
                <ChevronRightIcon className="w-4 h-4 rotate-180" />
              </button>
            )}
            {isLastQuestion ? (
              <button
                className="primary green px-12"
                onClick={() => submit()}
                disabled={
                  questions[currentQuestionIndex].required &&
                  !isCurrentQuestionValid()
                }
              >
                <div>{t('survey.response.submit')}</div>
              </button>
            ) : isFirstQuestion ? (
              <button
                className="primary px-12"
                onClick={() => gotoNextQuestion()}
                disabled={
                  questions[currentQuestionIndex].required &&
                  !answers[questions[currentQuestionIndex].id]
                }
              >
                {t('survey.response.begin')}
              </button>
            ) : (
              <button
                className="primary px-12"
                onClick={() => gotoNextQuestion()}
                disabled={!isCurrentQuestionValid()}
              >
                <div>{t('survey.response.next_question')}</div>
                <ChevronRightIcon className="w-4 h-4" />
              </button>
            )}
          </div>
          <div className="w-full">
            {questions[currentQuestionIndex].required && (
              <div className="flex items-center">
                <p className="italic text-gray-500 text-sm lg:text-base">
                  {t('survey.response.mandatory_question')}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function HeaderCompanyName({
  survey,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
}) {
  const { t } = useTranslation();
  const { data } = useCompanyNameQuery({
    variables: { id: survey.company?.id || '' },
    skip: !survey.company?.id,
  });
  return (
    <div className="lg:w-4/12 w-full flex items-center gap-4">
      <div className="flex items-center gap-2">
        <div className="shrink-0 h-20 w-20 flex items-center justify-center border border-gray-100 rounded-xl p-2 shadow-sm">
          {data?.companyName?.logo ? (
            <img
              src={data.companyName.logo}
              className="shrink-0 w-18 h-auto"
              alt=""
            />
          ) : (
            <LogoIcon className="w-12 text-green-500 shrink-0" />
          )}
        </div>

        {isEnterprise<CompanyNameFieldsFragment & EnterpriseNameFieldsFragment>(data?.companyName) && data?.companyName?.coach?.company?.logo && (
          <div className="flex items-center gap-2">
            <div className="text-gray-500 font-bold">&</div>
            <div className="shrink-0 h-20 w-20 flex items-center justify-center border border-gray-100 rounded-xl p-2 shadow-sm">
              <img
                src={data.companyName.coach.company.logo}
                className="shrink-0 w-18 h-auto"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
      <div className="text-sm">
        <div className="text-gray-500">
          {t('survey.response.csr_audit_for')}
        </div>
        {data?.companyName && (
          <div className="font-bold">{data.companyName.name}</div>
        )}
      </div>
    </div>
  );
}

function Header({
  survey,
  currentQuestionIndex,
  children,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
  currentQuestionIndex: number;
  children?: React.ReactNode;
}) {
  const questionsCount =
    survey.questions?.flatMap((q) => {
      if (q.type === SurveyQuestionType.Group) {
        return q.children;
      } else {
        return q;
      }
    }).length || 0;
  return (
    <div className="bg-white px-4 lg:px-16 pt-4 lg:py-4 shadow-sm z-10 flex items-center gap-4 flex-col lg:flex-row">
      <HeaderCompanyName survey={survey} />
      <div className="lg:w-4/12 w-full flex items-center gap-1">
        <ProgressBar
          value={currentQuestionIndex + 1}
          total={questionsCount}
          style={ProgressBarStyles.BLACK_AND_WHITE}
        />
        {questionsCount > 0 && (
          <div className="text-xs text-center text-gray-500 font-bold -mt-0.5">
            {currentQuestionIndex + 1}/{questionsCount}
          </div>
        )}
      </div>
      <div className="lg:w-4/12 flex justify-end w-full lg:mb-0 mb-2">
        {children}
      </div>
    </div>
  );
}

function SuccessMessage() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center gap-4 p-8 lg:p-16">
      <img src={surveyIllustration} className="w-40" alt="" />
      <h1 className="text-center w-full max-w-lg title-h3 lg:title-h1">
        {t('survey.response.answers_saved')}
      </h1>
      <p className="text-center">{t('survey.response.thank_you')}</p>
    </div>
  );
}

function getInitialAnswers(
  survey: SurveyResponse_QuestionableSurveyFragment,
  responseMode: SURVEY_RESPONSE_MODE,
  response: SurveyResponse_ResponseFragment | undefined,
): {
  [key: string]: SurveySubmitAnswerInput;
} {
  if (
    responseMode === SURVEY_RESPONSE_MODE.EDIT_EXISTING_RESPONSE &&
    response
  ) {
    // Fetch answers from response and map them to a SurveySubmitAnswerInput object
    const answers: { [key: string]: SurveySubmitAnswerInput } = {};
    response.answers?.forEach((answer) => {
      // Copy answer and omit id
      const { id, ...answerInput } = answer;
      answers[answer.question.id] = answerInput;
    });
    return answers;
  } else {
    // Initialize answers with local storage if it exists (survey has already been started)
    return JSON.parse(getSurveyAnswersFromLocalStorage(survey.id) || '{}');
  }
}

function EmptySurvey({
  survey,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
}) {
  const { t } = useTranslation();
  return (
    <div className="h-screen w-full flex flex-col items-stretch">
      <div className="bg-white px-4 lg:px-16 py-4 shadow-sm z-10 flex items-center gap-4 flex-col lg:flex-row">
        <HeaderCompanyName survey={survey} />
      </div>
      <div className="grow bg-yellow-50 flex flex-col justify-center items-center overflow-y-scroll w-full">
        <div>
          <MessageBox type={MessageBoxType.Warning}>
            {t('survey.response.empty_survey')}
          </MessageBox>
        </div>
      </div>
    </div>
  );
}

function UnpublishedSurvey({
  survey,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
}) {
  const { t } = useTranslation();
  return (
    <div className="h-screen w-full flex flex-col items-stretch">
      <div className="bg-white px-4 lg:px-16 py-4 shadow-sm z-10 flex items-center gap-4 flex-col lg:flex-row">
        <HeaderCompanyName survey={survey} />
      </div>
      <div className="grow bg-yellow-50 flex flex-col justify-center items-center overflow-y-scroll w-full">
        <div>
          <MessageBox type={MessageBoxType.Warning}>
            {t('survey.response.unpublished_survey')}
          </MessageBox>
        </div>
      </div>
    </div>
  );
}

function ClosedSurvey({
  survey,
}: {
  survey: SurveyResponse_QuestionableSurveyFragment;
}) {
  const { t } = useTranslation();
  return (
    <div className="h-screen w-full flex flex-col items-stretch">
      <div className="bg-white px-4 lg:px-16 py-4 shadow-sm z-10 flex items-center gap-4 flex-col lg:flex-row">
        <HeaderCompanyName survey={survey} />
      </div>
      <div className="grow bg-yellow-50 flex flex-col justify-center items-center overflow-y-scroll w-full">
        <div>
          <MessageBox type={MessageBoxType.Warning}>
            {t('survey.response.closed_survey')}
          </MessageBox>
        </div>
      </div>
    </div>
  );
}
