import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useFirebaseAuth } from './FirebaseProvider';

// undefined => loading
// null => logged out
// firebaseAuth.User => logged in
type AuthContextType = User | null | undefined;

const AuthContext = createContext<AuthContextType>(undefined);

export const useCurrentAuth = () => useContext(AuthContext);

export function CurrentAuthProvider({ children }: { children: ReactNode }) {
  const firebaseAuth = useFirebaseAuth();
  const [currentAuth, setCurrentAuth] = useState<AuthContextType>(undefined);

  useEffect(() => {
    return onAuthStateChanged(firebaseAuth, (authUser) => {
      if (authUser) {
        setCurrentAuth(authUser);
      } else {
        setCurrentAuth(null);
      }
    });
  }, [firebaseAuth]);

  return (
    <AuthContext.Provider value={currentAuth}>{children}</AuthContext.Provider>
  );
}
