export function sortByNullablePosition(
  aPosition: number | null | undefined,
  bPosition: number | null | undefined,
) {
  const newA =
    aPosition !== undefined && aPosition !== null ? aPosition + 1 : -1;
  const newB =
    bPosition !== undefined && bPosition !== null ? bPosition + 1 : 1;
  return newA - newB;
}

// Tooltips must have a unique id across the app
let tooltipLastId = 0;
export function getTooltipUniqueId(tooltipName: string) {
  tooltipLastId++;
  return `tooltip-${tooltipName}-${tooltipLastId}`;
}

export function convertFileToBase64(file: Blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
