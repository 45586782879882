import { useChecklistQuery } from '../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../layout/Loader';
import { CreateTaskGroupButton } from './CreateTaskGroupButton';
import { TaskGroup } from './TaskGroup';
import { sortByNullablePosition } from '../../services/UtilsService';

export function Checklist({ checklistId }: { checklistId: string }) {
  const checkListQuery = useChecklistQuery({
    variables: {
      id: checklistId,
    },
  });

  if (checkListQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!checkListQuery.data?.getChecklist) {
    return null;
  }

  return (
    <div className="space-y-4">
      {(checkListQuery.data?.getChecklist?.taskGroups || [])
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((taskGroup) => (
          <TaskGroup key={taskGroup.id} taskGroup={taskGroup} />
        ))}
      <CreateTaskGroupButton checklist={checkListQuery.data?.getChecklist} />
    </div>
  );
}
