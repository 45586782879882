import { getPublicLinkToSurvey } from '../../services/SurveyService';
import React from 'react';
import { CopyableUrlInput } from '../generic/CopyableUrlInput';
import { useModal } from '../layout/Modal';
import messageIllustration from '../../assets/images/illustrations/diagnostic/message.png';
import { ShareSurveyModal_SurveyFragment } from '../../graphql/generated';

export function ShareSurveyModal({
  survey,
}: {
  survey: ShareSurveyModal_SurveyFragment;
}) {
  const modal = useModal();
  return (
    <div className="flex flex-col items-center space-y-8">
      <img src={messageIllustration} alt="" className="w-16" />
      <h2>Partager le lien du questionnaire</h2>
      <p>Partagez le questionnaire via email ou via vos canaux habituels</p>
      <div className="w-full">
        <CopyableUrlInput urlToCopy={getPublicLinkToSurvey(survey.id)} />
      </div>
      <button
        className="link text-gray-500 text-sm"
        onClick={() => modal.closeModal()}
      >
        Fermer
      </button>
    </div>
  );
}
