import React from 'react';
import { impersonate } from '../../services/ImpersonateService';
import { useFirebaseApp } from '../../providers/FirebaseProvider';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export const ImpersonateButton: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const firebaseApp = useFirebaseApp();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const handleImpersonate = async (firebaseApp: any, userId: string) => {
    try {
      await impersonate(firebaseApp, userId);
      navigate('/');
      await apolloClient.resetStore();
    } catch (error) {
      console.error('Impersonation failed', error);
      return false;
    }
  };
  const { t } = useTranslation();

  return (
    <button
      onClick={() => {
        void handleImpersonate(firebaseApp, userId);
      }}
    >
      {t('admin:users.impersonateUser')}
    </button>
  );
};
