import {
  AnswerThemeTag_AnswerThemeFragment,
  useAnswerThemeTag_AnswerThemeDeleteMutation,
} from '../../../graphql/generated';
import clsx from 'clsx';
import { useState } from 'react';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import { AnalysisThemeModal } from './AnalysisThemeModal';
import { XIcon } from '../../icons';

export default function AnswerThemeTag({
  answerTheme,
  deleteCallback,
}: {
  answerTheme: AnswerThemeTag_AnswerThemeFragment;
  deleteCallback?: () => void;
}) {
  const modal = useModal();
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [answerThemeDeleteMutation] =
    useAnswerThemeTag_AnswerThemeDeleteMutation();
  const deleteSurveyTheme = () => {
    setIsDeleting(true);
    answerThemeDeleteMutation({ variables: { id: answerTheme.id } })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        toast.openToastWithMessage('Thème supprimé');
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .finally(() => setIsDeleting(false));
  };

  return (
    <div className={clsx('tag', answerTheme.analysisTheme.color)}>
      <span
        title={answerTheme.analysisTheme.name}
        className="cursor-pointer max-w-[10rem] truncate text-ellipsis font-semibold"
        onClick={() =>
          modal.openModalWithComponent(
            <AnalysisThemeModal
              analysisThemeId={answerTheme.analysisTheme.id}
            />,
            'Thème',
            true,
            true,
            'w-8/12',
          )
        }
      >
        {answerTheme.analysisTheme.name}
      </span>
      {isDeleting ? (
        <Loader className="w-3 h-3" />
      ) : (
        <XIcon
          className="w-3 h-3 cursor-pointer opacity-60 hover:opacity-100"
          onClick={deleteSurveyTheme}
        />
      )}
    </div>
  );
}
