import React, { useState } from 'react';
import clsx from 'clsx';
import { ImportStakeholderListMenu } from './ImportStakeholderListMenu';
import { AddNewStakeholderButton } from './AddNewStakeholderButton';
import { DeleteStakeholderButton } from '../surveyTrackingResponses/actions/DeleteStakeholderButton';
import {
  ManageStakeholderListDocument,
  RecipientsForm_SurveyFragment,
  StakeholderEditRow_StakeholderFragment,
  SurveyWaitingStakeholderDocument,
  useStakeholderEditRow_StakeholderCreateMutation,
} from '../../../graphql/generated';
import { NumberCircle, NumberCircleColor } from '../../generic/NumberCircle';
import { Loader } from '../../generic/Loader';
import { ErrorIcon } from '../../icons';
import Cookies from 'js-cookie';
import { ITEMS_PER_PAGE_COOKIE } from '../surveyTrackingResponses/responsesList/SurveyResponsesTable';

export function RecipientsForm({
  survey,
}: {
  survey: RecipientsForm_SurveyFragment;
}) {
  const sortedArrayOfRecipients = survey.recipients?.toSorted((a, b) => {
    if (a.firstName === '' || a.lastName === '' || a.email === '') {
      return 1;
    }
    if (b.firstName === '' || b.lastName === '' || b.email === '') {
      return -1;
    }
    return a.firstName.localeCompare(b.firstName);
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="space-y-1">
        {sortedArrayOfRecipients?.map((recipient, index) => (
          <StakeholderEditRow
            key={recipient.id}
            stakeholder={recipient}
            index={index}
            surveyId={survey.id}
          />
        ))}
      </div>
      <div className="flex items-center gap-2">
        <AddNewStakeholderButton survey={survey} />
        <ImportStakeholderListMenu survey={survey} />
      </div>
    </div>
  );
}

function StakeholderEditRow({
  stakeholder,
  surveyId,
  index,
}: {
  stakeholder: StakeholderEditRow_StakeholderFragment;
  surveyId: string;
  index: number;
}) {
  const [createStakeholder] = useStakeholderEditRow_StakeholderCreateMutation();

  const [firstName, setFirstName] = useState(stakeholder.firstName || '');
  const [lastName, setLastName] = useState(stakeholder.lastName || '');
  const [email, setEmail] = useState(stakeholder.email || '');

  const [isEmailValid, setIsEmailValid] = useState(true);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateStakeholder = () => {
    setIsLoading(true);
    createStakeholder({
      variables: {
        input: {
          stakeholderSurvey: {
            survey: {
              id: surveyId,
            },
            id: stakeholder.id,
            email: email,
            firstName: firstName,
            lastName: lastName,
          },
        },
      },
      refetchQueries: [
        {
          query: ManageStakeholderListDocument,
          variables: {
            id: surveyId,
          },
        },
        {
          query: SurveyWaitingStakeholderDocument,
          variables: {
            surveyId,
            paging: {
              first: parseInt(Cookies.get(ITEMS_PER_PAGE_COOKIE) || '20', 10),
            },
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const saveCurrentRow = () => {
    updateStakeholder();
    setIsEditing(false);
  };

  const validateFormAndSave = () => {
    const regex = /\S+@\S+\.\S+/;
    const isEmailValid = regex.test(email);
    setIsEmailValid(isEmailValid);
    if (isEmailValid) {
      saveCurrentRow();
    }
  };

  return (
    <div className="flex items-center gap-4">
      <NumberCircle
        number={index + 1}
        size={8}
        color={NumberCircleColor.Purple}
      />
      <div className="w-full relative">
        <input
          type="email"
          required={true}
          className={clsx('form-input-text', !isEmailValid && 'bg-red-50')}
          placeholder="@ Email"
          name="email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setIsEmailValid(true);
            setIsEditing(true);
          }}
        />
        {!isEmailValid && (
          <ErrorIcon className="w-4 h-4 absolute right-2 top-0 bottom-0 text-red-700 m-auto" />
        )}
      </div>
      <input
        type="text"
        required={true}
        placeholder="Prénom"
        className="form-input-text"
        name="firstName"
        value={firstName}
        onChange={(event) => {
          setFirstName(event.target.value);
          setIsEditing(true);
        }}
      />
      <input
        type="text"
        required={true}
        placeholder="Nom"
        className="form-input-text"
        name="lastName"
        value={lastName}
        onChange={(event) => {
          setLastName(event.target.value);
          setIsEditing(true);
        }}
      />
      <div className="w-96 flex items-center justify-start">
        {isLoading ? (
          <div className="px-4">
            <Loader className="w-6 h-6" />
          </div>
        ) : isEditing ? (
          <button
            className="tertiary small"
            title="Enregistrer"
            onClick={(event) => {
              event.preventDefault();
              validateFormAndSave();
            }}
          >
            Enregistrer
          </button>
        ) : (
          <DeleteStakeholderButton
            stakeholderId={stakeholder.id}
            surveyId={surveyId}
          />
        )}
      </div>
    </div>
  );
}
