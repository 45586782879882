import React, { useEffect, useRef } from 'react';
import { LeftNav } from '../nav/LeftNav/LeftNav';
import { RightDrawer, RightDrawerProvider } from './RightDrawer';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { Modal } from './Modal';
import { Toast } from './Toast';
import { ProjectContextProvider } from '../../providers/ProjectContextProvider';
import clsx from 'clsx';
import { LeftDrawer, LeftDrawerProvider } from './LeftDrawer';
import { useNavContext } from '../../providers/NavContextProvider';

export const ProjectLayout = () => {
  const location = useLocation();
  const mainContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    mainContentRef.current &&
      mainContentRef.current.scrollTo({
        top: 0,
        left: 0,
        // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        behavior: 'instant' as ScrollBehavior,
      });
  }, [location]);

  return (
    <ProjectContextProvider>
      <RightDrawerProvider>
        <LeftDrawerProvider>
          <LeftNav />
          <LeftDrawer />
          <ProInnerLayout mainContentRef={mainContentRef} />
          <RightDrawer />
          <Modal />
          <Toast />
        </LeftDrawerProvider>
      </RightDrawerProvider>
    </ProjectContextProvider>
  );
};

function ProInnerLayout({
  mainContentRef,
}: {
  mainContentRef: React.RefObject<HTMLElement>;
}) {
  const location = useLocation();
  const navContext = useNavContext();
  return (
    <main
      ref={mainContentRef}
      className={clsx(
        'flex flex-col h-screen overflow-y-auto bg-white transition-all duration-300 ease-in-out',
        navContext?.isNavCollapsed ? 'pl-[0px]' : 'pl-[230px] xl:pl-[280px]',
      )}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </main>
  );
}
