import React from 'react';
import {
  SurveyQuestionRow_SurveyQuestionFragment,
  SurveyQuestionType,
  useSurveyQuestionsQuery,
} from '../../../graphql/generated';
import { SurveyQuestionIcon } from '../../survey/SurveyQuestionIcon';
import { useModal } from '../../layout/Modal';
import { StakeTag, StakeTagStyle } from '../../stake/StakeTag';
import { QuestionAnswerThemesTags } from '../analysisThemes/QuestionAnswerThemesTags';
import { SurveyQuestionModal } from './SurveyQuestionModal';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { ChevronRightIcon } from '../../icons';

export function SurveyQuestions({ surveyId }: { surveyId: string }) {
  const query = useSurveyQuestionsQuery({
    variables: {
      id: surveyId,
    },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.survey) {
    return null;
  }

  const survey = query.data.survey;

  let questionIndex = 0;
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th>Question</th>
          <th>Enjeux / thèmes associés</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {survey.questions &&
          survey.questions
            .filter(
              (question) => question.type !== SurveyQuestionType.Statement,
            )
            .toSorted((a, b) => a.position - b.position)
            .map((question) => {
              if (question.type !== SurveyQuestionType.Group) {
                return (
                  <SurveyQuestionRow
                    key={question.id}
                    question={question}
                    index={questionIndex++}
                  />
                );
              } else {
                return question.children
                  ?.filter(
                    (question) =>
                      question.type !== SurveyQuestionType.Statement,
                  )
                  .toSorted((a, b) => a.position - b.position)
                  .map((subQuestion) => (
                    <SurveyQuestionRow
                      key={subQuestion.id}
                      question={subQuestion}
                      index={questionIndex++}
                    />
                  ));
              }
            })}
      </tbody>
    </table>
  );
}

function SurveyQuestionRow({
  question,
  index,
}: {
  question: SurveyQuestionRow_SurveyQuestionFragment;
  index: number;
}) {
  const modal = useModal();
  const openQuestionModal = () => {
    modal.openModalWithComponent(
      <SurveyQuestionModal questionId={question.id} />,
      null,
      true,
      false,
      'w-8/12',
    );
  };

  return (
    <tr>
      <td>
        <div className="flex items-center gap-4 font-semibold">
          <div className="text-gray-300">{index + 1}</div>
          <SurveyQuestionIcon questionType={question.type} />
          <div
            className="flex flex-col gap-1 cursor-pointer"
            onClick={openQuestionModal}
          >
            {question.parent && (
              <div className="text-gray-500 font-normal text-xs">
                <div>{question.parent.title}</div>
              </div>
            )}
            <div>{question.title}</div>
          </div>
        </div>
      </td>
      <td className="w-1/4 space-y-2">
        <StakeTag
          stake={question.stake}
          pillar={question.pillar}
          style={StakeTagStyle.GRAYED}
          disableDetailModalOpening={true}
        />
        <QuestionAnswerThemesTags question={question} />
      </td>
      <td>
        <div className="flex justify-end">
          <button className="primary purple small" onClick={openQuestionModal}>
            <ChevronRightIcon />
          </button>
        </div>
      </td>
    </tr>
  );
}
