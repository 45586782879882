import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface MatrixContextType {
  stakeholderSegments: string[];
  setStakeholderSegments: Dispatch<SetStateAction<string[]>>;
}

interface MatrixProviderProps {
  children: ReactNode;
  initialStakeholderSegments: string[];
}

const MatrixContext = createContext<MatrixContextType | undefined>(undefined);

export const MatrixProvider: FC<MatrixProviderProps> = ({
  children,
  initialStakeholderSegments,
}) => {
  const [stakeholderSegments, setStakeholderSegments] = useState<string[]>(
    initialStakeholderSegments,
  );

  return (
    <MatrixContext.Provider
      value={{ stakeholderSegments, setStakeholderSegments }}
    >
      {children}
    </MatrixContext.Provider>
  );
};

export const useMatrix = (): MatrixContextType => {
  const context = useContext(MatrixContext);
  if (!context) {
    throw new Error('useMatrix must be used within a MatrixProvider');
  }
  return context;
};
