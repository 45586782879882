import React, { useState } from 'react';
import { EyeIcon, EyeStrikeThroughIcon } from '../../icons';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useTranslation } from "@hooks/useTranslation";

export default function PasswordInput({
  value,
  onChangeCallback,
  placeholder,
}: {
  value: string;
  onChangeCallback: (value: string) => void;
  placeholder?: string;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const { t } = useTranslation();
  function validatePassword(password: string): string {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return t('nav:register.weak_credentials');
    }
    // if (!hasUpperCase) {
    //   return 'Password must contain at least one uppercase letter.';
    // }
    // if (!hasLowerCase) {
    //   return 'Password must contain at least one lowercase letter.';
    // }
    // if (!hasNumber) {
    //   return 'Password must contain at least one number.';
    // }
    // if (!hasSpecialChar) {
    //   return 'Password must contain at least one special character.';
    // }
    return '';
  };

  function handlePasswordChange(newPassword: string) {
    setValidationMessage(validatePassword(newPassword));
    onChangeCallback(newPassword);
  }
  return (
    <>
    <div className="relative">
      {showPassword ? (
        <button
          className="absolute top-3 right-4 unstyled"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(false);
          }}
        >
          <EyeStrikeThroughIcon className="text-gray-500 hover:text-gray-900" />
        </button>
      ) : (
        <button
          className="absolute top-1/2 bottom-1/2 right-4 unstyled"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(true);
          }}
        >
          <EyeIcon className="text-gray-500 hover:text-gray-900" />
        </button>
      )}
      <input
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        autoComplete="current-password"
        value={value}
        onChange={(e) => handlePasswordChange(e.target.value)}
        required
      />

    </div>
  {validationMessage && (
    <MessageBox type={MessageBoxType.Error}>
      {validationMessage}
    </MessageBox>
  )}</>
  );
}


