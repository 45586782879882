import React, { useState } from 'react';
import { GradientScoreSlider } from './GradientRangeSlider';
import { useTranslation } from '@hooks/useTranslation';
import { Field, Label } from '@headlessui/react';
import { CheckIcon } from '../../icons';
import { Checkbox, CheckboxColors } from '../../form/Checkbox';

export function ScoreRangeSlider({
  score,
  setScore,
  referenceScore,
}: {
  score: number | null;
  setScore: (score: number | null) => void;
  referenceScore: number | null | undefined;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(score);
  const [computedScoreSynced, setComputedScoreSynced] = useState(
    score === null,
  );

  // Trigger setScore only when score changed
  const updateScoreIfNeeded = (newScore: number) => {
    if (newScore !== score) {
      setScore(newScore);
      setValue(newScore);
    }
  };
  return (
    <div className="bg-white py-4 space-y-4 divide-y divide-gray-100 border border-gray-100 rounded-lg shadow-sm">
      <div className="flex items-center justify-between px-4">
        <div className="font-bold text-sm">
          {t('components.generic.ScoreRangeSlider.score')}
        </div>
        <div className="border border-gray-100 p-2 flex items-center rounded-md shadow-sm">
          <input
            type="number"
            className="form-input-text border-0 shadow-none w-12 p-0 mr-6 no-arrow"
            value={value ?? ''}
            onChange={(e) => setValue(parseFloat(e.target.value))}
            onBlur={(e) => updateScoreIfNeeded(parseFloat(e.target.value))}
          />
          <div className="text-sm border-l border-gray-100 px-2 text-gray-500">
            {t('components.generic.ScoreRangeSlider.computed')} :{' '}
            {referenceScore
              ? referenceScore.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                })
              : '-'}
          </div>
        </div>
      </div>
      <GradientScoreSlider
        scoreOn1To4Scale={value || referenceScore || 0}
        onChange={(newScore) => {
          setComputedScoreSynced(false);
          setValue(newScore);
        }}
        onBlur={updateScoreIfNeeded}
        referenceScore={referenceScore}
      />

      <div className="flex px-4 pt-4">
        <Field className="flex items-center gap-2">
          <Checkbox
            checked={computedScoreSynced}
            onChange={(checked) => {
              setComputedScoreSynced(checked);
              if (checked) {
                setValue(
                  referenceScore ? parseFloat(referenceScore.toFixed(1)) : null,
                );
                setScore(null);
              } else {
                setScore(referenceScore ?? 0);
              }
            }}
            color={CheckboxColors.Gray}
          >
            <CheckIcon />
          </Checkbox>
          <Label className="form-input-label text-sm">
            {t('components.generic.ScoreRangeSlider.keepComputedScoreSynced')}
          </Label>
        </Field>
      </div>
    </div>
  );
}
