import React from 'react';
import { Loader } from '../generic/Loader';
import { ProgressBar } from '../generic/ProgressBar';
import {
  SurveyProgressBar_SurveyFragment,
  useSurveyProgressBarQuery,
} from '../../graphql/generated';

export function SurveyProgressBar({
  survey,
}: {
  survey: SurveyProgressBar_SurveyFragment;
}) {
  // Fetch Recipients data
  const { data, loading } = useSurveyProgressBarQuery({
    variables: { id: survey.id },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  }
  if (data?.survey) {
    return (
      <ProgressBar
        value={data.survey.responses?.length || 0}
        total={data.survey.recipients?.length || 0}
        targetThreshold={survey.targetThreshold || 0}
        displayLabel={false}
        displayPercentage={true}
        displayCounts={true}
      />
    );
  }

  return null;
}
