import {
  IroContextualMenu_IroFragment,
  IroEvaluation_IroDocument,
  ProIroSummaryScreenDocument,
  useDeleteIroMutation,
} from '../../../../../graphql/generated';
import { useModal } from '../../../../layout/Modal';
import { useToast } from '../../../../layout/Toast';
import React, { useState } from 'react';
import { ContextualMenu } from '../../../../generic/ContextualMenu';
import { Loader } from '../../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { UpdateIroModal } from '../form/update/UpdateIroModal';
import { DeleteIcon, EditIcon } from '../../../../icons';
import { ConfirmationModal } from '../../../../modals/ConfirmationModal';
import { useReferential } from '../../../context/ReferentialContext';

export function IroContextualMenu({
  iro,
}: {
  iro: IroContextualMenu_IroFragment;
}) {
  const modal = useModal();
  const toast = useToast();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { referential: referentialId } = useReferential();
  const [deleteIro] = useDeleteIroMutation({
    refetchQueries: [
      {
        query: IroEvaluation_IroDocument,
        variables: {
          stakeId: iro?.stake?.id,
        },
      },
      {
        query: ProIroSummaryScreenDocument,
        variables: {
          id: referentialId,
        },
      },
    ],
  });
  const preventAccidentalDeletion = () => {
    modal.openModalWithComponent(
      <ConfirmationModal
        callbackIfConfirmed={handleDelete}
        message={t('iro.delete.confirm')}
      />,
      t('global:confirmation'),
    );
  };

  const handleDelete = () => {
    setIsDeleting(true);
    void deleteIro({
      variables: { id: iro.id },
    })
      .catch((err) => {
        console.error(err);
        toast.openToastWithError(t('toast:iro.delete.error'));
        return;
      })
      .then(() => {
        toast.openToastWithMessage(t('toast:iro.delete.success'));
        modal.closeModal();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };
  const openIroModal = () => {
    modal.openModalWithComponent(
      <UpdateIroModal iro={iro} />,
      t('iro.update.modal.title'),
      false,
      false,
      undefined,
      'p-0',
    );
  };

  if (isDeleting) {
    return <Loader />;
  }

  return (
    <ContextualMenu>
      <>
        <button className="contextual-menu-item" onClick={openIroModal}>
          <EditIcon />
          <span>{t('iro.update.button')}</span>
        </button>
        <button
          className="contextual-menu-item"
          onClick={preventAccidentalDeletion}
        >
          <DeleteIcon />
          <span>{t('iro.delete.button')}</span>
        </button>
      </>
    </ContextualMenu>
  );
}
