import React from 'react';
import { ScoreRangeSlider } from '../../../../generic/form/ScoreRangeSlider';
import {
  ImpactScoreRangeSliderFragment,
  StakeIroDetailDocument,
  useImpactScoreRangeSliderMutation,
} from '../../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../layout/Loader';

export function ImpactScoreRangeSlider({
  stake,
}: {
  stake: ImpactScoreRangeSliderFragment;
}) {
  const [updateStakeMutation] = useImpactScoreRangeSliderMutation();
  const [loading, setLoading] = React.useState(false);

  const setScore = (newScore: number | null) => {
    setLoading(true);
    void updateStakeMutation({
      variables: {
        input: {
          id: stake.id,
          impactMaterialityScore: newScore,
        },
      },
      refetchQueries: [
        { query: StakeIroDetailDocument, variables: { stakeId: stake.id } },
      ],
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute w-full h-full flex items-center bg-white z-50 border border-gray-100 rounded-lg shadow-sm">
          <LoaderFullscreen />
        </div>
      )}
      <div className={loading ? 'invisible' : 'visible'}>
        <ScoreRangeSlider
          score={stake.persistedImpactMaterialityScore ?? null}
          setScore={setScore}
          referenceScore={stake.computedImpactMaterialityScore}
        />
      </div>
    </div>
  );
}
