import React from 'react';
import { StarIcon, ThumbDownIcon, ThumbUpIcon, WarningIcon } from '../../icons';

export function IroIcon({ iroTypename }: { iroTypename: string }) {
  switch (iroTypename) {
    case 'RiskIro':
      return <WarningIcon className="shrink-0" />;
    case 'OpportunityIro':
      return <StarIcon className="shrink-0" />;
    case 'NegativeImpactIro':
      return <ThumbDownIcon className="shrink-0" />;
    case 'PositiveImpactIro':
      return <ThumbUpIcon className="shrink-0" />;
    default:
      return <WarningIcon className="shrink-0" />;
  }
}
