import React, { ReactNode } from 'react';
import { MinusIcon, PlusIcon } from '../../icons';
import clsx from 'clsx';

export function NumberInput({
  value,
  setValue,
  icon,
  className,
  iconClassName = 'text-gray-300',
  step = 1,
  numberOfDecimals = 0,
}: {
  value: number | null | undefined;
  setValue: (value: number) => void;
  className?: string;
  iconClassName?: string;
  step?: number;
  numberOfDecimals?: number;
  icon?: ReactNode;
}) {
  const decrement = () => {
    if (setValue) {
      setValue(Math.max((value || 0) - step, 0));
    }
  };

  const increment = () => {
    if (setValue) {
      setValue((value || 0) + step);
    }
  };

  return (
    <div className="bg-white rounded-md border border-gray-100 p-0 flex items-stretch shadow-sm">
      <label className="flex flex-grow items-center gap-1 pl-2 pr-2 py-1.5">
        <div className={iconClassName}>{icon}</div>
        <input
          className={clsx(
            'border-0 bg-transparent p-0 w-12 text-center text-gray-900',
            'no-arrow focus:border-none focus:ring-0',
            className,
          )}
          type="number"
          step={step}
          min="0"
          value={(value || 0).toFixed(numberOfDecimals)}
          onChange={(e) => setValue(parseFloat(e.target.value) || 0)}
        />
      </label>
      <div className="border-l border-gray-100 flex items-center justify-center">
        <button
          className="tertiary p-0 w-8 h-full rounded-none"
          onClick={decrement}
        >
          <MinusIcon className="w-3 h-3 text-gray-500" />
        </button>
      </div>
      <div className="border-l border-gray-100 flex items-center justify-center">
        <button
          className="tertiary p-0 w-8 h-full rounded-l-none rounded-r-md"
          onClick={increment}
        >
          <PlusIcon className="w-3 h-3 text-gray-500" />
        </button>
      </div>
    </div>
  );
}
