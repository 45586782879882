import React from 'react';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import { DownloadIcon } from '../icons';

export function DownloadFileButton({
  url,
  label,
  openInNewTab,
  className,
}: {
  url: string | null | undefined;
  label: string;
  openInNewTab: boolean;
  className?: string;
}) {
  if (url) {
    const target = openInNewTab ? '_blank' : '_self';
    return (
      <div className="flex">
        <a
          href={url}
          target={target}
          rel="noopener noreferrer"
          className={clsx(className, 'button primary')}
        >
          <DownloadIcon />
          <span>{label}</span>
        </a>
      </div>
    );
  } else {
    return (
      <div data-tooltip-content="Bientôt disponible">
        <Tooltip className="tooltip" />
        <button className={clsx('primary', className)} disabled={true}>
          <DownloadIcon />
          <span>{label}</span>
        </button>
      </div>
    );
  }
}
