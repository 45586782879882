import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export enum AdminNavRoutes {
  Users = '/admin/users',
  User = '/admin/users/:id',
  Companies = '/admin/companies',
}

type AdminNavProps = {
  activeSection?: AdminNavRoutes;
};

export const AdminNav = ({ activeSection }: AdminNavProps) => {
  const { t } = useTranslation();

  return (
    <nav className={clsx('flex space-x-2')} aria-label="Tabs">
      <NavLink
        to={AdminNavRoutes.Companies}
        className={({ isActive }) =>
          clsx(
            'cursor-pointer flex items-center gap-1.5 whitespace-nowrap p-2 text-sm',
            isActive || activeSection === AdminNavRoutes.Companies
              ? 'rounded-lg bg-gray-900 text-white font-bold hover:text-white'
              : 'text-gray-500',
          )
        }
      >
        {t('admin:nav.companies')}
      </NavLink>
      <NavLink
        to={AdminNavRoutes.Users}
        className={({ isActive }) =>
          clsx(
            'cursor-pointer flex items-center gap-1.5 whitespace-nowrap p-2 text-sm',
            isActive || activeSection === AdminNavRoutes.Users
              ? 'rounded-lg bg-gray-900 text-white font-bold hover:text-white'
              : 'text-gray-500',
          )
        }
      >
        {t('admin:nav.users')}
      </NavLink>
    </nav>
  );
};
