import React from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useCompanySettings_CompanyQuery } from '../../../../graphql/generated';
import { CompanySettingsCalculators } from './CompanySettingsCalculators';
import { CompanyReportsSettings } from './CompanyReportsSettings';

export function CompanySettings({ companyId }: { companyId: string }) {
  const companyQuery = useCompanySettings_CompanyQuery({
    variables: {
      id: companyId,
    },
  });

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      {companyQuery.data?.enterprise && (
        <>
          <CompanySettingsCalculators company={companyQuery.data.enterprise} />
          <CompanyReportsSettings company={companyQuery.data.enterprise} />
        </>
      )}
    </div>
  );
}
