import React from 'react';
import clsx from 'clsx';
import { UserProfileInitials_UserFragment } from '../../graphql/generated';

interface UserProfileInitialsProps {
  user: UserProfileInitials_UserFragment;
  size?: 'normal' | 'mini';
}

export function UserProfileInitials({
  user,
  size = 'normal',
}: UserProfileInitialsProps) {
  return (
    <div
      className={clsx(
        'rounded-full bg-purple-500 flex items-center justify-center text-white',
        'font-extrabold shrink-0 text-base',
        size === 'mini' ? 'w-8 h-4 lg:h-8' : 'w-8 h-8 lg:w-16 lg:h-16',
      )}
    >
      <div>
        {user.firstName.charAt(0).toUpperCase()}
        {user.lastName.charAt(0).toUpperCase()}
      </div>
    </div>
  );
}
