import mixpanel from 'mixpanel-browser';
import { UserContextType } from '../providers/CurrentUserProvider';

export enum MixpanelEvents {
  PageView = 'Page View',
}

export function identifyUserToMixpanel(user: UserContextType) {
  try {
    if (user) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $name: `${user.firstName} ${user.lastName}`,
        $company: `${user.company?.name as string}`,
        $type: `${user.company?.__typename as string}`,
        $roles: `${user.auth?.roles.join(',') as string}`,
        $last_login: new Date(),
      });
    } else {
      mixpanel.reset();
    }
  } catch (error) {
    // console.error(error);
  }
}

export function trackEvent(event: MixpanelEvents, title: string) {
  try {
    mixpanel.track(event, { title });
  } catch (error) {
    // console.error(error);
  }
}
