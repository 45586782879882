import React, { FC, PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

type TooltipProps = {
  id: string;
  content?: string;
  tooltipChildren?: React.ReactNode;
} & PropsWithChildren;

export const Tooltip: FC<TooltipProps> = ({
  id,
  content,
  tooltipChildren,
  children,
}) => {
  return (
    <div data-tooltip-content={content || null} data-tooltip-id={id}>
      <ReactTooltip id={id} className="tooltip">
        {tooltipChildren}
      </ReactTooltip>
      {children}
    </div>
  );
};
