import {
  MaterialityTypes,
  StakesMaterialityChart_StakeFragment,
  useSurveyQuestionsAnalysisQuery,
} from '../../../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../../layout/Loader';
import React from 'react';
import { StakesQualityChart } from './StakesQualityChart';

export const StakeQuestionsQuality = ({
  questionIds,
  stakes,
  materialityType,
}: {
  questionIds: string[];
  stakes: StakesMaterialityChart_StakeFragment[];
  materialityType: MaterialityTypes;
}) => {
  const surveyQuestionsAnalysisQuery = useSurveyQuestionsAnalysisQuery({
    variables: {
      ids: questionIds,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (surveyQuestionsAnalysisQuery.loading) {
    return <LoaderFullscreen />;
  }

  // Consolidate all answers from all questions
  const answers =
    surveyQuestionsAnalysisQuery.data?.surveyQuestions.flatMap(
      (question) => question.answers || [],
    ) || [];

  return (
    <StakesQualityChart
      stakes={stakes}
      answers={answers}
      materialityType={materialityType}
    />
  );
};
