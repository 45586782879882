export function getFrTimeFromISOString(isoString: string): string {
  const date = new Date(isoString);
  return date.toLocaleTimeString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getFrDateFromISOString(isoString: string): string {
  const date = new Date(isoString);
  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}

export function isDateInFuture(completionDate: string): boolean {
  if (!completionDate || completionDate === '') {
    return true;
  } else {
    const now = new Date();
    const date = new Date(completionDate);
    return date > now;
  }
}
