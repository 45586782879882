import React, { createContext, ReactNode, useContext } from 'react';
import { useCurrentAuth } from './CurrentAuthProvider';
import {
  CurrentUserFieldsFragment,
  useCurrentUserQuery,
} from 'graphql/generated';
import { MessageBox, MessageBoxType } from '../components/layout/MessageBox';
import { identifyUserToMixpanel } from '../services/MixpanelService';

// undefined => loading
// null => not found
// CurrentUserFieldsFragment => logged in
export type UserContextType = CurrentUserFieldsFragment | null | undefined;

const UserContext = createContext<UserContextType>(undefined);

export const useCurrentUser = () => useContext(UserContext);

export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const auth = useCurrentAuth();
  const currentUserQuery = useCurrentUserQuery({
    variables: {},
    skip: !auth,
    fetchPolicy: 'cache-and-network',
  });

  if (currentUserQuery.error) {
    console.error(currentUserQuery.error);
    return (
      <div className="h-screen w-full p-96">
        <MessageBox type={MessageBoxType.Error}>
          <div className="space-y-4">
            <h1>Oups, une erreur est survenue !</h1>
            <h6>Pour nous aider, contactez-nous avec ce message d'erreur : </h6>
            <p>{currentUserQuery.error.message}</p>
          </div>
        </MessageBox>
      </div>
    );
  }
  
  return currentUserQuery?.data?.currentUser ? (
    <CurrentUserProviderInner currentUser={currentUserQuery.data.currentUser}>
      {children}
    </CurrentUserProviderInner>
  ) : (
    <>{children}</>
  );
}

function CurrentUserProviderInner({
  currentUser,
  children,
}: {
  currentUser: UserContextType;
  children: ReactNode;
}) {
  identifyUserToMixpanel(currentUser);
  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
}
