import React from 'react';
import clsx from 'clsx';
import { ThemeColor } from '../../graphql/generated';
import { getThemeLightColor } from '../../services/ReferentialService';
import { CheckIcon } from '../icons';

export function ThemeColorPicker({
  currentColor,
  setCurrentColor,
}: {
  currentColor: ThemeColor;
  setCurrentColor: (color: ThemeColor) => void;
}) {
  const colors = [
    ThemeColor.Blue,
    ThemeColor.Gray,
    ThemeColor.Green,
    ThemeColor.Purple,
    ThemeColor.Red,
    ThemeColor.Yellow,
  ];

  return (
    <div className="flex items-stretch gap-2">
      {colors.map((themeColor) => (
        <div
          key={themeColor}
          className={clsx(
            'p-2 rounded-xl border-2 border-gray-100 w-12 h-12 flex items-center justify-center cursor-pointer text-gray-900',
            getThemeLightColor(themeColor),
            currentColor === themeColor && 'border-gray-900',
          )}
          onClick={() => setCurrentColor(themeColor)}
        >
          {currentColor === themeColor && <CheckIcon />}
        </div>
      ))}
    </div>
  );
}
