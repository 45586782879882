import { useStakeSurveysDetailQuery } from '../../../../graphql/generated';
import React from 'react';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { LoaderFullscreen } from '../../../layout/Loader';
import { StakeholderQuestions } from './StakeholderQuestions';
import { useTranslation } from '@hooks/useTranslation';
import { ProgressBarWithScale } from '../../../generic/ProgressBarWithScale';

export function StakeSurveysDetail({ stakeId }: { stakeId: string }) {
  const { t } = useTranslation();
  const query = useStakeSurveysDetailQuery({
    variables: {
      id: stakeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  const stake = query.data?.stakeMaterialityStakeholder;

  if (!stake) {
    return;
  }

  return (
    <div className="p-4 space-y-4 divide-y divide-gray-100">
      <div className="space-y-4">
        <h5>{t('stake.detailModal.materiality.impactScore')}</h5>
        <ProgressBarWithScale
          percentageValue={
            (query.data?.stakeMaterialityStakeholder?.impactMaterialityScore ??
              0) / 4
          }
          displayLevel={true}
        />
        <h5>{t('stake.detailModal.materiality.financialScore')}</h5>
        <ProgressBarWithScale
          percentageValue={
            (query.data?.stakeMaterialityStakeholder
              ?.financialMaterialityScore ?? 0) / 4
          }
          displayLevel={true}
        />
      </div>
      <div className="space-y-4 pt-4">
        <h6>{t('stake.detailModal.surveys.responsesDetails')}</h6>
        <StakeholderQuestions stake={stake} />
      </div>
    </div>
  );
}
