import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SkeletonLoader } from '../components/generic/loader/SkeletonLoader';

export type NavContextType = {
  isNavCollapsed: boolean;
  setIsNavCollapsed: (isNavCollapsed: boolean) => void;
  actions: { key: string; component: ReactNode }[];
  setActions: (actions: { key: string; component: ReactNode }[]) => void;
  title: ReactNode;
  setTitle: Dispatch<SetStateAction<ReactNode>>;
  onClose: () => void;
  setOnClose: (fn: () => void) => void;
};

const NavContext = createContext<NavContextType | undefined>(undefined);

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error("useNavContext must be used within a NavContextProvider");
  }
  return context;
};

export function NavContextProvider({ children }: { children: ReactNode }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [onClose, setOnClose] = useState<() => void>(() => {});
  const [actions, setActions] = useState<{ key: string; component: ReactNode }[]>([]);
  const [title, setTitle] = useState<ReactNode>(<SkeletonLoader size={{ height: 'h-6', width: 'w-16' }} />);

  const contextValue = useMemo(
    () => ({
      isNavCollapsed,
      setIsNavCollapsed,
      onClose,
      setOnClose,
      actions,
      setActions,
      title,
      setTitle,
    }),
    [isNavCollapsed, onClose, actions, title]
  );

  return (
    <NavContext.Provider value={contextValue}>
      {children}
    </NavContext.Provider>
  );
}
