import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { scoreFormatter } from '../../iro/summary/useIroSummaryColumns';
import {
  SurveyLanguage,
  ThemeColor,
  ThemeIcon as ThemeIconEnum,
} from '../../../../graphql/generated';
import { ColorIcon } from '../../../generic/icon/ColorIcon';
import { ThemeIcon } from '../../../generic/icon/ThemeIcon';

export type ConsultationRawMaterialityRow = {
  stake: {
    i18n?:
      | {
          name: string;
          language: SurveyLanguage;
        }[]
      | null;
    pillar: {
      icon: ThemeIconEnum;
      color: ThemeColor;
    };
  };
  financialMaterialityScore?: number | null;
  impactMaterialityScore?: number | null;
};

export const useConsultationRawMaterialityColumns = (): Array<
  ColumnDef<ConsultationRawMaterialityRow>
> => {
  const { t, translateProperty } = useTranslation();
  const columnHelper = createColumnHelper<ConsultationRawMaterialityRow>();

  return [
    columnHelper.accessor('stake', {
      cell: (data) => {
        const stake = data.getValue() as ConsultationRawMaterialityRow['stake'];
        const expandProps = {
          onClick: () => data.row.toggleExpanded(),
          style: { cursor: 'pointer' },
        };

        return (
          <div className="flex items-center justify-between">
            <div
              className={clsx(
                'flex items-center gap-3',
                'text-gray-900 font-bold',
              )}
            >
              <ColorIcon
                // @ts-expect-error same colors
                color={stake.pillar.color}
                icon={<ThemeIcon themeIcon={stake.pillar.icon} />}
              />{' '}
              <span {...expandProps}>{translateProperty(stake, 'name')}</span>
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t('consultationRawMateriality.results.table.header.stake.label')}
        </span>
      ),
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('financialMaterialityScore', {
      cell: scoreFormatter,
      header: () =>
        t(
          'consultationRawMateriality.results.table.header.financialMateriality.label',
        ),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
    columnHelper.accessor('impactMaterialityScore', {
      cell: scoreFormatter,
      header: () =>
        t(
          'consultationRawMateriality.results.table.header.impactMateriality.label',
        ),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
    }),
  ] as Array<ColumnDef<ConsultationRawMaterialityRow>>;
};
