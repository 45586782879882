import React from 'react';
import clsx from 'clsx';
import { CheckIcon } from '../icons';

export enum ProgressBarStyles {
  COLORED = 'COLORED',
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
}

export function ProgressBar({
  value,
  total,
  style,
  targetThreshold = 0, // 0 means no target
  displayLabel,
  displayPercentage,
  displayCounts,
}: {
  value: number;
  total: number;
  style?: ProgressBarStyles;
  targetThreshold?: number;
  displayLabel?: boolean;
  displayPercentage?: boolean;
  displayCounts?: boolean;
}) {
  const maxValue = Math.max(total, targetThreshold, value);
  const targetAchieved = isTargetAchieved(value, maxValue, targetThreshold);
  const progressPercent =
    maxValue > 0 ? applyPercentageBoundaries((value / maxValue) * 100) : 0;
  const targetPercent =
    total > 0
      ? applyPercentageBoundaries((targetThreshold / maxValue) * 100)
      : 0;

  return (
    <div
      className={clsx(
        'w-full flex items-center justify-between gap-4',
        style === ProgressBarStyles.BLACK_AND_WHITE && 'grayscale',
      )}
    >
      <div
        className={clsx(
          'flex items-center bg-white rounded-md h-4 shrink-0',
          displayPercentage || displayCounts ? 'w-8/12' : 'w-full',
          style !== ProgressBarStyles.BLACK_AND_WHITE && 'shadow-sm',
        )}
      >
        <div className="rounded-md bg-white w-full flex justify-between items-center relative">
          {/* Gray bar */}
          <div
            className={clsx(
              'absolute h-4 rounded-lg border-white border-2 flex justify-between items-center w-full bg-gray-100',
            )}
          ></div>

          {/* Colored bar */}
          <div
            className={clsx(
              'absolute h-4 rounded-lg border-white border-2 flex justify-between items-center',
              style === ProgressBarStyles.BLACK_AND_WHITE && 'bg-gray-900',
              style !== ProgressBarStyles.BLACK_AND_WHITE &&
                targetAchieved &&
                'bg-green-500',
              style !== ProgressBarStyles.BLACK_AND_WHITE &&
                !targetAchieved &&
                'bg-purple-500',
            )}
            style={{ width: `${Math.max(progressPercent, 5)}%` }} // 5% is the minimum width to avoid weird rendering
          ></div>
          {/* Target check */}
          {targetPercent > 0 && (
            <div
              className="absolute -top-2.5 transform -translate-x-2/4"
              style={{ left: `calc(${targetPercent}%)` }}
            >
              <div className="flex flex-col justify-center items-center">
                <div
                  className={clsx(
                    'rounded-full flex items-center justify-center w-5 h-5 border-2 border-white',
                    targetAchieved ? 'bg-green-500' : 'bg-gray-300',
                  )}
                >
                  <CheckIcon
                    className={clsx(
                      'text-white w-3 h-3 rounded-full',
                      targetAchieved ? 'bg-green-500' : 'bg-gray-300',
                    )}
                  />
                </div>
                {displayLabel && targetThreshold > 0 && (
                  <p className="mt-1 text-xs text-gray-500 text-center w-48">
                    {targetThreshold} réponses minimum requises
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {(displayPercentage || displayCounts) && (
        <div className="flex items-center gap-2 shrink-0 max-w-sm">
          {displayPercentage && (
            <div className="font-bold text-sm w-10 text-right">
              {Math.round(progressPercent)}%
            </div>
          )}
          {displayCounts && (
            <div className="text-gray-500 text-sm">
              {value}/{maxValue}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function applyPercentageBoundaries(value: number) {
  return Math.min(Math.max(value, 0), 100);
}

function isTargetAchieved(
  value: number,
  maxValue: number,
  targetThreshold: number | null,
): boolean {
  if (maxValue <= 0) {
    return false;
  }
  if (targetThreshold && value >= targetThreshold) {
    return true;
  }
  return value >= maxValue;
}
