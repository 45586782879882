import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { TSvgIcon } from '../../icons';

export const NavItem = ({
  name,
  Icon,
  href,
  children,
  end,
  badge,
}: {
  name: string;
  Icon?: TSvgIcon;
  href: string;
  children?: ReactNode;
  end?: boolean;
  badge?: ReactNode;
}) => {
  return (
    <div>
      <NavLink to={href} end={end}>
        {({ isActive }) => {
          return (
            <>
              <div
                className={clsx(
                  isActive &&
                    href !== '#' &&
                    'font-semibold text-gray-900 bg-gray-50',
                  'text-gray-500 relative group w-full flex items-center px-3 py-2 text-left rounded-md tertiary hover:bg-gray-50',
                )}
              >
                <div className="h-3.5 w-3.5 mr-2">
                  {Icon && (
                    <Icon className="h-full w-full" aria-hidden="true" />
                  )}
                </div>
                <div className="flex-1 flex items-center gap-1">
                  <span>{name}</span>
                  {badge}
                </div>
              </div>
              {isActive && <div className="ml-6">{children}</div>}
            </>
          );
        }}
      </NavLink>
    </div>
  );
};
