import React, { useState } from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import {
  RoadmapScreen_RoadmapFragment,
  useRoadmapScreenQuery,
} from '../../../../graphql/generated';
import { RoadmapContent } from '../../../../components/publications/roadmap/Roadmap';
import { CreateRoadmap } from '../../../../components/project/roadmap/CreateRoadmap';
import { EditRoadmap } from '../../../../components/project/roadmap/EditRoadmap';
import {
  getFrDateFromISOString,
  getFrTimeFromISOString,
} from '../../../../services/DateService';
import { PublishRoadmapButton } from '../../../../components/project/roadmap/PublishRoadmapButton';
import { useRightDrawer } from '../../../../components/layout/RightDrawer';
import { RoadmapSettingsDrawer } from '../../../../components/project/roadmap/RoadmapSettingsDrawer';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { EditIcon, XIcon } from '../../../../components/icons';
import { useNavContext } from '../../../../providers/NavContextProvider';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { useTranslation } from '@hooks/useTranslation';

export const RoadmapScreen = withProjectContext(
  () => {
    const [isEditMode, setIsEditMode] = useState(false);

    const projectContext = useProjectContext();

    const { data, loading } = useRoadmapScreenQuery({
      variables: {
        companyId: projectContext?.enterprise?.id!,
      },
      skip: !projectContext?.enterprise?.id,
      fetchPolicy: 'cache-and-network',
    });

    if (loading) {
      return <LoaderFullscreen />;
    }
    const roadmap = data?.roadmaps?.[0];

    return (
      <>
        <PageTitle title="Feuille de route" />
        {roadmap && (
          <ScreenTopBar
            roadmap={roadmap}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        )}

        {roadmap ? (
          isEditMode ? (
            <EditRoadmap roadmap={roadmap} />
          ) : (
            <RoadmapContent roadmap={roadmap} />
          )
        ) : (
          <CreateRoadmap />
        )}
      </>
    );
  },
  { requiredCompany: true },
);

function ScreenTopBar({
  roadmap,
  isEditMode,
  setIsEditMode,
}: {
  roadmap: RoadmapScreen_RoadmapFragment;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const drawer = useRightDrawer();
  const navContext = useNavContext();
  const { t } = useTranslation();
  const openSettings = () => {
    drawer.openDrawerWithComponent(<RoadmapSettingsDrawer roadmap={roadmap} />);
  };

  const editMode = () => {
    setIsEditMode(true);
    navContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    navContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          {t('screen.roadmap.titleEdit')}
        </h1>
        <div className="flex items-center gap-4">
          <RoadmapPublicationDate roadmap={roadmap} />
          <PublishRoadmapButton roadmap={roadmap} />
          <button className="secondary purple" onClick={openSettings}>
            <span>{t('screen.roadmap.settings')}</span>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">{t('screen.roadmap.title')}</h1>
        <div className="flex items-center gap-4">
          <RoadmapPublicationDate roadmap={roadmap} />
          <button className="primary purple" onClick={editMode}>
            <EditIcon className="w-4 h-4" />
            <span>{t('global:edit')}</span>
          </button>
        </div>
      </div>
    );
  }
}

function RoadmapPublicationDate({
  roadmap,
}: {
  roadmap: { publishedAt?: string };
}) {
  return (
    <div className="text-sm text-gray-500">
      {roadmap.publishedAt
        ? `Publiée le ${getFrDateFromISOString(
            roadmap.publishedAt,
          )} à ${getFrTimeFromISOString(roadmap.publishedAt)}`
        : 'Non publiée'}
    </div>
  );
}
