import { useParams } from 'react-router-dom';
import React from 'react';
import {
  ConsultationRawMateriality_ResultFragment,
  useProConsultationRawMaterialityScreenQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { ProConsultationRawMateriality } from '../../../../components/project/survey/ConsultationRawMaterialities/ProConsultationRawMateriality';

export const ProConsultationRawMaterialityScreen = () => {
  const { consultationId } = useParams();

  const { data, loading } = useProConsultationRawMaterialityScreenQuery({
    variables: { id: consultationId || '' },
    skip: !consultationId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.consultationRawMateriality) {
    return (
      <ProConsultationRawMaterialityScreenInner
        consultation={data.consultationRawMateriality}
      />
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de la consultation.
        </p>
      </MessageBox>
    );
  }
};

function ProConsultationRawMaterialityScreenInner({
  consultation,
}: {
  consultation: ConsultationRawMateriality_ResultFragment;
}) {
  return (
    <>
      <PageTitle title="Consultations" />
      <ProConsultationRawMateriality consultation={consultation} />
    </>
  );
}
