import React, { ComponentType, FC } from 'react';
import { AdminNav, AdminNavRoutes } from './AdminNav';
import { PageTitle } from '../../components/nav/PageTitle';

export const withAdminNavigation = <T extends Record<string, unknown>>(
  WrappedComponent: ComponentType<T>,
  activeSection?: AdminNavRoutes,
): FC<T> => {
  return (props: T) => (
    <div className="w-full">
      <PageTitle title="Admin" />
      <div className="p-4">
        <AdminNav activeSection={activeSection} />
      </div>
      <div className="p-4">
        <WrappedComponent {...props} />
      </div>
    </div>
  );
};
