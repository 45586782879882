import {
  DiagnosticStakeFieldsFragment,
  MaturityLevel,
  PriorityLevel,
  ProPrioritizationMatrixScreen_DiagnosticStakeFragment,
  ProPrioritizationMatrixScreen_PriorityMaterialityDiagnosticStakeFragment,
  ThemeIcon,
} from '../../../graphql/generated';
import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { NumberCircle } from '../../generic/NumberCircle';
import { ExportableToImage } from '../../generic/ExportableToImage';
import { PillarIcon } from '../../stake/PillarIcon';
import {
  getMaturityLevelFromComputedValue,
  getPriorityLevelFromComputedValue,
} from '../../../services/ReferentialService';
import {
  openStakeDetailModal,
  STAKE_DETAILS_TABS,
} from '../../stake/detail/StakeDetailModal';
import { useModal } from '../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';

export function PrioritizationMatrix({
  stakes,
  priorities,
}: {
  stakes: ProPrioritizationMatrixScreen_DiagnosticStakeFragment[];
  priorities: ProPrioritizationMatrixScreen_PriorityMaterialityDiagnosticStakeFragment[];
}) {
  const [isReactHovered, setIsReactHovered] = useState(false);
  const [isActHovered, setIsActHovered] = useState(false);
  const [isTrackHovered, setIsTrackHovered] = useState(false);
  const [isNurtureHovered, setIsNurtureHovered] = useState(false);

  return (
    <div className="relative">
      <ExportableToImage name="Matrice de priorisation">
        <div className="flex items-center justify-between gap-8 mb-4">
          <h2>Vos enjeux prioritaires</h2>
        </div>
        <div className="flex items-end justify-between gap-2 mb-1">
          <p className="font-bold">Importance</p>
          <div className="flex gap-1 items-center">
            <div
              className={clsx(
                'p-2 bg-red-300 text-sm rounded-xl cursor-pointer',
                isReactHovered
                  ? 'border-2 border-gray-900'
                  : 'border-2 border-white',
              )}
              onMouseLeave={() => setIsReactHovered(false)}
              onMouseOver={() => setIsReactHovered(true)}
            >
              Réagir
            </div>
            <div
              className={clsx(
                'p-2 bg-orange-100 text-sm rounded-xl cursor-pointer',
                isActHovered
                  ? 'border-2 border-gray-900'
                  : 'border-2 border-white',
              )}
              onMouseLeave={() => setIsActHovered(false)}
              onMouseOver={() => setIsActHovered(true)}
            >
              Agir
            </div>
            <div
              className={clsx(
                'p-2 bg-yellow-100 text-sm rounded-xl cursor-pointer',
                isTrackHovered
                  ? 'border-2 border-gray-900'
                  : 'border-2 border-white',
              )}
              onMouseLeave={() => setIsTrackHovered(false)}
              onMouseOver={() => setIsTrackHovered(true)}
            >
              Surveiller
            </div>
            <div
              className={clsx(
                'p-2 bg-green-100 text-sm rounded-xl cursor-pointer',
                isNurtureHovered
                  ? 'border-2 border-gray-900'
                  : 'border-2 border-white',
              )}
              onMouseLeave={() => setIsNurtureHovered(false)}
              onMouseOver={() => setIsNurtureHovered(true)}
            >
              Nourrir
            </div>
          </div>
        </div>
        <div className="flex flex-col place-content-stretch gap-1">
          <MatrixRow>
            <MatrixCellHeader index={4} />
            <StakesMatrixCell
              className="bg-red-300"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.High}
              cellMaturity={MaturityLevel.None}
              isCellHovered={isReactHovered}
            />
            <StakesMatrixCell
              className="bg-orange-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.High}
              cellMaturity={MaturityLevel.Low}
              isCellHovered={isActHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.High}
              cellMaturity={MaturityLevel.Medium}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-green-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.High}
              cellMaturity={MaturityLevel.High}
              isCellHovered={isNurtureHovered}
            />
          </MatrixRow>

          <MatrixRow>
            <MatrixCellHeader index={3} />
            <StakesMatrixCell
              className="bg-orange-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Medium}
              cellMaturity={MaturityLevel.None}
              isCellHovered={isActHovered}
            />
            <StakesMatrixCell
              className="bg-orange-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Medium}
              cellMaturity={MaturityLevel.Low}
              isCellHovered={isActHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Medium}
              cellMaturity={MaturityLevel.Medium}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-green-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Medium}
              cellMaturity={MaturityLevel.High}
              isCellHovered={isNurtureHovered}
            />
          </MatrixRow>

          <MatrixRow>
            <MatrixCellHeader index={2} />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Low}
              cellMaturity={MaturityLevel.None}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Low}
              cellMaturity={MaturityLevel.Low}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Low}
              cellMaturity={MaturityLevel.Medium}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-green-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.Low}
              cellMaturity={MaturityLevel.High}
              isCellHovered={isNurtureHovered}
            />
          </MatrixRow>

          <MatrixRow>
            <MatrixCellHeader index={1} />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.None}
              cellMaturity={MaturityLevel.None}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.None}
              cellMaturity={MaturityLevel.Low}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-yellow-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.None}
              cellMaturity={MaturityLevel.Medium}
              isCellHovered={isTrackHovered}
            />
            <StakesMatrixCell
              className="bg-green-100"
              stakes={stakes}
              priorities={priorities}
              cellPriority={PriorityLevel.None}
              cellMaturity={MaturityLevel.High}
              isCellHovered={isNurtureHovered}
            />
          </MatrixRow>

          <MatrixRow>
            <div className="w-12"></div>
            <div className="w-1/4 flex items-center justify-center relative">
              <p className="font-bold absolute left-0">Maturité</p>
              <MatrixCellHeader index={1} />
            </div>
            <div className="w-1/4 flex items-center justify-center">
              <MatrixCellHeader index={2} />
            </div>
            <div className="w-1/4 flex items-center justify-center">
              <MatrixCellHeader index={3} />
            </div>
            <div className="w-1/4 flex items-center justify-center">
              <MatrixCellHeader index={4} />
            </div>
          </MatrixRow>
        </div>
      </ExportableToImage>
    </div>
  );
}

function MatrixRow({ children }: { children: ReactNode }) {
  return <div className="flex gap-1 items-stretch w-full">{children}</div>;
}

function MatrixCellHeader({ index }: { index: number }) {
  return (
    <div className="flex items-center justify-center">
      <NumberCircle number={index} size={10} />
    </div>
  );
}

function StakesMatrixCell({
  className,
  stakes,
  priorities,
  cellPriority,
  cellMaturity,
  isCellHovered,
}: {
  className: string;
  stakes: ProPrioritizationMatrixScreen_DiagnosticStakeFragment[];
  priorities: ProPrioritizationMatrixScreen_PriorityMaterialityDiagnosticStakeFragment[];
  cellPriority: PriorityLevel;
  cellMaturity: MaturityLevel;
  isCellHovered?: boolean;
}) {
  // Regrouper les stakes par pilier
  const pillarsWithOnlyCorrespondingStakesWithinScore = stakes
    .filter((stake) => stake.isDisabled !== true) // Remove non-material stakes
    .filter((stake: ProPrioritizationMatrixScreen_DiagnosticStakeFragment) => {
      // Priority must match
      if (stake.priorityLevel === PriorityLevel.Computed) {
        // get priority level from priorities array
        const stakeFromPrioritiesArray = priorities.find(
          (p) => p.id === stake.id,
        );
        const priorityValue =
          stakeFromPrioritiesArray?.materialities?.x?.value || 0;

        const computedPriorityLevel =
          getPriorityLevelFromComputedValue(priorityValue);

        if (computedPriorityLevel !== cellPriority) {
          return false;
        }
      } else {
        if (stake.priorityLevel !== cellPriority) {
          return false;
        }
      }

      // Maturity must match
      if (stake.maturityLevel === MaturityLevel.Computed) {
        const computedMaturityLevel = getMaturityLevelFromComputedValue(
          stake.maturity?.score,
        );
        if (computedMaturityLevel !== cellMaturity) {
          return false;
        }
      } else {
        if (stake.maturityLevel !== cellMaturity) {
          return false;
        }
      }

      return true;
    })
    .reduce(
      (acc, stake) => {
        const pillar = acc.find((p) => p.id === stake.pillar.id);
        if (pillar) {
          pillar.stakes.push(stake);
        } else {
          acc.push({
            ...stake.pillar,
            stakes: [stake],
          });
        }
        return acc;
      },
      [] as Array<{
        id: string;
        stakes: ProPrioritizationMatrixScreen_DiagnosticStakeFragment[];
        icon: ThemeIcon;
      }>,
    )
    .filter((pillar) => pillar.stakes.length > 0); // Ne garder que les piliers qui ont des stakes correspondants

  return (
    <div
      className={clsx(
        'p-4 rounded-xl flex flex-col gap-2 justify-center w-1/4 transform transition duration-200',
        className,
        isCellHovered ? 'border-2 border-gray-900' : 'border-2 border-white',
      )}
    >
      {pillarsWithOnlyCorrespondingStakesWithinScore.map((pillar) => (
        <div
          className="flex items-stretch justify-start gap-2"
          key={pillar.id.concat(cellPriority).concat(cellMaturity)}
        >
          <div className="bg-white justify-center rounded-2xl p-2 flex items-center">
            <PillarIcon pillar={pillar} enableTooltip={true} />
          </div>
          <div className="flex flex-col gap-2 justify-center py-2">
            {pillar.stakes.map((diagnosticStake) => (
              <StakeItem key={diagnosticStake.id} stake={diagnosticStake} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function StakeItem({ stake }: { stake: DiagnosticStakeFieldsFragment }) {
  const { translateProperty } = useTranslation();
  const modal = useModal();
  return (
    <div className="flex items-center gap-2">
      <button
        className="unstyled text-xs"
        onClick={() =>
          openStakeDetailModal(modal, stake, STAKE_DETAILS_TABS.STAKE_OVERVIEW)
        }
      >
        {translateProperty(stake, 'name')}
      </button>
    </div>
  );
}
