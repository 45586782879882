import {
  ConsultationRawMateriality,
  MaturityEvaluation,
  Survey,
} from '../graphql/generated';

type AnySurvey = Pick<
  Survey | MaturityEvaluation | ConsultationRawMateriality,
  '__typename'
>;

export function isSurvey<T extends AnySurvey>(
  survey?: Partial<AnySurvey> | null | undefined,
): survey is T {
  return (survey as Survey)?.__typename === 'Survey';
}

export function isMaturityEvaluation<T extends AnySurvey>(
  survey?: Partial<AnySurvey> | null | undefined,
): survey is T {
  return (survey as MaturityEvaluation)?.__typename === 'MaturityEvaluation';
}
