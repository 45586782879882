import React from 'react';
import {
  MaterialityTypes,
  PriorityLevel,
  SimpleMaterialityArray_AnswerFragment,
  SimpleMaterialityArray_QuestionFragment,
  useSimpleMaterialityArray_ReferentialQuery,
} from '../../../../../graphql/generated';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../../layout/Loader';
import {
  MatrixArray,
  MatrixRowType,
  stakeAnswersToMatrixRows,
} from '../MatrixArray';

export function SimpleMaterialityArray({
  question,
  answers,
}: {
  question: SimpleMaterialityArray_QuestionFragment;
  answers: SimpleMaterialityArray_AnswerFragment[];
}) {
  const projectContext = useProjectContext();
  const referentialQuery = useSimpleMaterialityArray_ReferentialQuery({
    variables: { id: projectContext?.enterprise?.referential?.id || '' },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'cache-and-network',
  });
  const referential = referentialQuery.data?.referential;
  const stakes = referential?.pillars.flatMap((pillar) => pillar.stakes) || [];

  // Columns to display
  const columns: Array<PriorityLevel> = [
    PriorityLevel.None,
    PriorityLevel.Low,
    PriorityLevel.Medium,
    PriorityLevel.High,
  ];

  const matrix: MatrixRowType[] = stakeAnswersToMatrixRows(
    answers || [],
    stakes,
    columns,
    MaterialityTypes.Financial,
  );

  if (referentialQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <MatrixArray title={question.title} matrix={matrix} columns={columns} />
  );
}
