export function wrapLongLabel(label: string): string[] {
  const maxLength = 35;

  // Split label when too long (keeping words complete)
  if (label.length > maxLength) {
    const words = label.split(' ');
    const lines: string[] = [];
    let currentLine = '';
    words.forEach((word) => {
      if (currentLine.length + word.length + 1 > maxLength) {
        lines.push(currentLine);
        currentLine = '';
      }
      currentLine += `${word} `;
    });
    lines.push(currentLine);
    return lines;
  } else {
    return [label];
  }
}
