import React, { useEffect, useState } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import clsx from 'clsx';

export function QuestionRating({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const [selectedScore, setSelectedScore] = useState<number | null>(
    answer?.number || null,
  );

  const toggleScore = (score: number) => {
    if (score === selectedScore) {
      // Unselect
      setSelectedScore(null);
      updateAnswer(null);
    } else {
      setSelectedScore(score);
      updateAnswer(score);
    }
  };

  const updateAnswer = (newSelectedScore: number | null) => {
    if (newSelectedScore === null) {
      setIsAnswerValid(false);
      setAnswer(null);
      return;
    }
    setIsAnswerValid(true);
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Number,
      number: newSelectedScore,
    });
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(selectedScore !== null);
  }, [selectedScore, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div>
          <div className="flex flex-row gap-2 w-full items-center justify-center flex-wrap">
            {Array.from(
              {
                length: (question.valueMax || 5) - (question.valueMin || 0) + 1,
              },
              (_, i) => (question.valueMin || 0) + i,
            ).map((score) => (
              <div
                className={clsx(
                  'form-input-multiple-choice-item enps',
                  selectedScore === score && 'selected',
                )}
                key={score}
                onClick={() => toggleScore(score)}
              >
                {score}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
