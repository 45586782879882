import { createContext, useContext } from 'react';
import firebase from '@firebase/app-compat';
import { getAuth, signOut } from 'firebase/auth';

export function initializeApp() {
  return firebase.initializeApp(
    JSON.parse(process.env.FIREBASE_CONFIG ?? '{}'),
  );
}

export const FirebaseAppContext = createContext(initializeApp());

export const useFirebaseApp = () => useContext(FirebaseAppContext);
export const useFirebaseAuth = () => getAuth(useFirebaseApp());

export const useSignOut = () => {
  const auth = useFirebaseAuth();
  return () => signOut(auth);
};
