import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { InnerHtml } from '../../../../generic/InnerHtml';

export const TimeHorizonDefinition = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="p-8 bg-gray-50 border-b border-gray-100 title-h3 capitalize">
        {t('iro.criteria.timeHorizon.title')}
      </h1>
      <InnerHtml
        html={t('iro.criteria.timeHorizon.definition')}
        className="p-8 space-y-8"
      />
    </div>
  );
};
