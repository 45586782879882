import React, { useState } from 'react';
import RichTextEditorTextarea from '../RichTextEditorTextarea';
import { Loader } from '../../Loader';

interface CommentFormProps {
  mutate: (comment: string) => Promise<void>;
  cta?: string;
  initialComment?: string | null;
}

export const CommentForm = ({ cta, initialComment, mutate }: CommentFormProps) => {
  /*
    * commentView is the value of the textarea displayed to the user
    * commentModel is the value of the textarea in HTML format (used to send the comment to the server)
    * main difference is that commentModel contains the HTML tags versus commentView which have some transformation made
    * on html tags (<div> -> &lt;div&gt;) by ReactQuill
   */
  const [commentView, setCommentView] = useState(initialComment || '');
  const [commentModel, setcommentModel] = useState(initialComment || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    mutate(commentModel)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className="flex flex-col items-stretch gap-4"
    >
      <RichTextEditorTextarea
        inlined={true}
        value={commentView}
        onChange={(value, html) => {
          setcommentModel(html)
          setCommentView(value)} }
      />
      <button
        className="primary small"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading && <Loader />}
        {cta}
      </button>
    </div>
  );
};
