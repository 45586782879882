import {
  CurrentUserDocument,
  ProjectContextualMenu_CompanyFragment,
  ProjectContextualMenuDocument,
  useProjectContextualMenu_ChooseTemplateMutation,
  useProjectContextualMenu_CreateCompanyMutation,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../../layout/Toast';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import React, { useState } from 'react';
import clsx from 'clsx';
import { isSuperadminUser } from '../../../../services/CompanyService';
import { Loader } from '../../../generic/Loader';
import { CopyIcon } from '../../../icons';

export function DuplicateCompanyButton({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useCurrentUser();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [importUserData, setImportUserData] = useState(false);

  const [createCompanyMutation] =
    useProjectContextualMenu_CreateCompanyMutation({
      refetchQueries: () => [
        { query: CurrentUserDocument },
        {
          query: ProjectContextualMenuDocument,
          variables: {
            companyId: currentUser?.company?.id || '',
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  const [chooseTemplateMutation] =
    useProjectContextualMenu_ChooseTemplateMutation();

  const duplicate = () => {
    setIsDuplicating(true);
    toast.openToastWithMessage(
      t('project.contextualMenu.duplicate.duplicating'),
    );
    createCompanyMutation({
      variables: {
        input: {
          company: {
            name: `Copie de ${company.name}`,
            createdBy: {
              id: currentUser?.id || '',
            },
          },
          coach: {
            id: currentUser?.id || '',
          },
        },
      },
    })
      .then((copy) => {
        if (copy?.data?.createEnterprise) {
          chooseTemplateMutation({
            variables: {
              input: {
                companyId: copy.data.createEnterprise.id,
                templateId: company.id,
                importUserData,
              },
            },
            refetchQueries: () => [{ query: CurrentUserDocument }],
          })
            .then(() => {
              toast.openToastWithMessage(t('toast:project.duplicate.success'));
              setIsDuplicating(false);
            })
            .catch((err) => {
              toast.openToastWithError(err.message);
            });
        }
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="flex items-center gap-1 w-full">
      <button
        className={clsx(
          'contextual-menu-item',
          isSuperadminUser(currentUser) && ' w-auto',
        )}
        disabled={isDuplicating}
        onClick={duplicate}
      >
        {isDuplicating ? <Loader /> : <CopyIcon />}
        {isDuplicating ? (
          <div>{t('project.contextualMenu.duplicate.duplicating')}</div>
        ) : (
          <div>{t('project.contextualMenu.duplicate.button')}</div>
        )}
      </button>
      {!isDuplicating && isSuperadminUser(currentUser) && (
        <div className="flex items-center gap-1">
          <div className="text-xs italic shrink-0 text-gray-500">
            {t('project.contextualMenu.duplicate.withData')}
          </div>
          <input
            type="checkbox"
            className="form-input-checkbox"
            checked={importUserData}
            onChange={() => setImportUserData(!importUserData)}
          />
        </div>
      )}
    </div>
  );
}
