import { SurveyTemplateFieldsFragment } from '../../../../graphql/generated';
import React from 'react';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { getSurveyTargetLabel } from '../../../../services/SurveyService';
import {
  ChevronRightIcon,
  GoodStepsLogoIcon,
  SurveyIcon,
  UserIcon,
} from '../../../icons';

export function SurveyTemplateList({
  surveys,
  setPreviewSurveyTemplate,
}: {
  surveys: SurveyTemplateFieldsFragment[];
  setPreviewSurveyTemplate: (
    surveyTemplate: SurveyTemplateFieldsFragment,
  ) => void;
}) {
  return (
    <div className="">
      <div className="flex gap-4 text-gray-500 text-sm mb-4">
        <div className="w-6/12">Consultation</div>
        <div className="w-5/12 text-center">Type</div>
        <div className="w-1/12"></div>
      </div>
      {surveys.length > 0 ? (
        surveys.map((survey) => (
          <div
            className="mb-2 border-2 border-white rounded-lg hover:border-gray-900 cursor-pointer"
            key={survey.id}
            onClick={() => setPreviewSurveyTemplate(survey)}
          >
            <SurveyRow survey={survey} />
          </div>
        ))
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          Aucun modèle ne correspond aux critères de recherche
        </MessageBox>
      )}
    </div>
  );
}

function SurveyRow({ survey }: { survey: SurveyTemplateFieldsFragment }) {
  return (
    <div className="rounded-xl flex items-center justify-between gap-2">
      <div className="w-6/12 flex items-center gap-4 shrink-0">
        <SurveyIllustrationIcon survey={survey} />
        <div>
          <div className="font-bold text-gray-900">{survey.name}</div>
          {survey.company?.name && (
            <div className="text-gray-500 text-sm mt-0.5">
              {survey.company.name}
            </div>
          )}
        </div>
      </div>
      <div className="w-5/12 flex gap-2 justify-center items-center shrink-0">
        {survey.target && (
          <div className="flex items-center gap-1 font-semibold text-sm">
            <UserIcon className="" />
            <div>{getSurveyTargetLabel(survey.target)}</div>
          </div>
        )}
      </div>
      <div className="w-1/12">
        <button className="primary purple mr-2">
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}

export function SurveyIllustrationIcon({
  survey,
}: {
  survey: SurveyTemplateFieldsFragment;
}) {
  return (
    <div className="relative w-20 h-20 rounded-lg bg-purple-50 shrink-0 flex items-center justify-center">
      <SurveyIcon className="w-5 h-5 text-purple-500" />
      {survey.isTemplate && (
        <div className="absolute -top-2 -right-2 p-1 h-7 w-7 border-2 border-white rounded-full flex items-center justify-center bg-green-500 text-white">
          <GoodStepsLogoIcon className="w-3 h-3" />
        </div>
      )}
    </div>
  );
}
