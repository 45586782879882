export function getExportFileName(title: string) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = `0${date.getHours()}`.slice(-2);
  const minute = `0${date.getMinutes()}`.slice(-2);

  return `[Export Good Steps] ${title} ${year}-${month}-${day} ${hour}h${minute}`;
}

// Utility function to convert an array to an object, for XLS exports
export function convertArrayToObject(
  array: (string | number | undefined)[][],
  header: string[],
) {
  return array.map((row) => {
    const obj = {};
    for (let i = 0; i < row.length; i++) {
      // We disable linting and typescript warning here because we don't know the type of the object in advance
      // TODO: make it more typescript friendly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj[header[i]] = row[i];
    }
    return obj;
  });
}
