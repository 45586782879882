import React from 'react';
import { useModal } from '../../../layout/Modal';
import { MaterialityThresholdModal } from './MaterialityThresholdModal';
import { useTranslation } from '@hooks/useTranslation';

export function MaterialityThresholdButton({
  companyId,
}: {
  companyId: string;
}) {
  const modal = useModal();
  const { t } = useTranslation();

  const openMaterialityThresholdModal = () => {
    modal.openModalWithComponent(
      <MaterialityThresholdModal companyId={companyId} />,
      t('materiality.threshold.defineThresholds'),
      false,
      true,
      'w-3/4',
      'p-8',
    );
  };

  return (
    <button className="secondary" onClick={openMaterialityThresholdModal}>
      {t('materiality.threshold.defineThresholds')}
    </button>
  );
}
