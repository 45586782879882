import React, { DetailedHTMLProps, ReactNode, ThHTMLAttributes } from 'react';
import clsx from 'clsx';

type ThProps = {
  children: ReactNode;
} & DetailedHTMLProps<
  ThHTMLAttributes<HTMLTableHeaderCellElement>,
  HTMLTableHeaderCellElement
>;

export const Th = ({ children, className, ...thProps }: ThProps) => (
  <th className={clsx('text-base', className)} {...thProps}>
    {children}
  </th>
);
