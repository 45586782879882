import {
  ConsultingCompanyFragment,
  useCompanyUpdateMutation,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { CompanyLogoInput } from './CompanyLogoInput';

export function ConsultingCompany({
  company,
}: {
  company: ConsultingCompanyFragment;
}) {
  const toast = useToast();
  const projectContext = useProjectContext();

  const [updateCompanyMutation] = useCompanyUpdateMutation();

  const [name, setName] = useState<string>(company.name);

  useEffect(() => {
    setName(company.name);
  }, [company]);

  const saveCompany = () => {
    updateCompanyMutation({
      variables: {
        input: {
          id: company.id,
          name,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Informations mises à jour');
        projectContext?.refreshEnterprise();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <div className="p-16 space-y-8">
      <h5>À propos de votre entreprise</h5>
      <div className="grid grid-cols-2 gap-8 divide-x divide-gray-100">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="form-input-label">
              Nom de votre entreprise
            </label>
            <div className="flex items-center gap-2">
              <input
                type="text"
                name="name"
                id="name"
                className="form-input-text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <button className="primary small purple" onClick={saveCompany}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
        <div className="pl-8">
          <CompanyLogoInput company={company} />
        </div>
      </div>
    </div>
  );
}
