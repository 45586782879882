import {
  AddMaturityEvaluationRecipientForm_MaturityEvaluationFragment,
  useAddMaturityEvaluationRecipientForm_StakeholderCreateMutation,
} from '../../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../../generic/Loader';
import { PlusIcon } from '../../../icons';
import { useModal } from '../../../layout/Modal';
import { useToast } from '../../../layout/Toast';

export function AddMaturityEvaluationRecipientForm({
  maturityEvaluation,
}: {
  maturityEvaluation: AddMaturityEvaluationRecipientForm_MaturityEvaluationFragment;
}) {
  const modal = useModal();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [createStakeholder] =
    useAddMaturityEvaluationRecipientForm_StakeholderCreateMutation();
  const addStakeholder = () => {
    setIsLoading(true);
    createStakeholder({
      variables: {
        input: {
          stakeholderMaturityEvaluation: {
            maturityEvaluation: {
              id: maturityEvaluation.id,
            },
            email,
            firstName,
            lastName,
          },
        },
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage('Contact ajouté');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="space-y-4 p-4 min-w-96">
      <div className="space-y-2">
        <label className="form-input-label">Email</label>
        <input
          type="email"
          className="form-input-text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="space-y-2">
        <label className="form-input-label">Nom</label>
        <input
          type="text"
          className="form-input-text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="space-y-2">
        <label className="form-input-label">Prénom</label>
        <input
          type="text"
          className="form-input-text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <button className="primary purple" onClick={addStakeholder}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex items-center">
              <PlusIcon className="w-4 h-4" />
            </div>
          )}
          <div>Ajouter</div>
        </button>
      </div>
    </div>
  );
}
