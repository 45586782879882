import React, { useEffect, useRef, useState } from 'react';
import { useRightDrawer } from '../../../../layout/RightDrawer';
import { useTranslation } from '@hooks/useTranslation';
import IroScalePicker from '../picker/IroScalePicker';
import IroScopePicker from '../picker/IroScopePicker';
import IroLikelihoodPicker from '../picker/IroLikelihoodPicker';
import { IroScore } from './IroScore';
import { IroIrremediabilityPicker } from '../picker/IroIrremediabilityPicker';
import {
  FinancialOpportunityRow_OpportunityIroFragment,
  FinancialRiskRow_RiskIroFragment,
  IroType,
  NegativeImpactRow_NegativeImpactIroFragment,
  PositiveImpactRow_PositiveImpactIroFragment,
} from '../../../../../graphql/generated';
import { AddIroButton } from '../form/create/AddIroButton';
import { ScaleCriteriaDefinition } from '../iroCriteriaDefinition/ScaleCriteriaDefinition';
import { ScopeCriteriaDefinition } from '../iroCriteriaDefinition/ScopeCriteriaDefinition';
import { LikelihoodCriteriaDefinition } from '../iroCriteriaDefinition/LikelihoodCriteriaDefinition';
import { IrremediabilityCriteriaDefinition } from '../iroCriteriaDefinition/IrremediabilityCriteriaDefinition';
import { IroNameCell } from './cell/IroNameCell';
import { TimeHorizonDefinition } from '../iroCriteriaDefinition/TimeHorizonDefinition';
import clsx from 'clsx';
import {
  hasIrremediabilityCriteria,
  hasLikelihoodCriteria,
  hasScaleCriteria,
  hasScopeCriteria,
} from '../../../../../types/iro.types';
import { IroTimeHorizonPicker } from '../picker/IroTimeHorizonPicker';
import { IroIcon } from '../../IroIcon';

type IroFragment =
  | NegativeImpactRow_NegativeImpactIroFragment
  | PositiveImpactRow_PositiveImpactIroFragment
  | FinancialRiskRow_RiskIroFragment
  | FinancialOpportunityRow_OpportunityIroFragment;

export const IroRow = <T extends IroFragment>({
  stakeId,
  iros,
  iroType,
  lastSection = false,
}: {
  stakeId: string;
  iros: T[];
  iroType: IroType;
  lastSection?: boolean;
}) => {
  const rightDrawer = useRightDrawer();
  const { t } = useTranslation();
  const hasIros = iros.length > 0;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [leftShadowVisible, setLeftShadowVisible] = useState<boolean>(false);
  const [rightShadowVisible, setRightShadowVisible] = useState<boolean>(true);

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

    setLeftShadowVisible(scrollLeft > 0);
    setRightShadowVisible(scrollLeft + clientWidth < scrollWidth);
  };
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleResize = () => {
      handleScroll();
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', handleResize);
    handleScroll();

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const firstIro = iros.length > 0 ? iros[0] : null;

  const columnNumber = [
    firstIro ? hasScaleCriteria(firstIro) : false,
    firstIro ? hasScopeCriteria(firstIro) : false,
    firstIro ? hasIrremediabilityCriteria(firstIro) : false,
    firstIro ? hasLikelihoodCriteria(firstIro) : false,
    firstIro !== null,
  ].reduce((acc, hasCriteria) => acc + (hasCriteria ? 1 : 0), 2);

  return (
    <div className="w-full overflow-auto no-scrollbar" ref={scrollContainerRef}>
      <table className="min-w-full table-fixed ">
        <thead>
          <tr>
            <th
              className={clsx(
                'sticky left-0 font-bold text-base w-[20vw] xl:w-[33vw] max-w-[26rem]',
                leftShadowVisible && 'left-sticky-col-shadow',
              )}
            >
              <div className="flex items-center gap-2 capitalize justify-between">
                <div className="flex items-center gap-2 text-gray-900">
                  <IroIcon iroTypename={iroTypeToTypename(iroType)} />
                  {t(`translation:iro.types.${iroType}`)}
                </div>
                {hasIros && <AddIroButton stakeId={stakeId} type={iroType} />}
              </div>
            </th>

            {firstIro && hasScaleCriteria(firstIro) && (
              <th className="w-36 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    rightDrawer.openDrawerWithComponent(
                      <ScaleCriteriaDefinition />,
                    )
                  }
                >
                  {t('translation:iro.criteria.scale.title')}
                </div>
              </th>
            )}
            {firstIro && hasScopeCriteria(firstIro) && (
              <th className="w-36 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    rightDrawer.openDrawerWithComponent(
                      <ScopeCriteriaDefinition />,
                    )
                  }
                >
                  {t('translation:iro.criteria.scope.title')}
                </div>
              </th>
            )}
            {firstIro && hasIrremediabilityCriteria(firstIro) && (
              <th className="w-40 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    rightDrawer.openDrawerWithComponent(
                      <IrremediabilityCriteriaDefinition />,
                    )
                  }
                >
                  {t('translation:iro.criteria.irremediability.title')}
                </div>
              </th>
            )}
            {firstIro && hasLikelihoodCriteria(firstIro) && (
              <th className="w-48 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    rightDrawer.openDrawerWithComponent(
                      <LikelihoodCriteriaDefinition />,
                    )
                  }
                >
                  {t('translation:iro.criteria.likelihood.title')}
                </div>
              </th>
            )}
            {firstIro && (
              <th className="w-44 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    rightDrawer.openDrawerWithComponent(
                      <TimeHorizonDefinition />,
                    )
                  }
                >
                  {t('translation:iro.criteria.timeHorizon.title')}
                </div>
              </th>
            )}
            <th
              className={clsx(
                'md:sticky md:right-0 text-center w-24',
                rightShadowVisible && 'md:right-sticky-col-shadow',
              )}
            >
              {t('screen.iro.materiality_score')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!hasIros && (
            <tr>
              <td colSpan={columnNumber} className="text-center">
                <div className="relative flex flex-col items-center gap-4 py-4">
                  <div className="font-bold">
                    {t(`iro.create.add_to_stake.${iroType}`)}
                  </div>
                  <AddIroButton stakeId={stakeId} type={iroType} />
                </div>
              </td>
            </tr>
          )}
          {iros.map((iro, index) => {
            const contextualMenuPosition = {};
            let pickerPositionClassName = undefined;
            if (lastSection && iros.length === index + 1) {
              pickerPositionClassName = '-translate-y-full';
            }
            return (
              <tr key={iro.id}>
                <td
                  className={clsx(
                    'sticky left-0 bg-white border-r border-gray-100 group',
                    leftShadowVisible && 'left-sticky-col-shadow',
                  )}
                >
                  <IroNameCell {...{ iro, ...contextualMenuPosition }} />
                </td>
                {hasScaleCriteria(iro) && (
                  <td className="w-36 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroScalePicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                      />
                    </div>
                  </td>
                )}
                {hasScopeCriteria(iro) && (
                  <td className="w-32 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroScopePicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                      />
                    </div>
                  </td>
                )}
                {hasIrremediabilityCriteria(iro) && (
                  <td className="w-40 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroIrremediabilityPicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                      />
                    </div>
                  </td>
                )}
                {hasLikelihoodCriteria(iro) && (
                  <td className="w-48 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroLikelihoodPicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                      />
                    </div>
                  </td>
                )}
                <td className="w-44 border-r border-gray-100 text-center">
                  <div className="w-full flex items-center justify-center">
                    <IroTimeHorizonPicker
                      iro={iro}
                      extraPositionClassName={pickerPositionClassName}
                    />
                  </div>
                </td>
                <td
                  className={clsx(
                    'md:sticky md:right-0 w-24  bg-white text-center',
                    rightShadowVisible && 'md:right-sticky-col-shadow',
                  )}
                >
                  <div className="flex items-center gap-1">
                    <IroScore score={iro.score} />
                    <div className="shrink-0 text-sm text-gray-500">/4</div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function iroTypeToTypename(iroType: IroType) {
  switch (iroType) {
    case IroType.NegativeImpact:
      return 'NegativeImpactIro';
    case IroType.PositiveImpact:
      return 'PositiveImpactIro';
    case IroType.Risk:
      return 'RiskIro';
    case IroType.Opportunity:
      return 'OpportunityIro';
  }
}
