import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  FC,
  SetStateAction,
  Dispatch,
} from 'react';

interface ReferentialContextType {
  referential: string;
  setReferential: Dispatch<SetStateAction<string>>;
}

interface ReferentialProviderProps {
  children: ReactNode;
  initialReferential: string;
}

const ReferentialContext = createContext<ReferentialContextType | undefined>(
  undefined,
);

export const ReferentialProvider: FC<ReferentialProviderProps> = ({
  children,
  initialReferential,
}) => {
  const [referential, setReferential] = useState<string>(initialReferential);

  return (
    <ReferentialContext.Provider value={{ referential, setReferential }}>
      {children}
    </ReferentialContext.Provider>
  );
};

export const useReferential = (): ReferentialContextType => {
  const context = useContext(ReferentialContext);
  if (!context) {
    throw new Error('useReferential must be used within a ReferentialProvider');
  }
  return context;
};
