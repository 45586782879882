import React from 'react';

export function EnpsProgressBarChart({ enps }: { enps: number }) {
  // eNPS bar is from -100 to 100
  const width = ((100 + enps) / 200) * 100;
  const boundedWidth = Math.min(Math.max(width, 4), 100);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <div className="font-title title-h1 text-purple-500">
          {enps > 0 && '+'} {enps}
        </div>
        <div className="text-gray-500">{getEnpsQualifier(enps)}</div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="bg-gray-100 rounded-lg flex items-end w-full h-3">
          <div
            className="bg-purple-500 h-3 rounded-lg relative"
            style={{ width: `${boundedWidth}%` }}
          >
            <div className="rounded-full h-5 w-5 bg-purple-500 border-2 border-white absolute -right-2 m-auto bottom-1/2 top-1/2 -mt-2.5"></div>
          </div>
        </div>
        <div className="flex items-center justify-between text-gray-300 text-xs">
          <div>-100</div>
          <div>0</div>
          <div>+100</div>
        </div>
      </div>
    </div>
  );
}

function getEnpsQualifier(enps: number) {
  if (enps < 0) {
    return 'Mauvais';
  } else if (enps > 30) {
    return 'Excellent !';
  } else if (enps > 10) {
    return 'Bon';
  } else if (enps >= 0) {
    return 'Acceptable';
  }
}
