import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { SurveyLanguage } from '../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { CheckIcon, ChevronDownIcon } from '../icons';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';

type LanguageDropdownItem = {
  id: SurveyLanguage | null | undefined;
  label: string;
};

type LanguageDropdownProps = {
  onUpdate?: (language: SurveyLanguage) => void;
  currentLanguage?: SurveyLanguage;
};

export default function LanguageDropdown({
  onUpdate,
  currentLanguage,
}: LanguageDropdownProps) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(
    currentLanguage || (i18n.language as SurveyLanguage),
  );

  const languageItems = [
    {
      id: SurveyLanguage.Fr,
      label: '🇫🇷 Français',
    },
    {
      id: SurveyLanguage.En,
      label: '🇬🇧 Anglais',
    },
  ];

  const currentItem: LanguageDropdownItem =
    languageItems.find((availableItem) => availableItem.id === language) ||
    languageItems[0];

  const chooseItem = (item: LanguageDropdownItem) => {
    if (item.id) {
      setLanguage(item.id);
      if (onUpdate) {
        onUpdate(item.id);
      }
    }
  };

  return (
    <Listbox value={currentItem} onChange={chooseItem}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="form-input-select">
            <div className="flex items-center gap-2 w-full min-w-[100px]">
              {currentItem?.label || '-'}
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {languageItems.map((item: LanguageDropdownItem) => (
                <ListboxOption
                  key={item.id}
                  className={clsx(
                    'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                  )}
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={clsx(
                          selected ? 'font-semibold' : 'font-normal',
                          'flex items-center gap-2',
                        )}
                      >
                        <div>{item.label}</div>
                      </div>

                      {selected ? (
                        <span
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
