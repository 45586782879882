import { useTranslation } from '@hooks/useTranslation';
import { GradientScore } from '../../../../generic/GradientScore';
import React from 'react';
import { StakeMaterialityScoreFragment } from '../../../../../graphql/generated';

export function StakeMaterialityScore({
  stake: { impactMaterialityScore, financialMaterialityScore },
}: {
  stake: StakeMaterialityScoreFragment;
}) {
  const { t } = useTranslation();
  return (
    <div className="border border-gray-100 shadow-sm bg-white p-2 w-96 flex items-center justify-between gap-4 rounded-lg">
      <div className="font-bold text-sm">
        {t('screen.iro.computed_materiality_score')}
      </div>
      <GradientScore
        score={Math.max(
          impactMaterialityScore || 0,
          financialMaterialityScore || 0,
        )}
      />
    </div>
  );
}
