import React, { ReactNode, useState } from 'react';
import { PageTitle } from '../../components/nav/PageTitle';
import { useToast } from '../../components/layout/Toast';
import { useModal } from '../../components/layout/Modal';
import {
  NumberCircle,
  NumberCircleColor,
} from '../../components/generic/NumberCircle';
import {
  ProgressBar,
  ProgressBarStyles,
} from '../../components/generic/ProgressBar';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../components/nav/Tabs';
import { StakeTag, StakeTagStyle } from '../../components/stake/StakeTag';
import { SurveyLanguage, ThemeColor, ThemeIcon } from '../../graphql/generated';
import { Toggle } from '../../components/generic/form/Toggle';
import { ClockIcon, SurveyIcon } from '../../components/icons';

function Section({ title, children }: { title: string; children: ReactNode }) {
  return (
    <section className="space-y-2 p-4 border border-dashed rounded border-purple-500">
      <h2 className="text-purple-500 font-mono">{title}</h2>
      {children}
    </section>
  );
}

function Subsection({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-purple-500 font-mono">{title}</h3>
      {children}
    </div>
  );
}

function Swatch({ color }: { color: string }) {
  const text = color ? color.split('-')[2] : '';
  const className = `${color} rounded row-span-2 p-1`;
  return <div className={className}>{text}</div>;
}

const enum STORYBOOK_TABS {
  Item1 = 'Item1',
  Item2 = 'Item2',
  Item3 = 'Item3',
  Item4 = 'Item4',
}

export function StorybookScreen() {
  const toast = useToast();
  const modal = useModal();
  const tabs: TabItem[] = [
    { key: STORYBOOK_TABS.Item1, name: 'Item 1' },
    { key: STORYBOOK_TABS.Item2, name: 'Item 2', tag: 'tag' },
    { key: STORYBOOK_TABS.Item3, name: 'Item 3', icon: <ClockIcon /> },
    {
      key: STORYBOOK_TABS.Item4,
      name: 'Item 4',
      tag: '5',
      icon: <ClockIcon />,
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };
  const [toggleState, setToggleState] = useState<boolean>(false);

  return (
    <>
      <PageTitle title="Design System" />
      <div className="space-y-8 p-8 pb-24 flex flex-col h-screen overflow-y-auto">
        <h1 className="text-purple-500 font-mono">Good Steps Design System</h1>
        <Section title="Menu">
          <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
          <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
        </Section>
        <Section title="Stake tags">
          <StakeTag
            disableDetailModalOpening={true}
            style={StakeTagStyle.COLORIZED}
            stake={{
              id: '1',
              pillar: {
                id: '1',
                color: ThemeColor.Green,
                i18n: [
                  {
                    name: 'Pilier 1',
                    language: SurveyLanguage.Fr,
                  },
                ],
                icon: ThemeIcon.Environment,
              },
            }}
          />
          <StakeTag
            disableDetailModalOpening={true}
            style={StakeTagStyle.GRAYED}
            stake={{
              id: '1',
              pillar: {
                id: '1',
                color: ThemeColor.Green,
                i18n: [
                  {
                    name: 'Pilier 1',
                    language: SurveyLanguage.Fr,
                  },
                ],
                icon: ThemeIcon.Environment,
              },
            }}
          />
        </Section>
        <Section title="Colors">
          <div className="grid grid-rows-11 grid-flow-col gap-1">
            <div>Red</div>
            <Swatch color="bg-red-900" />
            <Swatch color="bg-red-500" />
            <Swatch color="bg-red-300" />
            <Swatch color="bg-red-100" />
            <Swatch color="bg-red-50" />

            <div>Gray</div>
            <Swatch color="bg-gray-900" />
            <Swatch color="bg-gray-500" />
            <Swatch color="bg-gray-300" />
            <Swatch color="bg-gray-100" />
            <Swatch color="bg-gray-50" />

            <div>Blue</div>
            <Swatch color="bg-blue-900" />
            <Swatch color="bg-blue-500" />
            <Swatch color="bg-blue-300" />
            <Swatch color="bg-blue-100" />
            <Swatch color="bg-blue-50" />

            <div>Green</div>
            <Swatch color="bg-green-900" />
            <Swatch color="bg-green-500" />
            <Swatch color="bg-green-300" />
            <Swatch color="bg-green-100" />
            <Swatch color="bg-green-50" />

            <div>Yellow</div>
            <Swatch color="bg-yellow-900" />
            <Swatch color="bg-yellow-500" />
            <Swatch color="bg-yellow-300" />
            <Swatch color="bg-yellow-100" />
            <Swatch color="bg-yellow-50" />

            <div>Purple</div>
            <Swatch color="bg-purple-900" />
            <Swatch color="bg-purple-500" />
            <Swatch color="bg-purple-300" />
            <Swatch color="bg-purple-100" />
            <Swatch color="bg-purple-50" />
          </div>
        </Section>

        <Section title="Titles">
          <h1>This is an H1 Title</h1>
          <h2>This is an H2 Title</h2>
          <h3>This is an H3 Title</h3>
          <h4>This is an H4 Title</h4>
          <h5>This is an H5 Title</h5>
        </Section>

        <Section title="Text">
          <p className="text-sm">This is a small regular paragraph</p>
          <p className="text-sm font-bold">This is a small bold paragraph</p>
          <p className="">This is a regular paragraph</p>
          <p className="font-bold">This is a bold paragraph</p>
          <p className="text-lg">This is a large regular paragraph</p>
          <p className="text-lg font-bold">This is a large bold paragraph</p>
        </Section>

        <Section title="Buttons">
          <Subsection title="Medium">
            <div className="grid grid-rows-3 grid-flow-col gap-1">
              <div>
                <button className="primary">Primary</button>
              </div>
              <div>
                <button className="primary" disabled>
                  Primary disabled
                </button>
              </div>
              <div>
                <button className="">Secondary</button>
              </div>
              <div>
                <button className="blue primary">Primary Blue</button>
              </div>
              <div>
                <button className="blue primary" disabled>
                  Primary Blue disabled
                </button>
              </div>
              <div>
                <button className="blue ">Secondary Blue</button>
              </div>
              <div>
                <button className="tertiary">Tertiary</button>
              </div>
            </div>
          </Subsection>
          <Subsection title="Small">
            <div className="grid grid-rows-3 grid-flow-col gap-1">
              <div>
                <button className="small primary">Primary</button>
              </div>
              <div>
                <button className="small">Secondary</button>
              </div>
              <div>
                <button className="small tertiary">Tertiary</button>
              </div>
              <div>
                <button className="blue small primary">Primary Blue</button>
              </div>
              <div>
                <button className="blue small">Secondary Blue</button>
              </div>
            </div>
          </Subsection>
          <Subsection title="Icon-only">
            <div className="grid grid-rows-3 grid-flow-col gap-1">
              <div>
                <button className="icon-only primary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="icon-only">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="icon-only tertiary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="icon-only blue primary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="icon-only blue">
                  <ClockIcon />
                </button>
              </div>
              <div></div>
              <div>
                <button className="small icon-only primary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="small icon-only">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="small icon-only tertiary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="small blue icon-only primary">
                  <ClockIcon />
                </button>
              </div>
              <div>
                <button className="small blue icon-only">
                  <ClockIcon />
                </button>
              </div>
            </div>
          </Subsection>
          <Subsection title="Text + Icon">
            <div className="grid grid-rows-3 grid-flow-col gap-1">
              <div>
                <button className="primary">
                  <SurveyIcon />
                  Leading
                </button>
              </div>
              <div>
                <button className="primary">
                  Following
                  <SurveyIcon />
                </button>
              </div>
              <div>
                <button className="primary">
                  <SurveyIcon />
                  Both
                  <SurveyIcon />
                </button>
              </div>
              <div>
                <button className="small primary blue">
                  <SurveyIcon />
                  Leading
                </button>
              </div>
              <div>
                <button className="small primary blue">
                  Following
                  <SurveyIcon />
                </button>
              </div>
              <div>
                <button className="small primary blue">
                  <SurveyIcon />
                  Both
                  <SurveyIcon />
                </button>
              </div>
            </div>
          </Subsection>
        </Section>

        <Section title="Tags">
          <div className="flex gap-4">
            <div className="tag">generic tag</div>
            <div className="tag gray">gray tag</div>
          </div>
        </Section>

        <Section title="Number in circles">
          <div className="flex gap-4">
            <NumberCircle number={1} size={8} color={NumberCircleColor.Gray} />
            <NumberCircle number={2} size={8} color={NumberCircleColor.Blue} />
            <NumberCircle number={3} size={8} color={NumberCircleColor.Green} />
            <NumberCircle
              number={4}
              size={8}
              color={NumberCircleColor.Yellow}
            />
            <NumberCircle number={5} size={8} color={NumberCircleColor.Red} />
            <NumberCircle
              number={6}
              size={8}
              color={NumberCircleColor.Purple}
            />
          </div>
          <div className="flex gap-4">
            <NumberCircle number={1} size={10} color={NumberCircleColor.Gray} />
            <NumberCircle number={2} size={10} color={NumberCircleColor.Blue} />
            <NumberCircle
              number={3}
              size={10}
              color={NumberCircleColor.Green}
            />
            <NumberCircle
              number={4}
              size={10}
              color={NumberCircleColor.Yellow}
            />
            <NumberCircle number={5} size={10} color={NumberCircleColor.Red} />
            <NumberCircle
              number={6}
              size={10}
              color={NumberCircleColor.Purple}
            />
          </div>
          <div className="flex gap-4">
            <NumberCircle number={1} size={12} color={NumberCircleColor.Gray} />
            <NumberCircle number={2} size={12} color={NumberCircleColor.Blue} />
            <NumberCircle
              number={3}
              size={12}
              color={NumberCircleColor.Green}
            />
            <NumberCircle
              number={4}
              size={12}
              color={NumberCircleColor.Yellow}
            />
            <NumberCircle number={5} size={12} color={NumberCircleColor.Red} />
            <NumberCircle
              number={6}
              size={12}
              color={NumberCircleColor.Purple}
            />
          </div>
        </Section>

        <Section title="Cards">
          <div className="grid grid-cols-3 gap-4">
            <div className="card">
              <h4>generic card</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur ornare ex sed bibendum auctor. Nam dignissim turpis
                non malesuada mollis. Phasellus et quam diam.
              </p>
            </div>
          </div>
        </Section>
        <Section title="Dynamic elements">
          <button onClick={() => toast.openToastWithMessage('This is a toast')}>
            Toast
          </button>
          <button
            onClick={() =>
              modal.openModalWithComponent(
                <p>This is a Modal</p>,
                'Modal title',
              )
            }
          >
            Modal
          </button>
          <div className="flex items-center gap-2">
            <Toggle state={toggleState} setState={setToggleState} />
            <label className="form-input-label">Toggle</label>
          </div>
        </Section>
        <Section title="Progress bars">
          <div className="grid grid-cols-3 gap-4 pb-8">
            <ProgressBar
              value={0}
              total={10}
              style={ProgressBarStyles.COLORED}
            />
            <ProgressBar
              value={5}
              total={10}
              style={ProgressBarStyles.COLORED}
            />
            <ProgressBar
              value={10}
              total={10}
              style={ProgressBarStyles.COLORED}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 pb-8">
            <ProgressBar
              value={0}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
            />
            <ProgressBar
              value={5}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
            />
            <ProgressBar
              value={10}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 pb-8">
            <ProgressBar
              value={0}
              total={10}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
            <ProgressBar
              value={5}
              total={10}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
            <ProgressBar
              value={10}
              total={10}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 pb-8">
            <ProgressBar
              value={0}
              total={0}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={0}
            />
            <ProgressBar
              value={5}
              total={0}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
            <ProgressBar
              value={10}
              total={0}
              style={ProgressBarStyles.COLORED}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={0}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 pb-8">
            <ProgressBar
              value={0}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
            <ProgressBar
              value={5}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
            <ProgressBar
              value={10}
              total={10}
              style={ProgressBarStyles.BLACK_AND_WHITE}
              displayCounts={true}
              displayLabel={true}
              displayPercentage={true}
              targetThreshold={5}
            />
          </div>
        </Section>
      </div>
    </>
  );
}
