import { useAuthQuery, UserFragment } from 'graphql/generated';
import { RemoveUserButton } from './RemoveUserButton';
import React from 'react';

export function User({
  user,
  companyId,
}: {
  user: UserFragment;
  companyId: string;
}) {
  const { data } = useAuthQuery({
    variables: {
      id: user.authId || '',
    },
    skip: !user.authId,
  });

  const getDateString = (dateAsString: string) => {
    const date = new Date(dateAsString);
    return date.toLocaleString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Europe/Paris',
    });
  };

  return (
    <tr>
      <td className="font-semibold">
        {user.firstName} {user.lastName}
      </td>
      <td>{data?.auth.email}</td>
      <td>
        {data?.auth.lastSignInDate
          ? getDateString(data.auth.lastSignInDate)
          : '-'}
      </td>
      <td>
        <RemoveUserButton userId={user.id} companyId={companyId} />
      </td>
    </tr>
  );
}
