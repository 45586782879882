import React, { ReactNode } from 'react';
import { Theme } from '../../graphql/generated';
import { Tooltip } from 'react-tooltip';
import { getTooltipUniqueId } from '../../services/UtilsService';
import clsx from 'clsx';
import {
  CollaboratorsIcon,
  EcosystemIcon,
  EnvironmentIcon,
  GovernanceIcon,
} from '../icons';
import { useTranslation } from '@hooks/useTranslation';

export function ThemeIcon({
  theme,
  enableTooltip,
  className,
}: {
  theme: Theme;
  enableTooltip?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  const id = getTooltipUniqueId('ThemeIcon');

  return (
    <div
      data-tooltip-content={
        enableTooltip ? t(`enum:theme.${theme?.toLowerCase()}`) : ''
      }
      data-tooltip-id={id}
    >
      <Tooltip id={id} className="tooltip" />
      {getThemeIcon(theme, className)}
    </div>
  );
}

function getThemeIcon(theme: Theme, className?: string): ReactNode {
  switch (theme) {
    case Theme.Collaborators:
      return <CollaboratorsIcon className={clsx('w-5 h-5', className)} />;
    case Theme.Governance:
      return <GovernanceIcon className={clsx('w-5 h-5', className)} />;
    case Theme.Ecosystem:
      return <EcosystemIcon className={clsx('w-5 h-5', className)} />;
    case Theme.Environment:
    default:
      return <EnvironmentIcon className={clsx('w-5 h-5', className)} />;
  }
}
