import headerIllustration from '../../../assets/images/illustrations/diagnostic/diag-header.png';
import React from 'react';

export function MaterialityMatrixHeader({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="bg-blue-50 px-8 relative">
      <div className="flex items-center gap-16 main-content">
        <div className="flex flex-col gap-4 w-7/12">
          <h1>Matrice de matérialité</h1>
          <p>
            Cette matrice permet de comparer la perception de l'importance de
            chaque enjeu entre les différentes parties prenantes. Plus les
            enjeux sont loins de la ligne de consensus, plus l'écart de
            perception est élevé.
          </p>
        </div>
        <div className="w-5/12 relative">
          <img src={headerIllustration} alt="" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
