import { useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';

type ContextType = 'minutes' | 'hours' | 'days' | 'months' | 'years';

export const useRelativeTime = (dateInput: Date | string | number): string => {
  const [relativeTime, setRelativeTime] = useState('');
  const { t } = useTranslation();

  const getRelativeTime = (inputDate: Date | string | number): { text: string; nextUpdateInMs: number } => {
    const now = new Date();
    const pastDate = new Date(inputDate);
    const diffMs = now.getTime() - pastDate.getTime();

    const diffMinutes = diffMs / (1000 * 60);
    const diffHours = diffMs / (1000 * 60 * 60);
    const diffDays = diffMs / (1000 * 60 * 60 * 24);
    const diffMonths = diffMs / (1000 * 60 * 60 * 24 * 30);
    const diffYears = diffMs / (1000 * 60 * 60 * 24 * 365);

    let contextPeriod: ContextType;
    let count: number;

    if (diffMinutes < 60) {
      contextPeriod = 'minutes';
      count = Math.floor(diffMinutes);
    } else if (diffHours < 24) {
      contextPeriod = 'hours';
      count = Math.floor(diffHours);
    } else if (diffDays < 30) {
      contextPeriod = 'days';
      count = Math.floor(diffDays);
    } else if (diffMonths < 12) {
      contextPeriod = 'months';
      count = Math.floor(diffMonths);
    } else {
      contextPeriod = 'years';
      count = Math.floor(diffYears);
    }

    const text = t(`global:relativeTime.${contextPeriod}`, {
      count,
    });

    return { text, nextUpdateInMs: (1 - (diffMinutes % 1)) * 60 * 1000 };
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    const updateRelativeTime = () => {
      const { text, nextUpdateInMs } = getRelativeTime(dateInput);
      setRelativeTime(text);

      timerId = setTimeout(updateRelativeTime, nextUpdateInMs);
    };

    updateRelativeTime();

    return () => {
      clearTimeout(timerId);
    };
  }, [dateInput, t]);

  return relativeTime;
};
