import React from 'react';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export function IroFormula() {
  const { t } = useTranslation();
  return (
    <div className="space-y-4">
      <p className="text-gray-900">{t('iro.criteria.formula.description')}</p>
      <div className="border p-2 rounded-md space-y-2">
        <div className="flex items-center font-mono gap-1 text-xs">
          <Pre highlighted={true} color={'green'}>
            {t('iro.criteria.formula.severity')}
          </Pre>
          <span>=</span>
          <Pre color={'green'}>{t('iro.criteria.scale.title')}</Pre>
          <span>+</span>
          <Pre color={'green'}>{t('iro.criteria.scope.title')}</Pre>
          <span>+</span>
          <Pre color={'green'}>{t('iro.criteria.irremediability.title')}</Pre>
        </div>
        <div className="flex items-center font-mono gap-1 text-xs">
          <Pre highlighted={true} color={'purple'}>
            {t('iro.criteria.formula.materiality')}
          </Pre>
          <span>=</span>
          <span>(</span>
          <Pre highlighted={true} color={'green'}>
            {t('iro.criteria.formula.severity')}
          </Pre>
          <span>/</span>
          <span>3</span>
          <span>)</span>
          <span>x</span>
          <Pre color={'purple'}>{t('iro.criteria.likelihood.title')}</Pre>
        </div>
      </div>
    </div>
  );
}

function Pre({
  highlighted,
  color,
  children,
}: {
  highlighted?: boolean;
  color: 'blue' | 'green' | 'red' | 'purple' | 'yellow' | 'indigo' | 'pink';
  children: React.ReactNode;
}) {
  return (
    <pre
      className={clsx(
        'rounded-md py-0.5 px-1',
        highlighted
          ? `bg-${color}-900 text-white font-bold`
          : `bg-${color}-50 text-gray-900 font-normal`,
      )}
    >
      {children}
    </pre>
  );
}
