import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../AppRoutes';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { FeatureNames } from '../../../graphql/generated';

export const enum PROJECT_HOME_TABS {
  METHODOLOGY = 'METHODOLOGY',
  NOTEBOOK = 'NOTEBOOK',
  COMPANY = 'COMPANY',
  USERS = 'USERS',
  SETTINGS = 'SETTINGS',
}

export const useProjectTabs = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      key: PROJECT_HOME_TABS.METHODOLOGY,
      name: t('nav:page.project.methodology'),
      path: AppRoutes.Project,
    },
    {
      key: PROJECT_HOME_TABS.NOTEBOOK,
      name: t('nav:page.project.notes'),
      path: AppRoutes.ProjectNotebook,
    },
    {
      key: PROJECT_HOME_TABS.COMPANY,
      name: t('nav:page.project.company'),
      path: AppRoutes.ProjectCompany,
    },
    {
      key: PROJECT_HOME_TABS.USERS,
      name: t('nav:page.project.users'),
      path: AppRoutes.ProjectUsers,
    }];

  const isSettingsEnabled = useFeatureEnabled(FeatureNames.CompanySettings);
  if (isSettingsEnabled) {
    tabs.push({
      key: PROJECT_HOME_TABS.SETTINGS,
      name: t('nav:page.project.settings'),
      path: AppRoutes.ProjectSettings,
    });
  }

  return tabs;
};
