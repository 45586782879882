import React, { useEffect } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerIdentityInput,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionIdentity({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t } = useTranslation();

  const [name, setName] = React.useState(answer?.identity?.name || '');
  const [email, setEmail] = React.useState(answer?.identity?.email || '');
  const [role, setRole] = React.useState(answer?.identity?.role || '');
  const [isEmailValid, setIsEmailValid] = React.useState(true);

  const updateAnswer = (identity: SurveySubmitAnswerIdentityInput) => {
    if (identity.name && identity.email && identity.role) {
      setIsAnswerValid(true);
    } else {
      setIsAnswerValid(false);
    }
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Email,
      identity,
    });
  };

  const setNewName = (newName: string) => {
    setName(newName);
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      updateAnswer({
        name: newName,
        email: '',
        role: role,
        userAgent: navigator.userAgent,
      });
    } else {
      setIsEmailValid(true);
      updateAnswer({
        name: newName,
        email: email,
        role: role,
        userAgent: navigator.userAgent,
      });
    }
  };

  const validateEmail = (email: string) => {
    if (!name || !role || !email) return true;
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const setNewEmail = (newEmail: string) => {
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setIsEmailValid(false);
      updateAnswer({
        name: name,
        email: '',
        role: role,
        userAgent: navigator.userAgent,
      });
    } else {
      setIsEmailValid(true);
      updateAnswer({
        name: name,
        email: newEmail,
        role: role,
        userAgent: navigator.userAgent,
      });
    }
  };

  const setNewRole = (newRole: string) => {
    setRole(newRole);
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      updateAnswer({
        name: name,
        email: '',
        role: newRole,
        userAgent: navigator.userAgent,
      });
    } else {
      setIsEmailValid(true);
      updateAnswer({
        name: name,
        email: email,
        role: newRole,
        userAgent: navigator.userAgent,
      });
    }
  };

  // Validate answer
  useEffect(() => {
    if (
      answer?.identity?.name &&
      answer?.identity?.email &&
      answer?.identity?.role
    ) {
      setIsAnswerValid(true);
    } else {
      setIsAnswerValid(false);
    }
  }, [answer, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <input
          type="text"
          className="form-input-text-survey w-full lg:w-3/4 xl:w-1/2"
          placeholder={t('survey.question.identity.name')}
          value={name}
          onChange={(e) => setNewName(e.target.value)}
        />
        <input
          type="email"
          className={clsx(
            'form-input-text-survey w-full lg:w-3/4 xl:w-1/2',
            !isEmailValid && 'invalid',
          )}
          placeholder={t('survey.question.identity.email')}
          value={email}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <input
          type="text"
          className="form-input-text-survey w-full lg:w-3/4 xl:w-1/2"
          placeholder={t('survey.question.identity.role')}
          value={role}
          onChange={(e) => setNewRole(e.target.value)}
        />
      </div>
    </div>
  );
}
