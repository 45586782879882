import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  CheckIcon,
  ErrorIcon,
  InfoIcon,
  StarIcon,
  WarningIcon,
} from '../icons';

export enum MessageBoxType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  Feature = 'feature',
}

const IllustrationByType = {
  [MessageBoxType.Info]: <InfoIcon className="shrink-0 w-18" />,
  [MessageBoxType.Warning]: <WarningIcon className="shrink-0 w-18" />,
  [MessageBoxType.Error]: <ErrorIcon className="shrink-0 w-18" />,
  [MessageBoxType.Success]: <CheckIcon className="shrink-0 w-18" />,
  [MessageBoxType.Feature]: <StarIcon className="shrink-0 w-18" />,
};

const TypeToColor = {
  [MessageBoxType.Info]: 'bg-blue-50 text-blue-900',
  [MessageBoxType.Feature]: 'bg-purple-50 text-purple-900',
  [MessageBoxType.Success]: 'bg-green-50 text-green-900',
  [MessageBoxType.Warning]: 'bg-yellow-50 text-yellow-900',
  [MessageBoxType.Error]: 'bg-red-50 text-red-900',
};

export function MessageBox({
  type,
  children,
  button,
}: {
  type: MessageBoxType;
  children: ReactNode;
  button?: ReactNode;
}) {
  return (
    <div
      className={clsx(
        'flex items-start w-full p-4 rounded-xl gap-4 text-sm',
        TypeToColor[type],
      )}
    >
      {IllustrationByType[type]}
      <div className="grow">{children}</div>
      {button && <div className="space-y-4 self-center">{button}</div>}
    </div>
  );
}
