import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { EditIcon } from '../../icons';
import { useNavContext } from '../../../providers/NavContextProvider';
import { AppRoutes } from '../../../screens/AppRoutes';

export function UpdateActionButton({
  action,
  displayLabel = false,
}: {
  action: { id: string };
  displayLabel?: boolean;
}) {
  const navContext = useNavContext();
  return (
    <NavLink
      to={generatePath(AppRoutes.RoadmapActionEdit, {
        actionId: action.id,
      })}
      className="button primary purple"
      onClick={() => navContext?.setIsNavCollapsed(true)}
    >
      <EditIcon />
      {displayLabel && 'Modifier'}
    </NavLink>
  );
}
