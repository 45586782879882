import React, { useEffect, useState } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionEnps({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t } = useTranslation();

  const [selectedScore, setSelectedScore] = useState<number | null>(
    answer?.nps || null,
  );

  const toggleScore = (score: number) => {
    if (score === selectedScore) {
      // Unselect
      setSelectedScore(null);
      updateAnswer(null);
    } else {
      setSelectedScore(score);
      updateAnswer(score);
    }
  };

  const updateAnswer = (newSelectedScore: number | null) => {
    if (newSelectedScore === null) {
      setIsAnswerValid(false);
      setAnswer(null);
      return;
    }
    setIsAnswerValid(true);
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Nps,
      nps: newSelectedScore,
    });
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(selectedScore !== null);
  }, [selectedScore, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div>
          <div className="flex flex-row gap-2 w-full items-center justify-center flex-wrap">
            {Array.from({ length: 11 }, (v, i) => i).map((score) => (
              <div
                className={clsx(
                  'form-input-multiple-choice-item enps',
                  selectedScore === score && 'selected',
                )}
                key={score}
                onClick={() => toggleScore(score)}
              >
                {score}
              </div>
            ))}
          </div>
          <div className="gap-4 justify-between font-semibold hidden md:flex">
            <div className="mt-2">{t('survey.question.enps.not_likely')}</div>
            <div className="mt-2">{t('survey.question.enps.very_likely')}</div>
          </div>
          <div className="flex flex-col text-center gap-1 mt-2 font-semibold md:invisible">
            <div>0: {t('survey.question.enps.not_likely')}</div>
            <div>10: {t('survey.question.enps.very_likely')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
