import React from 'react';
import { MaterialityTypes, QualityLevel } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export function UtopiesQualityLevelLabel({
  qualityLevel,
  materialityType,
}: {
  qualityLevel: QualityLevel;
  materialityType: MaterialityTypes;
}) {
  const { t } = useTranslation();
  if (materialityType === MaterialityTypes.Financial) {
    switch (qualityLevel) {
      case QualityLevel.Negative:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.financial.negative',
            )}
          </>
        );
      case QualityLevel.Positive:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.financial.positive',
            )}
          </>
        );
      case QualityLevel.Neutral:
      default:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.financial.neutral',
            )}
          </>
        );
    }
  } else {
    switch (qualityLevel) {
      case QualityLevel.Negative:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.impact.negative',
            )}
          </>
        );
      case QualityLevel.Positive:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.impact.positive',
            )}
          </>
        );
      case QualityLevel.Neutral:
      default:
        return (
          <>
            {t(
              'survey.question.utopies_stakes_double_materiality.levels.quality.impact.neutral',
            )}
          </>
        );
    }
  }
}
