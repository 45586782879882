import React from 'react';
import { DownloadRoadmapButton } from './DownloadRoadmapButton';
import { useRightDrawer } from '../../layout/RightDrawer';
import { ImportRoadmapJsonFileForm } from './ImportRoadmapJsonFileForm';
import { RoadmapSettingsDrawerFragment } from '../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export function RoadmapSettingsDrawer({
  roadmap,
}: {
  roadmap: RoadmapSettingsDrawerFragment;
}) {
  const drawer = useRightDrawer();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <h1 className="px-8 py-10  bg-gray-50 title-h3 border-b border-gray-100">
        {t('global:settings')}
      </h1>
      <div className="p-8 grow divide-y divide-gray-100 space-y-4">
        <div>
          <p className="text-base font-bold mb-4">
            Exporter la feuille de route
          </p>
          <DownloadRoadmapButton roadmap={roadmap} />
        </div>
        <div className="pt-4">
          <p className="text-base font-bold mb-4">
            Importer une feuille de route (JSON)
          </p>
          <ImportRoadmapJsonFileForm roadmap={roadmap} />
        </div>
      </div>
      <div className="p-8">
        <button className="w-full" onClick={() => drawer.closeDrawer()}>
          {t('global:close')}
        </button>
      </div>
    </div>
  );
}
