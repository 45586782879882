import clsx from 'clsx';
import { ActionPriorityLevel } from '../../graphql/generated';
import { NumberCircle, NumberCircleColor } from '../generic/NumberCircle';
import { Tooltip } from 'react-tooltip';
import React from 'react';
import { getTooltipUniqueId } from '../../services/UtilsService';

export function PriorityBadge({
  priorityLevel,
}: {
  priorityLevel: ActionPriorityLevel;
}) {
  const id = getTooltipUniqueId('PriorityBadge');
  return (
    <div data-tooltip-id={id} className="cursor-help">
      <PriorityBadgeTooltip priorityLevel={priorityLevel} id={id} />
      <NumberCircle
        number={priorityLevelToNumber(priorityLevel)}
        color={colorFromPriorityLevel(priorityLevel)}
        size={8}
      />
    </div>
  );
}

function priorityLevelToNumber(priorityLevel: ActionPriorityLevel): number {
  switch (priorityLevel) {
    case ActionPriorityLevel.Unimportant:
      return 4;
    case ActionPriorityLevel.Important:
      return 3;
    case ActionPriorityLevel.Priority:
      return 2;
    case ActionPriorityLevel.Urgent:
      return 1;
  }
}

export function PriorityBadgeTooltip({
  priorityLevel,
  id,
}: {
  priorityLevel: ActionPriorityLevel;
  id: string;
}) {
  return (
    <Tooltip id={id} className="tooltip">
      <p className="title-h6 mb-1">Priorité</p>
      <ol className="flex flex-col gap-1">
        <PriorityItem
          index={1}
          priorityLevel={ActionPriorityLevel.Urgent}
          isActive={priorityLevel === ActionPriorityLevel.Urgent}
        />
        <PriorityItem
          index={2}
          priorityLevel={ActionPriorityLevel.Priority}
          isActive={priorityLevel === ActionPriorityLevel.Priority}
        />
        <PriorityItem
          index={3}
          priorityLevel={ActionPriorityLevel.Important}
          isActive={priorityLevel === ActionPriorityLevel.Important}
        />
        <PriorityItem
          index={4}
          priorityLevel={ActionPriorityLevel.Unimportant}
          isActive={priorityLevel === ActionPriorityLevel.Unimportant}
        />
      </ol>
    </Tooltip>
  );
}

function PriorityItem({
  priorityLevel,
  index,
  isActive,
}: {
  priorityLevel: ActionPriorityLevel;
  index: number;
  isActive: boolean;
}) {
  return (
    <li className={clsx('flex items-center gap-1', isActive || 'opacity-70')}>
      <NumberCircle
        number={index}
        size={8}
        color={
          isActive
            ? colorFromPriorityLevel(priorityLevel)
            : NumberCircleColor.Gray
        }
      />
      <span
        className={clsx(
          'max-w-sm text-left',
          isActive ? 'font-bold' : 'font-normal',
        )}
      >
        {labelFromPriorityLevel(priorityLevel)}
      </span>
    </li>
  );
}

function labelFromPriorityLevel(priorityLevel: ActionPriorityLevel): string {
  switch (priorityLevel) {
    case ActionPriorityLevel.Unimportant:
      return 'Aller plus loin. “Nice to have”';
    case ActionPriorityLevel.Important:
      return 'Important, mais non prioritaire : à lancer dans les 12 prochains mois';
    case ActionPriorityLevel.Priority:
      return 'Prioritaire : à mettre en place dans les 6 prochains mois';
    case ActionPriorityLevel.Urgent:
      return 'Urgent : touche à la pérennité de l’entreprise à court et moyen terme et/ou est un pré-requis pour d’autres actions. Avantages significatifs à être enclenché dans les 3 prochains mois';
  }
}

export function titleFromPriorityLevel(
  priorityLevel: ActionPriorityLevel,
): string {
  switch (priorityLevel) {
    case ActionPriorityLevel.Unimportant:
      return 'Aller plus loin';
    case ActionPriorityLevel.Important:
      return 'Important';
    case ActionPriorityLevel.Priority:
      return 'Prioritaire ';
    case ActionPriorityLevel.Urgent:
      return 'Urgent';
  }
}

export function colorFromPriorityLevel(
  priorityLevel: ActionPriorityLevel,
): NumberCircleColor {
  switch (priorityLevel) {
    case ActionPriorityLevel.Unimportant:
      return NumberCircleColor.Gray;
    case ActionPriorityLevel.Important:
      return NumberCircleColor.Purple;
    case ActionPriorityLevel.Priority:
      return NumberCircleColor.Yellow;
    case ActionPriorityLevel.Urgent:
      return NumberCircleColor.Red;
  }
}
