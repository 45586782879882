import {
  DeleteSurveyButtonFragment,
  MaturityEvaluationContextualMenuFragment,
  MaturityEvaluationsListScreenDocument,
  useMaturityEvaluationContextualMenu_DeleteMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { generatePath, NavLink } from 'react-router-dom';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  ConfirmationModal,
  ConfirmationModalType,
} from '../../modals/ConfirmationModal';
import { useModal } from '../../layout/Modal';
import { DeleteIcon, EditIcon, SettingsIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { ContextualMenu } from '../../generic/ContextualMenu';
import { SURVEY_TABS } from '../survey/Survey';
import { AppRoutes } from '../../../screens/AppRoutes';

export function MaturityEvaluationContextualMenu({
  survey,
}: {
  survey: MaturityEvaluationContextualMenuFragment;
}) {
  const { t } = useTranslation();
  return (
    <ContextualMenu>
      <NavLink
        to={generatePath(AppRoutes.MaturityEvaluationItem, {
          surveyId: survey.id,
        })}
        className="contextual-menu-item button"
      >
        <EditIcon className="w-3 h-3" />
        <div>
          <div>{t('survey.contextualMenu.content')}</div>
        </div>
      </NavLink>
      <NavLink
        to={generatePath(AppRoutes.MaturityEvaluationItemTab, {
          surveyId: survey.id,
          tab: SURVEY_TABS.SETTINGS,
        })}
        className="contextual-menu-item button"
      >
        <SettingsIcon className="w-3 h-3" />
        <div>{t('survey.contextualMenu.settings')}</div>
      </NavLink>
      <hr className="border-t h-1 w-full mt-1 min-w-40" />
      <DeleteMaturityEvaluationButton survey={survey} />
    </ContextualMenu>
  );
}

function DeleteMaturityEvaluationButton({
  survey,
}: {
  survey: DeleteSurveyButtonFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const [deleteMaturityEvaluationMutation] =
    useMaturityEvaluationContextualMenu_DeleteMutation({
      refetchQueries: () => [
        {
          query: MaturityEvaluationsListScreenDocument,
          variables: {
            companyId: projectContext?.enterprise?.id || '',
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSurvey = () => {
    setIsDeleting(true);
    deleteMaturityEvaluationMutation({
      variables: {
        id: survey.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:maturity.delete.success'));
      })
      .catch((err) => console.error(err))
      .finally(() => setIsDeleting(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('survey.contextualMenu.delete.confirm.description')}
            callbackIfConfirmed={deleteSurvey}
            type={ConfirmationModalType.Danger}
          />,
          t('survey.contextualMenu.delete.confirm.title'),
        )
      }
    >
      {isDeleting ? <Loader /> : <DeleteIcon />}
      <div>{t('survey.contextualMenu.delete')}</div>
    </button>
  );
}
