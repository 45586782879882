import { useEffect, useRef } from 'react';
import { RightDrawer, RightDrawerProvider } from './RightDrawer';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { Modal, ModalProvider } from './Modal';
import { Toast, ToastProvider } from './Toast';
import { SSOProvider } from '../../providers/SSOProvider';

export function SSOLayout() {
  const location = useLocation();
  const mainContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    mainContentRef.current &&
      mainContentRef.current.scrollTo({
        top: 0,
        left: 0,
        // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        behavior: 'instant' as ScrollBehavior,
      });
  }, [location]);

  return (
    <SSOProvider>
      <RightDrawerProvider>
        <ModalProvider>
          <ToastProvider>
            <main
              ref={mainContentRef}
              className="flex flex-col h-screen overflow-y-auto bg-white"
            >
              <ErrorBoundary key={location.pathname}>
                <Outlet />
              </ErrorBoundary>
            </main>
            <RightDrawer />
            <Modal />
            <Toast />
          </ToastProvider>
        </ModalProvider>
      </RightDrawerProvider>
    </SSOProvider>
  );
}
