import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({ uri: process.env.STRAPI_URL });
const token = process.env.STRAPI_API_KEY;
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,

      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const cmsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, httpLink]),
  devtools: {
    enabled: process.env.NODE_ENV !== 'production',
  },
});
