import React from 'react';
import { CmsComponentGuidance_GuidanceFragment } from '../../../graphql/cms/generated';
import { BlocksRenderer } from '../renderers/BlocksRenderer';
import { useRightDrawer } from '../../layout/RightDrawer';
import { useTranslation } from '@hooks/useTranslation';
import { InfoIcon } from '../../icons';

export function Guidances({
  guidances,
}: {
  guidances: CmsComponentGuidance_GuidanceFragment[];
}) {
  const { t } = useTranslation();
  const rightDrawer = useRightDrawer();
  const openRightDrawer = () => {
    rightDrawer.openDrawerWithComponent(
      <div className="space-y-4 p-8">
        <h1>
          {t('disclosure_requirements.disclosure_requirement.guidelines.title')}
        </h1>
        {guidances.map((guidance, index) => (
          <div key={index} className="">
            <BlocksRenderer content={guidance.content} />
          </div>
        ))}
      </div>,
    );
  };
  return (
    <div className="bg-blue-100 p-4 rounded-xl space-y-4">
      <div className="flex items-start gap-2 text-blue-900">
        <InfoIcon className="w-4 h-4 shrink-0" />
        <div className="text-sm space-y-2">
          {t(
            'disclosure_requirements.disclosure_requirement.guidelines.description',
          )}
        </div>
      </div>

      <div className="flex justify-center">
        <button className="secondary purple" onClick={openRightDrawer}>
          {t('disclosure_requirements.disclosure_requirement.guidelines.cta')}
        </button>
      </div>
    </div>
  );
}
