import React, { ReactNode } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { Tag } from './Tag';

interface Props {
  children?: ReactNode;
}

export const RequiredBadge = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <Tag color="red" small>
      {children || t('global:required')}
    </Tag>
  );
};
