import {
  MaturityEvaluationSettings_SurveySettingsFormFragment,
  SurveysDocument,
  SurveyUpdateInput,
  useMaturityEvaluationSettings_DeleteMutation,
  useMaturityEvaluationSettings_SurveyQuery,
  useMaturityEvaluationSettingsMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { ExportSurveyButton } from '../survey/ExportSurveyButton';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { AppRoutes } from '../../../screens/AppRoutes';

export function MaturityEvaluationSettings({ surveyId }: { surveyId: string }) {
  const query = useMaturityEvaluationSettings_SurveyQuery({
    variables: { id: surveyId },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return null;
  }

  const survey = query.data?.maturityEvaluation;

  return <MaturityEvaluationSettingsForm survey={survey} />;
}

export function MaturityEvaluationSettingsForm({
  survey,
}: {
  survey: MaturityEvaluationSettings_SurveySettingsFormFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const navigate = useNavigate();
  const projectContext = useProjectContext();

  const [surveyUpdateInput, setSurveyUpdateInput] = useState<SurveyUpdateInput>(
    {
      id: survey.id,
      name: survey.name,
    },
  );

  const propagateNewSurveyState = (surveyUpdateInput: SurveyUpdateInput) => {
    setSurveyUpdateInput(surveyUpdateInput);
    updateSurvey(surveyUpdateInput);
  };

  const changeSurveyName = (value: string) => {
    propagateNewSurveyState({
      ...surveyUpdateInput,
      name: value,
    });
  };

  // Perform update mutation
  const [updateSurveyMutation] = useMaturityEvaluationSettingsMutation();
  const updateSurvey = (input: SurveyUpdateInput) => {
    updateSurveyMutation({
      variables: {
        input,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Évaluation mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  // Perform delete mutation
  const [deleteSurveyMutation] = useMaturityEvaluationSettings_DeleteMutation({
    refetchQueries: () => [
      {
        query: SurveysDocument,
        variables: {
          companyId: projectContext?.enterprise?.id || '',
        },
      },
    ],
  });
  const deleteSurvey = () => {
    deleteSurveyMutation({
      variables: {
        id: survey.id,
      },
    })
      .then((res) => {
        if (res.data?.deleteOneMaturityEvaluation) {
          toast.openToastWithMessage('Évaluation supprimée');
          navigate(AppRoutes.MaturityEvaluations);
        } else {
          toast.openToastWithError(
            "Erreur lors de la suppression de l'évaluation",
          );
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="main-content p-8 space-y-8">
      <h2 className="">Paramètres</h2>
      <div className="w-1/2 flex flex-col gap-8">
        <div>
          <label htmlFor="name" className="form-input-label mb-2">
            Nom de l&apos;évaluation
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-input-text"
            value={surveyUpdateInput.name || ''}
            onChange={(e) =>
              setSurveyUpdateInput({
                ...surveyUpdateInput,
                name: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                changeSurveyName(surveyUpdateInput.name || '');
              }
            }}
            onBlur={(e) => changeSurveyName(e.target.value)}
          />
        </div>
        <ExportSurveyButton survey={survey} />
        <div className="bg-red-50 p-4 rounded-xl flex items-center justify-between">
          <div className="text-red-900 text-sm">
            Supprimer l&apos;évaluation et toutes les données liées
          </div>
          <button
            className="primary danger"
            onClick={() =>
              modal.openModalWithComponent(
                <ConfirmationModal
                  message="Cela supprimera également les réponses et analyse liés à cette évaluation. Cette opération est irréversible !"
                  callbackIfConfirmed={deleteSurvey}
                />,
                `Supprimer l'évaluation de maturité`,
              )
            }
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
}
