import {
  useTranslation as useI18nextTranslation,
  UseTranslationResponse,
} from 'react-i18next';
import type { Namespace } from 'i18next';
import { SurveyLanguage } from '../graphql/generated';

type Translation = {
  language: SurveyLanguage;
  [key: string]: any;
};

export type TranslatableObject = {
  i18n?: Translation[] | null;
};

type ExtendedUseTranslationResponse = UseTranslationResponse<Namespace, ''> & {
  translateProperty: <
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    property: K,
    language?: SurveyLanguage,
    fallbackDefault?: boolean,
  ) => string;
  getMissingTranslations: <
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    properties: K[],
    languages: SurveyLanguage[],
  ) => SurveyLanguage[];
};

export const useTranslation = (): ExtendedUseTranslationResponse => {
  const i18nextTranslation = useI18nextTranslation();

  function translateProperty<
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    property: K,
    language?: SurveyLanguage,
    fallbackDefault = true,
  ): string {
    const currentLanguage = language || i18nextTranslation.i18n.language;
    const fallbackLanguage = process.env.DEFAULT_LANGUAGE as SurveyLanguage;

    const translation = obj?.i18n?.find(
      (i18n) => i18n.language === currentLanguage,
    );

    const fallbackTranslation = fallbackDefault
      ? obj?.i18n?.find((i18n) => i18n.language === fallbackLanguage)
      : null;

    if (translation && translation[property]) {
      return translation[property];
    } else if (
      fallbackDefault &&
      fallbackTranslation &&
      fallbackTranslation[property]
    ) {
      return fallbackTranslation[property];
    } else {
      return '';
    }
  }

  function getMissingTranslations<
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    properties: K[],
    languages: SurveyLanguage[],
  ): SurveyLanguage[] {
    if (!obj || !obj.i18n) {
      return languages;
    }

    return languages.filter((language) => {
      return properties.some((property) => {
        const translation = obj.i18n?.find(
          (i18n) => i18n.language === language,
        );
        return !(translation && translation[property]);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return {
    ...i18nextTranslation,
    translateProperty,
    getMissingTranslations,
  };
};
