import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { IroSummary as IroSummaryContent } from '../../../../components/project/iro/summary/IroSummary';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useProIroSummaryScreenQuery } from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { ReferentialProvider } from '../../../../components/project/context/ReferentialContext';

function IroSummary() {
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const companyReferentialDocumentQuery = useProIroSummaryScreenQuery({
    variables: {
      referentialId: projectContext?.enterprise?.referential?.id || '',
      companyId: projectContext?.enterprise?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });

  if (companyReferentialDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (companyReferentialDocumentQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {companyReferentialDocumentQuery.error.message}
      </MessageBox>
    );
  }

  if (companyReferentialDocumentQuery.data?.referential) {
    return (
      <ReferentialProvider
        initialReferential={
          companyReferentialDocumentQuery.data?.referential.id
        }
      >
        <PageTitle title={t('screen.iro.iro_summary')} />
        <Breadcrumb />
        <div className="w-full">
          <IroSummaryContent
            referential={companyReferentialDocumentQuery.data?.referential}
            scores={
              companyReferentialDocumentQuery.data?.computeDoubleMaterialities
            }
          />
        </div>
      </ReferentialProvider>
    );
  }

  return null;
}

export const IroSummaryScreen = withProjectContext(IroSummary, {
  requiredCompany: true,
});
