import {
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { ToggleRequired } from './atoms/ToggleRequired';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';

export function QuestionEnpsForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = () => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          title: questionDraft.title,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>

      <div className="pt-8 space-y-2">
        <h3>Contenu</h3>
        <div className="space-y-2">
          <label htmlFor="title" className="form-input-label">
            Titre
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() => updateQuestion()}
          />
          <MessageBox type={MessageBoxType.Info}>
            Nous recommandons de garder cet intitulé qui est la question
            officielle de mesure de l’eNPS : “Sur une échelle de 0 à 10, quelle
            est la probabilité pour que vous recommandiez à votre entourage de
            venir travailler dans notre entreprise ?”
          </MessageBox>
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
      </div>
    </div>
  );
}
