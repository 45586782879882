import React from 'react';
import { useLeftDrawer } from '../layout/LeftDrawer';
import { BurgerIcon } from '../icons';
import { useNavContext } from '../../providers/NavContextProvider';
import { LeftNavInner } from './LeftNav/LeftNavInner';

export function OpenLeftDrawerMenuButton() {
  const leftDrawer = useLeftDrawer();
  const navContext = useNavContext();
  const openLeftMenu = () => {
    leftDrawer.openDrawerWithComponent(
      <div className="p-4 h-full">
        <LeftNavInner />
      </div>,
    );
  };
  if (!navContext?.isNavCollapsed) {
    return null;
  }
  return (
    <button className="unstyled" onClick={openLeftMenu}>
      <BurgerIcon className="w-3 h-3" />
    </button>
  );
}
