import { LoaderFullscreen } from '../../../../components/layout/Loader';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { NavLink } from 'react-router-dom';
import { OpenIcon } from '../../../../components/icons';
import { MatrixProvider } from '../../../../components/project/publications/doubleMateriality/MatrixContext';
import { AppRoutes } from '../../../AppRoutes';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import {
  DOUBLE_MATERIALITY_MATRIX_TABS,
  useDoubleMaterialityMatrixTabs,
} from './useDoubleMaterialityMatrixTabs';
import { DoubleMaterialityHeader } from './DoubleMaterialityHeader';
import { DoubleMaterialityStakeholderMatrix } from '../../../../components/project/publications/doubleMateriality/DoubleMaterialityStakeholderMatrix';
import { SurveyQuestionType } from '../../../../graphql/generated';
import { UtopiesProDoubleMaterialityMatrixScreen } from '../custom/UtopiesProDoubleMaterialityMatrixScreen';

export const DoubleMaterialityMatrixStakeholderScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();

    if (projectContext?.isLoadingEnterprise) {
      return <LoaderFullscreen />;
    }

    return (
      <TabNavigationLayout<DOUBLE_MATERIALITY_MATRIX_TABS.STAKEHOLDER>
        tabs={useDoubleMaterialityMatrixTabs()}
        activeTab={DOUBLE_MATERIALITY_MATRIX_TABS.STAKEHOLDER}
        renderTabs={(tabsContent) => (
          <>
            <div className="py-12 px-16">
              <DoubleMaterialityHeader />
            </div>
            {tabsContent}
          </>
        )}
      >
        <PageTitle title={t('screen.double_materiality.title')} />
        <div className="bg-gray-50 shadow-inner">
          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityMatrixStakeholderInner />
          </MatrixProvider>
        </div>
      </TabNavigationLayout>
    );
  },
  { requiredCompany: true },
);

export const DoubleMaterialityMatrixStakeholderInner = () => {
  const { t } = useTranslation();
  const projectContext = useProjectContext()

    return (
      <div className="">
        <div className="py-16 main-content space-y-4">
          <div className="flex items-center gap-4 justify-between">
            <h3>{t('screen.double_materiality.stakeholder_matrix.title')}</h3>
            <NavLink
              to={AppRoutes.Survey}
              className="button unstyled small text-gray-500"
            >
              {t(
                'screen.double_materiality.stakeholder_matrix.gotoSurveyScreen',
              )}
              <OpenIcon />
            </NavLink>
          </div>

          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityStakeholderMatrix />
          </MatrixProvider>
        </div>
        <div className="bg-white">
          {(projectContext?.enterprise?.metadata?.customQuestionTypes || []).includes(
            SurveyQuestionType.UtopiesStakeDoubleMateriality,
          ) && <UtopiesProDoubleMaterialityMatrixScreen />}
        </div>
      </div>
    );
}

