import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import {
  CompanySummaryFieldsFragment,
  EnterpriseSummaryFieldsFragment,
  useAuthQuery,
  UserFieldsFragment,
} from '../../graphql/generated';
import { CoachProfileCircle } from './CoachProfileCircle';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { MessageIcon } from '../icons';
import { isEnterprise } from '../../types/companies.types';

export function CoachIndicator() {
  const currentUser = useCurrentUser();
  const coach =
    isEnterprise<
      CompanySummaryFieldsFragment & EnterpriseSummaryFieldsFragment
    >(currentUser?.company) && currentUser?.company?.coach;
  if (coach) {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={clsx(
                'p-0 w-full flex group bg-white rounded-md inline-flex items-center text-base font-medium border-transparent hover:border-transparent hover:bg-gray-50',
              )}
            >
              <div className="flex items-center rounded-xl p-2 w-full gap-3">
                <div className="flex-shrink-0">
                  <CoachProfileCircle size={10} />
                </div>
                <div className="grow text-left">
                  <p className="text-xs font-normal text-gray-500">
                    Votre coach
                  </p>
                  <p className="text-sm font-bold text-gray-900">
                    {coach.firstName} {coach.lastName}
                  </p>
                </div>
              </div>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute bg-white top-0 left-0 transform translate-x-64 mt-3 px-2 max-w-md sm:px-0 lg:max-w-3xl">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="divide-y divide-gray-100">
                    <CoachEmailLink coach={coach} />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  } else {
    // No coach, nothing to display
    return null;
  }
}

function CoachEmailLink({ coach }: { coach: UserFieldsFragment }) {
  const { data } = useAuthQuery({
    variables: {
      id: coach.authId || '',
    },
    skip: !coach.authId,
  });

  return (
    <a
      href={`mailto:${data?.auth.email}`}
      className="p-2 hover:bg-gray-50 transition ease-in-out duration-150 flex items-center gap-2"
    >
      <MessageIcon />
      <span>Envoyer un message</span>
    </a>
  );
}
