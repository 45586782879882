import { DisclosureRequirementMdrLevel1 } from './DisclosureRequirementMdrLevel1';
import React from 'react';
import { DisclosureRequirement_MdrFragment } from '../../../graphql/cms/generated';

export const DisclosureRequirementMdr = ({
  mdr,
}: {
  mdr: DisclosureRequirement_MdrFragment;
}) => {
  const mdrLevel1 = mdr.content;
  return (
    <div className="space-y-4 pt-4">
      {mdrLevel1 && <DisclosureRequirementMdrLevel1 mdrLevel1={mdrLevel1} />}
    </div>
  );
};
