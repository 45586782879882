import { IroContextualMenu } from '../IroContextualMenu';
import { IroComment } from '../../form/comment/IroComment';
import React from 'react';
import {
  IroCellName_IroFragment,
  SurveyLanguage,
} from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { MissingTranslationsWarningIcon } from '../../../../../generic/MissingTranslationsWarningIcon';

export function IroNameCell({ iro }: { iro: IroCellName_IroFragment }) {
  const { translateProperty, i18n } = useTranslation();
  return (
    <div className="flex items-center gap-1 justify-between">
      <div className="grow text-base">
        {translateProperty(iro, 'name')}
        <MissingTranslationsWarningIcon
          entity={iro}
          properties={['name']}
          languages={[i18n.language as SurveyLanguage]}
        />
      </div>
      <div className="flex items-center gap-2">
        <div className="invisible group-hover:visible">
          <IroContextualMenu
            {...{
              iro,
            }}
          />
        </div>
        <IroComment iro={iro} />
      </div>
    </div>
  );
}
