import React, { useState } from 'react';
import { EnpsCharts_SurveyAnswerFragment } from '../../../../graphql/generated';
import { ExportableToImage } from '../../../generic/ExportableToImage';
import { EnpsProgressBarChart } from './EnpsProgressBarChart';
import { EnpsPieChart } from './EnpsPieChart';
import { VerticalScoringChart } from './VerticalScoringChart';
import { ChevronDownIcon, ChevronUpIcon } from '../../../icons';

export function EnpsCharts({
  answers,
  isThumbnail,
}: {
  answers: EnpsCharts_SurveyAnswerFragment[];
  isThumbnail?: boolean;
}) {
  const [displayDetails, setDisplayDetails] = useState(false);

  // Build an array containing the count of all votes for each score
  const votes = getEnpsVotes(answers);

  // scores distribution
  const totalVotes = votes.reduce((acc, score) => acc + score, 0);
  const detractorsCount = votes
    .slice(0, 6)
    .reduce((acc, score) => acc + score, 0);
  const passivesCount = votes
    .slice(6, 8)
    .reduce((acc, score) => acc + score, 0);
  const promotersCount = votes.slice(8).reduce((acc, score) => acc + score, 0);
  const detractors = detractorsCount / totalVotes;
  const passives = passivesCount / totalVotes;
  const promoters = promotersCount / totalVotes;

  // eNPS = (Promoters - Detractors) / Total * 100
  const enps = Math.round((promoters - detractors) * 100);

  return (
    <div className="p-4 rounded-2xl shadow-sm bg-white relative">
      <div className="flex flex-col gap-8">
        <ExportableToImage name="eNPS">
          <div className="flex gap-8 items-center divide-x divide-gray-100 p-4">
            {isThumbnail ? (
              <div className="flex flex-col gap-2 w-full">
                <div className="font-bold">
                  Employee Net Promoter Score (eNPS)
                </div>
                <EnpsProgressBarChart enps={enps} />
              </div>
            ) : (
              <div className="flex flex-col gap-2 w-1/2">
                <div className="font-bold">
                  Employee Net Promoter Score (eNPS)
                </div>
                <EnpsProgressBarChart enps={enps} />
              </div>
            )}

            {!isThumbnail && (
              <EnpsPieChart
                promotersCount={promotersCount}
                passivesCount={passivesCount}
                detractorsCount={detractorsCount}
                promoters={promoters}
                passives={passives}
                detractors={detractors}
              />
            )}
          </div>
        </ExportableToImage>
        {displayDetails ? (
          <EnpsBarChart votes={votes} setDisplayDetails={setDisplayDetails} />
        ) : (
          <div
            className="flex items-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
            onClick={() => setDisplayDetails(true)}
          >
            <span>Voir le détail des scores</span>
            <ChevronDownIcon className="w-3 h-3" />
          </div>
        )}
      </div>
    </div>
  );
}

function EnpsBarChart({
  votes,
  setDisplayDetails,
}: {
  votes: number[];
  setDisplayDetails: (displayDetails: boolean) => void;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div
        className="flex items-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
        onClick={() => setDisplayDetails(false)}
      >
        <div>Détail des scores</div>
        <ChevronUpIcon className="w-3 h-3" />
      </div>
      <VerticalScoringChart
        scores={votes}
        isEnps={true}
        title="Employee Net Promoter Score (eNPS) - Détail des scores"
      />
    </div>
  );
}

function getEnpsVotes(answers: EnpsCharts_SurveyAnswerFragment[]): number[] {
  return (answers || [])
    .flatMap((answer) => answer?.nps)
    .reduce(
      (acc: number[], score) => {
        const index = (score || 0) - 1;
        acc[index] = acc[index] ? acc[index] + 1 : 1;
        return acc;
      },
      Array(10).fill(0) as number[],
    );
}
