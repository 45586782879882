import { GroupedStringDropdownItem } from './GroupedStringDropdown';
import { useTranslation } from '@hooks/useTranslation';

export const getGroupedLabels = (
  items: GroupedStringDropdownItem[],
): string => {
  return Object.entries(
    items.reduce<{
      [key: string]: string[];
    }>((acc, item) => {
      const groupName = item.group.name || 'default';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item.label);
      return acc;
    }, {}),
  )
    .map(([groupName, labels]) => `${groupName} > ${labels.join(', ')}`)
    .join('; ');
};

export const defaultLabelFn = (
  multiple: boolean,
  items: GroupedStringDropdownItem[],
) => {
  const { t } = useTranslation();
  if (multiple) {
    if (items.length === 0) {
      return t('global:all');
    }
    if (items.length > 2) {
      return t('global:nbOfItemsSelected', { count: items.length });
    }
    return getGroupedLabels(items);
  }
  return (
    (items[0] ? `${items[0].group.name} > ${items[0].label}` : null) || '-'
  );
};
