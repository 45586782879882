import React, { useEffect, useState } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerChoiceInput,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionMultipleChoice({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t } = useTranslation();
  const [selectedChoices, setSelectedChoices] = useState<
    SurveySubmitAnswerChoiceInput[]
  >(
    question.canSelectMultipleChoices
      ? answer?.choices || []
      : answer?.choice
        ? [answer.choice]
        : [],
  );

  const [comment, setComment] = useState<string>(answer?.comment || '');

  const getRefreshedChoicesArrayAfterPickingChoice = (choiceId: string) => {
    const choice = question.choices?.find((choice) => choice.id === choiceId);
    if (choice) {
      if (selectedChoices.find((c) => c.id === choice.id)) {
        // Already selected, remove it
        return [...selectedChoices.filter((c) => c.id !== choice.id)];
      } else {
        if (question.canSelectMultipleChoices) {
          // If multiple choices are allowed, toggle the choice
          return [...selectedChoices, { id: choice.id, label: choice.label }];
        } else {
          // If only one single choice is allowed, remove all other choices
          return [{ id: choice.id, label: choice.label }];
        }
      }
    }
    return selectedChoices;
  };

  const toggleChoice = (choiceId: string) => {
    const newChoices = getRefreshedChoicesArrayAfterPickingChoice(choiceId);
    setSelectedChoices(newChoices);
    updateAnswer(newChoices, comment);
  };

  const changeComment = (value: string) => {
    setComment(value);
    updateAnswer(selectedChoices, value);
  };

  const updateAnswer = (
    choices: SurveySubmitAnswerChoiceInput[],
    comment: string,
  ) => {
    if (choices.length === 0 && comment === '') {
      setIsAnswerValid(false);
      setAnswer(null);
      return;
    }
    setIsAnswerValid(true);
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Choices,
      choices: question.canSelectMultipleChoices ? choices : undefined,
      choice: question.canSelectMultipleChoices ? undefined : choices[0],
      comment: comment,
    });
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(selectedChoices.length > 0);
  }, [selectedChoices, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div
          className={clsx(
            'text-sm max-w-5xl sm:text-base lg:text-base grid gap-2 items-center',
            question.choices?.length === 2 && !question.canAddOtherChoice
              ? 'grid-cols-2'
              : 'grid-cols-1',
          )}
        >
          {(question.choices || [])
            .toSorted((a, b) => a.position - b.position)
            .map((choice) => (
              <div
                className={clsx(
                  'form-input-multiple-choice-item',
                  selectedChoices.find((c) => c.id === choice.id) && 'selected',
                )}
                key={choice.id}
                onClick={() => toggleChoice(choice.id)}
              >
                {choice.label}
              </div>
            ))}
          {question.canAddOtherChoice && (
            <textarea
              id="comment"
              name="comment"
              className="form-input-text-survey w-full h-24"
              placeholder="Autre ?"
              value={comment}
              onChange={(e) => changeComment(e.target.value)}
            />
          )}
        </div>
        <div className="w-full max-w-5xl"></div>
        {question.canSelectMultipleChoices ? (
          <div className="italic text-sm">
            {t('survey.question.multiple_choice.multiple_questions_possible')}
          </div>
        ) : (
          <div className="italic text-sm">
            {t('survey.question.multiple_choice.only_one_question_possible')}
          </div>
        )}
      </div>
    </div>
  );
}
