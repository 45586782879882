import React from 'react';
import { useNavContext } from '../../../providers/NavContextProvider';
import clsx from 'clsx';
import { LeftNavInner } from './LeftNavInner';

export const LeftNav = () => {
  const navContext = useNavContext();
  return (
    <div
      className={clsx(
        'z-30 w-[230px] xl:w-[280px] fixed inset-y-0 px-4 py-6 transition-transform duration-300 ease-in-out bg-white text-gray-900 border-r border-gray-200',
        navContext?.isNavCollapsed ? '-translate-x-full' : '',
      )}
    >
      <LeftNavInner />
    </div>
  );
};















