import React from 'react';
import clsx from 'clsx';
import { MessageIcon } from '../icons';

export function CoachProfileCircle({
  size,
}: {
  size: 12 | 10 | 8 | 7 | 6 | 4;
}) {
  return (
    <div
      className={clsx(
        'w-'.concat(size.toString()),
        'h-'.concat(size.toString()),
        'flex items-center justify-center shrink-0',
      )}
    >
      <div
        className={clsx(
          'w-'.concat((size - 2).toString()),
          'h-'.concat((size - 2).toString()),
          size > 8 ? 'text-base' : 'text-xs',
          'rounded-full flex items-center justify-center font-bold shrink-0 bg-gray-900 text-white',
        )}
      >
        <MessageIcon className="flex-shrink-0" />
      </div>
    </div>
  );
}
