import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useGapAnalysisSynthesis_RequirementsQuery } from '../../../../graphql/cms/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { getNumberOfDatapoints } from '../summary/useGapAnalysisTopicDRTableColumns';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  GapAnalysisSynthesisFragment,
  StandardEnum,
  useGapAnalysisSynthesisQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { DoughnutChart } from '../../../generic/chart/DoughnutChart';
import { NoData } from '../../../generic/NoData';

export function GapAnalysisSynthesis() {
  const context = useProjectContext();

  const apiQuery = useGapAnalysisSynthesisQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (apiQuery.loading) {
    return (
      <div className="px-16 py-8 main-content">
        <LoaderFullscreen />
      </div>
    );
  }

  if (apiQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {apiQuery.error.message}
      </MessageBox>
    );
  }

  if (apiQuery.data?.companyReport) {
    return <GapAnalysisSynthesisCharts report={apiQuery.data.companyReport} />;
  }
}

function GapAnalysisSynthesisCharts({
  report,
}: {
  report: GapAnalysisSynthesisFragment;
}) {
  const { t, i18n } = useTranslation();

  // Get all DR slugs to query CMS
  const disclosureRequirementSlugs = report.topics
    .flatMap((topic) => topic.disclosureRequirements.map((dr) => dr))
    .filter((dr) => dr.disabled !== true)
    .map((dr) => dr.referenceSlug);

  const requirementsQuery = useGapAnalysisSynthesis_RequirementsQuery({
    client: cmsClient,
    variables: {
      locale: i18n.language,
      slugs: disclosureRequirementSlugs,
    },
  });
  const requirements = requirementsQuery.data?.disclosureRequirements || [];

  const datapointsCount = requirements.reduce((acc, dr) => {
    return dr ? acc + getNumberOfDatapoints(dr) : acc;
  }, 0);

  return (
    <div className="px-16 py-8 grid grid-cols-1 xl:grid-cols-2 gap-4 main-content">
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t('gap_analysis.synthesis.selectedDatapointsOverTotalChart.title')}
        </h6>
        <SelectedDatapointsVsTotalDatapointsDoughnut
          selectedDatapointsCount={
            report.gapAnalysisProgress?.selectedDatapoints || 0
          }
          totalDatapointsCount={datapointsCount}
        />
      </div>
      <div className="bg-gray-50 rounded-xl p-8 items-center gap-8 space-y-4">
        <h6 className="text-center">
          {t(
            'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
          )}
        </h6>
        <AvailableDatapointsVsSelectedDatapointsDoughnut
          availableDatapointsCount={
            report.gapAnalysisProgress?.availableDatapoints || 0
          }
          selectedDatapointsCount={
            report.gapAnalysisProgress?.selectedDatapoints || 0
          }
        />
      </div>
    </div>
  );
}

function AvailableDatapointsVsSelectedDatapointsDoughnut({
  availableDatapointsCount,
  selectedDatapointsCount,
}: {
  availableDatapointsCount: number;
  selectedDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (availableDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t('global:noResult')} actions={[]}></NoData>;
  }

  return (
    <DoughnutChart
      data={[
        availableDatapointsCount,
        selectedDatapointsCount - availableDatapointsCount,
      ]}
      labels={[
        t(
          'gap_analysis.synthesis.availableDatapointsOverSelectedChart.availableDatapoints',
        ),
        t(
          'gap_analysis.synthesis.availableDatapointsOverSelectedChart.selectedDatapoints',
        ),
      ]}
      title={t(
        'gap_analysis.synthesis.availableDatapointsOverSelectedChart.title',
      )}
    />
  );
}

function SelectedDatapointsVsTotalDatapointsDoughnut({
  selectedDatapointsCount,
  totalDatapointsCount,
}: {
  selectedDatapointsCount: number;
  totalDatapointsCount: number;
}) {
  const { t } = useTranslation();

  if (totalDatapointsCount === 0 && selectedDatapointsCount === 0) {
    return <NoData text={t('global:noResult')} actions={[]}></NoData>;
  }

  return (
    <DoughnutChart
      data={[
        selectedDatapointsCount,
        totalDatapointsCount - selectedDatapointsCount,
      ]}
      labels={[
        t(
          'gap_analysis.synthesis.selectedDatapointsOverTotalChart.selectedDatapoints',
        ),
        t(
          'gap_analysis.synthesis.selectedDatapointsOverTotalChart.totalDatapoints',
        ),
      ]}
      title={t('gap_analysis.synthesis.selectedDatapointsOverTotalChart.title')}
    />
  );
}
