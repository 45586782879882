import {
  AnalysisThemeTag_AnalysisThemeFragment,
  ThemeColor,
} from '../../../graphql/generated';
import clsx from 'clsx';

export default function AnalysisThemeTag({
  analysisTheme,
}: {
  analysisTheme: AnalysisThemeTag_AnalysisThemeFragment;
}) {
  return (
    <div
      title={analysisTheme.name}
      className={clsx('tag cursor-pointer', analysisTheme.color)}
    >
      <div className="font-semibold max-w-[10rem] truncate text-ellipsis">
        {analysisTheme.name}
      </div>
    </div>
  );
}

export function getThemeBgColor(
  color: string,
  isHighlighted: boolean | undefined,
  currentlyHighlightedTheme: AnalysisThemeTag_AnalysisThemeFragment | null,
) {
  let colorToBeUsed = color;
  if (isHighlighted && currentlyHighlightedTheme) {
    colorToBeUsed = currentlyHighlightedTheme.color;
  }
  switch (colorToBeUsed) {
    case ThemeColor.Blue:
      return 'bg-blue-50';
    case ThemeColor.Red:
      return 'bg-red-50';
    case ThemeColor.Green:
      return 'bg-green-50';
    case ThemeColor.Purple:
      return 'bg-purple-50';
    case ThemeColor.Yellow:
      return 'bg-yellow-50';
    case ThemeColor.Gray:
    default:
      return 'bg-gray-50';
  }
}

export function getThemeTextColor(
  theme: AnalysisThemeTag_AnalysisThemeFragment | null,
) {
  switch (theme?.color) {
    case ThemeColor.Blue:
      return 'text-blue-900';
    case ThemeColor.Red:
      return 'text-red-900';
    case ThemeColor.Green:
      return 'text-green-900';
    case ThemeColor.Purple:
      return 'text-purple-900';
    case ThemeColor.Yellow:
      return 'text-yellow-900';
    case ThemeColor.Gray:
    default:
      return 'text-gray-900';
  }
}
