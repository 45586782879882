import {
  QuestionAnswersDataviz_AnswerFragment,
  SurveyQuestionDatavizForStake_QuestionFragment,
} from '../../../../graphql/generated';
import { QuestionAnswersDataviz } from '../../../project/survey/QuestionAnswersDataviz';
import React from 'react';

export function SurveyQuestionDatavizForStake({
  question,
  stakeId,
}: {
  question: SurveyQuestionDatavizForStake_QuestionFragment;
  stakeId: string;
}) {
  const answers: QuestionAnswersDataviz_AnswerFragment[] =
    question.answers || [];

  // When answers have stakes, filter stakes to keep only the ones linked to the current stake
  const filteredAnswers = answers.map((answer) => {
    return {
      ...answer,
      stakes: answer.stakes?.filter(
        (stakeAnswer) => stakeAnswer.stakeId === stakeId,
      ),
    };
  });

  return (
    <QuestionAnswersDataviz
      question={question}
      answers={filteredAnswers}
      isThumbnail={false}
    />
  );
}
