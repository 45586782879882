import React, { ReactNode } from 'react';

import { ThemeIcon as ThemeIconEnum } from '../../../graphql/generated';
import {
  BuildingIcon,
  CircularEconomyIcon,
  ClimateChangeIcon,
  CollaboratorIcon,
  CollaboratorsIcon,
  CommunityIcon,
  ConsumersIcon,
  EcosystemIcon,
  EndUserIcon,
  EnvironmentIcon,
  ExternalWorkerIcon,
  GovernanceIcon,
  LawIcon,
  ModelsIcon,
  MoneyIcon,
  PollutionIcon,
  SeaResourcesIcon,
  TargetIcon,
  TreeIcon,
} from '../../icons';

export function ThemeIcon({ themeIcon }: { themeIcon: ThemeIconEnum }) {
  return <div>{getIcon(themeIcon)}</div>;
}

function getIcon(themeIcon: ThemeIconEnum): ReactNode {
  switch (themeIcon) {
    case ThemeIconEnum.Building:
      return <BuildingIcon className="shrink-0" />;
    case ThemeIconEnum.CircularEconomy:
      return <CircularEconomyIcon className="shrink-0" />;
    case ThemeIconEnum.ClimateChange:
      return <ClimateChangeIcon className="shrink-0" />;
    case ThemeIconEnum.Collaborator:
      return <CollaboratorIcon className="shrink-0" />;
    case ThemeIconEnum.Collaborators:
      return <CollaboratorsIcon className="shrink-0" />;
    case ThemeIconEnum.Community:
      return <CommunityIcon className="shrink-0" />;
    case ThemeIconEnum.Consumers:
      return <ConsumersIcon className="shrink-0" />;
    case ThemeIconEnum.Ecosystem:
      return <EcosystemIcon className="shrink-0" />;
    case ThemeIconEnum.Enduser:
      return <EndUserIcon className="shrink-0" />;
    case ThemeIconEnum.Environment:
      return <EnvironmentIcon className="shrink-0" />;
    case ThemeIconEnum.ExternalWorker:
      return <ExternalWorkerIcon className="shrink-0" />;
    case ThemeIconEnum.Governance:
      return <GovernanceIcon className="shrink-0" />;
    case ThemeIconEnum.Law:
      return <LawIcon className="shrink-0" />;
    case ThemeIconEnum.Models:
      return <ModelsIcon className="shrink-0" />;
    case ThemeIconEnum.Money:
      return <MoneyIcon className="shrink-0" />;
    case ThemeIconEnum.Pollution:
      return <PollutionIcon className="shrink-0" />;
    case ThemeIconEnum.SeaResources:
      return <SeaResourcesIcon className="shrink-0" />;
    case ThemeIconEnum.Target:
      return <TargetIcon className="shrink-0" />;
    case ThemeIconEnum.Tree:
      return <TreeIcon className="shrink-0" />;
    default:
      return <EnvironmentIcon className="shrink-0" />;
  }
}
