import { Helmet } from 'react-helmet-async';
import { MixpanelEvents, trackEvent } from '../../services/MixpanelService';

export function PageTitle({ title }: { title: string }) {
  trackEvent(MixpanelEvents.PageView, title);
  return (
    <Helmet>
      <title>{`${title}${title ? ' - ' : ''}Good Steps`}</title>
    </Helmet>
  );
}
