import React, { useEffect } from "react";
import { PageTitle } from '../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { ProjectHeader } from './ProjectHeader';
import { PROJECT_HOME_TABS, useProjectTabs } from './useProjectTabs';
import { TabNavigationLayout } from '../../../components/generic/nav/TabNavigationLayout';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { OrganizationalUnitContent } from '../../../components/project/project/company/OrganizationalUnitContent';
import { useProjectCompanyScreenQuery } from '../../../graphql/generated';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { Loader } from "../../../components/generic/Loader";

export const ProjectCompanyScreen = () => {
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const tabs = useProjectTabs();

  const { data, error } = useProjectCompanyScreenQuery({
    variables: { companyId: projectContext?.enterprise?.id || '' },
    fetchPolicy: 'cache-and-network',
  });

  if(!projectContext?.enterprise?.id){
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (error) {
        return <MessageBox type={MessageBoxType.Error}>{error.message}</MessageBox>;
  }

  return (
    <TabNavigationLayout<PROJECT_HOME_TABS>
      tabs={tabs}
      activeTab={PROJECT_HOME_TABS.COMPANY}
      renderTabs={(tabsContent) => (
        <>
          <div className="py-12 px-16">
            <ProjectHeader />
          </div>
          {tabsContent}
        </>
      )}
    >
      <PageTitle title={t('screen.project.company.meta.title')} />
      {data?.enterprise && (
        <OrganizationalUnitContent company={data.enterprise} />
      )}
    </TabNavigationLayout>
  );
};
