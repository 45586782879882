import React from 'react';
import clsx from 'clsx';

export type Choice = {
  label: string;
  count: number;
};

export function ChoicesBarChart({
  availableChoices,
  sumOfAllCounts,
  otherChoices,
}: {
  availableChoices: Choice[];
  sumOfAllCounts: number;
  otherChoices?: Choice[];
}) {
  return (
    <div className="flex flex-col items-stretch gap-4 justify-evenly">
      {availableChoices.map((score) => (
        <ChoiceBar
          key={score.label}
          label={score.label}
          score={score.count}
          maxOfAllCounts={sumOfAllCounts}
        />
      ))}
      {otherChoices && otherChoices.length > 0 && (
        <ChoiceBar
          key="other"
          label="Autre"
          score={otherChoices.length}
          maxOfAllCounts={sumOfAllCounts}
        />
      )}
    </div>
  );
}

function ChoiceBar({
  label,
  score,
  maxOfAllCounts,
}: {
  label: string;
  score: number;
  maxOfAllCounts: number;
}) {
  const percent = score / maxOfAllCounts;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4 justify-between items-center text-sm">
        <div className="text-gray-900">{label}</div>
        <div className="flex gap-2 items-center">
          <div className={clsx(percent > 0 && 'font-bold')}>
            {Math.round(percent * 100)}%
          </div>
          <div className="text-gray-500">{score}</div>
        </div>
      </div>
      <div className="bg-gray-100 rounded-lg flex items-end w-full h-6">
        <div
          className="bg-purple-500 h-6 rounded-lg"
          style={{ width: `${Math.min(100, percent * 100)}%` }}
        ></div>
      </div>
    </div>
  );
}
