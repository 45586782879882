import {
  IroName_IroFragment,
  SurveyLanguage,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

export type IroNameProps = {
  iro: IroName_IroFragment;
  language?: SurveyLanguage;
};

export function IroName({ iro, language }: IroNameProps) {
  const { translateProperty } = useTranslation();
  return (
    <div className="text-gray-700 grow">
      {translateProperty(iro, 'name', language) || '-'}
    </div>
  );
}
