import React from 'react';

type SkeletonLoaderProps = {
  size?: {
    width?: string;
    height?: string;
  };
  randomSizes?: {
    width?: string[];
    height?: string[];
  };
};

const getRandomTailwindSize = (
  dimension: 'width' | 'height',
  randomSizes?: {
    width?: string[];
    height?: string[];
  }
) => {
  const defaultSizes = dimension === 'width'
    ? ['w-24', 'w-32', 'w-48', 'w-50', 'w-56', 'w-64', 'w-72', 'w-80', 'w-96']
    : ['h-6', 'h-8', 'h-10', 'h-12', 'h-16'];

  const sizes = randomSizes?.[dimension] || defaultSizes;
  return sizes[Math.floor(Math.random() * sizes.length)];
};

export const SkeletonLoader = ({ size, randomSizes }: SkeletonLoaderProps) => {
  const width = size?.width || getRandomTailwindSize('width', randomSizes);
  const height = size?.height || getRandomTailwindSize('height', randomSizes);

  return (
    <div className={`${width} ${height} bg-gray-200 rounded-lg animate-pulse`} />
  );
};
