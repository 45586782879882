import React, { ReactNode } from 'react';
import { ActionFieldsFragment, Resource } from '../../../graphql/generated';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { PageTitle } from '../../nav/PageTitle';
import { ComplexityBadge } from '../../badges/ComplexityBadge';
import { PriceBadge } from '../../badges/PriceBadge';
import { PriorityBadge } from '../../badges/PriorityBadge';
import { InnerHtml } from '../../generic/InnerHtml';
import { StakeTag } from '../../stake/StakeTag';
import { Checklist } from '../../task/Checklist';
import { CreateChecklistButton } from '../../task/CreateChecklistButton';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import {
  CheckCircleIcon,
  LinkIcon,
  MetricIcon,
  QuoteWhiteIcon,
} from '../../icons';

export function Action({ action }: { action: ActionFieldsFragment }) {
  return (
    <>
      <PageTitle title={`${action.title}`} />
      <div className="flex grow">
        {/* Left column */}
        <div className="flex flex-col w-full">
          <div
            className="flex-none bg-cover bg-center h-60"
            style={{
              backgroundImage: action.illustration
                ? `url(${action.illustration})`
                : `url(/actions/riccardo-annandale-7e2pe9wjL9M-unsplash.jpg)`,
            }}
          >
            <div className="flex flex-col place-content-end h-full bg-gradient-to-b from-gray-900/[.2] to-gray-900/[.5]"></div>
          </div>
          <div className="p-8 space-y-6 grow">
            <div className="flex items-start gap-2">
              <ThemeIcon
                theme={action.theme}
                className="bg-gray-900 text-white rounded-lg p-1 w-8 h-8"
              />
              <h1 className="title-h2">{action.title}</h1>
            </div>
            {action.contextualData && action.contextualData.title && (
              <div className="rounded-xl bg-blue-50 p-8 flex items-center gap-8">
                <div className="font-title font-extrabold text-5xl shrink-0">
                  {action.contextualData.title}
                </div>
                <div className="text-gray-500">
                  {action.contextualData.description}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              {action.description && <InnerHtml html={action.description} />}
            </div>
            {action.solutions && action.solutions.length > 0 && (
              <Section title="Suggestions">
                <div className="flex flex-col gap-4">
                  {action.solutions.map((solution, index) => (
                    <div className="card gap-2" key={index}>
                      <h6>{solution.title}</h6>
                      {solution.description && (
                        <InnerHtml
                          className="text-gray-500"
                          html={solution.description}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Section>
            )}

            {action.diagnosticStakes && action.diagnosticStakes.length > 0 && (
              <Section title="Enjeux liés">
                <div className="flex gap-2 flex-wrap">
                  {action.diagnosticStakes.map((diagnosticStake) => (
                    <StakeTag
                      stake={diagnosticStake}
                      key={diagnosticStake.id}
                    />
                  ))}
                </div>
              </Section>
            )}
          </div>

          {action.checklist?.id ? (
            <div className="p-8 bg-gray-50">
              <Checklist checklistId={action.checklist.id} />
            </div>
          ) : (
            <div className="p-8">
              <IllustratedExplanationMessage
                illustration={<img src={loginIllustration} alt="" />}
                title="Créez une liste de tâches"
                description="Mettez en place une liste de tâches pour vous mettre en mouvement !"
              >
                <CreateChecklistButton actionId={action.id} />
              </IllustratedExplanationMessage>
            </div>
          )}
        </div>

        {/* Right column */}
        <div className="min-w-[400px] max-w-[500px] shrink-0 px-6 bg-yellow-50 divide-y divide-yellow-300 h-full">
          {action.coachComment && (
            <CoachCommentSection coachComment={action.coachComment} />
          )}
          <AboutSidebarSection action={action} />
          {action.benefits && action.benefits?.length > 0 && (
            <ActionBenefitsSection action={action} />
          )}
          {action.metrics && action.metrics?.length > 0 ? (
            <ActionMetricSection action={action} />
          ) : (
            ''
          )}
          {action.resources && action.resources.length > 0 && (
            <Section title="Ressources">
              <ActionResources resources={action.resources} />
            </Section>
          )}
        </div>
      </div>
    </>
  );
}

function Section({ title, children }: { title?: string; children: ReactNode }) {
  return (
    <section className="py-6">
      {title && <h5 className="mb-2">{title}</h5>}
      {children}
    </section>
  );
}

function CoachCommentSection({ coachComment }: { coachComment: string }) {
  return (
    <Section>
      <div className="flex gap-4 mb-2">
        <QuoteWhiteIcon className="shrink-0 inline-block h-12 w-12 rounded-full" />
        <p className="title-h3 mt-2">{coachComment}</p>
      </div>
    </Section>
  );
}

function AboutSidebarSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="A propos">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row bg-white shadow-sm p-3 rounded-lg justify-between items-center">
          <div className="font-bold text-sm">Priorité</div>
          <PriorityBadge priorityLevel={action.priorityLevel} />
        </div>
        <div className="flex flex-row bg-white shadow-sm p-3 rounded-lg justify-between items-center">
          <div className="font-bold text-sm">Complexité</div>
          <ComplexityBadge complexityLevel={action.complexityLevel} />
        </div>
        <div className="flex flex-row bg-white shadow-sm p-3 rounded-lg justify-between items-center">
          <div className="font-bold text-sm">Coût</div>
          <PriceBadge priceLevel={action.priceLevel} />
        </div>
      </div>
    </Section>
  );
}

function ActionMetricSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="Métrique à suivre">
      <div className="flex flex-col gap-2">
        {action.metrics &&
          action.metrics.map((metric, index) => (
            <div
              key={index}
              className="flex gap-4 bg-white shadow-sm p-3 rounded-lg justify-start items-center"
            >
              <MetricIcon className="shrink-0" />
              <div>
                <p className="font-bold text-sm text-gray-900">
                  {metric.title}
                </p>
              </div>
            </div>
          ))}
      </div>
    </Section>
  );
}

function ActionBenefitsSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="Bénéfices">
      <ul className="flex flex-col gap-2 mt-4">
        {action.benefits?.map((benefit) => (
          <li className="flex gap-2 items-start text-sm" key={benefit}>
            <CheckCircleIcon className="h-6 w-6 shrink-0" />
            {benefit}
          </li>
        ))}
      </ul>
    </Section>
  );
}

function ActionResources({ resources }: { resources: Array<Resource> }) {
  return (
    <ul className="space-y-1 text-sm font-bold">
      {resources.map((resource, index) => (
        <li key={index} className="flex gap-1 items-center">
          {resource.url ? (
            <>
              <LinkIcon className="shrink-0" />
              <a
                href={resource.url}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                {resource.title}
              </a>
            </>
          ) : (
            <p>{resource.title}</p>
          )}
        </li>
      ))}
    </ul>
  );
}
