import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React from 'react';

export enum TemplatesTypes {
  All = 'All',
  Internal = 'Internal',
  External = 'External',
  Ceo = 'Ceo',
}

export function TypeFilter({
  type,
  selectedTypeFilter,
  setSelectedTypeFilter,
}: {
  type: TemplatesTypes;
  selectedTypeFilter: TemplatesTypes;
  setSelectedTypeFilter: (selectedTypeFilter: TemplatesTypes) => void;
}) {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(
        'filter-button',
        type === selectedTypeFilter && 'selected',
      )}
      onClick={() => setSelectedTypeFilter(type)}
    >
      <span>
        {type === TemplatesTypes.All
          ? t('survey.templateModal.all')
          : type === TemplatesTypes.Internal
            ? t('survey.templateModal.internal')
            : type === TemplatesTypes.External
              ? t('survey.templateModal.external')
              : t('survey.templateModal.ceo')}
      </span>
    </button>
  );
}
