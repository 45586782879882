import React from 'react';
import clsx from 'clsx';

export enum GradientScoreStrength {
  VeryLight = 'VeryLight',
  Light = 'Light',
  Medium = 'Medium',
  Strong = 'Strong',
  VeryStrong = 'VeryStrong',
}

export function GradientScore({ score }: { score: number | null | undefined }) {
  if (score === null || score === undefined) {
    return (
      <div
        className={clsx(
          'rounded-xl px-4 py-1 font-bold',
          getBgColorFromColorStrength(GradientScoreStrength.VeryLight),
          getTextColorFromColorStrength(GradientScoreStrength.VeryLight),
        )}
      >
        -
      </div>
    );
  }

  const strength =
    score < 1
      ? GradientScoreStrength.VeryLight
      : score < 2
        ? GradientScoreStrength.Light
        : score < 3
          ? GradientScoreStrength.Medium
          : score < 4
            ? GradientScoreStrength.Strong
            : GradientScoreStrength.VeryStrong;
  const formattedScore = (Math.round(score * 10) / 10).toFixed(1);
  return (
    <div
      className={clsx(
        'rounded-xl px-2 py-1 font-bold',
        getBgColorFromColorStrength(strength),
        getTextColorFromColorStrength(strength),
      )}
    >
      {formattedScore}
    </div>
  );
}

function getBgColorFromColorStrength(strength: GradientScoreStrength) {
  switch (strength) {
    case GradientScoreStrength.VeryLight:
      return 'bg-white';
    case GradientScoreStrength.Light:
      return 'bg-purple-100';
    case GradientScoreStrength.Medium:
      return 'bg-purple-300';
    case GradientScoreStrength.Strong:
      return 'bg-purple-500';
    case GradientScoreStrength.VeryStrong:
      return 'bg-purple-900';
  }
}

function getTextColorFromColorStrength(strength: GradientScoreStrength) {
  switch (strength) {
    case GradientScoreStrength.VeryLight:
      return 'text-purple-900';
    case GradientScoreStrength.Light:
      return 'text-purple-900';
    case GradientScoreStrength.Medium:
      return 'text-purple-900';
    case GradientScoreStrength.Strong:
      return 'text-purple-50';
    case GradientScoreStrength.VeryStrong:
      return 'text-purple-50';
  }
}
