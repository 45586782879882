import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

function ErrorBoundaryFallback() {
  return (
    <div className="flex h-screen">
      <div className="flex flex-col m-auto gap-4 items-center">
        <h2>Oups ! Une erreur est survenue !</h2>
        <p>
          Pas d'inquiétude, notre équipe technique a été notifiée pour résoudre
          cela 😅
        </p>
        <p>
          En attendant, nous vous invitons à réessayer en rechargeant la page.
        </p>
        <button
          className="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Recharger la page
        </button>
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <SentryErrorBoundary
      fallback={<ErrorBoundaryFallback />}
      onError={(err) => console.error(err)}
    >
      {children}
    </SentryErrorBoundary>
  );
}
