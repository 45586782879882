import { Tooltip } from 'react-tooltip';
import React from 'react';
import { getTooltipUniqueId } from '../../services/UtilsService';
import { InfoIcon } from '../icons';

export function HelpTooltip({ children }: { children: React.ReactNode }) {
  const id = getTooltipUniqueId('HelpTooltip');
  return (
    <div data-tooltip-id={id}>
      <InfoIcon className="text-gray-900 w-4 h-4" />
      <Tooltip id={id} className="tooltip text-sm">
        {children}
      </Tooltip>
    </div>
  );
}
