import {
  SurveyTarget,
  SurveyTemplates_SurveyFragment,
  useSurveyTemplatesQuery,
} from '../../../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../layout/Modal';
import React, { useState } from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import { SearchIcon, XIcon } from '../../../icons';
import { SurveyTemplateList } from './SurveyTemplateList';
import { SourceFilter, TemplatesSources } from './SourceFilter';
import { TemplatesTypes, TypeFilter } from './TypeFilter';

export function SurveyTemplates({
  setPreviewSurveyTemplate,
}: {
  setPreviewSurveyTemplate: (
    surveyTemplate: SurveyTemplates_SurveyFragment | null,
  ) => void;
}) {
  const { t } = useTranslation();
  const { data, loading } = useSurveyTemplatesQuery({
    fetchPolicy: 'network-only',
  });
  const modal = useModal();
  const [selectedSourceFilter, setSelectedSourceFilter] =
    useState<TemplatesSources>(TemplatesSources.GoodSteps);
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<TemplatesTypes>(
    TemplatesTypes.All,
  );
  const [searchTextFilter, setSearchTextFilter] = useState('');

  const filteredSurveyTemplates = (
    surveyTemplates: SurveyTemplates_SurveyFragment[],
  ) => {
    // TODO: fetch only stakeholder surveys?
    let filteredSurveyTemplates: SurveyTemplates_SurveyFragment[] =
      surveyTemplates;

    if (selectedSourceFilter === TemplatesSources.Own) {
      filteredSurveyTemplates = filteredSurveyTemplates.filter(
        (surveyTemplate) => !surveyTemplate.isTemplate,
      );
    }
    if (selectedSourceFilter === TemplatesSources.GoodSteps) {
      filteredSurveyTemplates = filteredSurveyTemplates.filter(
        (surveyTemplate) => surveyTemplate.isTemplate,
      );
    }
    if (selectedTypeFilter !== TemplatesTypes.All) {
      if (selectedTypeFilter === TemplatesTypes.Internal) {
        filteredSurveyTemplates = filteredSurveyTemplates.filter(
          (surveyTemplate) =>
            surveyTemplate.target === SurveyTarget.Collaborators,
        );
      }
      if (selectedTypeFilter === TemplatesTypes.External) {
        filteredSurveyTemplates = filteredSurveyTemplates.filter(
          (surveyTemplate) => surveyTemplate.target === SurveyTarget.External,
        );
      }
      if (selectedTypeFilter === TemplatesTypes.Ceo) {
        filteredSurveyTemplates = filteredSurveyTemplates.filter(
          (surveyTemplate) => surveyTemplate.target === SurveyTarget.Ceo,
        );
      }
    }
    if (searchTextFilter) {
      // Fetch surveyx having searchTextFilter in name or description
      filteredSurveyTemplates = filteredSurveyTemplates.filter(
        (surveyTemplate) =>
          surveyTemplate.name
            .toLowerCase()
            .includes(searchTextFilter.toLowerCase()) ||
          surveyTemplate.description
            ?.toLowerCase()
            .includes(searchTextFilter.toLowerCase()),
      );
    }
    return filteredSurveyTemplates;
  };

  return loading ? (
    <LoaderFullscreen />
  ) : (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <h4 className="text-center grow">
          {t('survey.templateModal.chooseConsultation')}
        </h4>
      </div>

      <div className="flex h-full">
        <div className="bg-gray-50 w-80 p-8 divide-y divide-gray-100 space-y-8">
          <div className="flex flex-col gap-1 pt-4">
            <h3 className="title-h5">{t('survey.templateModal.sources')}</h3>
            <SourceFilter
              source={TemplatesSources.GoodSteps}
              selectedSourceFilter={selectedSourceFilter}
              setSelectedSourceFilter={setSelectedSourceFilter}
            />
            <SourceFilter
              source={TemplatesSources.Own}
              selectedSourceFilter={selectedSourceFilter}
              setSelectedSourceFilter={setSelectedSourceFilter}
            />
          </div>
          <div className="flex flex-col gap-1 pt-4">
            <h3 className="title-h5">{t('survey.templateModal.type')}</h3>
            <TypeFilter
              type={TemplatesTypes.All}
              selectedTypeFilter={selectedTypeFilter}
              setSelectedTypeFilter={setSelectedTypeFilter}
            />
            <TypeFilter
              type={TemplatesTypes.Internal}
              selectedTypeFilter={selectedTypeFilter}
              setSelectedTypeFilter={setSelectedTypeFilter}
            />
            <TypeFilter
              type={TemplatesTypes.External}
              selectedTypeFilter={selectedTypeFilter}
              setSelectedTypeFilter={setSelectedTypeFilter}
            />
            <TypeFilter
              type={TemplatesTypes.Ceo}
              selectedTypeFilter={selectedTypeFilter}
              setSelectedTypeFilter={setSelectedTypeFilter}
            />
          </div>
        </div>
        <div className="grow p-8 overflow-scroll flex flex-col gap-8">
          <h1 className="max-w-3xl">
            {t('survey.templateModal.inspirationMessage')}
          </h1>
          <div className="flex items-center justify-start gap-2 px-2 border border-gray-100 shadow-sm rounded-md">
            <SearchIcon className="text-gray-300" />
            <input
              placeholder={t('survey.templateModal.searchPlaceholder')}
              className="border-0 w-full focus:border-0 focus:ring-0 focus:outline-none text-sm placeholder-gray-300 pl-0"
              type={'text'}
              value={searchTextFilter}
              onChange={(e) => setSearchTextFilter(e.target.value)}
            />
          </div>
          {data?.getSurveyTemplates && (
            <SurveyTemplateList
              surveys={filteredSurveyTemplates(data.getSurveyTemplates)}
              setPreviewSurveyTemplate={setPreviewSurveyTemplate}
            />
          )}
        </div>
      </div>
    </div>
  );
}
