import {
  GetPillarColorFragment,
  MaturityLevel,
  PriorityLevel,
  ThemeColor,
} from '../graphql/generated';

export function getPillarColor(
  pillar: GetPillarColorFragment | null | undefined,
): string {
  return getThemeLightColor(pillar?.color);
}

export function getThemeLightColor(
  themeColor: ThemeColor | null | undefined,
): string {
  switch (themeColor) {
    case ThemeColor.Gray:
      return 'bg-gray-50';
    case ThemeColor.Green:
      return 'bg-green-50';
    case ThemeColor.Purple:
      return 'bg-purple-50';
    case ThemeColor.Blue:
      return 'bg-blue-50';
    case ThemeColor.Red:
      return 'bg-red-50';
    case ThemeColor.Yellow:
      return 'bg-yellow-50';
    default:
      return 'bg-gray-50';
  }
}

export function getMaturityLevelFromComputedValue(
  value: number | null | undefined,
): MaturityLevel | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (value >= 4) {
    return MaturityLevel.High;
  }
  if (value >= 3) {
    return MaturityLevel.Medium;
  }
  if (value >= 2) {
    return MaturityLevel.Low;
  }
  if (value > 1) {
    return MaturityLevel.None;
  }
  return null;
}

export function getPriorityLevelFromComputedValue(
  computedValue: number,
): PriorityLevel | null {
  if (computedValue && computedValue >= 0) {
    const truncatedValue = Math.trunc(computedValue || 0);
    if (truncatedValue > 3) {
      return PriorityLevel.High;
    }
    if (truncatedValue > 2) {
      return PriorityLevel.Medium;
    }
    if (truncatedValue > 1) {
      return PriorityLevel.Low;
    }
    return PriorityLevel.None;
  }
  return null;
}
