import React, { useState } from 'react';
import {
  Theme,
  UpdateCatalystModalFragment,
  useCatalystUpdateMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';
import ThemeFilterDropdown from '../../form/ThemeFilterDropdown';
import {
  CheckCircleIcon,
  ErrorIcon,
  PlusIcon,
  QuoteWhiteIcon,
  XIcon,
} from '../../icons';

export function UpdateCatalystModal({
  catalyst,
}: {
  catalyst: UpdateCatalystModalFragment;
}) {
  const toast = useToast();
  const modal = useModal();

  const [title, setTitle] = useState(catalyst.title);
  const [quote, setQuote] = useState(catalyst.quote || '');
  const [theme, setTheme] = useState<Theme | null>(catalyst.theme);
  const [obstacles, setObstacles] = useState<string[]>(
    catalyst.obstacles || [],
  );
  const [strengthArray, setStrengthArray] = useState<string[]>(
    catalyst.strength || [],
  );

  const [updateCatalyst] = useCatalystUpdateMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  const update = () => {
    setIsUpdating(true);
    updateCatalyst({
      variables: {
        input: {
          id: catalyst.id,
          roadmap: {
            id: catalyst.roadmap.id,
          },
          title: title,
          quote: quote,
          theme: theme,
          obstacles: obstacles,
          strength: strengthArray,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Axe stratégique mis à jour');
        modal.closeModal();
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <h4 className="text-center">Axe stratégique ・ Édition</h4>
        <button
          className="primary purple"
          onClick={() => update()}
          disabled={isUpdating || !title}
        >
          {isUpdating ? <Loader /> : 'Sauvegarder'}
        </button>
      </div>

      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-2 items-center bg-purple-50 p-8">
          <div className="flex flex-col gap-2">
            <ThemeFilterDropdown
              selectedTheme={theme}
              setFilterByTheme={setTheme}
              displayAllThemes={false}
              inlined={true}
            />
          </div>
          <input
            type="text"
            id="title"
            className="form-input-text-inline title-h3 text-lg"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="p-8 space-y-4 divide-y divide-gray-100">
          <div className="flex gap-2 p-4">
            <QuoteWhiteIcon className="shrink-0 inline-block h-12 w-12 rounded-full" />
            <textarea
              id="quote"
              className="form-input-text-inline h-24"
              placeholder="Citation pour mettre l'axe stratégique en contexte"
              value={quote}
              onChange={(e) => setQuote(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-2 p-4">
            <div className="flex flex-col gap-2 ">
              <div className="flex justify-between items-center">
                <h5>Obstacles</h5>
                <button
                  className="inlined"
                  onClick={() => setObstacles([...obstacles, ''])}
                >
                  <PlusIcon />
                  <span>Ajouter</span>
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {obstacles.map((obstacle, index) => (
                <div className="flex gap-2 items-center">
                  <ErrorIcon className="text-red-500 shrink-0 h-6 w-6" />
                  <input
                    type="text"
                    className="form-input-text-inline"
                    value={obstacle}
                    onChange={(e) => {
                      const newObstacles = [...obstacles];
                      newObstacles[index] = e.target.value;
                      setObstacles(newObstacles);
                    }}
                  />
                  <button
                    className="inlined"
                    onClick={() => {
                      const newObstacles = [...obstacles];
                      newObstacles.splice(index, 1);
                      setObstacles(newObstacles);
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-2 p-4">
            <div className="flex justify-between items-center">
              <h5>Atouts</h5>

              <button
                className="inlined"
                onClick={() => setStrengthArray([...strengthArray, ''])}
              >
                <PlusIcon />
                <span>Ajouter</span>
              </button>
            </div>

            <div className="flex flex-col gap-2">
              {strengthArray.map((strength, index) => (
                <div className="flex gap-2 items-center">
                  <CheckCircleIcon className="text-green-500 shrink-0 h-6 w-6" />
                  <input
                    type="text"
                    className="form-input-text-inline"
                    value={strength}
                    onChange={(e) => {
                      const newStrength = [...strengthArray];
                      newStrength[index] = e.target.value;
                      setStrengthArray(newStrength);
                    }}
                  />
                  <button
                    className="inlined"
                    onClick={() => {
                      const newStrength = [...strengthArray];
                      newStrength.splice(index, 1);
                      setStrengthArray(newStrength);
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
