import {
  DiagnosticStakeFieldsFragment,
  ImpactLevel,
  PriorityLevel,
  ProbabilityLevel,
  SurveyAnswerStakeFieldsFragment,
  SurveyQuestionStakeOptionsFieldsFragment,
  SurveySubmitAnswerStakeInput,
} from '../../../../graphql/generated';
import { CheckboxSurveyInput } from '../../../generic/form/CheckboxSurveyInput';
import React from 'react';

export type CheckBoxProps = {
  stake: DiagnosticStakeFieldsFragment | null;
  stakeOptions: SurveyQuestionStakeOptionsFieldsFragment | null | undefined;
  stakeAnswer: SurveyAnswerStakeFieldsFragment | null | undefined;
  mergeAnswerStakeInput: (input: SurveySubmitAnswerStakeInput) => void;
};

export function StakeOptionCheckBox({
  checkboxProps,
  placeholder,
  levelType,
  level,
  tooltip,
}: {
  checkboxProps: CheckBoxProps;
  placeholder?: string | null | undefined;
  levelType: 'priority' | 'impact' | 'probability';
  level: PriorityLevel | ImpactLevel | ProbabilityLevel;
  tooltip?: string | null | undefined;
}) {
  const checked =
    (levelType === 'priority' &&
      checkboxProps.stakeAnswer?.priority === level) ||
    (levelType === 'impact' && checkboxProps.stakeAnswer?.impact === level) ||
    (levelType === 'probability' &&
      checkboxProps.stakeAnswer?.probability === level);

  const input: SurveySubmitAnswerStakeInput = {
    stakeId: checkboxProps.stake?.id || '',
  };

  const check = () => {
    if (levelType === 'priority') {
      input.priority = level as PriorityLevel;
    } else if (levelType === 'impact') {
      input.impact = level as ImpactLevel;
    } else if (levelType === 'probability') {
      input.probability = level as ProbabilityLevel;
    }
    checkboxProps.mergeAnswerStakeInput(input);
  };

  const uncheck = () => {
    if (levelType === 'priority') {
      input.priority = undefined;
    } else if (levelType === 'impact') {
      input.impact = undefined;
    } else if (levelType === 'probability') {
      input.probability = undefined;
    }
    checkboxProps.mergeAnswerStakeInput(input);
  };

  return (
    <div>
      <CheckboxSurveyInput
        placeholder={placeholder}
        checked={checked}
        check={check}
        uncheck={uncheck}
        tooltip={tooltip}
      />
    </div>
  );
}
