import {
  CurrentUserDocument,
  ProjectContextualMenu_CompanyFragment,
  ProjectContextualMenuDocument,
  useProjectContextualMenu_DeleteCompanyMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useModal } from '../../../layout/Modal';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { useTranslation } from '@hooks/useTranslation';
import React, { useState } from 'react';
import { ConfirmationModal } from '../../../modals/ConfirmationModal';
import { Loader } from '../../../generic/Loader';
import { DeleteIcon } from '../../../icons';

export function DeleteCompanyButton({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const [deleteCompanyMutation] =
    useProjectContextualMenu_DeleteCompanyMutation({
      refetchQueries: () => [
        { query: CurrentUserDocument },
        {
          query: ProjectContextualMenuDocument,
          variables: {
            companyId: currentUser?.company?.id || '',
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: company.id,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:project.delete.success'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.contextualMenu.deleteProject.confirmMessage', {
              companyName: company.name,
            })}
            callbackIfConfirmed={deleteCompany}
          />,
          t('project.contextualMenu.deleteProject.confirmTitle', {
            companyName: company.name,
          }),
        )
      }
    >
      {isDeleting ? <Loader /> : <DeleteIcon />}
      <div>{t('global:delete')}</div>
    </button>
  );
}
