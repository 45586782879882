import React, { useState } from 'react';
import {
  ChecklistDocument,
  UpdateTaskGroupModal_TaskGroupFragment,
  useUpdateTaskGroupModal_DeleteMutation,
  useUpdateTaskGroupModal_UpdateMutation,
} from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';

export function UpdateTaskGroupModal({
  taskGroup,
}: {
  taskGroup: UpdateTaskGroupModal_TaskGroupFragment;
}) {
  const toast = useToast();
  const modal = useModal();

  const [name, setName] = useState(taskGroup.name);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [updateTaskGroupMutation] = useUpdateTaskGroupModal_UpdateMutation();
  const [deleteTaskGroupMutation] = useUpdateTaskGroupModal_DeleteMutation({
    refetchQueries: [
      {
        query: ChecklistDocument,
        variables: {
          id: taskGroup.checklist.id,
        },
      },
    ],
  });
  const deleteTaskGroup = () => {
    setIsDeleting(true);
    deleteTaskGroupMutation({ variables: { id: taskGroup.id } })
      .then(() => {
        toast.openToastWithMessage('Catégorie supprimée');
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  const updateTaskGroup = () => {
    setIsUpdating(true);
    updateTaskGroupMutation({
      variables: {
        input: {
          id: taskGroup.id,
          name: name,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Tâche mise à jour');
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-sm">
      <div className="flex flex-col gap-1">
        <label htmlFor="title" className="form-input-label">
          Nom de la catégorie
        </label>
        <input
          type="text"
          id="title"
          className="form-input-text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <MessageBox type={MessageBoxType.Info}>
        Les catégories permettent de grouper et d&apos;organiser vos tâches de
        manière claire et motivante
      </MessageBox>

      <div className="flex gap-2 justify-between">
        <button
          className="secondary danger"
          onClick={deleteTaskGroup}
          disabled={isDeleting || !name}
        >
          {isDeleting ? <Loader /> : 'Supprimer'}
        </button>
        <button
          className="primary purple"
          onClick={updateTaskGroup}
          disabled={isUpdating || !name}
        >
          {isUpdating ? <Loader /> : 'Modifier'}
        </button>
      </div>
    </div>
  );
}
