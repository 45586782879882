import { CompanyReportsSettings_CompanyFragment } from '../../../../graphql/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { useAvailableStandardsQuery } from '../../../../graphql/cms/generated';
import { AddCompanyReportFormInput } from './AddCompanyReportFormInput';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';

export const CompanyReportsSettings = ({
  company,
}: {
  company: CompanyReportsSettings_CompanyFragment;
}) => {
  const { t } = useTranslation();
  const standardsQuery = useAvailableStandardsQuery({
    client: cmsClient,
    fetchPolicy: 'cache-and-network',
  });
  const data = standardsQuery.data;
  if (standardsQuery.loading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return <div>Error</div>;
  }

  return (
    <div className="space-y-4">
      <h2 className="title-h4">Rapports</h2>
      <MessageBox type={MessageBoxType.Warning}>
        {t('company.settings.reports.cannotUnCheckYet')}
      </MessageBox>
      {data.standards.map((standard) => {
        const relatedReport = company.reports?.find(
          (report) => report.standard === standard?.slug,
        );
        let isActivated = false;
        if (relatedReport) {
          isActivated = true;
        }

        return (
          standard && (
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                {/* this button can only be checked, cannot be unchecked */}
                <AddCompanyReportFormInput
                  companyId={company.id}
                  standard={standard}
                  isActivated={isActivated}
                />
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};
