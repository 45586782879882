import { AnnotationElement } from 'chartjs-plugin-annotation';
import { Plugin } from 'chart.js';

export class DragHandler {
  public dragging = false;
  public element: AnnotationElement | undefined;
  private lastEvent: { x: number; y: number } | undefined;

  constructor() {
    this.element = undefined;
    this.lastEvent = undefined;
  }

  private drag(moveX: number, moveY: number) {
    if (this.element) {
      this.dragging = true;
      this.element.x += moveX;
      this.element.y += moveY;
      this.element.x2 += moveX;
      this.element.y2 += moveY;
      this.element.centerX += moveX;
      this.element.centerY += moveY;
    }
  }

  private handleElementDragging(event: { x: number; y: number } | undefined) {
    if (!this.lastEvent || !this.element) {
      return false;
    }
    if (event) {
      this.dragging = true;
      const moveX = event.x - this.lastEvent.x;
      const moveY = event.y - this.lastEvent.y;
      this.drag(moveX, moveY);
      this.lastEvent = event;
      return true;
    }
  }

  private handleDrag(
    event:
      | {
          x: number;
          y: number;
          type: 'mousemove' | 'mouseout' | 'mouseup' | 'mousedown';
        }
      | undefined,
  ) {
    if (this.element && event) {
      switch (event.type) {
        case 'mousemove':
          return this.handleElementDragging(event);
        case 'mouseup':
          this.dragging = false;
          this.onLeave();
          break;
        case 'mousedown':
          this.lastEvent = event;
          break;
        case 'mouseout':
        default:
      }
    }

    return false;
  }

  public getPlugin(): Plugin<'bubble'> {
    return {
      id: 'dragger',
      beforeEvent: (chart, args) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (this.handleDrag(args.event)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          args.changed = true;
          return;
        }
      },
    };
  }

  public onEnter(dragElement: AnnotationElement | undefined) {
    if (this.dragging) {
      return;
    }
    this.element = dragElement;
  }

  public onLeave() {
    if (this.dragging) {
      return;
    }
    this.element = undefined;
    this.lastEvent = undefined;
  }
}
