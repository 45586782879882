import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';

export type SurveyWaitingStakeholderColumnsRow = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export const useSurveyWaitingStakeholderColumnsRow = (): Array<
  ColumnDef<SurveyWaitingStakeholderColumnsRow>
> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SurveyWaitingStakeholderColumnsRow>();

  return [
    columnHelper.accessor('email', {
      cell: (data) => {
        return (
          <div className="flex items-center gap-2">
            <div className="font-bold">{data.getValue()}</div>
          </div>
        );
      },
      header: () => <span>{t('survey.waitingStakeholders.table.email')}</span>,
    }),
    columnHelper.accessor('firstName', {
      cell: (data) => {
        return data.getValue();
      },
      header: () => (
        <span>{t('survey.waitingStakeholders.table.firstName')}</span>
      ),
    }),
    columnHelper.accessor('lastName', {
      cell: (data) => {
        return data.getValue();
      },
      header: () => (
        <span>{t('survey.waitingStakeholders.table.lastName')}</span>
      ),
    }),
  ] as ColumnDef<SurveyWaitingStakeholderColumnsRow>[];
};
