import React from 'react';

export function IllustratedExplanationMessage({
  title,
  description,
  illustration,
  children,
}: {
  title: string;
  description: string;
  illustration?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <div className="bg-purple-50 rounded-xl flex items-stretch gap-8 p-4 w-full">
      {illustration && (
        <div className="max-w-20 flex items-center justify-center">
          {illustration}
        </div>
      )}
      <div className="grow flex justify-between items-center gap-8">
        <div className="space-y-2">
          <h4>{title}</h4>
          <div className="text-gray-500 max-w-xl text-sm">{description}</div>
        </div>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}
