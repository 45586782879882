export const enum AppRoutes {
  Home = '/',
  Settings = '/settings',
  SettingsCoach = '/settings/coaches',
  SettingsCompany = '/settings/company',
  SettingsTemplates = '/settings/templates',
  Stakes = '/stakes',
  Iro = '/iro',
  GapAnalysis = '/gap-analysis',
  GapAnalysisDisclosureRequirement = '/gap-analysis/dr/:referenceSlug',
  GapAnalysisSynthesis = '/gap-analysis/synthesis',
  GapAnalysisChecklist = '/gap-analysis/checklist',
  IroRating = '/iro/:pillarId/:stakeId',
  StakeholderMapping = '/stakeholder-mapping',
  Project = '/project',
  ProjectNotebook = '/project/notebook',
  ProjectUsers = '/project/users',
  ProjectCompany = '/project/company',
  ProjectSettings = '/project/settings',
  Publication = '/publications',
  PublicationMaturity = '/publications/maturity',
  PublicationPrioritizationMatrix = '/publications/prioritization-matrix',
  PublicationMaterialityMatrix = '/publications/materiality-matrix',
  PublicationDoubleMaterialityMatrixIro = '/publications/double-materiality-matrix/iro',
  PublicationDoubleMaterialityMatrixStakeholder = '/publications/double-materiality-matrix/stakeholder',
  Roadmap = '/roadmap',
  RoadmapAction = '/roadmap/action/:actionId',
  RoadmapActionEdit = '/roadmap/action/:actionId/edit',
  Survey = '/survey',
  SurveyItem = '/survey/:surveyId',
  SurveyResults = '/survey/results',
  SurveyTab = '/survey/:surveyId/:tab',
  MaturityEvaluations = '/maturity',
  MaturityEvaluationImport = '/maturity/import',
  MaturityEvaluationItem = '/maturity/:surveyId',
  MaturityEvaluationItemTab = '/maturity/:surveyId/:tab',
  MaturityEvaluationAssign = '/maturity/:surveyId/assign',
  ConsultationRawMaterialities = '/consultation/raw-materialities/:consultationId',
  ActionTemplateEdit = '/settings/templates/action/:actionId/edit',
}
