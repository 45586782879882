import { QualityLevel } from '../../../../../../graphql/generated';

export const mapQualityLevelToNumber = (
  level: QualityLevel | undefined | null,
): number => {
  switch (level) {
    case QualityLevel.Negative:
      return -1;
    case QualityLevel.Positive:
      return 1;
    case QualityLevel.Neutral:
    default:
      return 0;
  }
};
