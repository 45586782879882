import {
  EditSurvey_QuestionableSurvey_MaturityEvaluationFragment,
  EditSurvey_QuestionableSurvey_SurveyFragment,
  EditSurvey_QuestionFragment,
  MaturityEvaluation,
  SurveyLanguage,
  SurveyQuestionType,
  SurveyStatus,
} from '../../../../graphql/generated';
import React, { useState } from 'react';
import { QuestionForm } from './QuestionForm';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { QuestionRouter } from '../../../survey/questions/QuestionRouter';
import { SurveyQuestionsMenuItems } from './SurveyQuestionsMenuItems';
import { LanguageScopeWrapper } from '../../../../screens/project/home/LanguageScopeWrapper';
import { isMaturityEvaluation } from '../../../../types/survey.types';

// GraphQL limitation: we cannot use the parent type directly since the query is done on the child
export type EditSurvey_QuestionableSurveyFragment =
  | EditSurvey_QuestionableSurvey_SurveyFragment
  | EditSurvey_QuestionableSurvey_MaturityEvaluationFragment;

export function EditSurvey({
  survey,
  isPreview,
}: {
  survey: EditSurvey_QuestionableSurveyFragment;
  isPreview: boolean;
}) {
  const isEditable = isSurveyEditable(survey);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null,
  );
  const [isPreviewExpanded, setIsPreviewExpanded] = useState<boolean>(
    isPreview || !isEditable,
  );

  const questions = React.useMemo(() => {
    return (
      survey.questions?.flatMap((question) => {
        if (question.type === SurveyQuestionType.Group) {
          return [question, ...(question.children || [])];
        } else {
          return [question];
        }
      }) || []
    );
  }, [survey.questions]);

  const selectedQuestion: EditSurvey_QuestionFragment | null =
    questions.find((question) => question?.id === selectedQuestionId) || null;

  return (
    <div className="flex w-full">
      <div className="w-1/3 max-w-[500px] min-w-[400px] border-r border-gray-100 bg-white shadow-lg z-10 overflow-scroll flex flex-col">
        <SurveyQuestionsMenuItems
          survey={survey}
          selectedQuestion={selectedQuestion}
          setSelectedQuestionId={setSelectedQuestionId}
          isEditable={!isPreview && isEditable}
        />
      </div>
      <div className="w-2/3 grow overflow-scroll">
        {/* Preview */}
        {selectedQuestion ? (
          <div>
            {isPreviewExpanded && (
              <div className="border-b border-gray-100">
                <div className={'border-b border-gray-100 w-full'}>
                  <LanguageScopeWrapper
                    language={survey.language || SurveyLanguage.Fr}
                  >
                    <QuestionRouter
                      question={selectedQuestion}
                      answer={undefined}
                      setAnswer={() => console.log('preview')}
                      setIsAnswerValid={() => console.log('preview')}
                    />
                  </LanguageScopeWrapper>
                </div>
              </div>
            )}
            <div className="">
              {!isPreview && isEditable && selectedQuestionId && (
                <QuestionForm
                  questionId={selectedQuestionId}
                  isPreviewExpanded={isPreviewExpanded}
                  setIsPreviewExpanded={setIsPreviewExpanded}
                />
              )}
              {!isPreview && !isEditable && (
                <div className="p-8">
                  <MessageBox type={MessageBoxType.Info}>
                    Votre questionnaire n&apos;est plus modifiable car il est
                    publié
                  </MessageBox>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="p-8">
            <MessageBox type={MessageBoxType.Info}>
              Aucune question sélectionnée
            </MessageBox>
          </div>
        )}
      </div>
    </div>
  );
}

function isSurveyEditable(survey: EditSurvey_QuestionableSurveyFragment) {
  if (isMaturityEvaluation<MaturityEvaluation>(survey)) {
    // Maturity evaluations are always editable (even if sent)
    return survey.status
      ? [SurveyStatus.Created, SurveyStatus.Ready, SurveyStatus.Sent].includes(
          survey.status,
        )
      : false;
  } else {
    return survey.status
      ? [SurveyStatus.Created].includes(survey.status)
      : false;
  }
}
