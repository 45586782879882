import './App.css';
import { ScreensRouter } from './screens/ScreensRouter';
import { CurrentAuthProvider } from './providers/CurrentAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { CurrentUserProvider } from './providers/CurrentUserProvider';
import { ErrorBoundary } from 'components/generic/ErrorBoundary';
import { NavContextProvider } from 'providers/NavContextProvider';
import { ToastProvider } from 'components/layout/Toast';
import { ModalProvider } from './components/layout/Modal';

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <CurrentAuthProvider>
          <CurrentUserProvider>
            <NavContextProvider>
              <ToastProvider>
                <ModalProvider>
                  <ScreensRouter />
                </ModalProvider>
              </ToastProvider>
            </NavContextProvider>
          </CurrentUserProvider>
        </CurrentAuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
