import React from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  StandardEnum,
  useGapAnalysisChecklistQuery,
} from '../../../../graphql/generated';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { CreateChecklistButton } from '../../../task/CreateChecklistButton';
import { Checklist } from '../../../task/Checklist';
import { NoData } from '../../../generic/NoData';
import { AutoIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export function GapAnalysisChecklist() {
  const { t } = useTranslation();
  const context = useProjectContext();

  const apiQuery = useGapAnalysisChecklistQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (apiQuery.loading) {
    return (
      <div className="px-16 py-8 main-content">
        <LoaderFullscreen />
      </div>
    );
  }

  if (apiQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {apiQuery.error.message}
      </MessageBox>
    );
  }

  const createChecklistButton = (
    <CreateChecklistButton
      reportId={apiQuery.data?.companyReport?.id || ''}
      className="primary purple"
    />
  );

  const generateChecklistButton = (
    <button className="secondary purple disabled cursor-auto opacity-50">
      <AutoIcon />
      {t('gap_analysis.checklist.generate')}
      <span className="tag yellow">{t('global:soon')}</span>
    </button>
  );

  if (apiQuery.data?.companyReport) {
    if (apiQuery.data?.companyReport?.checklist) {
      return (
        <div className="px-16 py-8 main-content">
          <Checklist checklistId={apiQuery.data.companyReport.checklist.id} />
        </div>
      );
    } else {
      return (
        <div className="px-16 py-8 main-content">
          <NoData
            text={t('gap_analysis.checklist.no_checklist_label')}
            actions={[
              {
                key: 'create-checklist',
                component: createChecklistButton,
              },
              {
                key: 'generate-checklist',
                component: generateChecklistButton,
              },
            ]}
          />
        </div>
      );
    }
  }
}
