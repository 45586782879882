import {
  AddNewStakeButton_PillarFragment,
  useAddNewStakeButton_StakeCreateMutation,
} from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import React, { useState } from 'react';
import { UpdateStakeModal } from './UpdateStakeModal';
import { Loader } from '../../generic/Loader';
import { PlusIcon } from '../../icons';

export function AddNewStakeButton({
  pillar,
}: {
  pillar: AddNewStakeButton_PillarFragment;
}) {
  const modal = useModal();
  const [createDiagnosticStake] = useAddNewStakeButton_StakeCreateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const addNewStake = () => {
    setIsLoading(true);
    createDiagnosticStake({
      variables: {
        input: {
          pillar: {
            id: pillar.id,
          },
        },
      },
    })
      .then((res) => {
        if (res.data?.diagnosticStakeCreate) {
          modal.openModalWithComponent(
            <UpdateStakeModal stake={res.data?.diagnosticStakeCreate} />,
            "Modifier l'enjeu",
            true,
            false,
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <button className="primary small" onClick={addNewStake}>
      {isLoading ? <Loader /> : <PlusIcon />}
    </button>
  );
}
