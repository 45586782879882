import {
  StakeholderListDocument,
  useRemoveStakeholderGroupButtonMutation,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../../layout/Modal';
import React, { useState } from 'react';
import { Loader } from '../../../../generic/Loader';

export const RemoveStakeholderGroupButton = ({
  stakeholderGroupId,
  companyId,
}: {
  stakeholderGroupId: string;
  companyId: string;
}) => {
  const { t } = useTranslation();
  const modal = useModal();

  const [isDeleting, setIsDeleting] = useState(false);
  const [removeStakeholderGroupMutation] =
    useRemoveStakeholderGroupButtonMutation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const removeStakeholderGroup = () => {
    setIsDeleting(true);
    removeStakeholderGroupMutation({
      variables: { id: stakeholderGroupId },
      refetchQueries: () => [
        {
          query: StakeholderListDocument,
          variables: {
            companyId: companyId,
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        modal.closeModal();
      })
      .finally(() => setIsDeleting(false))
      .catch((err) => {
        console.error(err);
      });
  };

  if (confirmDelete) {
    return (
      <div className="flex items-center gap-1">
        <p className="text-sm">{t('global:confirm_delete')}</p>
        <button
          className="primary danger small"
          onClick={() => removeStakeholderGroup()}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : t('global:yes')}
        </button>
        <button
          className="tertiary text-gray-500 small"
          onClick={() => setConfirmDelete(false)}
        >
          {t('global:no')}
        </button>
      </div>
    );
  }

  return (
    <button
      className="secondary purple"
      onClick={() => setConfirmDelete(true)}
      disabled={isDeleting}
    >
      {t('global:delete')}
    </button>
  );
};
