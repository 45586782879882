import React from 'react';
import { useModal } from '../layout/Modal';
import { InfoIcon } from '../icons';

export function HelpModal({
  modalTitle,
  children,
}: {
  modalTitle: string;
  children: React.ReactNode;
}) {
  const modal = useModal();
  return (
    <button
      className="unstyled"
      onClick={() =>
        modal.openModalWithComponent(
          children,
          modalTitle,
          false,
          true,
          'w-10/12 lg:w-1/2',
        )
      }
    >
      <InfoIcon className="text-gray-900 w-4 h-4" />
    </button>
  );
}
