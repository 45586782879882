import React from 'react';
import { StakeholderGroupForm_StakeholderGroupFragment } from '../../../../../graphql/generated';
import { RemoveStakeholderGroupButton } from '../remove/RemoveStakeholderGroupButton';
import { Loader } from '../../../../generic/Loader';

type FormFooterProps = {
  initialData?: StakeholderGroupForm_StakeholderGroupFragment;
  isLoading: boolean;
  handleSubmit: () => void;
  t: (key: string, options?: any) => string;
};

export const FormFooter: React.FC<FormFooterProps> = ({
  initialData,
  isLoading,
  handleSubmit,
  t,
}) => {
  return (
    <div className="flex gap-2 justify-between px-6 py-4 border-t border-gray-100">
      {initialData?.id && (
        <RemoveStakeholderGroupButton
          stakeholderGroupId={initialData.id}
          companyId={initialData.company?.id ?? ''}
        />
      )}
      <button
        className="primary purple"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? <Loader /> : t('global:save')}
      </button>
    </div>
  );
};
