import {
  SurveyQuestionType,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionQuery,
} from '../../../../graphql/generated';
import { QuestionIdentityForm } from './questionsForm/QuestionIdentityForm';
import { QuestionTextForm } from './questionsForm/QuestionTextForm';
import { QuestionMultipleChoiceForm } from './questionsForm/QuestionMultipleChoiceForm';
import { QuestionMaturityEvaluationForm } from './questionsForm/QuestionMaturityEvaluationForm';
import { QuestionEnpsForm } from './questionsForm/QuestionEnpsForm';
import { QuestionRatingForm } from './questionsForm/QuestionRatingForm';
import { QuestionStatementForm } from './questionsForm/QuestionStatementForm';
import { QuestionGroupForm } from './questionsForm/QuestionGroupForm';
import { QuestionStakesSimpleMaterialityForm } from './questionsForm/QuestionStakesSimpleMaterialityForm';
import { QuestionStakesDoubleMaterialityForm } from './questionsForm/QuestionStakesDoubleMaterialityForm';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import React from 'react';
import { QuestionStakesRankingForm } from './questionsForm/QuestionStakesRankingForm';
import { QuestionUtopiesStakesDoubleMaterialityForm } from './questionsForm/custom/QuestionUtopiesStakesDoubleMaterialityForm';
import { LoaderFullscreen } from '../../../layout/Loader';
import { QuestionNumberForm } from './questionsForm/QuestionNumberForm';
import { Toggle } from '../../../generic/form/Toggle';

export function QuestionForm({
  questionId,
  isPreviewExpanded,
  setIsPreviewExpanded,
}: {
  questionId: string;
  isPreviewExpanded: boolean;
  setIsPreviewExpanded: (display: boolean) => void;
}) {
  const questionQuery = useSurveyQuestionQuery({
    variables: {
      id: questionId,
    },
    fetchPolicy: 'network-only',
  });

  // Refetch when question changes
  React.useEffect(() => {
    void questionQuery.refetch({
      id: questionId,
    });
  }, [questionId, questionQuery]);

  if (questionQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!questionQuery.data?.surveyQuestion) {
    return (
      <div className="p-16">
        <MessageBox type={MessageBoxType.Error}>Erreur</MessageBox>
      </div>
    );
  }

  const question = questionQuery.data.surveyQuestion;
  const questionForm = getQuestionForm(question);

  if (question.type === SurveyQuestionType.Group) {
    // Groups have no preview
    return (
      <div className="space-y-8 p-8">
        <div className="flex items-center justify-between">
          <h2>Édition</h2>
        </div>
        {questionForm}
      </div>
    );
  } else {
    return (
      <div className="space-y-8 p-8">
        <div className="flex items-center justify-between">
          <h2>Édition</h2>
          <div className="flex items-center gap-2">
            <Toggle state={isPreviewExpanded} setState={setIsPreviewExpanded} />
            <label className="form-input-label">Afficher aperçu</label>
          </div>
        </div>
        {questionForm}
      </div>
    );
  }
}

function getQuestionForm(question: SurveyQuestionSummaryFieldsFragment) {
  switch (question.type) {
    case SurveyQuestionType.Email:
      return <QuestionIdentityForm question={question} />;
    case SurveyQuestionType.ShortText:
      return <QuestionTextForm question={question} />;
    case SurveyQuestionType.Number:
      return <QuestionNumberForm question={question} />;
    case SurveyQuestionType.MultipleChoice:
      return <QuestionMultipleChoiceForm question={question} />;
    case SurveyQuestionType.MaturityEvaluation:
      return <QuestionMaturityEvaluationForm question={question} />;
    case SurveyQuestionType.Enps:
      return <QuestionEnpsForm question={question} />;
    case SurveyQuestionType.Rating:
      return <QuestionRatingForm question={question} />;
    case SurveyQuestionType.StakesRanking:
      return <QuestionStakesRankingForm question={question} />;
    case SurveyQuestionType.StakeDoubleMateriality:
      return <QuestionStakesDoubleMaterialityForm question={question} />;
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return <QuestionUtopiesStakesDoubleMaterialityForm question={question} />;
    case SurveyQuestionType.Statement:
      return <QuestionStatementForm question={question} />;
    case SurveyQuestionType.Group:
      return <QuestionGroupForm question={question} />;
    case SurveyQuestionType.Matrix:
      return <QuestionStakesSimpleMaterialityForm question={question} />;
    default:
      return (
        <div className="p-16">
          <MessageBox type={MessageBoxType.Warning}>
            Type de question non supporté
          </MessageBox>
        </div>
      );
  }
}
