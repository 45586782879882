import {
  BaseIro,
  NegativeImpactIro,
  OpportunityIro,
  PositiveImpactIro,
  RiskIro,
  WithIrremediabilityCriteria,
  WithLikelihoodCriteria,
  WithScaleCriteria,
  WithScopeCriteria,
} from '../graphql/generated';

export function isRiskIro<T extends RiskIro>(
  iro?: BaseIro | null | undefined,
): iro is T {
  return (iro as RiskIro)?.__typename === 'RiskIro';
}

export function isOpportunityIro<T extends OpportunityIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'OpportunityIro';
}

export function isPositiveImpactIro<T extends PositiveImpactIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'PositiveImpactIro';
}

export function isNegativeImpactIro<T extends NegativeImpactIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'NegativeImpactIro';
}

export function hasScaleCriteria<T extends WithScaleCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithScaleCriteria).scaleCriteria) {
    return false;
  }
  return true;
}

export function hasScopeCriteria<T extends WithScopeCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithScopeCriteria).scopeCriteria) {
    return false;
  }
  return true;
}

export function hasIrremediabilityCriteria<
  T extends WithIrremediabilityCriteria,
>(iro?: unknown | null | undefined): iro is T {
  if (!(iro as WithIrremediabilityCriteria).irremediabilityCriteria) {
    return false;
  }
  return true;
}

export function hasLikelihoodCriteria<T extends WithLikelihoodCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithLikelihoodCriteria).likelihoodCriteria) {
    return false;
  }
  return true;
}
