import { useTranslation } from '@hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import React from 'react';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { FeatureNames } from '../../../../graphql/generated';

export const NeedToCreateReportFirst = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSettingsEnabled = useFeatureEnabled(FeatureNames.CompanySettings);
  return (
    <div className="space-y-8 px-16 py-8">
      <div className="flex justify-between mb-10">
        <MessageBox type={MessageBoxType.Warning}>
          <>
            {t('gap_analysis.summary.needCreateReportFirst.description')}
            {isSettingsEnabled && (
              <button
                className="secondary mt-5"
                onClick={() => navigate(AppRoutes.ProjectSettings)}
              >
                {t('gap_analysis.summary.needCreateReportFirst.button')}
              </button>
            )}
          </>
        </MessageBox>
      </div>
    </div>
  );
};
