import { useTranslation } from '@hooks/useTranslation';
import { StepIndicatorItem } from '../../../generic/StepsIndicator';
import { InnerHtml } from '../../../generic/InnerHtml';
import React from 'react';

export function getMaturityEvaluationStepsItems(
  hasSurveys: boolean,
  hasPublishedSurveys: boolean,
  hasAnswers: boolean,
) {
  const step1Status = hasSurveys ? 'complete' : 'current';
  const step2Status = hasSurveys
    ? hasPublishedSurveys
      ? 'complete'
      : 'current'
    : 'upcoming';
  const step3Status = hasAnswers
    ? 'complete'
    : step2Status === 'complete'
      ? 'current'
      : 'upcoming';
  const step4Status = step3Status === 'complete' ? 'current' : 'upcoming';
  const { t } = useTranslation();

  const maturityEvaluationSteps: StepIndicatorItem[] = [
    {
      id: 1,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step1')} />
      ),
      status: step1Status,
    },
    {
      id: 2,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step2')} />
      ),
      status: step2Status,
    },
    {
      id: 3,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step3')} />
      ),
      status: step3Status,
    },
    {
      id: 4,
      name: (
        <InnerHtml html={t('maturity.evaluation.messageBox.steps.step4')} />
      ),
      status: step4Status,
    },
  ];

  return maturityEvaluationSteps;
}
