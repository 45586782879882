import React from 'react';
import { IroOpportunityCard_IroFragment } from '../../../../../graphql/generated';
import { ColorIcon, ColorIconColor } from '../../../../generic/icon/ColorIcon';
import { IroName, IroNameProps } from './IroName';
import { StarIcon } from '../../../../icons';

export function IroOpportunityCard({
  iro,
  language,
}: {
  iro: IroOpportunityCard_IroFragment;
} & IroNameProps) {
  return (
    <div className="bg-white p-2 rounded-lg border border-gray-100 shadow-sm">
      <div className="flex items-center gap-4">
        <div className="bg-green-50 rounded-lg p-1 text-green-900 shrink-0 w-8 h-8 flex items-center justify-center">
          <ColorIcon color={ColorIconColor.Green} icon={<StarIcon />} />
        </div>
        <IroName {...{ iro, language }} />
      </div>
    </div>
  );
}
