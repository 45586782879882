import React from 'react';
import {
  QuestionRouter_QuestionFragment,
  SurveyQuestionType,
  SurveySubmitAnswerInput,
} from '../../../graphql/generated';
import { QuestionText } from './QuestionText';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { QuestionStatement } from './QuestionStatement';
import { QuestionMultipleChoice } from './QuestionMultipleChoice';
import { QuestionEnps } from './QuestionEnps';
import { QuestionIdentity } from './QuestionIdentity';
import { QuestionRating } from './QuestionRating';
import { QuestionStakesSimpleMateriality } from './QuestionStakesSimpleMateriality';
import { QuestionStakesRanking } from './QuestionStakesRanking';
import { QuestionStakesDoubleMateriality } from './QuestionStakesDoubleMateriality';
import { QuestionNumber } from './QuestionNumber';
import { QuestionUtopiesStakesDoubleMateriality } from './custom/QuestionUtopiesStakesDoubleMateriality';

export function QuestionRouter({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: QuestionRouter_QuestionFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  switch (question.type) {
    case SurveyQuestionType.Email:
      return (
        <QuestionIdentity
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.ShortText:
      return (
        <QuestionText
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.Number:
      return (
        <QuestionNumber
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.MultipleChoice:
    case SurveyQuestionType.MaturityEvaluation:
      return (
        <QuestionMultipleChoice
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.Enps:
      return (
        <QuestionEnps
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.Matrix:
      return (
        <QuestionStakesSimpleMateriality
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.StakeDoubleMateriality:
      return (
        <QuestionStakesDoubleMateriality
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return (
        <QuestionUtopiesStakesDoubleMateriality
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.StakesRanking:
      return (
        <QuestionStakesRanking
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.Rating:
      return (
        <QuestionRating
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          setIsAnswerValid={setIsAnswerValid}
        />
      );
    case SurveyQuestionType.Statement:
      return <QuestionStatement question={question} />;
    case SurveyQuestionType.Group:
      return null;
    default:
      return (
        <div className="p-16">
          <MessageBox type={MessageBoxType.Warning}>
            Type de question non supporté
          </MessageBox>
        </div>
      );
  }
}
