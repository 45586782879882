import {
  PreviewCompanyTemplate_CompanyFragment,
  PreviewCompanyTemplate_MaturityEvaluationFragment,
  PreviewCompanyTemplate_QuestionFragment,
  PreviewCompanyTemplate_QuestionsFragment,
  PreviewCompanyTemplate_SurveyFragment,
  SurveyQuestionType,
  useChooseTemplateMutation,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useToast } from '../../../layout/Toast';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import React, { useState } from 'react';
import { ChevronRightIcon } from '../../../icons';
import { isSuperadminUser } from '../../../../services/CompanyService';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { Referential } from '../../stakes/Referential';
import { ChecklistPreview } from '../../../task/ChecklistPreview';
import { StakeholderList } from '../../stakeholder/mapping/StakeholderList';
import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';

export const PreviewCompanyTemplate = ({
  template,
  surveys,
  maturityEvaluations,
  setPreviewCompanyTemplateId,
}: {
  template: PreviewCompanyTemplate_CompanyFragment;
  surveys: PreviewCompanyTemplate_SurveyFragment[];
  maturityEvaluations: PreviewCompanyTemplate_MaturityEvaluationFragment[];
  setPreviewCompanyTemplateId: (companyTemplateId: string | null) => void;
}) => {
  const modal = useModal();
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useCurrentUser();

  const [importUserData, setImportUserData] = useState(false);

  const [isChoosingTemplate, setIsChoosingTemplate] = useState(false);
  const [chooseTemplateMutation] = useChooseTemplateMutation();
  const chooseTemplate = () => {
    setIsChoosingTemplate(true);
    void chooseTemplateMutation({
      variables: {
        input: {
          companyId: projectContext?.enterprise?.id || '',
          templateId: template.id,
          importUserData,
        },
      },
    })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage('Modèle importé avec succès 🎉');
        projectContext?.updateEnterprise(
          projectContext?.enterprise?.id || '',
          false,
        );
      })
      .finally(() => {
        setIsChoosingTemplate(false);
      });
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => setPreviewCompanyTemplateId(null)}
        >
          <ChevronRightIcon className="w-4 h-4 transform rotate-180" />
          <span>
            {t('project.create.chooseTemplateModal.previewTemplate.back')}
          </span>
        </button>
        <h4 className="text-center grow">
          {t('project.create.chooseTemplateModal.previewTemplate.title')}
        </h4>
        {isSuperadminUser(currentUser) && (
          <div className="flex items-center gap-2">
            <label htmlFor="importUserData" className="text-sm italic">
              {t(
                'project.create.chooseTemplateModal.previewTemplate.useTemplate.withDemoData',
              )}
            </label>
            <input
              type="checkbox"
              id="importUserData"
              className="form-input-checkbox"
              checked={importUserData}
              onChange={() => setImportUserData(!importUserData)}
            />
          </div>
        )}
        <button
          className="primary purple"
          onClick={chooseTemplate}
          disabled={isChoosingTemplate}
        >
          {isChoosingTemplate && <Loader />}
          <span>
            {t(
              'project.create.chooseTemplateModal.previewTemplate.useTemplate.button',
            )}
          </span>
        </button>
      </div>
      <ProjectTemplatePreview
        template={template}
        surveys={surveys}
        maturityEvaluations={maturityEvaluations}
      />
    </div>
  );
};

const ProjectTemplatePreview = ({
  template,
  surveys,
  maturityEvaluations,
}: {
  template: PreviewCompanyTemplate_CompanyFragment;
  surveys: PreviewCompanyTemplate_SurveyFragment[];
  maturityEvaluations: PreviewCompanyTemplate_MaturityEvaluationFragment[];
}) => {
  const { t } = useTranslation();

  const enum ProjectTemplatePreviewNavItem {
    Referential = 'Referential',
    Methodology = 'Methodology',
    Survey = 'Survey',
    StakeholderMapping = 'StakeholderMapping',
  }

  const [selectedSurvey, setSelectedSurvey] = useState<
    | PreviewCompanyTemplate_SurveyFragment
    | PreviewCompanyTemplate_MaturityEvaluationFragment
    | null
  >(null);
  const [selectedNavItem, setSelectedNavItem] =
    useState<ProjectTemplatePreviewNavItem>(
      ProjectTemplatePreviewNavItem.Referential,
    );

  const selectSurvey = (
    survey:
      | PreviewCompanyTemplate_SurveyFragment
      | PreviewCompanyTemplate_MaturityEvaluationFragment,
  ) => {
    setSelectedSurvey(survey);
    setSelectedNavItem(ProjectTemplatePreviewNavItem.Survey);
  };

  return (
    <div className="flex items-stretch w-full h-full overflow-scroll">
      <div className="w-3/12 divide-y divide-gray-100 overflow-scroll bg-gray-50">
        <div className="p-8 space-y-4">
          <div className="flex">
            <div className="tag blue font-bold">
              {t(
                'project.create.chooseTemplateModal.previewTemplate.nav.templateProject',
              )}
            </div>
          </div>
          <h2>{template.name}</h2>
          {template?.referential?.description && (
            <p className="text-gray-500 text-sm">
              {template.referential.description}
            </p>
          )}

          <div className="space-y-2">
            {template.referential && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem === ProjectTemplatePreviewNavItem.Referential
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(ProjectTemplatePreviewNavItem.Referential);
                  setSelectedSurvey(null);
                }}
              >
                <div>
                  {t(
                    'project.create.chooseTemplateModal.previewTemplate.nav.referential',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {template.checklist && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem === ProjectTemplatePreviewNavItem.Methodology
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(ProjectTemplatePreviewNavItem.Methodology);
                  setSelectedSurvey(null);
                }}
              >
                <div>
                  {t(
                    'project.create.chooseTemplateModal.previewTemplate.nav.methodology',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {template.stakeholderGroups.length > 0 && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem ===
                    ProjectTemplatePreviewNavItem.StakeholderMapping
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(
                    ProjectTemplatePreviewNavItem.StakeholderMapping,
                  );
                  setSelectedSurvey(null);
                }}
              >
                <div>
                  {t(
                    'translation:project.create.chooseTemplateModal.previewTemplate.nav.stakeholderMapping',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
          </div>
        </div>
        {surveys.length > 0 && (
          <div className="p-8 space-y-4">
            <h3 className="flex items-center gap-2">
              <div>
                {t(
                  'translation:project.create.chooseTemplateModal.previewTemplate.nav.surveys',
                )}
              </div>
              <div className="tag purple font-bond">{surveys.length}</div>
            </h3>
            <ul className="space-y-2">
              {surveys.map((survey) => (
                <SurveyItem
                  survey={survey}
                  key={survey.id}
                  selectedSurvey={selectedSurvey}
                  setSelectedSurvey={() => selectSurvey(survey)}
                />
              ))}
            </ul>
          </div>
        )}
        {maturityEvaluations.length > 0 && (
          <div className="p-8 space-y-4">
            <h3 className="flex items-center gap-2">
              <div>
                {t(
                  'translation:project.create.chooseTemplateModal.previewTemplate.nav.maturityEvaluation',
                )}
              </div>
              <div className="tag purple font-bond">
                {maturityEvaluations.length}
              </div>
            </h3>
            <ul className="space-y-2">
              {maturityEvaluations.map((survey) => (
                <SurveyItem
                  survey={survey}
                  key={survey.id}
                  selectedSurvey={selectedSurvey}
                  setSelectedSurvey={() => selectSurvey(survey)}
                />
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="w-9/12 shadow-inner overflow-y-scroll">
        {selectedNavItem === ProjectTemplatePreviewNavItem.Referential &&
          template.referential && (
            <div className="p-8 w-full">
              <Referential
                referentialId={template.referential.id}
                readonly={true}
              />
            </div>
          )}
        {selectedNavItem === ProjectTemplatePreviewNavItem.Methodology &&
          template.checklist && (
            <div className="p-8 space-y-8">
              <h3>
                {t(
                  'project.create.chooseTemplateModal.previewTemplate.methodology.title',
                )}
              </h3>
              <ChecklistPreview checklistId={template.checklist.id} />
            </div>
          )}
        {selectedSurvey && <SurveyPreview survey={selectedSurvey} />}

        {selectedNavItem === ProjectTemplatePreviewNavItem.StakeholderMapping &&
          template.stakeholderGroups && (
            <StakeholderList
              companyId={template.id}
              readonly
              gridClassname={'overflow-auto'}
            />
          )}
      </div>
    </div>
  );
};

const SurveyItem = ({
  survey,
  selectedSurvey,
  setSelectedSurvey,
}: {
  survey:
    | PreviewCompanyTemplate_SurveyFragment
    | PreviewCompanyTemplate_MaturityEvaluationFragment;
  selectedSurvey:
    | PreviewCompanyTemplate_SurveyFragment
    | PreviewCompanyTemplate_MaturityEvaluationFragment
    | null;
  setSelectedSurvey: (
    selectedSurvey:
      | PreviewCompanyTemplate_SurveyFragment
      | PreviewCompanyTemplate_MaturityEvaluationFragment
      | null,
  ) => void;
}) => {
  return (
    <li
      className={clsx(
        'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
        selectedSurvey?.id === survey.id
          ? 'bg-gray-900 text-white border-gray-900'
          : 'bg-white border-gray-100',
      )}
      onClick={() => setSelectedSurvey(survey)}
    >
      <div>{survey.name}</div>
      <ChevronRightIcon />
    </li>
  );
};

function SurveyPreview({
  survey,
}: {
  survey:
    | PreviewCompanyTemplate_SurveyFragment
    | PreviewCompanyTemplate_MaturityEvaluationFragment;
}) {
  const questions = (survey.questions || []).toSorted(
    (a, b) => a.position - b.position,
  );
  return (
    <div className="p-8">
      <h3 className="">{survey.name}</h3>
      <SurveyQuestions questions={questions} />
    </div>
  );
}

function SurveyQuestions({
  questions,
}: {
  questions: PreviewCompanyTemplate_QuestionsFragment[];
}) {
  return (
    <div className="space-y-2 mt-4">
      {questions
        .toSorted((a, b) => a.position - b.position)
        .map((question, index) =>
          question.type === SurveyQuestionType.Group ? (
            <div key={question.id}>
              <div className="flex items-center gap-2 text-sm text-gray-500 mt-6 mb-2">
                <div className="font-semibold grow">
                  {question.title.substring(0, 100)}
                  {question.title.length > 100 && '...'}
                </div>
              </div>
              {question.children && (
                <div className="ml-4 space-y-2">
                  {(question.children || [])
                    .toSorted((a, b) => a.position - b.position)
                    .map((child) => (
                      <QuestionItem
                        question={child}
                        index={index}
                        key={question.id}
                      />
                    ))}
                </div>
              )}
            </div>
          ) : (
            <QuestionItem key={question.id} question={question} index={index} />
          ),
        )}
    </div>
  );
}

function QuestionItem({
  question,
  index,
}: {
  question: PreviewCompanyTemplate_QuestionFragment;
  index: number;
}) {
  return (
    <div className="bg-white border-2 border-gray-100 rounded-xl p-4 flex items-center text-gray-500 gap-3 text-sm shadow-sm">
      <div className="font-semibold">{index + 1}</div>
      <SurveyQuestionIcon questionType={question.type} />
      <div className="font-semibold grow">{question.title}</div>
    </div>
  );
}
