import { DeleteIcon } from '../icons';
import React from 'react';
import { convertFileToBase64 } from '../../services/UtilsService';
import { useToast } from '../layout/Toast';

export function LogoUploadForm({
  logo,
  setLogo,
}: {
  logo: string | null | undefined;
  setLogo: (logo: string | null | undefined) => void;
}) {
  const toast = useToast();

  const convertLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        toast.openToastWithError("Le format du fichier n'est pas supporté");
        return;
      }
      if (file.size > 1000000) {
        toast.openToastWithError('Le fichier ne doit pas dépasser 1 Mo');
        return;
      }
      convertFileToBase64(file)
        .then((base64) => {
          setLogo(base64 as string);
        })
        .catch((error) => {
          setLogo(null);
        });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <input
        className="hidden"
        type="file"
        id="logo"
        name="logo"
        accept="image/jpeg, image/png"
        onChange={(event) => convertLogo(event)}
      />
      <div className="flex items-center gap-2">
        {logo && (
          <button
            className="unstyled small text-gray-500"
            onClick={() => {
              setLogo(null);
            }}
          >
            <DeleteIcon />
          </button>
        )}
        <label
          htmlFor="logo"
          className="button purple secondary small inline-block"
        >
          Choisir un fichier
        </label>
      </div>
      {logo ? (
        <p className="text-gray-300 text-sm">
          Choisissez une image au format PNG ou JPEG de moins d'1 Mo
        </p>
      ) : (
        <p className="text-gray-300 text-sm">
          Aucun fichier sélectionné.
          <br />
          Choisissez une image au format PNG ou JPEG de moins d'1 Mo
        </p>
      )}
    </div>
  );
}
