import { useTranslation } from '@hooks/useTranslation';
import { getTooltipUniqueId } from '../../../services/UtilsService';
import { InfoIcon } from '../../icons';
import { Tooltip } from 'react-tooltip';
import React from 'react';

export const UnknownRespondentIcon = ({
  respondentName,
}: {
  respondentName: string;
}) => {
  const { t } = useTranslation();
  const nameToDisplay = respondentName || t('survey.respondents.unknown');
  const id = getTooltipUniqueId('UnknownRecipientIcon');
  return (
    <div data-tooltip-id={id}>
      <InfoIcon className="w-5 h-5 text-blue-500" />
      <Tooltip id={id} className="tooltip">
        {t('survey.respondents.unknownRecipientTooltip.content', {
          nameToDisplay,
        })}
        <br />
        {t('survey.respondents.unknownRecipientTooltip.question')}
      </Tooltip>
    </div>
  );
};
