import { MaturityEvaluation_MaturityEvaluationFragment } from '../../../graphql/generated';
import React, { useEffect, useState } from 'react';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../nav/Tabs';
import { MaturityEvaluationSettings } from './MaturityEvaluationSettings';
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { EditSurvey } from '../survey/editSurvey/EditSurvey';
import { EyeIcon, XIcon } from '../../icons';
import { useNavContext } from '../../../providers/NavContextProvider';
import { AppRoutes } from '../../../screens/AppRoutes';

const enum SURVEY_TABS {
  SURVEY_CONTENT = 'SURVEY_CONTENT',
  SETTINGS = 'SETTINGS',
}

export function MaturityEvaluation({
  survey,
}: {
  survey: MaturityEvaluation_MaturityEvaluationFragment;
}) {
  const navcontext = useNavContext();
  const navigate = useNavigate();

  useEffect(() => {
    navcontext?.setIsNavCollapsed(true);
    /* eslint-disable-next-line */
  }, []);

  const { tab } = useParams();
  const tabs: TabItem[] = [
    { key: SURVEY_TABS.SURVEY_CONTENT, name: 'Contenu' },
    { key: SURVEY_TABS.SETTINGS, name: 'Paramètres' },
  ];
  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.key === tab) || tabs[0],
  );
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="px-8 py-4 border-b border-gray-100 shadow-sm flex items-center justify-between">
        <div className="flex items-center gap-1 w-1/3">
          {navcontext?.isNavCollapsed && (
            <button
              className="tertiary"
              onClick={() => {
                navigate(AppRoutes.MaturityEvaluations);
                navcontext?.setIsNavCollapsed(false);
              }}
            >
              <XIcon />
            </button>
          )}
          <div className="flex gap-1 title-h5 items-center flex-wrap">
            <span>{survey.name}</span>
          </div>
        </div>
        <div className="w-1/3 flex justify-center">
          <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
        </div>
        <div className="w-1/3 flex justify-end">
          <NavLink to={generatePath(AppRoutes.PublicationMaturity)}>
            <button className="secondary small purple">
              <EyeIcon />
              <div>Voir les scores</div>
            </button>
          </NavLink>
        </div>
      </div>
      <div className="grow overflow-scroll flex">
        <SurveyInnerTab currentTab={currentTab} survey={survey} />
      </div>
    </div>
  );
}

function SurveyInnerTab({
  currentTab,
  survey,
}: {
  currentTab: TabItem;
  survey: MaturityEvaluation_MaturityEvaluationFragment;
}) {
  switch (currentTab.key) {
    case SURVEY_TABS.SURVEY_CONTENT:
      return <EditSurvey survey={survey} isPreview={false} />;
    case SURVEY_TABS.SETTINGS:
      return (
        <div className="w-full">
          <MaturityEvaluationSettings surveyId={survey.id} />
        </div>
      );
    default:
      return null;
  }
}
