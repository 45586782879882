import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { EvaluationsList } from '../../../components/project/maturity/MaturityEvaluationList/EvaluationsList';
import { useTranslation } from '@hooks/useTranslation';
import { useMaturityEvaluationsListScreenQuery } from '../../../graphql/generated';
import { withProjectContext } from '../../../providers/withProjectContext';

export const MaturityEvaluationsListScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();

    const { data, loading } = useMaturityEvaluationsListScreenQuery({
      variables: {
        companyId: projectContext?.enterprise?.id || '',
      },
      skip: !projectContext?.enterprise?.id,
      fetchPolicy: 'network-only',
    });

    const maturityEvaluations = data?.maturityEvaluations || [];

    return (
      <>
        <PageTitle title={t('maturity.evaluation.title')} />
        <Breadcrumb />
        <div className="main-content w-full py-8 space-y-8">
          <EvaluationsList
            maturityEvaluations={maturityEvaluations}
            loading={loading}
          />
        </div>
      </>
    );
  },
  { requiredCompany: true },
);
