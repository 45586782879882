import {
  AssociateEmployeesToEnterpriseList_EnterpriseFragment,
  useAssociateEmployeesToEnterpriseList_EmployeesQuery,
  useContributionAddMutation,
  useContributionRemoveMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import React, { useState } from 'react';
import { Checkbox, CheckboxColors } from '../../../form/Checkbox';
import { UserProfileInitials } from '../../../user/UserProfileInitials';
import { useTranslation } from '@hooks/useTranslation';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import { ChevronRightIcon, SearchIcon } from '../../../icons';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { useRightDrawer } from '../../../layout/RightDrawer';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../layout/Loader';

export function AssociateEmployeesToEnterpriseList({
  enterprise,
}: {
  enterprise: AssociateEmployeesToEnterpriseList_EnterpriseFragment;
}) {
  const { t } = useTranslation();
  const company = useProjectContext();
  const toast = useToast();
  const [contributionAddMutation] = useContributionAddMutation();
  const [contributionRemoveMutation] = useContributionRemoveMutation();
  const navigate = useNavigate();
  const { closeDrawer } = useRightDrawer();

  const [searchTerm, setSearch] = useState('');

  const employeesQuery = useAssociateEmployeesToEnterpriseList_EmployeesQuery({
    variables: {
      enterpriseId: company?.enterprise?.id || '',
    },
    skip: !company?.enterprise?.id,
  });

  function addContributor(enterpriseId: string, userId: string) {
    function preloadResponse(userId: string) {
      const contribution = {
        id: 'temporary-id',
        user: {
          id: userId,
          firstName: '',
          lastName: '',
        },
      };
      const clone = contributors.filter(() => true);
      clone.push(contribution);
      setContributors(clone);
    }

    function resetAddPreloadResponse() {
      setContributors(
        contributors.filter(
          (contribution) => contribution.id !== 'temporary-id',
        ),
      );
    }

    preloadResponse(userId);
    contributionAddMutation({
      variables: {
        companyId: enterpriseId,
        userId: userId,
      },
    })
      .then((resp) => {
        setContributors(resp.data?.contributionAdd.contributors ?? []);
        toast.openToastWithMessage(t('toast:contributors.add.success'));
      })
      .catch((e) => {
        resetAddPreloadResponse();
        toast.openToastWithMessage(e.message);
      });
  }

  const employees =
    employeesQuery.data?.enterprise?.employees
      ?.filter((employee) => !employee.auth?.disabled)
      .toSorted((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      }) || [];
  const [contributors, setContributors] = useState(
    enterprise.contributors ?? [],
  );

  function removeContributor(userId: string) {
    const contribution = contributors?.find((c) => c.user?.id === userId);

    function preloadResponse(contributionId: string) {
      setContributors(
        contributors.filter(
          (contributors) => contributors.id !== contributionId,
        ),
      );
    }

    function resetRemovePreloadResponse(contribution: any) {
      const clone = contributors.filter(() => true);
      clone.push(contribution);
      setContributors(clone);
    }

    if (contribution) {
      preloadResponse(contribution.id);
      contributionRemoveMutation({
        variables: {
          contributionId: contribution.id,
          companyId: enterprise.id,
        },
      })
        .then((resp) => {
          setContributors(resp.data?.contributionRemove.contributors ?? []);
          toast.openToastWithMessage(t('toast:contributors.remove.success'));
        })
        .catch((e) => {
          resetRemovePreloadResponse(contribution);
          toast.openToastWithMessage(t('toast:contributors.remove.error'));
        });
    }
  }

  const filteredEmployees = employees.filter(
    (employee) =>
      employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.auth?.email?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const helpMessage = (
    <div className={'px-2'}>
      <IllustratedExplanationMessage
        title={t(
          'company.organizationalUnit.associateCollaborator.help.block.title',
        )}
        description={t(
          'company.organizationalUnit.associateCollaborator.help.block.description',
        )}
      >
        <button
          className="tertiary bg-transparent"
          onClick={() => {
            navigate(AppRoutes.ProjectUsers);
            closeDrawer();
          }}
        >
          <ChevronRightIcon />
        </button>
      </IllustratedExplanationMessage>
    </div>
  );

  if (employeesQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-5 p-4">
      {employees.length > 0 ? (
        <>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder={t(
                'company.organizationalUnit.associateCollaborator.filter.placeholder',
              )}
              value={searchTerm}
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-700"
            />
          </div>
          <div className={'space-y-2'}>
            {(filteredEmployees.length > 0 &&
              filteredEmployees.map((user) => {
                const isContributor = !!contributors.find(
                  (item) => item.user.id === user.id,
                );
                let action = () =>
                  isContributor
                    ? removeContributor(user.id)
                    : addContributor(enterprise.id, user.id);

                return (
                  <div
                    key={`employee-${user.id}`}
                    className="ml-2 flex gap-6 items-center"
                  >
                    <Checkbox
                      color={CheckboxColors.Gray}
                      onClick={action}
                      checked={isContributor}
                    />
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={action}
                    >
                      <UserProfileInitials user={user} size={'mini'} />
                      <div className="space-y-0">
                        <div className="capitalize font-semibold">
                          {user.firstName} {user.lastName}
                        </div>
                        <div className="text-gray-500 text-xs">
                          {user.auth?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })) || (
              <div className="text-center text-gray-500">
                {t(
                  'company.organizationalUnit.associateCollaborator.filter.emptyState',
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <MessageBox type={MessageBoxType.Info}>
            {t('toast:contributors.placeholder')}
          </MessageBox>
        </>
      )}
      {helpMessage}
    </div>
  );
}
