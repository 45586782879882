import { RespondentsHeader_SurveyFragment } from '../../../graphql/generated';
import React from 'react';
import { ProgressBar } from '../../generic/ProgressBar';
import { useTranslation } from '@hooks/useTranslation';

export function RespondentsHeader({
  survey,
}: {
  survey: RespondentsHeader_SurveyFragment;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-2 justify-between">
      <div className="flex items-center gap-2">
        <h2 className="title-h3 flex items-center gap-2 shrink-0">
          {t('survey.respondents.title')}
        </h2>
      </div>

      {(survey.recipients?.length ?? 0) > 0 && (
        <div className="flex items-center gap-2">
          <div className="flex items-center ml-2 w-96">
            <ProgressBar
              value={survey.responses?.length || 0}
              total={survey.recipients?.length || 0}
              targetThreshold={survey.targetThreshold || 0}
              displayLabel={false}
              displayPercentage={true}
              displayCounts={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
