import {
  AddCatalystButtonFragment,
  useCatalystCreateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import { UpdateCatalystModal } from './UpdateCatalystModal';
import { PlusIcon } from '../../icons';

export function AddCatalystButton({
  roadmap,
}: {
  roadmap: AddCatalystButtonFragment;
}) {
  const modal = useModal();
  const [addCatalyst] = useCatalystCreateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addCatalyst({
      variables: {
        input: {
          roadmapId: roadmap.id,
        },
      },
    })
      .then((res) => {
        if (res.data?.catalystCreate) {
          // Get old catalyst ids in roadmap
          const oldCatalystids = roadmap.catalysts.map((c) => c.id);

          // Get new catalyst ids in roadmap
          const newCatalystids =
            res.data?.catalystCreate.catalysts.map((c) => c.id) || [];

          // Get new catalyst id
          const newCatalystId = newCatalystids.find(
            (id) => !oldCatalystids.includes(id),
          );

          if (newCatalystId) {
            // Get catalyst
            const newCatalyst = res.data?.catalystCreate.catalysts.find(
              (c) => c.id === newCatalystId,
            );

            if (newCatalyst) {
              // Open modal to edit new catalyst
              modal.openModalWithComponent(
                <UpdateCatalystModal catalyst={newCatalyst} />,
                null,
                true,
                false,
                'w-1/2',
              );
            }
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  return (
    <button className="secondary purple" onClick={add} disabled={isAdding}>
      {isAdding ? <Loader /> : <PlusIcon />}
      <span>Ajouter un axe</span>
    </button>
  );
}
