import React from 'react';
import { ActionIllustrationIcon_ActionFragment } from '../../../graphql/generated';
import { StarIcon } from '../../icons';

export function ActionIllustrationIcon({
  action,
}: {
  action: ActionIllustrationIcon_ActionFragment;
}) {
  return (
    <div
      className="relative w-12 h-12 border rounded-lg bg-cover bg-center shrink-0 flex items-center justify-center"
      style={{
        backgroundImage: action.illustration
          ? `url(${action.illustration})`
          : `url(/actions/riccardo-annandale-7e2pe9wjL9M-unsplash.jpg)`,
      }}
    >
      {action.isTemplate && (
        <div className="absolute -top-2 -right-2 p-0.5 h-6 w-6 border-2 border-white rounded-full flex items-center justify-center bg-green-500 text-white">
          <StarIcon className="w-3 h-3" />
        </div>
      )}
    </div>
  );
}
