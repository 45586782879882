import { NavLink, useParams } from 'react-router-dom';
import { Action } from '../../../../components/publications/roadmap/Action';
import {
  ActionFieldsFragment,
  useActionQuery,
} from '../../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { UpdateActionButton } from '../../../../components/project/roadmap/UpdateActionButton';
import { ChevronRightIcon } from '../../../../components/icons';
import { AppRoutes } from '../../../AppRoutes';
import { withProjectContext } from '../../../../providers/withProjectContext';

function ActionScreenInner() {
  const { actionId } = useParams();

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.action) {
    return (
      <>
        <ScreenTopBar action={data.action} />
        <Action action={data.action} />
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>Une erreur est survenue lors de la récupération de l'action.</p>
      </MessageBox>
    );
  }
}

function ScreenTopBar({ action }: { action: ActionFieldsFragment }) {
  return (
    <div className="sticky top-0 bg-white py-4 px-8 flex items-center gap-4 justify-between">
      <h1 className="title-h5 flex items-center gap-1">
        <NavLink to={AppRoutes.Roadmap}>
          <span>Feuille de route</span>
        </NavLink>
        <span>
          <ChevronRightIcon className="shrink-0" />
        </span>
        <span>{action.title}</span>
      </h1>
      <UpdateActionButton action={action} displayLabel={true} />
    </div>
  );
}

export const ActionScreen = withProjectContext(ActionScreenInner, {
  requiredCompany: true,
});
