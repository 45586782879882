import { SurveyTarget } from '../../../graphql/generated';
import surveyCollaborators from '../../../assets/images/illustrations/survey/survey-collaborators.png';
import surveyExternal from '../../../assets/images/illustrations/survey/survey-external.png';
import surveyCeo from '../../../assets/images/illustrations/survey/survey-ceo.png';
import React from 'react';

export function SurveyTargetIcon({ type }: { type: SurveyTarget }) {
  switch (type) {
    case SurveyTarget.Collaborators:
      return <img src={surveyCollaborators} alt="" className="w-12" />;
    case SurveyTarget.External:
      return <img src={surveyExternal} alt="" className="w-12" />;
    case SurveyTarget.Ceo:
      return <img src={surveyCeo} alt="" className="w-12" />;
  }
}
