import headerIllustration from '../../../assets/images/illustrations/diagnostic/diag-header.png';
import React from 'react';

export function PrioritizationMatrixHeader({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="bg-blue-50 px-8 relative">
      <div className="flex items-center gap-16 main-content">
        <div className="flex flex-col gap-4 w-7/12">
          <h1>Matrice de priorisation</h1>
          <p>
            Cette matrice permet de confronter l'importance de chaque enjeu à
            leur maturité évaluée. En mettant en avant les enjeux prioritaires
            mais non matures, cette matrice permet d'identifier les actions à
            mettre en place en priorité dans votre feuille de route.
          </p>
        </div>
        <div className="w-5/12 relative">
          <img src={headerIllustration} alt="" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
