import { useState, useEffect } from 'react';
import { FeatureNames, useIsFeatureEnabledQuery } from '../graphql/generated';

export function useFeatureEnabled(featureName: FeatureNames) {
  const [isEnabled, setIsEnabled] = useState(false);

  const { data, loading, error } = useIsFeatureEnabledQuery({
    variables: { name: featureName },
  });

  useEffect(() => {
    if (!loading && !error) {
      setIsEnabled(data?.isFeatureEnabled ?? false);
    }
  }, [data, loading, error]);

  return isEnabled;
}
