import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { ProjectHeader } from './ProjectHeader';
import { PROJECT_HOME_TABS, useProjectTabs } from './useProjectTabs';
import { TabNavigationLayout } from '../../../components/generic/nav/TabNavigationLayout';
import { ProjectSettingsContent } from '../../../components/project/project/settings/ProjectSettingsContent';

export const ProjectSettingsScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <TabNavigationLayout<PROJECT_HOME_TABS>
        tabs={useProjectTabs()}
        activeTab={PROJECT_HOME_TABS.SETTINGS}
        renderTabs={(tabsContent) => (
          <>
            <div className="py-12 px-16">
              <ProjectHeader />
            </div>
            {tabsContent}
          </>
        )}
      >
        <PageTitle title={t('screen.project.settings.meta.title')} />
        <div className="w-full">
          <ProjectSettingsContent />
        </div>
      </TabNavigationLayout>
    </>
  );
};

