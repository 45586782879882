import {
  SurveyPreviewFragment,
  useCreateFromPreviewSurveyTemplateMutation,
} from '../../../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../layout/Modal';
import { useToast } from '../../../layout/Toast';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { generatePath, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { ChevronRightIcon } from '../../../icons';
import { Loader } from '../../../generic/Loader';
import { SurveyPreview } from './SurveyPreview';
import { AppRoutes } from '../../../../screens/AppRoutes';

export function PreviewSurveyTemplate({
  surveyTemplate,
  setPreviewSurveyTemplate,
}: {
  surveyTemplate: SurveyPreviewFragment;
  setPreviewSurveyTemplate: (
    surveyTemplate: SurveyPreviewFragment | null,
  ) => void;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const toast = useToast();
  const projectContext = useProjectContext();
  const navigate = useNavigate();

  const [surveyCreateFromTemplateMutation] =
    useCreateFromPreviewSurveyTemplateMutation();

  const [defaultSegmentId, setDefaultSegmentId] = useState<string | null>(
    surveyTemplate.defaultSegment?.id ?? null,
  );
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    if (defaultSegmentId) {
      setIsAdding(true);
      surveyCreateFromTemplateMutation({
        variables: {
          input: {
            surveyId: surveyTemplate.id,
            companyId: projectContext?.enterprise?.id || '',
            defaultSegment: { id: defaultSegmentId },
          },
        },
      })
        .then((result) => {
          toast.openToastWithMessage(
            t('survey.templateModal.consultationAdded'),
          );
          modal.closeModal();
          projectContext?.refreshEnterprise();
          // Goto the survey page
          navigate(
            generatePath(AppRoutes.SurveyItem, {
              surveyId: result.data?.surveyCreateFromTemplate.id!,
            }),
          );
        })
        .catch((error) => {
          toast.openToastWithError(error.message);
        })
        .finally(() => {
          setIsAdding(false);
        });
    } else {
      toast.openToastWithError(t('survey.templateModal.selectSegment'));
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => setPreviewSurveyTemplate(null)}
        >
          <ChevronRightIcon className="w-4 h-4 transform rotate-180" />
          <span>{t('survey.templateModal.backToModels')}</span>
        </button>
        <h4 className="text-center grow">
          {t('survey.templateModal.consultationAdd')}
        </h4>
        <button
          className="primary purple"
          onClick={add}
          disabled={isAdding || !defaultSegmentId}
        >
          {isAdding && <Loader />}
          <span>{t('survey.templateModal.useTemplate')}</span>
        </button>
      </div>
      <SurveyPreview
        survey={surveyTemplate}
        defaultSegmentId={defaultSegmentId}
        setDefaultSegmentId={setDefaultSegmentId}
      />
    </div>
  );
}
