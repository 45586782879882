import React from 'react';
import clsx from 'clsx';
import { ThemeColor } from '../../graphql/generated';
import { mapThemeColorToTailwindClass } from '../../services/TailwindService';

export function ListItemRank({
  rank,
  size,
  color,
}: {
  rank: number;
  size: 12 | 10 | 8 | 7 | 6 | 5 | 4;
  color: ThemeColor;
}) {
  return (
    <div
      className={clsx(
        'w-'.concat(size.toString()),
        'h-'.concat(size.toString()),
        'flex items-center justify-center shrink-0',
      )}
    >
      <div
        className={clsx(
          'w-'.concat((size > 6 ? size - 2 : size - 1).toString()),
          'h-'.concat((size > 6 ? size - 2 : size - 1).toString()),
          size > 8 ? 'text-base' : 'text-xs',
          'rounded-full flex items-center justify-center font-bold shrink-0 text-white',
          mapThemeColorToTailwindClass(color, 500, 'bg'),
        )}
      >
        {rank}
      </div>
    </div>
  );
}
