import { ProIroScreenDocument } from '../../../../../graphql/generated';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';

export const useIroPickerRefetchQueries = () => {
  const projectContext = useProjectContext();
  return [
    {
      query: ProIroScreenDocument,
      variables: {
        id: projectContext?.enterprise?.referential?.id || '',
      },
    },
  ];
};
