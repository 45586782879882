import i18n, { InitOptions } from 'i18next';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const backendOptions: InitOptions<HttpBackendOptions> = {
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  react: {
    useSuspense: false,
  },
  ns: ['translation', 'toast', 'global', 'nav', 'admin', 'enum'],
  defaultNS: 'translation',
  debug: false,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
};

const createI18nInstance = () => {
  const instance = i18n.createInstance();
  instance
    .use(HttpApi)
    .use(initReactI18next)
    .init<HttpBackendOptions>(backendOptions)
    .catch((e) => console.error(e));

  return instance;
};

const mainI18n = createI18nInstance();

export default mainI18n;
export { createI18nInstance };
