import React, { useEffect, useRef, useState } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import { useTranslation } from '@hooks/useTranslation';
import useAutoresizeTextarea from '../../../hooks/useAutoresizeTextarea';

export function QuestionText({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t } = useTranslation();
  const [text, setText] = useState<string>(answer?.text || '');

  const updateAnswer = (value: string) => {
    if (value !== '') {
      setIsAnswerValid(true);
      setAnswer({
        question: {
          id: question.id,
        },
        type: SurveyAnswerType.Text,
        text: value,
      });
    } else {
      setAnswer(null);
      setIsAnswerValid(false);
    }
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(text !== '');
  }, [text, setIsAnswerValid]);

  // Make textarea resize automatically using scrollHeight
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutoresizeTextarea(textAreaRef.current, text);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target?.value;
    setText(value);
    updateAnswer(value);
  };

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <textarea
          ref={textAreaRef}
          className="form-input-text-survey w-full max-w-5xl min-h-[5rem]"
          placeholder={t('survey.question.text.placeholder')}
          value={text}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
