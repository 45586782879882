import React from 'react';
import { useModal } from '../../../layout/Modal';
import StakeDropdown from '../../../form/StakeDropdown';
import {
  openStakeDetailModal,
  STAKE_DETAILS_TABS,
} from '../../../stake/detail/StakeDetailModal';
import { StakeMaterialityScore } from './scores/StakeMaterialityScore';
import {
  IroEvaluationHeader_PillarFragment,
  IroEvaluationHeader_StakeFragment,
} from '../../../../graphql/generated';
import { EyeIcon } from '../../../icons';
import PillarDropdown from '../../../form/PillarDropdown';
import { getThemeLightColor } from '../../../../services/ReferentialService';
import clsx from 'clsx';

export function IroEvaluationHeader({
  stake,
  setStakeId,
  pillar,
  setPillarId,
}: {
  stake: IroEvaluationHeader_StakeFragment | null;
  setStakeId: (id: string | null) => void;
  pillar: IroEvaluationHeader_PillarFragment | null;
  setPillarId: (id: string | null) => void;
}) {
  const modal = useModal();
  const bgColor = getThemeLightColor(pillar?.color);

  return (
    <div
      className={clsx(
        'bg-green-50 px-8 py-8 space-y-4 border-b border-gray-100 shadow-inner',
        bgColor,
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-start gap-4 flex-col 2xl:flex-row 2xl:items-center">
          <PillarDropdown
            currentPillarId={pillar?.id}
            setPillarId={setPillarId}
          />
          <div className="flex items-center gap-1">
            <StakeDropdown
              currentStakeId={stake?.id}
              setCurrentStakeId={setStakeId}
              pillarId={pillar?.id}
              isRequired={false}
            />
            {stake && (
              <button
                className="tertiary bg-transparent"
                onClick={() =>
                  openStakeDetailModal(
                    modal,
                    stake,
                    STAKE_DETAILS_TABS.STAKE_MATERIALITY,
                  )
                }
              >
                <EyeIcon />
              </button>
            )}
          </div>
        </div>
        {stake && <StakeMaterialityScore stake={stake} />}
      </div>
    </div>
  );
}
