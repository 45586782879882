import {
  StakeholderQuestions_QuestionFragment,
  StakeholderQuestions_StakeFragment,
  StakeholderQuestions_SurveyFragment,
  SurveyQuestionType,
  useStakeSurveysDetail_SurveyQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import React from 'react';
import { ConsolidatedMaterialityArrays } from './ConsolidatedMaterialityArrays';
import { SurveyQuestion } from './SurveyQuestion';

export function StakeholderQuestions({
  stake,
}: {
  stake: StakeholderQuestions_StakeFragment;
}) {
  // Fetch all stakeholder surveys
  const projectContext = useProjectContext();
  const surveysQuery = useStakeSurveysDetail_SurveyQuery({
    variables: {
      companyId: projectContext?.enterprise?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });

  if (surveysQuery.loading) {
    return <LoaderFullscreen />;
  }

  const surveys: StakeholderQuestions_SurveyFragment[] =
    surveysQuery.data?.surveys || [];

  // Get Stake questions: get only questions that are not MaturityEvaluation and that are linked to this stake (or stake's pillar)
  const questions = surveys
    .flatMap((survey) => survey.questions || [])
    .flatMap((question) =>
      question.type === SurveyQuestionType.Group
        ? (question.children as StakeholderQuestions_QuestionFragment[])
        : ([question] as StakeholderQuestions_QuestionFragment[]),
    )
    .filter(
      (question) =>
        ![SurveyQuestionType.MaturityEvaluation].includes(question?.type),
    )
    .filter(
      (question) =>
        question.stake?.id === stake.id ||
        question.pillar?.id === stake.pillar.id,
    );
  if (questions.length === 0) {
    return (
      <MessageBox type={MessageBoxType.Info}>
        Aucune question liée à cet enjeu
      </MessageBox>
    );
  }

  return (
    <div className="space-y-4">
      <ConsolidatedMaterialityArrays stake={stake} surveys={surveys} />
      {questions.map((question) => (
        <SurveyQuestion
          key={question.id}
          question={question}
          stakeId={stake.id}
        />
      ))}
    </div>
  );
}
