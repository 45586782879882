import React, { Fragment, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon, DeleteKeyIcon, XIcon } from '../../icons';
import { Float } from '@headlessui-float/react';
import { useTranslation } from '@hooks/useTranslation';

export type StringDropdownItem = {
  id: string | null;
  label: string;
};

export default function StringDropdown<T extends StringDropdownItem>({
                                                                       availableItems,
                                                                       item,
                                                                       setItem,
                                                                       extraPositionClassName,
                                                                       allowEmpty = false,
                                                                       filterable = false,
                                                                     }: {
  availableItems: T[];
  item: T | null;
  setItem: (item: T | null) => void;
  extraPositionClassName?: string;
  allowEmpty?: boolean;
  filterable?: boolean;
}) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  const chooseItem = (item: T) => {
    setItem(item);
  };

  const resetItem = () => {
    setItem(null);
  };

  const filteredItems = filterable
    ? availableItems.filter((availableItem) =>
      availableItem.label.toLowerCase().includes(filter.toLowerCase())
    )
    : availableItems;

  return (
    <Listbox value={item} onChange={chooseItem}>
      {({ open }) => (
        <Float portal adaptiveWidth>
          <div className="relative">
            <ListboxButton className="form-input-select">
              <span className="flex items-center w-full">
                {item?.label || '-'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            {allowEmpty && item && (
              <XIcon onClick={resetItem}
                     className="h-10 w-6 absolute inset-y-0 right-8 flex items-center pr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                     aria-hidden="true"  />
            )}
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={clsx(
              'absolute z-10 mt-1 w-full min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg',
              'ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm max-h-60 py-1',
              extraPositionClassName,
            )}
            >
              <ListboxOptions static>
                {filterable && (
                  <div className="relative">
                    <div className="mt-1 block w-full items-center">
                      <input
                        type="text"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder={t('components.generic.dropdown.filter.placeholder')}
                        className="w-full border-0 bg-white m-[2px] text-sm pt-1.5 pl-2.5 h-8 focus:outline-0 focus:shadow-none focus:ring-0"
                        autoFocus
                      />
                      {filter && (
                        <div className="absolute inset-y-0 right-0 flex items-center pt-1.5 pr-2 text-gray-400 hover:text-gray-600 cursor-pointer">
                          <DeleteKeyIcon onClick={() => setFilter('')}/>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {filteredItems.map((item) => (
                  <ListboxOption
                    key={item.id}
                    className={clsx(
                      'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                      'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                    )}
                    value={item}
                  >
                    {({ selected }) => (
                      <div className="flex items-center gap-1">
                        <div
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-1 shrink-0',
                          )}
                        >
                          {item.label}
                        </div>

                        {selected ? (
                          <div
                            className={clsx(
                              'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5 shrink-0"
                              aria-hidden="true"
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Transition>
        </Float>
      )}
    </Listbox>
  );
}

export const enumToDropdownItems = (
  enumObject: object,
  labelFunction: (key: string) => string,
) => {
  return Object.keys(enumObject).map((key) => ({
    id: key,
    label: labelFunction(key),
  }));
};
