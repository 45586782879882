import React from 'react';
import { SurveyQuestionSummaryFieldsFragment } from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';

export function QuestionStatement({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
      </div>
    </div>
  );
}
