import React, { ComponentType } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

interface RouterProps {
  params: {
    [key: string]: string | undefined;
  };
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
}

export function withRouter<T>(Component: ComponentType<RouterProps & T>) {
  return (props: T) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <Component
        {...props}
        params={params}
        location={location}
        navigate={navigate}
      />
    );
  };
}
