import React from 'react';
import { useRightDrawer } from '../../layout/RightDrawer';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { CatalystDrawer } from './CatalystDrawer';
import { InfoIcon } from '../../icons';
import { PublicationRoadmapCatalystTitleFragment } from '../../../graphql/generated';

export function CatalystTitle({
  catalyst,
  index,
}: {
  catalyst: PublicationRoadmapCatalystTitleFragment;
  index: number;
}) {
  const { openDrawerWithComponent } = useRightDrawer();
  return (
    <div className="flex flex-col">
      <p className="text-gray-500 text-sm font-semibold">
        Axe stratégique #{index + 1}
      </p>
      <div className="flex items-center mt-2 mb-4 gap-2 justify-between">
        <div className="flex items-center gap-2">
          <div className="bg-gray-900 p-2 rounded-xl">
            <ThemeIcon
              theme={catalyst.theme}
              enableTooltip={true}
              className="text-white w-5 h-5"
            />
          </div>
          <h3 className="title-h2">{catalyst.title}</h3>
        </div>
        <button
          className="tertiary small"
          onClick={() =>
            openDrawerWithComponent(<CatalystDrawer catalyst={catalyst} />)
          }
        >
          <InfoIcon />
          <span>Pourquoi</span>
        </button>
      </div>
    </div>
  );
}
