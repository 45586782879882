import {
  ManageStakeholderListDocument,
  SurveyWaitingStakeholderDocument,
  useDeleteStakeholderButton_StakeholderDeleteMutation,
} from '../../../../graphql/generated';
import React from 'react';
import { Loader } from '../../../generic/Loader';
import { XIcon } from '../../../icons';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { ITEMS_PER_PAGE_COOKIE } from '../responsesList/SurveyResponsesTable';

export function DeleteStakeholderButton({
  stakeholderId,
  surveyId,
}: {
  stakeholderId: string;
  surveyId: string;
}) {
  const { t } = useTranslation();
  const [isDeletingContact, setIsDeletingContact] = React.useState(false);
  const [deleteStakeholder] =
    useDeleteStakeholderButton_StakeholderDeleteMutation();
  const deleteContact = () => {
    setIsDeletingContact(true);
    deleteStakeholder({
      variables: { id: stakeholderId },
      refetchQueries: [
        {
          query: ManageStakeholderListDocument,
          variables: {
            id: surveyId,
          },
        },
        {
          query: SurveyWaitingStakeholderDocument,
          variables: {
            surveyId,
            paging: {
              first: parseInt(Cookies.get(ITEMS_PER_PAGE_COOKIE) || '20', 10),
            },
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeletingContact(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeletingContact}
      onClick={deleteContact}
    >
      {isDeletingContact ? <Loader /> : <XIcon />}
      <div>{t('survey.stakeholderLists.delete')}</div>
    </button>
  );
}
