import React from 'react';
import { XIcon } from '../../../../icons';
import LanguageDropdown from '../../../../form/LanguageDropdown';
import { SurveyLanguage } from '../../../../../graphql/generated';

type FormHeaderProps = {
  initialData: boolean;
  pickLanguage: (language: SurveyLanguage) => void;
  onClose: () => void;
  t: (key: string, options?: any) => string;
};

export const FormHeader: React.FC<FormHeaderProps> = ({
  initialData,
  pickLanguage,
  onClose,
  t,
}) => {
  return (
    <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
      <div className="w-12">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={onClose}
        >
          <XIcon className="w-4 h-4" />
        </button>
      </div>
      <h4 className="grow">
        {t(
          initialData
            ? 'stakeholder.group.update.modal.title'
            : 'stakeholder.group.create.modal.title',
        )}
      </h4>
      <LanguageDropdown onUpdate={pickLanguage} />
    </div>
  );
};
