import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import { ProgressBar, ProgressBarStyles } from '../../../generic/ProgressBar';
import { GapAnalysisSummary_DisclosureRequirementFragment } from '../../../../graphql/generated';
import {
  GapAnalysisSummary_Topic_TreeFragment,
  UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment,
} from '../../../../graphql/cms/generated';
import { EditIcon } from '../../../icons';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { generatePath, useNavigate } from 'react-router-dom';
import { DisclosureRequirementJustifyButton } from './DisclosureRequirementJustifyButton';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { AppRoutes } from '../../../../screens/AppRoutes';

export type GapAnalysisTopicDRTableRow = {
  name?: string;
  datapointAvailability: {
    count: number;
    total: number;
  };
  totalNumberOfDatapoints?: number;
  isDisabled: boolean;
  actions: {
    key: string;
    component: ReactNode;
  }[];
};

export const useGapAnalysisTopicDRTableColumns = (): Array<
  ColumnDef<GapAnalysisTopicDRTableRow>
> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<GapAnalysisTopicDRTableRow>();

  return [
    columnHelper.accessor('name', {
      cell: (data) => {
        const name = data.getValue();
        const isDisabled = data.row.original.isDisabled;
        return (
          (name && (
            <div
              className={`font-bold ${isDisabled ? 'text-gray-300' : 'font-bold'}`}
            >
              {name}
            </div>
          )) || (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{
                width: [
                  'w-1/2',
                  'w-7/12',
                  'w-3/4',
                  'w-9/12',
                  'w-10/12',
                  'w-11/12',
                  'w-12/12',
                ],
              }}
            />
          )
        );
      },
      header: () => t('gap_analysis.summary.table.header.name.label'),
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
    columnHelper.accessor('datapointAvailability', {
      cell: (data) => {
        const datapointAvailability = data.getValue();
        const isDisabled = data.row.original.isDisabled;
        return (
          (isDisabled && <div />) || (
            <div className={'flex items-center gap-2'}>
              <ProgressBar
                value={datapointAvailability.count}
                total={datapointAvailability.total}
                style={ProgressBarStyles.COLORED}
              />
              <div className={'font-bold'}>
                {datapointAvailability.count}/{datapointAvailability.total}
              </div>
            </div>
          )
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t('gap_analysis.summary.table.header.datapointAvailability.label')}
        </span>
      ),
    }),
    columnHelper.accessor('totalNumberOfDatapoints', {
      cell: (data) => {
        const totalNumberOfDatapoints = data.getValue();
        const isDisabled = data.row.original.isDisabled;
        return (
          (isDisabled && <div />) || (
            <div className={'font-bold text-center'}>
              {typeof totalNumberOfDatapoints == 'number' ? (
                totalNumberOfDatapoints
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <SkeletonLoader
                    size={{ height: 'h-5' }}
                    randomSizes={{
                      width: ['w-4', 'w-6', 'w-8'],
                    }}
                  />
                </div>
              )}
            </div>
          )
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t('gap_analysis.summary.table.header.totalNumberOfDatapoints.label')}
        </span>
      ),
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        const actions = data.getValue();
        return (
          <div>
            {actions.map(({ key, component }) => (
              <div key={key}>{component}</div>
            ))}
          </div>
        );
      },
      sortDescFirst: false,
      header: () => <span />,
    }),
  ] as Array<ColumnDef<GapAnalysisTopicDRTableRow>>;
};

export const prepareData = (
  disclosureRequirements: GapAnalysisSummary_DisclosureRequirementFragment[],
  cmsTopics: GapAnalysisSummary_Topic_TreeFragment[],
  navigate: ReturnType<typeof useNavigate>,
  t: TFunction,
): Array<GapAnalysisTopicDRTableRow> => {
  const data: Array<GapAnalysisTopicDRTableRow> = [];

  disclosureRequirements.forEach((dr) => {
    const cmsTopic = cmsTopics.find(
      (topic) => topic.slug === dr.reportTopic.referenceSlug,
    );
    const cmsDisclosureRequirement = cmsTopic?.disclosure_requirements.find(
      (cmsDR) => cmsDR?.slug === dr.referenceSlug,
    );
    const name = cmsDisclosureRequirement?.title
      ? `${cmsDisclosureRequirement?.code} - ${cmsDisclosureRequirement?.title}`
      : undefined;

    let availableDataPoints = 0;
    const enabledDatapoints = dr.dataPoints.filter((dp) => !dp.disabled);
    enabledDatapoints.forEach((dp) => {
      if (dp.isAvailable) {
        availableDataPoints++;
      }
    });
    const totalNumberOfDatapoints = cmsDisclosureRequirement
      ? getNumberOfDatapoints(cmsDisclosureRequirement)
      : undefined;
    let mainAction = (
      <a
        className={clsx(
          totalNumberOfDatapoints === 0
            ? 'cursor-not-allowed text-gray-100'
            : 'cursor-pointer text-gray-300 hover:text-black',
        )}
        title={
          totalNumberOfDatapoints === 0
            ? t(
                'gap_analysis.summary.table.header.actions.noDatapoints.tooltip',
              )
            : undefined
        }
        onClick={() => {
          if (totalNumberOfDatapoints === 0) {
            return;
          }
          navigate(
            generatePath(AppRoutes.GapAnalysisDisclosureRequirement, {
              referenceSlug: dr.referenceSlug,
            }),
          );
        }}
      >
        <EditIcon />
      </a>
    );
    if (dr.disabled) {
      mainAction = (
        <DisclosureRequirementJustifyButton disclosureRequirement={dr} />
      );
    }

    data.push({
      name,
      datapointAvailability: {
        count: availableDataPoints,
        total: enabledDatapoints.length,
      },
      totalNumberOfDatapoints,
      isDisabled: dr.disabled,
      actions: [
        {
          key: 'mainAction',
          component: mainAction,
        },
      ],
    });
  });

  return data;
};

export const getNumberOfDatapoints = (
  disclosureRequirement: UseGapAnalysisTopicDrTableColumns_DisclosureRequirementFragment,
): number => {
  let datapointCount = 0;

  if (disclosureRequirement.application_requirements) {
    disclosureRequirement.application_requirements.forEach((req) => {
      if (req?.datapoints) {
        datapointCount += req.datapoints.length;
      }
    });
  }

  if (disclosureRequirement.minimum_disclosure_requirements) {
    disclosureRequirement.minimum_disclosure_requirements.forEach((minDR) => {
      if (minDR?.content) {
        datapointCount += minDR.content.datapoints.length;
      }
    });
  }

  if (disclosureRequirement.content) {
    disclosureRequirement.content.dr_level_2?.forEach((level2) => {
      if (level2?.datapoints) {
        datapointCount += level2.datapoints.length;
      }

      if (level2?.dr_level_3) {
        level2.dr_level_3.forEach((level3) => {
          if (level3?.datapoints) {
            datapointCount += level3.datapoints.length;
          }
        });
      }
    });
  }

  return datapointCount;
};
