import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

export function EnpsPieChart({
  promotersCount,
  passivesCount,
  detractorsCount,
  promoters,
  passives,
  detractors,
}: {
  promotersCount: number;
  passivesCount: number;
  detractorsCount: number;
  promoters: number;
  passives: number;
  detractors: number;
}) {
  return (
    <div className="w-1/2 flex items-center">
      <div className="h-40 w-5/12 p-6">
        <PieChart
          data={getPieChartData(promotersCount, passivesCount, detractorsCount)}
          startAngle={270}
          lineWidth={40}
          paddingAngle={3}
        />
      </div>
      <div className="w-7/12 flex flex-col gap-2 -ml-4">
        <div className="grid grid-cols-6 items-center text-sm gap-2">
          <div className="rounded-full w-2.5 h-2.5 bg-green-500 shrink-0 justify-self-end"></div>
          <div className="grow col-span-3">Promoteurs</div>
          <div className="font-bold">{Math.round(promoters * 100)}%</div>
          <div className="text-gray-500">{promotersCount}</div>
          <div className="rounded-full w-2.5 h-2.5 bg-gray-300 shrink-0  justify-self-end"></div>
          <div className="grow col-span-3">Passifs</div>
          <div className="font-bold">{Math.round(passives * 100)}%</div>
          <div className="text-gray-500">{passivesCount}</div>
          <div className="rounded-full w-2.5 h-2.5 bg-red-500 shrink-0  justify-self-end"></div>
          <div className="grow col-span-3">Détracteurs</div>
          <div className="font-bold">{Math.round(detractors * 100)}%</div>
          <div className="text-gray-500">{detractorsCount}</div>
        </div>
      </div>
    </div>
  );
}

function getPieChartData(
  promotersCount: number,
  passivesCount: number,
  detractorsCount: number,
) {
  const data = [];
  if (promotersCount > 0) {
    data.push({
      title: 'Promoteurs',
      value: promotersCount,
      color: '#00a77f',
    });
  }
  if (passivesCount > 0) {
    data.push({ title: 'Passifs', value: passivesCount, color: '#A6ADC6' });
  }
  if (detractorsCount > 0) {
    data.push({
      title: 'Détracteurs',
      value: detractorsCount,
      color: '#F46031',
    });
  }
  return data;
}
