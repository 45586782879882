import React, { useCallback, useEffect, useState } from 'react';
import { useModal } from '../../layout/Modal';
import {
  ActionFieldsFragment,
  ActionSearchTemplateInput,
  ActionTemplateGallery_CatalystFragment,
  ActionTemplateSource,
  DiagnosticStakeFieldsFragment,
  ReferentialFieldsFragment,
  useActionCreateFromTemplateMutation,
  useActionSearchTemplatesMutation,
  useReferentialTemplatesQuery,
} from '../../../graphql/generated';
import { ActionPreview } from './ActionPreview';
import { Loader } from '../../generic/Loader';
import { LoaderFullscreen } from '../../layout/Loader';
import { ActionTemplateList } from './ActionTemplateList';
import clsx from 'clsx';
import { useToast } from '../../layout/Toast';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { PillarIcon } from '../../stake/PillarIcon';
import {
  ArrowRightIcon,
  ChevronRightIcon,
  GoodStepsLogoIcon,
  PlusIcon,
  SearchIcon,
  XIcon,
} from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function ActionTemplateGalleryModal({
  catalyst,
}: {
  catalyst: ActionTemplateGallery_CatalystFragment;
}) {
  const currentUser = useCurrentUser();
  const [previewActionTemplate, setPreviewActionTemplate] =
    useState<ActionFieldsFragment | null>(null);

  const [searchCriteria, setSearchCriteria] =
    useState<ActionSearchTemplateInput>({
      source: ActionTemplateSource.Goodsteps,
      text: '',
      page: 0,
      companyId: currentUser?.company?.id || '',
    });

  return previewActionTemplate ? (
    <PreviewActionTemplate
      catalyst={catalyst}
      actionTemplate={previewActionTemplate}
      setPreviewActionTemplate={setPreviewActionTemplate}
    />
  ) : (
    <ActionTemplates
      catalyst={catalyst}
      setPreviewActionTemplate={setPreviewActionTemplate}
      searchCriteria={searchCriteria}
      setSearchCriteria={setSearchCriteria}
    />
  );
}

function PreviewActionTemplate({
  catalyst,
  actionTemplate,
  setPreviewActionTemplate,
}: {
  catalyst: ActionTemplateGallery_CatalystFragment;
  actionTemplate: ActionFieldsFragment;
  setPreviewActionTemplate: (
    actionTemplate: ActionFieldsFragment | null,
  ) => void;
}) {
  const modal = useModal();
  const toast = useToast();

  const [addAction] = useActionCreateFromTemplateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addAction({
      variables: {
        input: {
          actionTemplateId: actionTemplate.id,
          roadmapId: catalyst.roadmap.id,
          catalystId: catalyst.id,
          companyId: catalyst.roadmap.company?.id!,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Action ajoutée');
        modal.closeModal();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => setPreviewActionTemplate(null)}
        >
          <ChevronRightIcon className="w-4 h-4 transform rotate-180" />
          <span>Retour aux modèles</span>
        </button>
        <h4 className="text-center grow">Action ・ Ajout</h4>
        <button className="primary purple" onClick={add} disabled={isAdding}>
          {isAdding ? <Loader /> : <PlusIcon className="w-4 h-4" />}
          <span>Ajouter</span>
        </button>
      </div>
      <ActionPreview actionSummary={actionTemplate} />
    </div>
  );
}

function ActionTemplates({
  catalyst,
  setPreviewActionTemplate,
  searchCriteria,
  setSearchCriteria,
}: {
  catalyst: ActionTemplateGallery_CatalystFragment;
  setPreviewActionTemplate: (
    actionTemplate: ActionFieldsFragment | null,
  ) => void;
  searchCriteria: ActionSearchTemplateInput;
  setSearchCriteria: (searchCriteria: ActionSearchTemplateInput) => void;
}) {
  const modal = useModal();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<ActionFieldsFragment[]>([]);

  // Fetch template stakes: from referential templates
  const referentialTemplatesDocumentQuery = useReferentialTemplatesQuery({
    fetchPolicy: 'network-only',
  });
  const availableReferentialTemplates =
    referentialTemplatesDocumentQuery?.data?.getReferentialTemplates || [];
  const availableStakes =
    availableReferentialTemplates.flatMap(
      (referential) =>
        referential.pillars.flatMap((pillar) => pillar.stakes) || [],
    ) || [];

  const selectSource = (source: ActionTemplateSource) => {
    setSearchCriteria({ ...searchCriteria, source, page: 0 });
    refreshActionsList({ ...searchCriteria, source, page: 0 });
  };

  const selectStake = (stakeId: string | null) => {
    if (stakeId) {
      setSearchCriteria({ ...searchCriteria, stake: { id: stakeId }, page: 0 });
      refreshActionsList({
        ...searchCriteria,
        stake: { id: stakeId },
        page: 0,
      });
    } else {
      setSearchCriteria({ ...searchCriteria, stake: null, page: 0 });
      refreshActionsList({ ...searchCriteria, stake: null, page: 0 });
    }
  };

  const setSearchText = (text: string) => {
    setSearchCriteria({ ...searchCriteria, text, page: 0 });
  };

  const incrementPage = () => {
    setSearchCriteria({
      ...searchCriteria,
      page: (searchCriteria.page || 0) + 1,
    });
    refreshActionsList({
      ...searchCriteria,
      page: (searchCriteria.page || 0) + 1,
    });
  };

  const decrementPage = () => {
    setSearchCriteria({
      ...searchCriteria,
      page: (searchCriteria.page || 0) - 1,
    });
    refreshActionsList({
      ...searchCriteria,
      page: (searchCriteria.page || 0) - 1,
    });
  };

  const [searchActionTemplatesMutation] = useActionSearchTemplatesMutation();
  const refreshActionsList = useCallback(
    (searchCriteria: ActionSearchTemplateInput) => {
      setIsSearching(true);
      searchActionTemplatesMutation({
        variables: {
          input: searchCriteria,
        },
      })
        .then((result) => {
          result.data?.searchActionTemplates &&
            setSearchResult(result.data.searchActionTemplates);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => setIsSearching(false));
    },
    [searchActionTemplatesMutation],
  );

  // Load list on first render
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      refreshActionsList(searchCriteria);
    }
  }, [isFirstRender, refreshActionsList, searchCriteria]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-100"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <h4 className="text-center grow">Choisissez un modèle d&apos;action</h4>
      </div>

      <div className="flex overflow-hidden grow">
        <div className="bg-gray-50 w-1/4 p-8 divide-y divide-gray-100 space-y-8 overflow-y-scroll overflow-x-hidden">
          <div className="flex flex-col gap-1 pt-4">
            <h3 className="title-h5">Sources</h3>
            <SourceFilter
              source={ActionTemplateSource.Goodsteps}
              selectedSourceFilter={searchCriteria.source}
              setSelectedSourceFilter={selectSource}
            />
            <SourceFilter
              source={ActionTemplateSource.OwnTemplates}
              selectedSourceFilter={searchCriteria.source}
              setSelectedSourceFilter={selectSource}
            />
            <SourceFilter
              source={ActionTemplateSource.History}
              selectedSourceFilter={searchCriteria.source}
              setSelectedSourceFilter={selectSource}
            />
          </div>
          {availableStakes.length > 0 && (
            <StakesFilter
              availableReferentialTemplates={availableReferentialTemplates}
              searchCriteria={searchCriteria}
              selectStake={selectStake}
            />
          )}
        </div>
        <div className="w-3/4 p-8 overflow-hidden flex flex-col gap-8">
          <h1 className="max-w-3xl">
            Inspirez-vous de nos modèles d&apos;actions, conçus pour vous faire
            gagner en efficacité.
          </h1>
          <div className="flex items-center justify-start gap-2 px-2 mr-4 border border-gray-100 shadow-sm rounded-md">
            <SearchIcon className="text-gray-300" />
            <input
              placeholder="Rechercher un modèle"
              className="border-0 w-full focus:border-0 focus:ring-0 focus:outline-none text-sm placeholder-gray-300 pl-0"
              type={'text'}
              value={searchCriteria.text || ''}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  refreshActionsList(searchCriteria);
                }
              }}
            />
          </div>
          <div className="overflow-y-scroll">
            {isSearching ? (
              <LoaderFullscreen />
            ) : (
              searchResult && (
                <ActionTemplateList
                  actions={searchResult}
                  catalyst={catalyst}
                  previewAction={setPreviewActionTemplate}
                />
              )
            )}
          </div>
          {!isSearching && (
            <div className="flex items-center justify-between flex-row-reverse gap-2 w-full">
              <button
                className="tertiary small justify-self-end"
                disabled={searchResult.length < 10}
                onClick={incrementPage}
              >
                <div>Continuer la recherche</div>
                <ArrowRightIcon className="w-4 h-4" />
              </button>
              {(searchCriteria.page || 0) > 0 && (
                <button className="tertiary small" onClick={decrementPage}>
                  <ArrowRightIcon className="w-4 h-4 rotate-180" />
                  <div>Précédents résultats</div>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function StakesFilter({
  availableReferentialTemplates,
  searchCriteria,
  selectStake,
}: {
  availableReferentialTemplates: ReferentialFieldsFragment[];
  searchCriteria: ActionSearchTemplateInput;
  selectStake: (stakeId: string | null) => void;
}) {
  const { translateProperty } = useTranslation();
  const [expandedReferentialIds, setExpandedReferentialIds] = useState<
    string[]
  >([]);
  const [expandedPillarIds, setExpandedPillarIds] = useState<string[]>([]);

  return (
    <div className="flex flex-col gap-2 pt-4">
      <h3 className="title-h5">Enjeux</h3>
      <StakeFilter
        diagnosticStake={null}
        selectedStakeFilter={searchCriteria.stake?.id || ''}
        setSelectedStakeFilter={selectStake}
      />

      {availableReferentialTemplates.map((referential) => (
        <div className="flex flex-col gap-2 pl-2" key={referential.id}>
          <h4 className="font-title text-sm font-bold flex items-center gap-1">
            <div>{referential.name}</div>
            <button
              className="unstyled small"
              onClick={() => {
                if (expandedReferentialIds.includes(referential.id)) {
                  setExpandedReferentialIds(
                    expandedReferentialIds.filter(
                      (id) => id !== referential.id,
                    ),
                  );
                } else {
                  setExpandedReferentialIds([
                    ...expandedReferentialIds,
                    referential.id,
                  ]);
                }
              }}
            >
              <ChevronRightIcon
                className={clsx(
                  'w-4 h-4',
                  expandedReferentialIds.includes(referential.id) &&
                    'transform rotate-90',
                )}
              />
            </button>
          </h4>
          {expandedReferentialIds.includes(referential.id) &&
            referential.pillars.map((pillar) => (
              <div className="flex flex-col gap-1" key={referential.id}>
                <h5 className="font-title text-sm font-bold pl-2 flex items-center gap-1">
                  <div>{translateProperty(pillar, 'name')}</div>
                  <button
                    className="unstyled small"
                    onClick={() => {
                      if (expandedPillarIds.includes(pillar.id)) {
                        setExpandedPillarIds(
                          expandedPillarIds.filter((id) => id !== pillar.id),
                        );
                      } else {
                        setExpandedPillarIds([...expandedPillarIds, pillar.id]);
                      }
                    }}
                  >
                    <ChevronRightIcon
                      className={clsx(
                        'w-4 h-4',
                        expandedPillarIds.includes(pillar.id) &&
                          'transform rotate-90',
                      )}
                    />
                  </button>
                </h5>
                {expandedPillarIds.includes(pillar.id) &&
                  pillar.stakes.map((diagnosticStake) => (
                    <StakeFilter
                      key={diagnosticStake.id}
                      diagnosticStake={diagnosticStake}
                      selectedStakeFilter={searchCriteria.stake?.id || ''}
                      setSelectedStakeFilter={selectStake}
                    />
                  ))}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}

function StakeFilter({
  diagnosticStake,
  selectedStakeFilter,
  setSelectedStakeFilter,
}: {
  diagnosticStake: DiagnosticStakeFieldsFragment | null;
  selectedStakeFilter: string;
  setSelectedStakeFilter: (selectedStakeFilter: string | null) => void;
}) {
  const { translateProperty } = useTranslation();
  if (diagnosticStake === null) {
    return (
      <button
        className={clsx(
          'filter-button',
          (selectedStakeFilter === '' ||
            selectedStakeFilter === null ||
            selectedStakeFilter === undefined) &&
            'selected',
        )}
        onClick={() => setSelectedStakeFilter(null)}
      >
        Tous les enjeux
      </button>
    );
  }

  return (
    <button
      className={clsx(
        'filter-button',
        diagnosticStake?.id === selectedStakeFilter && 'selected',
      )}
      onClick={() => setSelectedStakeFilter(diagnosticStake?.id || '')}
    >
      <PillarIcon pillar={diagnosticStake.pillar} />
      <div
        className="truncate"
        title={translateProperty(diagnosticStake, 'name')}
      >
        {translateProperty(diagnosticStake, 'name')}
      </div>
    </button>
  );
}

function SourceFilter({
  source,
  selectedSourceFilter,
  setSelectedSourceFilter,
}: {
  source: ActionTemplateSource;
  selectedSourceFilter: ActionTemplateSource;
  setSelectedSourceFilter: (selectedSourceFilter: ActionTemplateSource) => void;
}) {
  return (
    <button
      className={clsx(
        'filter-button',
        source === selectedSourceFilter && 'selected',
      )}
      onClick={() => setSelectedSourceFilter(source)}
    >
      <span>{getSourceLabel(source)}</span>
      {source === ActionTemplateSource.Goodsteps && <GoodStepsLogoIcon />}
    </button>
  );
}

function getSourceLabel(source: ActionTemplateSource): string {
  switch (source) {
    case ActionTemplateSource.Goodsteps:
      return 'Modèles Good Steps';
    case ActionTemplateSource.OwnTemplates:
      return 'Vos modèles';
    case ActionTemplateSource.History:
      return 'Votre historique';
    default:
      return '';
  }
}
