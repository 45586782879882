import { GroupedStakeholderSegmentDropdown } from '../../../project/stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { Loader } from '../../../generic/Loader';
import React, { useState } from 'react';
import { GroupedStringDropdownItem } from '../../../generic/dropdown/GroupedStringDropdown';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import {
  CursorPaging,
  SurveyResponsesDocument,
  SurveyResponsesTableDocument,
  useChangeResponseSegmentMutation,
} from '../../../../graphql/generated';

type Props = {
  response: {
    id: string;
    survey: {
      id: string;
    };
  };
  availableItems: GroupedStringDropdownItem[];
  currentPaging: CursorPaging;
  segment?: { id: string } | null;
  defaultSegment?: { id: string } | null;
};

export const SurveyReponseRowStakeholderDropdown = ({
  response,
  defaultSegment,
  segment,
  availableItems,
  currentPaging,
}: Props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [changeResponseSegment] = useChangeResponseSegmentMutation();

  const [isUpdatingSegment, setIsUpdatingSegment] = useState(false);
  const handleChangeStakeholderSegment = (
    items: GroupedStringDropdownItem[] | null,
  ) => {
    setIsUpdatingSegment(true);
    void changeResponseSegment({
      variables: {
        id: response.id,
        input: {
          segment:
            (items?.[0] && {
              id: items[0].id,
            }) ||
            null,
        },
      },
      refetchQueries: [
        {
          query: SurveyResponsesDocument,
          variables: {
            surveyId: response.survey.id,
          },
        },
        {
          query: SurveyResponsesTableDocument,
          variables: {
            surveyId: response.survey.id,
            paging: currentPaging,
          },
        },
      ],
      onCompleted: () => {
        toast.openToastWithMessage(
          t('toast:survey.respondent.changeStakeholderType.success'),
        );
        setIsUpdatingSegment(false);
      },
    });
  };
  return (
    <div className="flex items-center gap-2">
      <GroupedStakeholderSegmentDropdown
        readonly={!response.id}
        availableItems={availableItems}
        selectedSegmentIds={[segment?.id ?? defaultSegment?.id ?? '']}
        handleChange={handleChangeStakeholderSegment}
      />
      {isUpdatingSegment && <Loader />}
    </div>
  );
};
