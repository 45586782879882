import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { likelihoodCriteriaChoices } from '../picker/IroLikelihoodPicker';
import { CriteriaRatingTable } from './CriteriaRatingTable';
import { IroFormula } from './IroFormula';

export function LikelihoodCriteriaDefinition() {
  const { t } = useTranslation();
  const items = likelihoodCriteriaChoices(t);
  return (
    <div>
      <h1 className="p-8 bg-gray-50 border-b border-gray-100 title-h3 capitalize">
        {t('iro.criteria.likelihood.title')}
      </h1>
      <div className="p-8 space-y-8">
        <p className="">{t('iro.criteria.likelihood.definition')}</p>
        <hr />
        <div className="space-y-2">
          <h2 className="title-h4">{t('iro.criteria.rating.title')}</h2>
          <CriteriaRatingTable items={items} />
        </div>
        <hr />
        <div className="space-y-2">
          <h2 className="title-h4">{t('iro.criteria.formula.title')}</h2>
          <IroFormula />
        </div>
      </div>
    </div>
  );
}
