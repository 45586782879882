import { useParams } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useMaturityEvaluationAssignScreenQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { MaturityEvaluationAssignQuestions } from '../../../components/project/maturity/MaturityEvaluationAssignQuestions';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { withProjectContext } from '../../../providers/withProjectContext';

function MaturityEvaluationAssign() {
  const { surveyId } = useParams();

  const { data, loading } = useMaturityEvaluationAssignScreenQuery({
    variables: { id: surveyId || '' },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.maturityEvaluation) {
    return (
      <>
        <PageTitle title="Évaluation de maturité" />
        <Breadcrumb />
        <div className="p-16">
          <MaturityEvaluationAssignQuestions
            maturityEvaluation={data.maturityEvaluation}
          />
        </div>
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de l&apos;évaluation
          de maturité.
        </p>
      </MessageBox>
    );
  }
}

export const MaturityEvaluationAssignScreen = withProjectContext(
  MaturityEvaluationAssign,
  {
    requiredCompany: true,
  },
);
