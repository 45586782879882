import React, { useState } from 'react';
import { SurveyTemplateSummaryFieldsFragment } from '../../../../graphql/generated';
import { PreviewSurveyTemplate } from './PreviewSurveyTemplate';
import { SurveyTemplates } from './SurveyTemplates';

export function SurveyTemplateGalleryModal() {
  const [previewSurveyTemplate, setPreviewSurveyTemplate] =
    useState<SurveyTemplateSummaryFieldsFragment | null>(null);

  return previewSurveyTemplate ? (
    <PreviewSurveyTemplate
      surveyTemplate={previewSurveyTemplate}
      setPreviewSurveyTemplate={setPreviewSurveyTemplate}
    />
  ) : (
    <SurveyTemplates setPreviewSurveyTemplate={setPreviewSurveyTemplate} />
  );
}
