import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { QuestionAnswersDataviz } from '../../../components/project/survey/QuestionAnswersDataviz';
import { useQuery } from '@apollo/client';
import {
  SurveyQuestion_QuestionFragment,
  SurveyQuestionResults_QuestionFragment,
  SurveyQuestionResultsDocument,
  SurveyQuestionType,
  SurveyResults_SurveyFragment,
  useProSurveyResultsScreenQuery,
} from '../../../graphql/generated';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { SurveyQuestionIcon } from '../../../components/survey/SurveyQuestionIcon';
import clsx from 'clsx';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import headerIllustration from '../../../assets/images/illustrations/diagnostic/diag-header.png';
import { SurveyTargetIcon } from '../../../components/project/survey/SurveyTargetIcon';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronRightIcon } from '../../../components/icons';

export function ProSurveyResultsScreen() {
  const projectContext = useProjectContext();
  const surveysQuery = useProSurveyResultsScreenQuery({
    variables: {
      companyId: projectContext?.enterprise?.id || '',
    },
  });

  if (surveysQuery.loading) {
    return <LoaderFullscreen />;
  }

  const surveys = surveysQuery.data?.surveys || [];
  return (
    <>
      <PageTitle title="Résultats des consultations" />
      <Breadcrumb />
      <ProSurveyResultsScreenHeader />
      <div className="main-content w-full p-8">
        <SurveyResults surveys={surveys} />
      </div>
    </>
  );
}

function ProSurveyResultsScreenHeader({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <div className="bg-blue-50 px-8 relative">
      <div className="flex items-center gap-16 main-content">
        <div className="flex flex-col gap-4 w-7/12">
          <h1>{t('survey.results.title')}</h1>
          <p>{t('survey.results.description')}</p>
        </div>
        <div className="w-5/12 relative">
          <img src={headerIllustration} alt="" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function SurveyResults({
  surveys,
}: {
  surveys: SurveyResults_SurveyFragment[];
}) {
  return (
    <ul className="divide-y divide-gray-100 space-y-4">
      {surveys.map((survey) => {
        return <Survey survey={survey} key={survey.id} />;
      })}
    </ul>
  );
}

function Survey({ survey }: { survey: SurveyResults_SurveyFragment }) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <li className="pt-4">
      <div className="flex items-center gap-2">
        <SurveyTargetIcon type={survey.target} />
        <h2
          className="cursor-pointer title-h4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {survey.name}
        </h2>
        <button className="tertiary" onClick={() => setIsExpanded(!isExpanded)}>
          <ChevronRightIcon
            className={clsx('w-4 h-4 text-gray-900', isExpanded && 'rotate-90')}
          />
        </button>
      </div>
      {isExpanded && (
        <div className="space-y-4">
          {survey.questions &&
            survey.questions
              .filter((question) => !question.parent)
              .filter(
                (question) => question.type !== SurveyQuestionType.Statement,
              )
              .toSorted((a, b) => a.position - b.position)
              .map((question) => {
                if (question.type !== SurveyQuestionType.Group) {
                  return (
                    <SurveyQuestion key={question.id} question={question} />
                  );
                } else {
                  return (
                    <div>
                      {question.children
                        ?.filter(
                          (question) =>
                            question.type !== SurveyQuestionType.Statement,
                        )
                        .toSorted((a, b) => a.position - b.position)
                        .map((subQuestion) => (
                          <SurveyQuestion
                            key={subQuestion.id}
                            question={subQuestion}
                          />
                        ))}
                    </div>
                  );
                }
              })}
        </div>
      )}
    </li>
  );
}

function SurveyQuestion({
  question,
}: {
  question: SurveyQuestion_QuestionFragment;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <li className="border border-gray-100 rounded-xl mt-4">
      <div className="p-2 flex items-center justify-between">
        <div className="flex items-center gap-4 font-semibold">
          <SurveyQuestionIcon questionType={question.type} />
          <div
            className="flex flex-col gap-1 cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {question.parent && (
              <div className="text-gray-500 font-normal text-xs">
                <div>{question.parent.title}</div>
              </div>
            )}
            <div>{question.title}</div>
          </div>
        </div>
        <button
          className="primary purple"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ChevronRightIcon className={clsx(isExpanded && 'rotate-90')} />
        </button>
      </div>
      {isExpanded && <SurveyQuestionResults question={question} />}
    </li>
  );
}

function SurveyQuestionResults({
  question,
}: {
  question: SurveyQuestion_QuestionFragment;
}) {
  const resultsQuery = useQuery(SurveyQuestionResultsDocument, {
    variables: {
      id: question.id,
    },
  });

  if (resultsQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!resultsQuery.data?.surveyQuestion) {
    return null;
  }

  const questionWithResults: SurveyQuestionResults_QuestionFragment =
    resultsQuery.data?.surveyQuestion;

  return (
    <div className="p-4 bg-gray-50">
      <QuestionAnswersDataviz
        question={questionWithResults}
        answers={questionWithResults?.answers || []}
        isThumbnail={false}
      />
    </div>
  );
}
