import { TMaterialityMatrixChartJsOptions } from './types';
import { ChartOptions } from 'chart.js';
import { tailwindColors } from '../../../../services/TailwindService';
import { BubbleDataPoint, Point } from 'chart.js/auto';

export const getChartJsOptions = (
  options: TMaterialityMatrixChartJsOptions,
): ChartOptions<'bubble'> => {
  return {
    events: ['mousedown', 'mouseup', 'mousemove', 'mouseout'],
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
      padding: {
        top: 30,
        right: 10,
        bottom: 10,
        left: 10,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        position: 'bottom',
        max: 4,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value % 1 === 0) {
              return tailwindColors.gray[100];
            } else {
              return tailwindColors.gray[50];
            }
          },
          lineWidth: function (context) {
            if (context.tick.value % 1 === 0) {
              return 1;
            } else {
              return 2;
            }
          },
        },
        title: {
          display: true,
          text: options.xAxisLabel,
          font: {
            weight: 'bold',
            size: 14,
          },
        },
        ticks: {
          display: true,
          precision: 1,
          includeBounds: false,
          // mirror: true,
        },
      },
      y: {
        beginAtZero: true,
        max: 4,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value % 1 === 0) {
              return tailwindColors.gray[100];
            } else {
              return tailwindColors.gray[50];
            }
          },
          lineWidth: function (context) {
            if (context.tick.value % 1 === 0) {
              return 1;
            } else {
              return 2;
            }
          },
        },
        title: {
          display: true,
          text: options.yAxisLabel,
          font: {
            weight: 'bold',
            size: 14,
          },
        },
        ticks: {
          display: true,
          precision: 1,
          includeBounds: false,
          // mirror: true,
        },
      },
    },
    plugins: {
      zoom: options.enableZoom
        ? {
            limits: {
              x: { min: 0, max: 4 },
              y: { min: 0, max: 4 },
            },
            pan: {
              enabled: true,
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
          }
        : {},
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += `: [${context.parsed.x.toFixed(
                1,
              )}, ${context.parsed.y.toFixed(1)}]`;
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: 'white',
        // borderColor: 'black',
        backgroundColor: tailwindColors['purple'][500],
        borderRadius: 100,
        // borderWidth: 2,
        font: {
          weight: 'bolder',
          size: 12,
          lineHeight: 1.2,
        },
        offset: 0,
        // hack: dynamic padding to make it a circle
        padding: function (context) {
          const value:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | null = context.dataset.data[context.dataIndex];
          if (
            value &&
            typeof value === 'object' &&
            'r' in value &&
            value.r &&
            value.r > 9
          ) {
            return {
              top: 3,
              bottom: 0,
              right: 2,
              left: 2,
            };
          }
          return {
            top: 3,
            bottom: 1,
            right: 6,
            left: 6,
          };
        },
      },
    },
  };
};
