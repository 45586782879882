import {
  MaturityEvaluationIllustrationIconFragment,
  MaturityEvaluationRowFragment,
} from '../../../../graphql/generated';
import React from 'react';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { ChevronRightIcon, GoodStepsLogoIcon, MaturityIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function MaturityEvaluationTemplateList({
  maturityEvaluations,
  setPreviewMaturityEvaluationTemplate,
}: {
  maturityEvaluations: MaturityEvaluationRowFragment[];
  setPreviewMaturityEvaluationTemplate: (
    maturityEvaluationTemplate: MaturityEvaluationRowFragment,
  ) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="flex gap-4 text-gray-500 text-sm mb-4">
        <div className="w-6/12">{t('maturity.evaluation.new.modal.list.title')}</div>
        <div className="w-1/12"></div>
      </div>
      {maturityEvaluations.length > 0 ? (
        maturityEvaluations.map((maturityEvaluation) => (
          <div
            className="mb-2 border-2 border-white rounded-lg hover:border-gray-900 cursor-pointer"
            key={maturityEvaluation.id}
            onClick={() => setPreviewMaturityEvaluationTemplate(maturityEvaluation)}
          >
            <MaturityEvaluationRow maturityEvaluation={maturityEvaluation} />
          </div>
        ))
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          {t('maturity.evaluation.new.modal.list.empty')}
        </MessageBox>
      )}
    </div>
  );
}

function MaturityEvaluationRow({ maturityEvaluation }: { maturityEvaluation: MaturityEvaluationRowFragment }) {
  return (
    <div className="rounded-xl flex items-center justify-between gap-2">
      <div className="w-6/12 flex items-center gap-4 shrink-0">
        <MaturityEvaluationIllustrationIcon maturityEvaluation={maturityEvaluation} />
        <div>
          <div className="font-bold text-gray-900">{maturityEvaluation.name}</div>
          {maturityEvaluation.company?.name && (
            <div className="text-gray-500 text-sm mt-0.5">
              {maturityEvaluation.company.name}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/12">
        <button className="primary purple mr-2">
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}

export function MaturityEvaluationIllustrationIcon({
  maturityEvaluation,
}: {
  maturityEvaluation: MaturityEvaluationIllustrationIconFragment;
}) {
  return (
    <div className="relative w-20 h-20 rounded-lg bg-purple-50 shrink-0 flex items-center justify-center">
      <MaturityIcon className="w-5 h-5 text-purple-500" />
      {maturityEvaluation.isTemplate && (
        <div className="absolute -top-2 -right-2 p-1 h-7 w-7 border-2 border-white rounded-full flex items-center justify-center bg-green-500 text-white">
          <GoodStepsLogoIcon className="w-3 h-3" />
        </div>
      )}
    </div>
  );
}
