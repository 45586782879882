import { useRelativeTime } from '@hooks/useRelativeTime';
import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

export const LastUpdate = ({ lastUpdated }: { lastUpdated: Date }) => {
  const relativeTime = useRelativeTime(lastUpdated);
  const { t } = useTranslation();
  return (
    <div className="text-gray-500 text-sm">
      {t('global:lastUpdate', { date: relativeTime })}
    </div>
  );
};
