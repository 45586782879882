import { useStakeholderSegmentMatrixFiltersQuery } from '../../../../graphql/generated';

type SelectedSegmentIds = {
  x: string[];
  y: string[];
};

type UseSelectedStakeholderSegmentIdsResult = {
  selectedSegmentIds: SelectedSegmentIds;
  loading: boolean;
  error: Error | null;
};

export const useSelectedStakeholderSegmentIds = ({
  companyId,
}: {
  companyId: string;
}): UseSelectedStakeholderSegmentIdsResult => {
  const { data, loading, error } = useStakeholderSegmentMatrixFiltersQuery({
    variables: {
      companyId,
    },
  });

  return {
    selectedSegmentIds: {
      x: data?.enterprise?.xStakeholderSegments || [],
      y: data?.enterprise?.yStakeholderSegments || [],
    },
    loading,
    error: error || null,
  };
};
