import React from 'react';
import clsx from 'clsx';

export class NumberCircleColor {
  static readonly Gray = new NumberCircleColor(
    'gray',
    'bg-gray-100',
    'text-gray-900',
  );
  static readonly Blue = new NumberCircleColor(
    'blue',
    'bg-blue-50',
    'text-blue-900',
  );
  static readonly Yellow = new NumberCircleColor(
    'yellow',
    'bg-yellow-100',
    'text-yellow-900',
  );
  static readonly Green = new NumberCircleColor(
    'green',
    'bg-green-50',
    'text-green-900',
  );
  static readonly Purple = new NumberCircleColor(
    'purple',
    'bg-purple-50',
    'text-purple-900',
  );
  static readonly Red = new NumberCircleColor(
    'red',
    'bg-red-50',
    'text-red-900',
  );

  // private to disallow creating other instances of this type
  private constructor(
    private readonly key: string,
    public readonly bgColor: string,
    public readonly textColor: string,
  ) {}

  toString() {
    return this.key;
  }
}

export function NumberCircle({
  number,
  size,
  color,
  mode,
}: {
  number: number;
  size: 12 | 10 | 8 | 7 | 6 | 5 | 4;
  color?: NumberCircleColor;
  mode?: 'number' | 'alpha';
}) {
  return (
    <div
      className={clsx(
        'w-'.concat(size.toString()),
        'h-'.concat(size.toString()),
        'flex items-center justify-center shrink-0',
      )}
    >
      <div
        className={clsx(
          'w-'.concat((size > 6 ? size - 2 : size - 1).toString()),
          'h-'.concat((size > 6 ? size - 2 : size - 1).toString()),
          size > 8 ? 'text-base' : 'text-xs',
          'rounded-full flex items-center justify-center font-bold shrink-0',
          color ? color.bgColor : 'bg-gray-900',
          color ? color.textColor : 'text-white',
        )}
      >
        {mode === 'alpha'
          ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(number)
          : number}
      </div>
    </div>
  );
}
