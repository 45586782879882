import { ProConsultationRawMateriality_ResultFragment } from '../../../../graphql/generated';
import { Table } from '../../../molecules/table/Table';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  ConsultationRawMaterialityRow,
  useConsultationRawMaterialityColumns,
} from './useConsultationRawMaterialityColumns';
import { getSortedRowModel } from '@tanstack/react-table';

type Props = {
  consultation: ProConsultationRawMateriality_ResultFragment;
};

export const ProConsultationRawMaterialityResults = ({
  consultation,
}: Props) => {
  const { t } = useTranslation();
  const columns = useConsultationRawMaterialityColumns();
  const data = consultation.values as ConsultationRawMaterialityRow[];

  return (
    <div className="w-1/3 mx-auto overflow-auto flex flex-col gap-4 my-10">
      <div className="mt-5">
        <div className="flex justify-between items-center self-stretch">
          <div className="text-[#282a2c] font-['Bitter'] text-xl font-extrabold leading-[150%]">
            {t('consultationRawMateriality.results.title')}
          </div>
        </div>
      </div>
      {data && (
        <Table<ConsultationRawMaterialityRow>
          {...{
            tableClassName: 'table table-fixed',
            columns,
            data,
            enableSorting: true,
            getSortedRowModel: getSortedRowModel(),
            initialState: {
              sorting: [
                {
                  id: 'stake',
                  desc: true,
                },
              ],
            },
          }}
        />
      )}
    </div>
  );
};
