import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { GoodStepsLogoIcon } from '../../../icons';
import React from 'react';

export enum TemplatesSources {
  GoodSteps = 'GoodSteps',
  Own = 'Own',
}

export function SourceFilter({
  source,
  selectedSourceFilter,
  setSelectedSourceFilter,
}: {
  source: TemplatesSources;
  selectedSourceFilter: TemplatesSources;
  setSelectedSourceFilter: (selectedSourceFilter: TemplatesSources) => void;
}) {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(
        'filter-button',
        source === selectedSourceFilter && 'selected',
      )}
      onClick={() => setSelectedSourceFilter(source)}
    >
      <span>
        {source === TemplatesSources.GoodSteps
          ? t('survey.templateModal.goodStepsModels')
          : t('survey.templateModal.yourHistory')}
      </span>
      {source === TemplatesSources.GoodSteps && <GoodStepsLogoIcon />}
    </button>
  );
}
