import React, { useRef, useState } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import clsx from 'clsx';
import { Loader } from './Loader';

export function ExportChartJsToImageButton({
  chartRef,
  className,
  children,
}: {
  chartRef:
    | React.RefObject<ChartJSOrUndefined<'polarArea', number[], string>>
    | React.RefObject<ChartJSOrUndefined<'radar', number[], string[]>>
    | React.RefObject<
        ChartJSOrUndefined<
          'bubble',
          [{ x: number; y: number; r: number }],
          string
        >
      >;
  className?: string;
  children: React.ReactNode;
}) {
  const myRef = useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className={clsx(isLoading && 'opacity-0')}>
        <img
          className={clsx(
            'p-4 border border-gray-100 rounded-xl w-full m-auto',
            'absolute',
            !isLoading ? 'hidden' : '',
          )}
          src={imgSrc || ''}
          alt={''}
          ref={myRef}
        />
      </div>
      <button
        className={className}
        disabled={isLoading}
        onClick={() => {
          if (chartRef?.current) {
            setIsLoading(true);
            const base64Image = chartRef.current.toBase64Image();
            setImgSrc(base64Image);
            setTimeout(() => {
              exportComponentAsPNG(myRef, {
                fileName: `[Good Steps] Export.png`,
              })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }, 500);
          }
        }}
      >
        {isLoading ? <Loader /> : children}
      </button>
    </>
  );
}
