import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  SurveyPreview_QuestionFragment,
  SurveyPreviewFragment,
  SurveyQuestionType,
  useSurveyTemplateQuery,
} from '../../../../graphql/generated';
import clsx from 'clsx';
import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';
import { LoaderFullscreen } from '../../../layout/Loader';
import { QuestionRouter } from '../../../survey/questions/QuestionRouter';
import { GroupedStakeholderSegmentDropdown } from '../../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useGroupedStakeholderSegments } from '../../stakeholder/dropdown/useGroupedStakeholderSegments';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export function SurveyPreview({
  survey,
  defaultSegmentId,
  setDefaultSegmentId,
}: {
  survey: SurveyPreviewFragment;
  defaultSegmentId?: string | null;
  setDefaultSegmentId?: Dispatch<SetStateAction<string | null>>;
}) {
  const projectContext = useProjectContext();
  const { data, loading } = useSurveyTemplateQuery({
    variables: {
      id: survey.id,
    },
    fetchPolicy: 'network-only',
  });
  const { availableItems } = useGroupedStakeholderSegments(
    projectContext?.enterprise?.id || '',
  );
  const [selectedQuestion, setSelectedQuestion] =
    useState<SurveyPreview_QuestionFragment | null>(null);

  return (
    <div className="flex items-stretch w-full h-full overflow-scroll">
      <div className="w-1/3 divide-y divide-gray-100 overflow-scroll">
        <div className="p-8 space-y-2">
          <div className="flex">
            <div className="tag purple font-bold">Modèle</div>
          </div>
          <h2>{survey.name}</h2>
          {survey.description && (
            <p className="text-gray-500 text-sm">{survey.description}</p>
          )}
          {survey.target && setDefaultSegmentId && (
            <dl className="flex items-center w-full text-sm">
              <dt className="font-bold w-1/3">Parties prenantes</dt>
              <dd className="flex items-center gap-1 font-semibold text-sm">
                <GroupedStakeholderSegmentDropdown
                  selectedSegmentIds={
                    defaultSegmentId ? [defaultSegmentId] : []
                  }
                  handleChange={(selectedItems) =>
                    setDefaultSegmentId(selectedItems[0].id)
                  }
                  availableItems={availableItems}
                />
              </dd>
            </dl>
          )}
        </div>
        <div className="p-8 overflow-scroll">
          <h3 className="flex items-center gap-2">
            <div>Questions</div>
            <div className="tag purple font-bond">
              {data?.survey?.questions?.length}
            </div>
          </h3>
          {loading && <LoaderFullscreen />}
          {data?.survey?.questions && (
            <SurveyQuestions
              questions={data.survey.questions}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
            />
          )}
        </div>
      </div>
      <div className="w-2/3 p-4 bg-yellow-50 shadow-inner">
        {selectedQuestion && (
          <QuestionRouter
            question={selectedQuestion}
            answer={undefined}
            setAnswer={() => console.log('preview')}
            setIsAnswerValid={() => console.log('preview')}
          />
        )}
      </div>
    </div>
  );
}

export function SurveyQuestions({
  questions,
  selectedQuestion,
  setSelectedQuestion,
}: {
  questions: SurveyPreview_QuestionFragment[];
  selectedQuestion: SurveyPreview_QuestionFragment | null;
  setSelectedQuestion: (question: SurveyPreview_QuestionFragment) => void;
}) {
  return (
    <div className="space-y-2 mt-4">
      {questions
        .toSorted((a, b) => a.position - b.position)
        .map((question, index) =>
          question.type === SurveyQuestionType.Group ? (
            <GroupItem
              key={question.id}
              question={question}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              index={index}
            />
          ) : (
            <div
              key={question.id}
              onClick={() => setSelectedQuestion(question)}
            >
              <QuestionItem
                question={question}
                isSelected={selectedQuestion?.id === question.id}
                index={index}
              />
            </div>
          ),
        )}
    </div>
  );
}

function GroupItem({
  question,
  selectedQuestion,
  setSelectedQuestion,
  index,
}: {
  question: SurveyPreview_QuestionFragment;
  selectedQuestion: SurveyPreview_QuestionFragment | null;
  setSelectedQuestion: (question: SurveyPreview_QuestionFragment) => void;
  index: number;
}) {
  return (
    <div>
      <div className="flex items-center gap-2 text-sm text-gray-500 mt-6 mb-2">
        <div className="font-semibold grow">
          {question.title.substring(0, 100)}
          {question.title.length > 100 && '...'}
        </div>
      </div>
      {question.children && (
        <div className="ml-4 space-y-2">
          {(question.children || [])
            .toSorted((a, b) => a.position - b.position)
            .map((child) => (
              <div key={question.id} onClick={() => setSelectedQuestion(child)}>
                <QuestionItem
                  question={child}
                  isSelected={selectedQuestion?.id === question.id}
                  index={index}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

function QuestionItem({
  question,
  isSelected,
  index,
}: {
  question: SurveyPreview_QuestionFragment;
  isSelected: boolean;
  index: number;
}) {
  return (
    <div
      className={clsx(
        'bg-white border-2 border-gray-100 rounded-xl p-4 flex items-center text-gray-500 gap-3 text-sm shadow-sm cursor-pointer hover:border-gray-900 hover:text-gray-900',
        isSelected && 'border-2 border-gray-900 text-gray-900',
      )}
    >
      <div className="font-semibold">{index}</div>
      <SurveyQuestionIcon questionType={question.type} />
      <div className="font-semibold grow">
        {question.title.substring(0, 100)}
        {question.title.length > 100 && '...'}
      </div>
    </div>
  );
}
