import { useCreateChecklistButtonMutation } from '../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../generic/Loader';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { PlusIcon } from '../icons';

export function CreateChecklistButton({
  actionId,
  companyId,
  reportId,
  className,
}: {
  actionId?: string;
  companyId?: string;
  reportId?: string;
  className?: string;
}) {
  const projectContext = useProjectContext();

  const [createChecklistMutation] = useCreateChecklistButtonMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createChecklist = () => {
    setIsLoading(true);
    createChecklistMutation({
      variables: {
        input: {
          actionId: actionId || undefined,
          projectId: companyId || undefined,
          reportId: reportId || undefined,
        },
      },
    })
      .then(() => {
        if (companyId && projectContext) {
          // If we are creating a checklist for a company, we need to update the company
          projectContext.updateEnterprise(companyId, false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      onClick={createChecklist}
      className={className || 'primary small purple'}
      disabled={isLoading}
    >
      {isLoading ? <Loader /> : <PlusIcon />}
      <span>Créer une liste de tâches</span>
    </button>
  );
}
