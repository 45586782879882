import React from 'react';
import {
  ProSurveyList_SurveyFragment,
  ThemeIcon,
  useSurveyWithRecipientsQuery,
} from '../../../graphql/generated';
import { generatePath, NavLink } from 'react-router-dom';
import { ProgressBar } from '../../generic/ProgressBar';
import { Loader } from '../../generic/Loader';
import { SurveyStatusTag } from '../../survey/SurveyStatusTag';
import { SurveyContextualMenu } from './SurveyContextualMenu';
import surveysIllustration from '../../../assets/images/illustrations/survey/surveys-home.png';
import { OpenSurveyTemplateModalButton } from './OpenSurveyTemplateModalButton';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import { ChevronRightIcon } from '../../icons';
import { ThemeIcon as ThemeIconComponent } from '../../generic/icon/ThemeIcon';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../../screens/AppRoutes';

export function SurveyList({
  surveys,
}: {
  surveys: ProSurveyList_SurveyFragment[];
}) {
  const { t } = useTranslation();
  const sortedSurveys = surveys.toSorted((a, b) =>
    a.name.localeCompare(b.name),
  );
  return (
    <>
      {surveys?.length > 0 ? (
        <table className="">
          <thead>
            <tr>
              <th className="text-left">{t('survey.list.table.survey')}</th>
              <th className="text-center">{t('survey.list.table.type')}</th>
              <th className="text-center">{t('survey.list.table.status')}</th>
              <th className="text-center">
                {t('survey.list.table.completionRate')}
              </th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
            {sortedSurveys.map((survey) => {
              return <SurveyRow survey={survey} key={survey.id} />;
            })}
          </tbody>
        </table>
      ) : (
        <IllustratedExplanationMessage
          illustration={<img src={surveysIllustration} alt="" />}
          title={t('survey.list.table.emptyState.title')}
          description={t('survey.list.table.emptyState.description')}
        >
          <OpenSurveyTemplateModalButton />
        </IllustratedExplanationMessage>
      )}
    </>
  );
}

function SurveyRow({ survey }: { survey: ProSurveyList_SurveyFragment }) {
  // Fetch Recipients data
  const { data, loading } = useSurveyWithRecipientsQuery({
    variables: { id: survey.id },
    fetchPolicy: 'network-only',
  });
  const tooltipId = `survey-tooltip-${survey.id}`;
  const { translateProperty } = useTranslation();

  return (
    <tr>
      <td>
        <NavLink to={generatePath(AppRoutes.SurveyItem, {
          surveyId: survey.id,
        })}>
          <div className="flex items-center gap-2">
            <div
              data-tooltip-content={translateProperty(
                survey.defaultSegment,
                'name',
              )}
              data-tooltip-id={tooltipId}
            >
              <Tooltip id={tooltipId} className="tooltip" />
              <ThemeIconComponent
                themeIcon={
                  survey.defaultSegment?.group?.icon || ThemeIcon.Collaborators
                }
              />
            </div>
            <div className="font-title font-extrabold">{survey.name}</div>
          </div>
        </NavLink>
      </td>
      <td>
        <div className="flex justify-center font-bold">
          {translateProperty(survey.defaultSegment, 'name')}
        </div>
      </td>
      <td>
        <div className="flex justify-center">
          <SurveyStatusTag survey={survey} />
        </div>
      </td>
      <td className="text-center w-1/4">
        {loading ? (
          <Loader />
        ) : (
          data?.survey && (
            <ProgressBar
              value={data.survey.responses?.length || 0}
              total={data.survey.recipients?.length || 0}
              targetThreshold={data.survey.targetThreshold || 0}
              displayLabel={false}
              displayPercentage={true}
              displayCounts={true}
            />
          )
        )}
      </td>
      <td>
        <div className="flex items-center gap-4 justify-end">
          <SurveyContextualMenu survey={survey} />
          <NavLink to={generatePath(AppRoutes.SurveyItem, {
            surveyId: survey.id,
          })}>
            <button className="primary purple small">
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </NavLink>
        </div>
      </td>
    </tr>
  );
}
