import React from 'react';
import { PillarIcon_PillarFragment } from '../../graphql/generated';
import { Tooltip } from 'react-tooltip';
import { getTooltipUniqueId } from '../../services/UtilsService';
import { ThemeIcon } from '../generic/icon/ThemeIcon';
import { useTranslation } from '@hooks/useTranslation';

export function PillarIcon({
  pillar,
  enableTooltip,
}: {
  pillar: PillarIcon_PillarFragment | null | undefined;
  enableTooltip?: boolean;
}) {
  if (!pillar) {
    return null;
  }
  const { translateProperty } = useTranslation();
  const id = getTooltipUniqueId('PillarIcon');
  return (
    <div
      data-tooltip-content={
        enableTooltip ? translateProperty(pillar, 'name') : ''
      }
      data-tooltip-id={id}
    >
      <Tooltip id={id} className="tooltip" />
      <ThemeIcon themeIcon={pillar.icon} />
    </div>
  );
}
