import React from 'react';
import { WeightIcon } from '../../icons';
import { NumberInput } from './NumberEditor';

export function WeightNumberEditor({
  weight,
  setWeight,
}: {
  weight: number | null | undefined;
  setWeight: (weight: number) => void;
}) {
  return (
    <NumberInput
      value={weight}
      setValue={setWeight}
      step={0.1}
      numberOfDecimals={1}
      icon={<WeightIcon />}
      iconClassName="text-black"
    />
  );
}
