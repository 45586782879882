import React from 'react';
import { PageTitle } from 'components/nav/PageTitle';
import {
  AuthRoles,
  FeatureNames,
  useAdminUserScreenQuery,
  useAuthUpdateMutation,
} from 'graphql/generated';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withAdminNavigation } from '../../withAdminNavigation';
import { AdminNavRoutes } from '../../AdminNav';
import { withRouter } from '../../../withRouter';
import { useTranslation } from '@hooks/useTranslation';
import { ImpersonateButton } from '../../../../components/impersonate/ImpersonateButton';

type AdminUserProps = {
  params?: {
    id: string;
  };
};

export const AdminUser = withRouter<AdminUserProps>(({ params: { id } }) => {
  const [updateAuthMutation] = useAuthUpdateMutation();
  const { t } = useTranslation();
  const userQuery = useAdminUserScreenQuery({
    variables: {
      id,
    },
  });
  const user = userQuery.data?.user;

  if (userQuery.error?.message) {
    return (
      <div className="p-8 flex justify-center items-center w-full">
        <MessageBox type={MessageBoxType.Error}>
          {userQuery.error.message}
        </MessageBox>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const userRoles = user?.auth?.roles || [];
  const checkRole = (
    role: AuthRoles | FeatureNames,
    wasPreviouslyChecked: boolean,
  ) => {
    if (!user.auth) {
      throw new Error("Can't checkRole for nonexistant Auth");
    }

    const clonedRolesArray = [...userRoles];
    const newRoles = wasPreviouslyChecked
      ? [...clonedRolesArray.filter((previousRole) => previousRole !== role)]
      : [...clonedRolesArray, role];

    updateAuthMutation({
      variables: {
        input: {
          id: user.auth.id,
          roles: newRoles,
        },
      },
    })
      .then((r) => console.log(r))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <PageTitle
        title={`Admin - ${t('admin:users.form.pageTitle', {
          name: user.firstName.concat(' ', user.lastName),
        })}`}
      />
      {user ? (
        <div className="mt-4 ml-4 space-y-4">
          <div>
            <label className="form-input-label">
              {t('admin:users.form.field.firstname')}
            </label>
            <p>{user.firstName}</p>
          </div>
          <div>
            <label className="form-input-label">
              {t('admin:users.form.field.lastname')}
            </label>
            <p>{user.lastName}</p>
          </div>
          <div>
            <div className="form-input-label">
              {t('admin:users.form.field.roles')}
            </div>
            {!user.auth ? (
              <div>[not associated with an Auth]</div>
            ) : (
              <div className="flex items-center gap-2">
                {Object.values(AuthRoles).map((role) => {
                  const checked = userRoles.includes(role);
                  return (
                    <label className="tag purple" key={role}>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => checkRole(role, checked)}
                      />
                      <span>{role}</span>
                    </label>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <div className="form-input-label">
              {t('admin:users.form.field.featureFlag')}
            </div>
            {!user.auth ? (
              <div>[not associated with an Auth]</div>
            ) : (
              <div className="flex items-center gap-2">
                {Object.values(FeatureNames).map((role) => {
                  const checked = userRoles.includes(role);

                  return (
                    <label className="tag yellow" key={role}>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => checkRole(role, checked)}
                      />
                      <span>
                        {t(`${'admin:users.form.featureFlag.'.concat(role)}`)}
                      </span>
                    </label>
                  );
                })}
              </div>
            )}
          </div>

          <ImpersonateButton userId={user.id} />
        </div>
      ) : null}
    </>
  );
});

export const AdminUserScreen = withAdminNavigation(
  AdminUser,
  AdminNavRoutes.Users,
);
