import { LoaderFullscreen } from '../../../../components/layout/Loader';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../../components/nav/PageTitle';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { IroEvaluation } from '../../../../components/project/iro/evaluation/IroEvaluation';
import { useTranslation } from '@hooks/useTranslation';
import { useProIroScreenQuery } from '../../../../graphql/generated';
import { ScreenTopBar } from '../../../../components/layout/ScreenTopBar';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '../../../../components/icons';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { ReferentialProvider } from '../../../../components/project/context/ReferentialContext';
import { AppRoutes } from '../../../AppRoutes';

const Iro = () => {
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  // Fetch stakes
  const companyReferentialDocumentQuery = useProIroScreenQuery({
    variables: { id: projectContext?.enterprise?.referential?.id || '' },
    skip: !projectContext?.enterprise?.id,
  });

  if (companyReferentialDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (companyReferentialDocumentQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {companyReferentialDocumentQuery.error.message}
      </MessageBox>
    );
  }

  if (companyReferentialDocumentQuery.data?.referential) {
    return (
      <ReferentialProvider
        initialReferential={
          companyReferentialDocumentQuery.data?.referential.id
        }
      >
        <PageTitle title={t('screen.iro.iro_rating')} />
        <ScreenTopBar>
          <div className="flex gap-1 title-h5 items-center flex-wrap">
            <NavLink to={AppRoutes.Iro} className="text-gray-500">
              {t('screen.iro.iro_summary')}
            </NavLink>
            <ChevronRightIcon className="shrink-0" />
            {t('screen.iro.iro_rating')}
          </div>
        </ScreenTopBar>
        <div className="w-full">
          <IroEvaluation
            referential={companyReferentialDocumentQuery.data.referential}
          />
        </div>
      </ReferentialProvider>
    );
  }

  return null;
};

export const IroScreen = withProjectContext(Iro, { requiredCompany: true });
