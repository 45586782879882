import React, { useState } from 'react';
import {
  Header_ResponseFragment,
  RespondentAnswer_AnswerFragment,
  useSurveyResponseModalQuery,
} from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import { LoaderFullscreen } from '../../layout/Loader';
import { SurveyQuestionIcon } from '../../survey/SurveyQuestionIcon';
import clsx from 'clsx';
import {
  getFrDateFromISOString,
  getFrTimeFromISOString,
} from '../../../services/DateService';
import { QuestionAnswersDataviz } from '../survey/QuestionAnswersDataviz';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { ChevronDownIcon, UserIcon, XIcon } from '../../icons';

export function SurveyResponseModal({ responseId }: { responseId: string }) {
  const modal = useModal();

  // Load response and answers
  const query = useSurveyResponseModalQuery({
    variables: {
      id: responseId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  const response = query.data?.surveyResponse;

  if (!response) {
    return null;
  }

  return (
    <div className="flex flex-col h-full w-full overflow-scroll">
      <div className="bg-white flex items-center gap-2 p-4 w-full justify-between">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="flex items-center justify-between grow">
          <h4 className="flex items-center justify-center gap-3 w-full">
            <span>Analyse de réponse</span>
          </h4>
        </div>
        <div className="w-12"></div>
      </div>
      <div className="">
        <Header response={response} />
      </div>
      <div className={'bg-gray-50 grow px-16 py-8 space-y-4'}>
        {response.answers &&
          response.answers.length > 0 &&
          response.answers.map((answer) => (
            <RespondentAnswer answer={answer} key={answer.id} />
          ))}
      </div>
    </div>
  );
}

function RespondentAnswer({
  answer,
}: {
  answer: RespondentAnswer_AnswerFragment;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white p-4 border border-gray-100 rounded-xl space-y-8">
      <div
        className="flex items-center justify-between gap-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-4">
          <SurveyQuestionIcon questionType={answer.question.type} />
          <div className="">
            <div className="font-title font-bold whitespace-pre-wrap">
              {answer.question.title}
            </div>
          </div>
        </div>
        <ChevronDownIcon className={clsx(isExpanded && 'rotate-180')} />
      </div>
      {isExpanded && (
        <QuestionAnswersDataviz
          question={answer.question}
          answers={[answer]}
          isThumbnail={false}
        />
      )}
    </div>
  );
}

function Header({ response }: { response: Header_ResponseFragment }) {
  const name = response.respondent?.name || `#${response.id.substring(0, 5)}`;
  return (
    <div className="py-8 px-16 border-y border-gray-100 flex flex-col gap-2">
      <div className="flex items-center gap-4">
        <div className="w-12 h-12 rounded-full flex items-center justify-center font-bold shrink-0 bg-gray-100 text-gray-900">
          <UserIcon className="flex-shrink-0 h-6 w-6" />
        </div>
        <div className="">
          <div className="flex items-center gap-2">
            <h1>{name}</h1>
            {response.respondent?.email && (
              <div className="text-gray-500 text-sm">
                ({response.respondent?.email})
              </div>
            )}
          </div>

          {response.submittedAt && (
            <div className="text-gray-500 text-sm">
              Réponse envoyée le {getFrDateFromISOString(response.submittedAt)}{' '}
              à {getFrTimeFromISOString(response.submittedAt)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
