import React from 'react';
import {
  ChooseReportDisclosureRequirementsRow,
  SelectedData,
} from './useChooseReportDisclosureRequirementsColumns';
import { Td } from '../../../../molecules/table/Td';
import { Checkbox, CheckboxColors } from '../../../../form/Checkbox';
import { useTranslation } from '@hooks/useTranslation';
import { getNumberOfDatapoints } from '../useGapAnalysisTopicDRTableColumns';
import { SummaryDetailDisclosureRequirementsRow_DisclosureRequirementFragment } from '../../../../../graphql/cms/generated';

export const SummaryDetailDisclosureRequirementsRow = ({
  data: { disclosureRequirements, topic },
  columnLength,
  toggleDisclosureRequirementSelection,
  selectedData,
}: {
  data: ChooseReportDisclosureRequirementsRow;
  columnLength: number;
  toggleDisclosureRequirementSelection: (
    topicReferenceSlug: string,
    referenceSlug: string,
  ) => void;
  selectedData: SelectedData;
}) => {
  return (
    <tr className={'bg-gray-50'}>
      <Td colSpan={columnLength}>
        <div className="flex flex-col items-left ml-9 gap-2">
          {disclosureRequirements.map((disclosureRequirement) => (
            <DisclosureRequirementRow
              key={disclosureRequirement.documentId}
              topicReferenceSlug={topic.slug}
              disclosureRequirement={disclosureRequirement}
              toggleDisclosureRequirementSelection={
                toggleDisclosureRequirementSelection
              }
              selectedData={selectedData}
            />
          ))}
        </div>
      </Td>
    </tr>
  );
};

type DisclosureRequirementRowProps = {
  topicReferenceSlug: string;
  disclosureRequirement: SummaryDetailDisclosureRequirementsRow_DisclosureRequirementFragment;
  toggleDisclosureRequirementSelection: (
    topicReferenceSlug: string,
    referenceSlug: string,
  ) => void;
  selectedData: SelectedData;
};

const DisclosureRequirementRow = ({
  topicReferenceSlug,
  disclosureRequirement,
  toggleDisclosureRequirementSelection,
  selectedData,
}: DisclosureRequirementRowProps) => {
  const isChecked = selectedData[
    topicReferenceSlug
  ]?.disclosureRequirements.includes(disclosureRequirement.slug!);
  const { t } = useTranslation();
  const numberOfDatapoints = getNumberOfDatapoints(disclosureRequirement);

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        color={CheckboxColors.Purple}
        checked={isChecked}
        onChange={() =>
          toggleDisclosureRequirementSelection(
            topicReferenceSlug,
            disclosureRequirement.slug!,
          )
        }
      />
      <div>
        {disclosureRequirement.code} - {disclosureRequirement.title}
        <span className="ml-2 text-gray-500 text-xs text-nowrap">
          {t(
            'gap_analysis.chooseReportTopicModal.table.header.disclosureRequirements.numberOfDatapoints',
            {
              count: numberOfDatapoints,
            },
          )}
        </span>
      </div>
    </div>
  );
};
