import React from 'react';
import clsx from 'clsx';
import DOMPurify from 'dompurify';

export function InnerHtml({
  html,
  className,
}: {
  html: string;
  className?: string;
}) {
  function htmlValue(html: string): { __html: string } {
    return { __html: html || '' };
  }

  // Sanitize HTML thanks to DOMPurify
  const sanitizedHtml = DOMPurify.sanitize(html);

  return (
    <div
      className={clsx('dangerouslySetInnerHTML', className)}
      dangerouslySetInnerHTML={htmlValue(sanitizedHtml)}
    ></div>
  );
}
