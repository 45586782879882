import { getAuth, getIdToken, signInWithCustomToken } from 'firebase/auth';

export const getHeaders = async (firebaseApp: any) => {
  const auth = getAuth(firebaseApp);
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return {
      'Content-Type': 'application/json',
    };
  }
  const token = await getIdToken(currentUser);
  return {
    'Content-Type': 'application/json',
    ...(token ? { authorization: `Bearer ${token}` } : {}),
  };
};

export const impersonate = async (
  firebaseApp: any,
  userId: string,
): Promise<void> => {
  const headers = await getHeaders(firebaseApp);

  const response = await fetch(`${process.env.API_URL}/impersonate`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ userId }),
  });

  const { token } = await response.json();
  if (!token) {
    throw new Error('No token in response');
  }

  const auth = getAuth(firebaseApp);
  await signInWithCustomToken(auth, token);
};
