import React, { ReactNode } from 'react';
import { useFirebaseAuth } from '../../../../providers/FirebaseProvider';
import { useCurrentAuth } from '../../../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import {
  linkWithCredential,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { Auth } from '@firebase/auth';
import logo from '../../../../assets/images/logo-with-name.svg';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';

function getSamiOAuthProvider() {
  return new OAuthProvider('oidc.sami');
}

function initiateSamiLoginWithPopup(firebaseAuth: Auth) {
  console.log('initiateSamiLogin');
  const samiOAuthProvider = getSamiOAuthProvider();

  if (samiOAuthProvider) {
    console.log('signInWithPopup');

    signInWithPopup(firebaseAuth, samiOAuthProvider).catch(function (error) {
      // This error is "normal"
      // @see https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
      if (error.code === 'auth/account-exists-with-different-credential') {
        // The pending Google credential.
        const pendingCred = OAuthProvider.credentialFromError(error);
        if (pendingCred) {
          linkWithCredential(error.user, pendingCred)
            .then(function (usercred) {
              // TODO: Handle the case where the user has already signed in with a different account
              console.log('linkWithCredential success', usercred);
            })
            .catch(function (error) {
              console.error('linkWithCredential error', error);
            });
        }
      }
    });
  }
}

export function SamiIntegrationLoginScreen() {
  const currentAuth = useCurrentAuth();
  const currentUser = useCurrentUser();
  const firebaseAuth = useFirebaseAuth();

  if (currentAuth && currentUser) {
    return (
      <CenteredLayout>
        <div className="w-full overflow-scroll">
          <MessageBox type={MessageBoxType.Info}>
            <p>Vous êtes déjà connecté en tant que {currentUser.authId}</p>
            <p>{currentUser.firstName}</p>
            <p>{currentUser.lastName}</p>
            <p>({currentUser.company?.name})</p>
          </MessageBox>
        </div>
      </CenteredLayout>
    );
  }

  return (
    <CenteredLayout>
      <h1>Bienvenue !</h1>
      <p className="">Vous avez déjà un compte Sami ?</p>
      <button
        className="primary"
        onClick={() => initiateSamiLoginWithPopup(firebaseAuth)}
      >
        Login Sami
      </button>
    </CenteredLayout>
  );
}

function CenteredLayout({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full bg-white ">
      <div className="w-full p-8">
        <img className="h-10" src={logo} alt="Good Steps logo" />
      </div>
      <div className="grow max-w-lg flex flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
}
