import React, { useState } from 'react';
import { useToast } from '../../../../../layout/Toast';
import { Loader } from '../../../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import {
  DeleteIroButton_IroFragment,
  IroEvaluation_IroDocument,
  useDeleteIroButtonMutation,
} from '../../../../../../graphql/generated';

export function DeleteIroButton({
  iro,
  onDelete,
}: {
  iro: DeleteIroButton_IroFragment;
  onDelete?: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [deleteIroButtonMutation] = useDeleteIroButtonMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const preventAccidentalDeletion = () => {
    setShowDeleteConfirmation(true);
  };

  const deleteIro = () => {
    setIsDeleting(true);
    deleteIroButtonMutation({
      variables: {
        id: iro.id,
      },
      refetchQueries: () => [
        {
          query: IroEvaluation_IroDocument,
          variables: {
            stakeId: iro.stake?.id ?? '',
          },
        },
      ],
    })
      .finally(() => {
        setIsDeleting(false);
        onDelete && onDelete();
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  if (showDeleteConfirmation) {
    return (
      <div className="flex items-center gap-2">
        <p className="text-sm">{t('global:confirm_delete')}</p>
        <button
          className="primary danger small"
          onClick={deleteIro}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : t('global:yes')}
        </button>
        <button
          className="tertiary text-gray-500 small"
          onClick={() => setShowDeleteConfirmation(false)}
        >
          Non
        </button>
      </div>
    );
  }

  return (
    <button
      className="secondary danger"
      onClick={preventAccidentalDeletion}
      disabled={isDeleting}
    >
      {t('global:delete')}
    </button>
  );
}
