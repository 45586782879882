import React from 'react';
import {
  IdentityAnswer_AnswerFragment,
  SurveyIdentityAnswers_AnswerFragment,
} from '../../../../graphql/generated';

export function SurveyIdentityAnswers({
  answers,
}: {
  answers?: SurveyIdentityAnswers_AnswerFragment[];
}) {
  return (
    <div className="relative">
      {answers?.map((answer, answerIndex) => (
        <IdentityAnswer answer={answer} key={answer.id} />
      ))}
    </div>
  );
}

function IdentityAnswer({ answer }: { answer: IdentityAnswer_AnswerFragment }) {
  return (
    <div className="flex items-stretch justify-start">
      <div className="w-full border-r border-gray-100 pr-4 py-4">
        <div className="text-purple-500 font-semibold text-sm mb-2">
          Réponse #{answer.id.substring(0, 5)}
        </div>
        <div className="relative">
          <div className="shadow-sm rounded-2xl bg-white p-4 border border-transparent hover:border-gray-900">
            <div className="font-bold">
              {answer.identity?.name || 'Inconnu'}
            </div>
            <div className="text-sm text-gray-500">
              {answer.identity?.email}{' '}
              {answer.identity?.role && `(${answer.identity?.role})`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
