import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { PageTitle } from '../../../../components/nav/PageTitle';
import React from 'react';
import { ConsultingCompanyEmployeesList } from '../../../../components/project/project/ConsultingCompanyEmployeesList';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useCoachsSettingsScreenQuery } from '../../../../graphql/generated';

export function CoachsSettingsScreen() {
  const currentUser = useCurrentUser();

  // Get full company data
  const { data, loading } = useCoachsSettingsScreenQuery({
    variables: {
      id: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.consultingCompany) {
    return (
      <div className="">
        <PageTitle title="Gérer les coachs" />
        <Breadcrumb />
        <ConsultingCompanyEmployeesList
          users={data.consultingCompany.employees ?? []}
          companyId={data.consultingCompany.id}
        />
      </div>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
