import React, { useState } from 'react';
import {
  EditableAnalysisThemeTitle_AnalysisThemeFragment,
  ThemeColor,
  useEditableAnalysisThemeTitle_UpdateMutation,
} from '../../../graphql/generated';
import clsx from 'clsx';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';

export function EditableAnalysisThemeTitle({
  theme,
}: {
  theme: EditableAnalysisThemeTitle_AnalysisThemeFragment;
}) {
  const [newName, setNewName] = useState(theme.name);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [analysisThemeEdit] = useEditableAnalysisThemeTitle_UpdateMutation();
  const updateName = () => {
    if (newName === theme.name) return;
    setIsLoading(true);
    analysisThemeEdit({
      variables: {
        input: {
          id: theme.id,
          name: newName,
        },
      },
    })
      .then((res) => {
        toast.openToastWithMessage('Thème mis à jour');
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateColor = (newColor: ThemeColor) => {
    if (newColor === theme.color) return;
    setIsLoading(true);
    analysisThemeEdit({
      variables: {
        input: {
          id: theme.id,
          name: newName,
          color: newColor,
        },
      },
    })
      .then((res) => {
        toast.openToastWithMessage('Thème mis à jour');
        theme.color = newColor;
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center w-full gap-2">
        {isLoading && <Loader />}
        <input
          type="text"
          disabled={isLoading}
          autoFocus={true}
          className="form-input-text-inline p-0 text-3xl font-title font-extrabold border-0 w-full"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onBlur={updateName}
          onSubmit={updateName}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              updateName();
            }
          }}
        />
      </div>
      <div className="flex items-center gap-0.5">
        <div
          className={clsx(
            'rounded-full w-3 h-3 border border-transparent bg-green-300 cursor-pointer',
            theme.color === ThemeColor.Green && 'border-gray-900',
          )}
          onClick={() => updateColor(ThemeColor.Green)}
        ></div>
        <div
          className={clsx(
            'rounded-full w-3 h-3 border border-transparent bg-gray-300 cursor-pointer',
            theme.color === ThemeColor.Gray && 'border-gray-900',
          )}
          onClick={() => updateColor(ThemeColor.Gray)}
        ></div>
        <div
          className={clsx(
            'rounded-full w-3 h-3 border border-transparent bg-blue-300 cursor-pointer',
            theme.color === ThemeColor.Blue && 'border-gray-900',
          )}
          onClick={() => updateColor(ThemeColor.Blue)}
        ></div>
        <div
          className={clsx(
            'rounded-full w-3 h-3 border border-transparent bg-yellow-300 cursor-pointer',
            theme.color === ThemeColor.Yellow && 'border-gray-900',
          )}
          onClick={() => updateColor(ThemeColor.Yellow)}
        ></div>
        <div
          className={clsx(
            'rounded-full w-3 h-3 border border-transparent bg-red-300 cursor-pointer',
            theme.color === ThemeColor.Red && 'border-gray-900',
          )}
          onClick={() => updateColor(ThemeColor.Red)}
        ></div>
      </div>
    </div>
  );
}
