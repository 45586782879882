import React, { FC, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { SurveyLanguage } from '../../../graphql/generated';
import mainI18n from '../../../i18n';

const cloneMainI18nInstance = (lng: SurveyLanguage) => {
  return mainI18n.cloneInstance({
    lng,
  });
};

type LanguageScopeWrapperProps = {
  language: SurveyLanguage;
} & PropsWithChildren;

export const LanguageScopeWrapper: FC<LanguageScopeWrapperProps> = ({
  language,
  children,
}) => {
  const i18nInstance = React.useMemo(
    () => cloneMainI18nInstance(language),
    [language],
  );
  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>;
};
