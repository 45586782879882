import { StakeholderTable_StakeholderFragment } from '../../../graphql/generated';
import { NumberCircle, NumberCircleColor } from '../../generic/NumberCircle';

export function StakeholderTable({
  stakeholders,
}: {
  stakeholders: StakeholderTable_StakeholderFragment[];
}) {
  return (
    <div className="max-h-96 overflow-y-scroll pr-4">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {stakeholders.map((stakeholder, index) => (
            <tr
              key={`${stakeholder.firstName}${stakeholder.lastName}${stakeholder.email}`}
            >
              <td>
                <NumberCircle
                  number={index + 1}
                  size={8}
                  color={NumberCircleColor.Purple}
                />
              </td>
              <td>{stakeholder.firstName}</td>
              <td>{stakeholder.lastName}</td>
              <td>{stakeholder.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
