import React from 'react';
import { SurveyLanguage } from '../../../../../graphql/generated';
import { DeleteIcon } from '../../../../icons';
import { WeightNumberEditor } from '../../../../generic/form/WeightNumberEditor';

type SegmentInputProps = {
  segment: {
    id?: string;
    i18n: { language: SurveyLanguage; name: string }[];
    weight: number;
  };
  index: number;
  language: SurveyLanguage;
  editSegment: (index: number, field: string, value: string) => void;
  editSegmentWeight: (index: number, weight: number) => void;
  removeSegment: (index: number) => void;
  t: (key: string, options?: any) => string;
};

export const SegmentInput: React.FC<SegmentInputProps> = ({
  segment,
  index,
  language,
  editSegment,
  editSegmentWeight,
  removeSegment,
  t,
}) => {
  const segmentTranslation = segment.i18n.find(
    (i18n) => i18n.language === language,
  );

  return (
    <div
      key={index + (segment?.id || '')}
      className="flex gap-2 items-center w-full"
    >
      <input
        type="text"
        className="form-input-text w-80"
        placeholder={t('stakeholder.group.form.segment.name.placeholder', {
          context: language,
        })}
        value={segmentTranslation?.name || ''}
        onChange={(e) => editSegment(index, 'name', e.target.value)}
        aria-label={t('stakeholder.group.form.segment.name.label', {
          context: language,
        })}
      />
      <WeightNumberEditor
        weight={segment.weight}
        setWeight={(weight) => editSegmentWeight(index, weight)}
      />
      <button
        className="tertiary"
        onClick={() => removeSegment(index)}
        aria-label={t('stakeholder.group.form.segment.delete.label')}
        title={t('stakeholder.group.form.segment.delete.label')}
      >
        <DeleteIcon className="w-4 h-4 text-gray-500" />
      </button>
    </div>
  );
};
