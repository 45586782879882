import React from 'react';
import { StakesMaterialityChart_StakeFragment } from '../../../../../graphql/generated';
import { Toggle } from '../../../../generic/form/Toggle';
import { useModal } from '../../../../layout/Modal';
import clsx from 'clsx';
import { ContextualMenu } from '../../../../generic/ContextualMenu';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { ExportChartJsToXlsButton } from '../../../../generic/ExportChartJsToXlsButton';
import PillarDropdown from '../../../../form/PillarDropdown';
import { DownloadIcon, ExportIcon, TableYIcon } from '../../../../icons';
import { StakesMaterialityLegend } from './StakesMaterialityLegend';
import { MaterialityBubbleChartJs } from '../../../../generic/chart/materialityBubbleChart/MaterialityBubbleChartJs';
import { useTranslation } from '@hooks/useTranslation';
import {
  TMaterialityMatrixChartDatapoint,
  TMaterialityMatrixChartJsOptions,
} from '../../../../generic/chart/materialityBubbleChart/types';
import { getChartJsDataset } from '../../../../generic/chart/materialityBubbleChart/getChartJsDataset';
import { useMatrix } from '../../../publications/doubleMateriality/MatrixContext';
import { GroupedStakeholderSegmentDropdown } from '../../../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useGroupedStakeholderSegments } from '../../../stakeholder/dropdown/useGroupedStakeholderSegments';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';

export type TStakeMateriality = {
  stake: StakesMaterialityChart_StakeFragment;
  xMateriality: number;
  yMateriality: number;
  materialityScore: number;
};

export function StakesMaterialityChart({
  stakesMaterialities,
  options,
}: {
  stakesMaterialities: TStakeMateriality[];
  options: TMaterialityMatrixChartJsOptions;
}) {
  const modal = useModal();
  const { t, translateProperty } = useTranslation();

  const [showThresholds, setShowThresholds] = React.useState(
    options.enableThresholds ?? false,
  );
  const [showZoom, setShowZoom] = React.useState(options.enableZoom ?? false);
  const [showConsensusLine, setShowConsensusLine] = React.useState(
    (options.enableConsensusLine &&
      (options.defaultConsensusLineState ?? true)) ??
      false,
  );
  const [currentPillarId, setCurrentPillarId] = React.useState<string | null>(
    null,
  );

  const [stakesIdsToExport, setStakesIdsToExport] = React.useState<string[]>(
    [],
  );
  const [isExportToImageMode, setIsExportToImageMode] =
    React.useState<boolean>(false);

  const filteredStakeIds = stakesMaterialities
    .filter(
      (stakeMateriality) =>
        currentPillarId === null ||
        stakeMateriality.stake.pillar?.id === currentPillarId,
    )
    .map((stakeValue) => stakeValue.stake.id);

  // Sort stakes by materiality score. If the materiality score is the same, sort by the product of x and y materialities
  const sortedStakesMaterialities = stakesMaterialities.toSorted((a, b) => {
    if (a.materialityScore === b.materialityScore) {
      return b.xMateriality * b.yMateriality - a.xMateriality * a.yMateriality;
    }
    return b.materialityScore - a.materialityScore;
  });

  const bubbleChartData: TMaterialityMatrixChartDatapoint[] =
    sortedStakesMaterialities
      ?.filter((stake) => {
        return !(!stake.xMateriality && !stake.yMateriality);
      })
      .map((stakeMateriality) => {
        return {
          id: stakeMateriality.stake.id,
          name: translateProperty(stakeMateriality.stake, 'name'),
          color: stakeMateriality.stake.pillar.color,
          position: {
            x: stakeMateriality.xMateriality,
            y: stakeMateriality.yMateriality,
          },
        };
      });

  const openChartInModal = () => {
    modal.openModalWithComponent(
      <div className="p-4 space-y-4">
        <MaterialityBubbleChartJs
          datapoints={bubbleChartData}
          filteredDatapointIds={stakesIdsToExport}
          options={{
            ...options,
            enableConsensusLine:
              options.enableConsensusLine && showConsensusLine,
            enableThresholds: options.enableThresholds && showThresholds,
            enableZoom: false, // enabling zoom break the annotation/label drag functionality
            enableLabels: true,
            enableExportAsImage: true,
          }}
        />
        <MessageBox type={MessageBoxType.Info}>
          {t('charts.materiality_matrix.move_annotations_info')}
        </MessageBox>
      </div>,
      t('charts.materiality_matrix.export_with_labels'),
      false,
      true,
      'w-6/12',
    );
  };
  const { setStakeholderSegments, stakeholderSegments } = useMatrix();
  const projectContext = useProjectContext();
  const { availableItems } = useGroupedStakeholderSegments(
    projectContext?.enterprise?.id || '',
  );

  return (
    <div className="border border-gray-100 rounded-xl bg-white shadow-sm">
      <div className="flex items-center justify-between gap-8 border-b border-gray-100 py-4 px-4">
        <div className="title-h6">
          {t('components.generic.chart.MaterialityBubbleChartJs.matrix')}
        </div>
        <div className="flex items-center gap-8">
          {options.enableThresholds && (
            <div className="flex items-center gap-1">
              <Toggle state={showThresholds} setState={setShowThresholds} />
              <div className="font-bold text-sm">
                {t(
                  'components.generic.chart.MaterialityBubbleChartJs.thresholds',
                )}
              </div>
            </div>
          )}
          {options.enableZoom && (
            <div className="flex items-center gap-1">
              <Toggle state={showZoom} setState={setShowZoom} />
              <div className="font-bold text-sm">
                {t('components.generic.chart.MaterialityBubbleChartJs.zoom')}
              </div>
            </div>
          )}
          {!isExportToImageMode && options.enableConsensusLine && (
            <div className="flex items-center gap-1">
              <Toggle
                state={showConsensusLine}
                setState={setShowConsensusLine}
              />
              <div className="font-bold text-sm">
                {t(
                  'components.generic.chart.MaterialityBubbleChartJs.consensus_line',
                )}
              </div>
            </div>
          )}
          {options.enableStakeholderSegmentsFilter && (
            <GroupedStakeholderSegmentDropdown
              handleChange={(items) => {
                setStakeholderSegments(items.map((item) => item.id));
              }}
              multiple={true}
              selectedSegmentIds={stakeholderSegments}
              availableItems={availableItems}
            />
          )}
          <PillarDropdown
            currentPillarId={currentPillarId}
            setPillarId={setCurrentPillarId}
            isFilter={true}
          />
          {!isExportToImageMode && (
            <StakesMaterialityChartContextualMenu
              bubbleChartData={bubbleChartData}
              setIsExportMode={setIsExportToImageMode}
              xAxisLabel={options.xAxisLabel ?? 'x'}
              yAxisLabel={options.yAxisLabel ?? 'y'}
            />
          )}
          {isExportToImageMode && (
            <div className="flex items-center gap-1 pr-6">
              <button
                className="small tertiary"
                onClick={() => setIsExportToImageMode(false)}
              >
                {t('global:cancel')}
              </button>
              <button
                className="small purple secondary"
                onClick={openChartInModal}
              >
                {t('charts.materiality_matrix.export_with_selected_stakes')}
                <TableYIcon className="rotate-180" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-hidden">
        <div className="flex flex-row justify-between">
          <div
            className={clsx(
              'relative h-[600px] transition-all duration-300 ease-in-out',
              isExportToImageMode ? 'opacity-50 w-6/12' : 'w-8/12',
            )}
          >
            <MaterialityBubbleChartJs
              datapoints={bubbleChartData}
              filteredDatapointIds={filteredStakeIds}
              options={{
                ...options,
                enableConsensusLine:
                  options.enableConsensusLine && showConsensusLine,
                enableThresholds: options.enableThresholds && showThresholds,
                enableZoom: options.enableZoom && showZoom,
              }}
            />
          </div>
          <div
            className={clsx(
              'relative h-[600px] overflow-y-scroll border-l border-gray-100 p-4',
              isExportToImageMode ? 'w-6/12' : 'w-4/12',
            )}
          >
            <StakesMaterialityLegend
              stakesMaterialities={sortedStakesMaterialities}
              filteredStakeIds={filteredStakeIds}
              isExportToImageMode={isExportToImageMode}
              stakesIdsToExport={stakesIdsToExport}
              setStakesIdsToExport={setStakesIdsToExport}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function StakesMaterialityChartContextualMenu({
  bubbleChartData,
  xAxisLabel,
  yAxisLabel,
  setIsExportMode,
}: {
  bubbleChartData: TMaterialityMatrixChartDatapoint[];
  xAxisLabel: string;
  yAxisLabel: string;
  setIsExportMode: (isExportMode: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <ContextualMenu
      button={
        <ExportIcon className="button tertiary p-1.5 flex-shrink-0 h-8 w-8 text-gray-500" />
      }
    >
      <button
        className="contextual-menu-item"
        onClick={() => setIsExportMode(true)}
      >
        <DownloadIcon />
        {t('charts.materiality_matrix.export_matrix')}
      </button>
      <ExportChartJsToXlsButton
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
        data={{
          datasets: getChartJsDataset(bubbleChartData, []),
        }}
      />
    </ContextualMenu>
  );
}
