import { LoaderFullscreen } from '../../../components/layout/Loader';
import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { MaterialityMatrixHeader } from '../../../components/publications/materialityMatrix/MaterialityMatrixHeader';
import { SimpleMaterialityMatrix } from '../../../components/project/publications/simpleMateriality/financialByStakeholderSegmentsMatrix/SimpleMaterialityMatrix';
import { MatrixProvider } from '../../../components/project/publications/doubleMateriality/MatrixContext';
import { withProjectContext } from '../../../providers/withProjectContext';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';

export const MaterialityMatrixScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();

    if (projectContext?.isLoadingEnterprise) {
      return <LoaderFullscreen />;
    }

    return (
      <>
        <PageTitle title="Matrice de matérialité" />
        <Breadcrumb />
        <MaterialityMatrixHeader />
        <div className="p-8 flex flex-col gap-8 main-content w-full">
          <MatrixProvider initialStakeholderSegments={[]}>
            <SimpleMaterialityMatrix />
          </MatrixProvider>
        </div>
      </>
    );
  },
  { requiredCompany: true },
);
