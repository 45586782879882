import { useModal } from '../../../layout/Modal';
import { CreateSubsidiaryEnterprise } from './CreateSubsidiaryEnterprise';
import React from 'react';
import { PlusIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { EnterpriseCard_EnterpriseFragment } from '../../../../graphql/generated';

export function AddSubsidiaryButton({
  parent,
  onAdd,
}: {
  parent: EnterpriseCard_EnterpriseFragment;
  onAdd?: () => void;
}) {
  const modal = useModal();
  const { t } = useTranslation();

  function openAddCompanyModal(companyId: string) {
    if (companyId)
      modal.openModalWithComponent(
        <CreateSubsidiaryEnterprise
          enterpriseParent={parent}
          onSave={() => {
            modal.closeModal();
            if (onAdd) onAdd();
          }}
        />,
        t('company.organizationalUnit.addOrganizationalUnit'),
      );
  }

  return (
    <button
      className="contextual-menu-item"
      onClick={() => openAddCompanyModal(parent.id)}
    >
      <PlusIcon />
      {t('company.organizationalUnit.addOrganizationalUnit')}
    </button>
  );
}
