import React, { useState } from 'react';
import {
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../../graphql/generated';
import RichTextEditorTextarea from '../../../../../generic/form/RichTextEditorTextarea';
import { useToast } from '../../../../../layout/Toast';
import { ChevronDownIcon } from '../../../../../icons';

export function QuestionDescriptionInput({
  question,
  setQuestion,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  setQuestion: (question: SurveyQuestionSummaryFieldsFragment) => void;
}) {
  const regex = /(<([^>]+)>)/gi;
  const [description, setDescription] = useState<string>(
    question.description || '',
  );
  const [displayDescription, setDisplayDescription] = useState<boolean>(
    !!question.description?.replace(regex, ''),
  );

  const toast = useToast();
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateDescription = (newDescription: string | null | undefined) => {
    const newQuestionDraft = { ...question };
    newQuestionDraft.description = newDescription;
    updateQuestionMutation({
      variables: {
        input: {
          id: question.id,
          description: newDescription,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
        setQuestion(newQuestionDraft);
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  if (displayDescription) {
    return (
      <div className="py-6">
        <label htmlFor="description" className="form-input-label mb-2">
          Texte additionnel
        </label>
        <RichTextEditorTextarea
          value={description || ''}
          onChange={(value) => {
            setDescription(value);
            setQuestion({ ...question, description: value });
          }}
          onBlur={() => updateDescription(description)}
        />
      </div>
    );
  } else {
    return (
      <div className="py-2">
        <button
          className="unstyled small text-gray-500"
          onClick={() => setDisplayDescription(true)}
        >
          <div>Ajouter un texte additionnel</div>
          <ChevronDownIcon />
        </button>
      </div>
    );
  }
}
