import { IroType } from '../../../../../../graphql/generated';
import { PlusIcon } from '../../../../../icons';
import React from 'react';
import { useModal } from '../../../../../layout/Modal';
import { CreateIroModal } from './CreateIroModal';
import { useTranslation } from '@hooks/useTranslation';
import { useReferential } from '../../../../context/ReferentialContext';

type CreateIroButtonProps = {
  stakeId: string;
  type: IroType;
};

export function AddIroButton({ type, stakeId }: CreateIroButtonProps) {
  const modal = useModal();
  const { t } = useTranslation();
  const { referential: referentialId } = useReferential();

  const openCreateIroModal = () => {
    modal.openModalWithComponent(
      <CreateIroModal {...{ type, stakeId, referentialId }} />,
      t('iro.create.modal.title'),
      false,
      false,
      undefined,
      'p-0',
    );
  };

  return (
    <button className="secondary small" onClick={openCreateIroModal}>
      <PlusIcon />
      <span className={'hidden lg:block'}>{t('global:add')}</span>
    </button>
  );
}
