import React from 'react';
import PillarDropdown from '../../../form/PillarDropdown';

type FilterProps = {
  currentPillarId: string | null;
  setCurrentPillarId: (pillarId: string | null) => void;
};

export const IroSummaryFilters = ({
  currentPillarId,
  setCurrentPillarId,
}: FilterProps) => {
  return (
    <div className="flex gap-3 mt-5">
      <PillarDropdown
        currentPillarId={currentPillarId}
        setPillarId={setCurrentPillarId}
        isFilter={true}
      />
      <div className="grow"></div>
    </div>
  );
};
