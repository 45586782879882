import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { Td } from './Td';
import { Th } from './Th';
import { TableOptions } from '@tanstack/table-core';
import { CaretDown, CaretUp } from '../../icons';
import clsx from 'clsx';

type TableProps<T extends RowData> = {
  columns: ColumnDef<T>[];
  data: T[];
  expandedRowRenderFn?: (data: T, columnLength: number) => React.ReactNode;
  tableClassName?: string;
  getRowClassName?: (row: T) => string; // Propriété pour les classes conditionnelles des lignes
  getCellClassName?: (row: T, columnId: string) => string; // Propriété pour les classes conditionnelles des cellules
} & Omit<TableOptions<T>, 'data' | 'columns' | 'getCoreRowModel'>;

export enum TableSpecialColumn {
  Actions = 'actions',
}

export const Table = <T,>({
  columns,
  data,
  expandedRowRenderFn,
  tableClassName,
  getRowClassName,
  getCellClassName,
  ...options
}: TableProps<T>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...options,
  });

  return (
    <table className={tableClassName}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                className={header.column.columnDef.meta?.th?.className ?? ''}
              >
                <div
                  className={clsx(
                    'flex items-center gap-2',
                    header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                          ? 'Sort descending'
                          : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {{
                    asc: (
                      <a href={'#'}>
                        <CaretUp
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </a>
                    ),
                    desc: (
                      <a href={'#'}>
                        <CaretDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </a>
                    ),
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              </Th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <React.Fragment key={row.id}>
            <tr
              className={getRowClassName ? getRowClassName(row.original) : ''}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  className={clsx(
                    cell.column.columnDef.meta?.td?.className ?? '',
                    getCellClassName
                      ? getCellClassName(row.original, cell.column.id)
                      : '',
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </tr>
            {row.getIsExpanded() &&
              expandedRowRenderFn &&
              expandedRowRenderFn(row.original, columns.length)}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};
