import React from 'react';
import { IroSummaryStakeRow } from './useIroSummaryColumns';
import { Td } from '../../../molecules/table/Td';
import { tidyIros } from '../../../../hooks/tidyIros';
import { useTranslation } from '@hooks/useTranslation';
import { GradientScore } from '../../../generic/GradientScore';
import { IroIcon } from '../IroIcon';

const SummaryDetailIroRow_List = ({
  title,
  iros,
}: { title: string } & {
  iros: {
    __typename?:
      | 'RiskIro'
      | 'OpportunityIro'
      | 'NegativeImpactIro'
      | 'PositiveImpactIro';
    name?: string | null;
    score?: number | null;
  }[];
}) => {
  const { t } = useTranslation();
  return (
    <section className="p-5 flex flex-col gap-3 text-sm">
      <h3 className={'text-lg'}>{title}</h3>
      <div className="flex gap-4">
        {(iros.length === 0 && (
          <div>{t('iro.summary.table.iroDetailRow.empty')}</div>
        )) || (
          <div className="flex flex-wrap gap-2">
            {iros.map((iro, index) => (
              <div
                className="bg-white border rounded-xl border-gray-100 flex items-center gap-4 p-3 max-w-lg"
                key={index}
              >
                {iro.__typename && <IroIcon iroTypename={iro.__typename} />}
                {iro.name}
                <GradientScore score={iro.score} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export const SummaryDetailIroRow = ({
  data: { iros },
  columnLength,
}: {
  data: IroSummaryStakeRow;
  columnLength: number;
}) => {
  const { negativeImpacts, positiveImpacts, risks, opportunities } =
    tidyIros(iros);
  const { t } = useTranslation();
  return (
    <tr className={'bg-gray-50'}>
      <Td colSpan={columnLength}>
        <SummaryDetailIroRow_List
          title={t('iro.summary.table.iroDetailRow.impactMateriality')}
          iros={[...positiveImpacts, ...negativeImpacts].filter(
            (item) => item !== undefined,
          )}
        />
        <SummaryDetailIroRow_List
          title={t('iro.summary.table.iroDetailRow.financialMateriality')}
          iros={[...risks, ...opportunities].filter(
            (item) => item !== undefined,
          )}
        />
      </Td>
    </tr>
  );
};
