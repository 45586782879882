import React, { useState } from 'react';
import {
  MaturityQuestion_QuestionFragment,
  SurveyAnswerType,
  SurveyQuestionType,
  SurveySubmitAnswerChoiceInput,
  SurveySubmitAnswerInput,
} from '../../../../graphql/generated';
import { NumberCircle, NumberCircleColor } from '../../../generic/NumberCircle';
import clsx from 'clsx';
import { StakeTag, StakeTagStyle } from '../../../stake/StakeTag';
import { InnerHtml } from '../../../generic/InnerHtml';

export function MaturityQuestion({
  question,
  index,
  answer,
  setAnswer,
  setUnsavedChanges,
}: {
  question: MaturityQuestion_QuestionFragment;
  index: number;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (
    answer: SurveySubmitAnswerInput | null,
    question: MaturityQuestion_QuestionFragment,
  ) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}) {
  const [selectedChoices, setSelectedChoices] = useState<
    SurveySubmitAnswerChoiceInput[]
  >(
    question.canSelectMultipleChoices
      ? answer?.choices || []
      : answer?.choice
        ? [answer.choice]
        : [],
  );
  const [comment, setComment] = useState<string>(answer?.comment || '');
  const [text, setText] = useState<string>(answer?.text || '');
  const [number, setNumber] = useState<string>(
    answer?.number?.toString() || '',
  );
  const getRefreshedChoicesArrayAfterPickingChoice = (choiceId: string) => {
    const choice = question.choices?.find((choice) => choice.id === choiceId);
    if (choice) {
      if (selectedChoices.find((c) => c.id === choice.id)) {
        // Already selected, remove it
        return [...selectedChoices.filter((c) => c.id !== choice.id)];
      } else {
        if (question.canSelectMultipleChoices) {
          // If multiple choices are allowed, toggle the choice
          return [...selectedChoices, { id: choice.id, label: choice.label }];
        } else {
          // If only one single choice is allowed, remove all other choices
          return [{ id: choice.id, label: choice.label }];
        }
      }
    }
    return selectedChoices;
  };

  const updateAnswer = (newAnswer: Partial<SurveySubmitAnswerInput>) => {
    if (!answer) {
      setAnswer(
        {
          question: {
            id: question.id,
          },
          type: SurveyAnswerType.Choices, //TODO: remove answer type, not useful (use question type instead)
          ...newAnswer,
        },
        question,
      );
    } else {
      setAnswer(
        {
          ...answer,
          ...newAnswer,
        },
        question,
      );
    }
  };

  const toggleChoice = (choiceId: string) => {
    const newChoices = getRefreshedChoicesArrayAfterPickingChoice(choiceId);
    setSelectedChoices(newChoices);
    updateAnswer({
      ...answer,
      choices: question.canSelectMultipleChoices ? newChoices : undefined,
      choice: question.canSelectMultipleChoices ? undefined : newChoices[0],
    });
  };

  return (
    <div className="bg-white border border-gray-100 rounded-xl shadow-sm p-4 space-y-2">
      <div className="flex items-start gap-2">
        <NumberCircle number={index + 1} size={8} />
        <div className="flex flex-col gap-2 items-stretch w-full">
          <div className="flex items-start gap-2 justify-between">
            <div className="font-bold ">{question.title}</div>
            <div className="flex items-center gap-1 shrink-0">
              <StakeTag
                stake={question.stake}
                pillar={question.pillar}
                style={StakeTagStyle.GRAYED}
                disableDetailModalOpening={true}
              />
            </div>
          </div>
          {question.description && (
            <InnerHtml
              html={question.description}
              className="text-gray-500 text-sm"
            />
          )}

          {(question.choices || [])
            .toSorted((a, b) => (a.score || 0) - (b.score || 0))
            .map((choice, choiceIndex) => (
              <div
                className={clsx(
                  'border border-gray-100 rounded-lg p-2 flex items-start gap-2 shadow-sm cursor-pointer hover:border-gray-900',
                  selectedChoices.find((c) => c.id === choice.id) &&
                    'border-gray-900 '.concat(
                      getScoreColor(choice.score).bgColor,
                    ),
                )}
                key={choice.id}
                onClick={() => toggleChoice(choice.id)}
              >
                <NumberCircle
                  number={choiceIndex}
                  size={6}
                  color={getScoreColor(choice.score)}
                />
                <div className="text-sm">{choice.label}</div>
              </div>
            ))}
          {question.type === SurveyQuestionType.ShortText && (
            <textarea
              id={`text-${question.id}`}
              name={`text-${question.id}`}
              className="border border-gray-100 rounded-lg p-2 text-sm"
              placeholder="Réponse"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                setUnsavedChanges(true);
              }}
              onBlur={() =>
                updateAnswer({
                  text,
                })
              }
            />
          )}
          {question.type === SurveyQuestionType.Number && (
            <input
              type="number"
              id={`number-${question.id}`}
              name={`number-${question.id}`}
              className="border border-gray-100 rounded-lg p-2 text-sm w-44"
              placeholder="Réponse"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                setUnsavedChanges(true);
              }}
              onBlur={() =>
                updateAnswer({
                  number: Number(number),
                })
              }
            />
          )}

          {question.type !== SurveyQuestionType.Statement && (
            <textarea
              id={`comment-${question.id}`}
              name={`comment-${question.id}`}
              className="border border-gray-100 rounded-lg p-2 text-sm"
              placeholder="Commentaire"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setUnsavedChanges(true);
              }}
              onBlur={() =>
                updateAnswer({
                  comment,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export function getScoreColor(score: number | null | undefined) {
  switch (score) {
    case 0:
      return NumberCircleColor.Red;
    case 1:
      return NumberCircleColor.Yellow;
    case 2:
      return NumberCircleColor.Green;
    case 3:
      return NumberCircleColor.Blue;
    default:
      return NumberCircleColor.Gray;
  }
}
