import clsx from 'clsx';
import { GoodStepsLogoIcon } from '../../../icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum TemplatesSources {
  GoodSteps = 'GoodSteps',
  Own = 'Own',
}

export function SourceFilter({
  source,
  selectedSourceFilter,
  setSelectedSourceFilter,
}: {
  source: TemplatesSources;
  selectedSourceFilter: TemplatesSources;
  setSelectedSourceFilter: (selectedSourceFilter: TemplatesSources) => void;
}) {
  const { t } = useTranslation();

  return (
    <button
      className={clsx(
        'filter-button',
        source === selectedSourceFilter && 'selected',
      )}
      onClick={() => setSelectedSourceFilter(source)}
    >
      <span>{t(`maturity.templateModal.sourceFilter.${source}`)}</span>
      {source === TemplatesSources.GoodSteps && <GoodStepsLogoIcon />}
    </button>
  );
}
