import React from 'react';
import {
  StakeStandardTopicsPickerModal_StakeFragment,
  StandardEnum,
  useStakeStandardTopicsPickerModal_UpdateMutation,
  useStakeStandardTopicsPickerModalQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import {
  StakeStandardTopicsPickerModal_Topic_Level1Fragment,
  StakeStandardTopicsPickerModal_Topic_Level2Fragment,
  StakeStandardTopicsPickerModal_Topic_Level3Fragment,
  StakeStandardTopicsPickerModal_TopicFragment,
  useStakeStandardTopicsPickerModal_TopicsQuery,
} from '../../../../graphql/cms/generated';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { StandardTopicTag } from './StandardTopicTag';
import { Checkbox } from '../../../form/Checkbox';
import clsx from 'clsx';
import { ChevronDownIcon } from '../../../icons';
import { StandardPicker } from './StandardPicker';
import { LoaderFullscreen } from '../../../layout/Loader';

export function StakeStandardTopicsPickerModal({
  stake,
  callback,
}: {
  stake: StakeStandardTopicsPickerModal_StakeFragment;
  callback?: () => void;
}) {
  // Arbitrary choice: we default to CSRD
  const defaultStandardSlug = StandardEnum.Csrd;

  const { t, i18n } = useTranslation();
  const modal = useModal();

  const [newTopicIds, setNewTopicIds] = React.useState<string[]>([]);

  const cmsQuery = useStakeStandardTopicsPickerModal_TopicsQuery({
    variables: {
      locale: i18n.language,
      standardSlug: defaultStandardSlug,
    },
    client: cmsClient,
    fetchPolicy: 'cache-and-network',
  });

  const stakeQuery = useStakeStandardTopicsPickerModalQuery({
    variables: {
      stakeId: stake.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNewTopicIds(data.diagnosticStake?.topicIds || []);
    },
  });

  const [stakeMutation] = useStakeStandardTopicsPickerModal_UpdateMutation();

  const toggleTopicId = (id: string) => {
    const newTopicIdsCopy = newTopicIds.slice();
    const index = newTopicIdsCopy.indexOf(id);
    if (index === -1) {
      newTopicIdsCopy.push(id);
    } else {
      newTopicIdsCopy.splice(index, 1);
    }
    setNewTopicIds(newTopicIdsCopy);
  };

  const [selectedStandardSlug, setSelectedStandardSlug] = React.useState<
    string | null
  >(defaultStandardSlug);

  const initialTopicIds = stakeQuery.data?.diagnosticStake?.topicIds || [];

  const pickStandardSlug = (standardSlug: string) => {
    setSelectedStandardSlug(standardSlug);
    cmsQuery
      .refetch({
        locale: i18n.language,
        standardSlug: standardSlug,
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const save = () => {
    stakeMutation({
      variables: {
        input: {
          id: stake.id,
          topicIds: newTopicIds,
        },
      },
    })
      .then(() => {
        callback && callback();
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
        setNewTopicIds(newTopicIds);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      <StandardPicker
        defaultStandardSlug={selectedStandardSlug}
        setStandardSlug={pickStandardSlug}
      />

      <div className="space-y-4 max-h-96 overflow-scroll">
        {cmsQuery.error && (
          <MessageBox type={MessageBoxType.Error}>
            {cmsQuery.error.message}
          </MessageBox>
        )}

        {stakeQuery.error && (
          <MessageBox type={MessageBoxType.Error}>
            {stakeQuery.error.message}
          </MessageBox>
        )}

        {(cmsQuery.loading || stakeQuery.loading) && <LoaderFullscreen />}

        {!cmsQuery.loading && !stakeQuery.loading && (
          <div className="space-y-2">
            {cmsQuery.data?.topics?.map(
              (topic) =>
                topic && (
                  <TopicAndItsChildren
                    key={topic.documentId}
                    topic={topic}
                    initialTopicIds={initialTopicIds}
                    newTopicIds={newTopicIds}
                    toggleTopicId={toggleTopicId}
                  />
                ),
            )}
          </div>
        )}
      </div>

      <div className="flex items-center justify-between">
        <button className="secondary" onClick={() => modal.closeModal()}>
          {t('global:cancel')}
        </button>
        <button className="primary" onClick={save}>
          {t('global:edit')}
        </button>
      </div>
    </div>
  );
}

// We handle a limited number of levels of topics
type TopicWithChildren =
  | StakeStandardTopicsPickerModal_Topic_Level1Fragment
  | StakeStandardTopicsPickerModal_Topic_Level2Fragment
  | StakeStandardTopicsPickerModal_Topic_Level3Fragment;

const isNodeOrDescendantSelected = (
  topic: TopicWithChildren,
  selectedTopicIds: string[],
): boolean => {
  if (selectedTopicIds.includes(topic.documentId)) {
    return true;
  }
  if (topic.children) {
    return topic.children.some(
      (child) =>
        child &&
        isNodeOrDescendantSelected(
          child as TopicWithChildren,
          selectedTopicIds,
        ),
    );
  }
  return false;
};

function TopicAndItsChildren({
  topic,
  initialTopicIds,
  newTopicIds,
  toggleTopicId,
}: {
  topic: TopicWithChildren;
  initialTopicIds: string[];
  newTopicIds: string[];
  toggleTopicId: (id: string) => void;
}) {
  const hasChildren = topic.children?.length > 0;

  const isSelectedOrHasChildSelected = isNodeOrDescendantSelected(
    topic,
    initialTopicIds,
  );

  const [isExpanded, setIsExpanded] = React.useState(
    isSelectedOrHasChildSelected,
  );

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Topic
          topic={topic}
          isSelected={newTopicIds.includes(topic.documentId)}
          toggleTopicId={toggleTopicId}
        />
        {hasChildren && (
          <button
            className="unstyled"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <ChevronDownIcon
              className={clsx(
                'w-3 h-3 text-gray-500',
                isExpanded && 'rotate-180',
              )}
            />
          </button>
        )}
      </div>
      {isExpanded && hasChildren && (
        <div className="ml-4 space-y-2">
          {topic.children?.map(
            (child) =>
              child && (
                <TopicAndItsChildren
                  key={topic.documentId}
                  topic={child as TopicWithChildren}
                  initialTopicIds={initialTopicIds}
                  newTopicIds={newTopicIds}
                  toggleTopicId={toggleTopicId}
                />
              ),
          )}
        </div>
      )}
    </div>
  );
}

function Topic({
  topic,
  isSelected,
  toggleTopicId,
}: {
  topic: StakeStandardTopicsPickerModal_TopicFragment;
  isSelected: boolean;
  toggleTopicId: (id: string) => void;
}) {
  return (
    <div
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => toggleTopicId(topic.documentId)}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => toggleTopicId(topic.documentId)}
      />
      <StandardTopicTag topic={topic} />
    </div>
  );
}
