import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

export const StakesHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 py-10">
      <div className="main-content flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h2>{t('referential.header.title')}</h2>
          <p className="text-gray-500 max-w-3xl">
            {t('referential.header.description')}
          </p>
        </div>
      </div>
    </div>
  );
};
