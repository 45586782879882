import { CompanyTemplateSource } from '../../../../graphql/generated';
import clsx from 'clsx';
import { GoodStepsLogoIcon } from '../../../icons';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';

export const SourceFilter = ({
  source,
  selectedSourceFilter,
  setSelectedSourceFilter,
}: {
  source: CompanyTemplateSource;
  selectedSourceFilter: CompanyTemplateSource;
  setSelectedSourceFilter: (
    selectedSourceFilter: CompanyTemplateSource,
  ) => void;
}) => {
  return (
    <button
      className={clsx(
        'filter-button',
        source === selectedSourceFilter && 'selected',
      )}
      onClick={() => setSelectedSourceFilter(source)}
    >
      {source === CompanyTemplateSource.Goodsteps && <GoodStepsLogoIcon />}
      <span>{getSourceLabel(source)}</span>
    </button>
  );
};

const getSourceLabel = (source: CompanyTemplateSource): string => {
  const { t } = useTranslation();
  switch (source) {
    case CompanyTemplateSource.Goodsteps:
      return t(
        'project.create.chooseTemplateModal.sourceFilter.goodstepsTemplates',
      );
    case CompanyTemplateSource.OwnTemplates:
      return t('project.create.chooseTemplateModal.sourceFilter.ownTemplates');
    default:
      return '';
  }
};
