import React, { ReactNode } from 'react';

export const NavSection = ({
  label,
  children,
}: {
  label?: string;
  children: ReactNode;
}) => {
  return (
    <div className="pt-2">
      {label && <div className="uppercase text-xs mt-2 px-3 py-2">{label}</div>}
      <div className="flex flex-col space-y-0.5">{children}</div>
    </div>
  );
};
