import { useParams } from 'react-router-dom';
import { useActionQuery } from '../../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { UpdateAction } from '../../../../components/project/roadmap/UpdateAction';
import { withProjectContext } from '../../../../providers/withProjectContext';

function ActionEdit() {
  const { actionId } = useParams();

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.action) {
    return <UpdateAction action={data.action} />;
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>Une erreur est survenue lors de la récupération de l'action.</p>
      </MessageBox>
    );
  }
}

export const ActionEditScreen = withProjectContext(ActionEdit, {
  requiredCompany: true,
});
