import React from 'react';
import {
  MaturityEvaluationsListScreen_MaturityEvaluationFragment,
  SurveyStatus,
} from '../../../../graphql/generated';
import { generatePath, NavLink } from 'react-router-dom';
import stakesIllustration from '../../../../assets/images/illustrations/stakes/stakes.png';
import { OpenMaturityEvaluationTemplateModalButton } from '../OpenMaturityEvaluationTemplateModalButton';
import { StepsIndicator } from '../../../generic/StepsIndicator';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useModal } from '../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { getMaturityEvaluationStepsItems } from './GetMaturityEvaluationStepsItems';
import { MaturityEvaluationRow } from './MaturityEvaluationRow';
import { TableSkeletonLoader } from '../../../generic/loader/TableSkeletonLoader';

export const EvaluationsList = ({
  maturityEvaluations,
  loading,
}: {
  maturityEvaluations: MaturityEvaluationsListScreen_MaturityEvaluationFragment[];
  loading: boolean;
}) => {
  const projectContext = useProjectContext();
  const modal = useModal();
  const { t } = useTranslation();

  const hasSurveys = maturityEvaluations.length > 0;
  const hasPublishedSurveys = maturityEvaluations.some(
    (maturityEvaluation) =>
      maturityEvaluation.status &&
      [SurveyStatus.Ready, SurveyStatus.Sent, SurveyStatus.Closed].includes(
        maturityEvaluation.status,
      ),
  );
  const hasAnswers = maturityEvaluations.some(
    (maturityEvaluation) => (maturityEvaluation.responses?.length ?? 0) > 0,
  );

  return (
    <div className="pb-16 space-y-8">
      <div className="bg-green-50 rounded-xl p-8 space-y-4">
        <div className="flex items-center justify-between gap-8">
          <div className="space-y-4">
            <h2>
              {t('maturity.evaluation.messageBox.title', {
                companyName: projectContext?.enterprise?.name || "l'entreprise",
              })}
            </h2>
            <p className="max-w-xl">
              {t('maturity.evaluation.messageBox.description')}
            </p>
          </div>

          <img className=" w-36" src={stakesIllustration} alt="" />
        </div>
        <StepsIndicator
          steps={getMaturityEvaluationStepsItems(
            hasSurveys,
            hasPublishedSurveys,
            hasAnswers,
          )}
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between gap-8">
          <h6>{t('maturity.evaluation.title')}</h6>
          {hasAnswers && (
            <NavLink to={generatePath(AppRoutes.PublicationMaturity)}>
              <button className="primary purple small">
                {t('maturity.evaluation.maturityScore.button')}
              </button>
            </NavLink>
          )}
        </div>
        {projectContext?.isLoadingEnterprise ||
          (loading && (
            <TableSkeletonLoader
              columns={4}
              rows={5}
              randomSizes={{
                width: ['w-16', 'w-24', 'w-32', 'w-48'],
              }}
            />
          )) || (
            <table className="">
              <thead>
                <tr>
                  <th className="text-left">
                    {t('maturity.evaluation.table.name')}
                  </th>
                  <th className="text-center">
                    {t('maturity.evaluation.table.status')}
                  </th>
                  <th className="text-center">
                    {t('maturity.evaluation.table.progress')}
                  </th>
                  <th className="text-center">
                    {t('maturity.evaluation.table.lastUpdatedAt')}
                  </th>
                  <th className=""></th>
                </tr>
              </thead>
              {maturityEvaluations?.length > 0 ? (
                <tbody>
                  {maturityEvaluations.map((maturityEvaluation) => {
                    return (
                      <MaturityEvaluationRow
                        maturityEvaluation={maturityEvaluation}
                        key={maturityEvaluation.id}
                      />
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className="flex flex-col items-center justify-center gap-4 text-gray-500 p-8">
                        <p>
                          {t('maturity.evaluation.noMaturityEvaluation.title')}
                        </p>
                        <div className="flex items-center gap-2">
                          <OpenMaturityEvaluationTemplateModalButton />
                          <NavLink
                            to={generatePath(
                              AppRoutes.MaturityEvaluationImport,
                            )}
                            onClick={() => modal.closeModal()}
                          >
                            <button className="secondary purple">
                              {t('maturity.evaluation.importExcel')}
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          )}
      </div>
    </div>
  );
};

