import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import {
  GapAnalysisSummary_Topic_TreeFragment,
  SummaryDetailDisclosureRequirementsRow_DisclosureRequirementFragment,
} from '../../../../../graphql/cms/generated';
import { GapAnalysisSummary_StakeFragment } from '../../../../../graphql/generated';
import { Checkbox, CheckboxColors } from '../../../../form/Checkbox';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../icons';
import { StakeTag, StakeTagStyle } from '../../../../stake/StakeTag';

export type ChooseReportDisclosureRequirementsRow = {
  topic: GapAnalysisSummary_Topic_TreeFragment;
  stakes: GapAnalysisSummary_StakeFragment[];
  disclosureRequirements: SummaryDetailDisclosureRequirementsRow_DisclosureRequirementFragment[];
};

export type SelectedData = {
  [topicReferenceSlug: string]: {
    topicSelected: boolean;
    disclosureRequirements: string[];
  };
};

export const useChooseReportDisclosureRequirementsColumns = (
  toggleTopicSelection: (referenceSlug: string) => void,
  selectedData: SelectedData,
): Array<ColumnDef<ChooseReportDisclosureRequirementsRow>> => {
  const { t, translateProperty } = useTranslation();
  const columnHelper =
    createColumnHelper<ChooseReportDisclosureRequirementsRow>();

  return [
    columnHelper.accessor('topic', {
      cell: (data) => {
        const topic = data.getValue();
        const numberOfDRChecked =
          selectedData[topic.slug]?.disclosureRequirements.length || 0;
        const isChecked =
          (numberOfDRChecked > 0 && selectedData[topic.slug]?.topicSelected) ||
          false;
        const expandProps = {
          onClick: () => data.row.toggleExpanded(),
          style: { cursor: 'pointer', fontWeight: 'bold' },
          className: 'text-base',
        };
        const isIndeterminate =
          numberOfDRChecked > 0 &&
          numberOfDRChecked < topic.disclosure_requirements.length;
        return (
          <div className="flex items-center gap-3">
            <Checkbox
              color={CheckboxColors.Purple}
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={() => toggleTopicSelection(topic.slug)}
            />
            <div {...expandProps}>{topic.name}</div>
          </div>
        );
      },
      header: () =>
        t('gap_analysis.chooseReportTopicModal.table.header.topic.label'),
    }),
    columnHelper.accessor('stakes', {
      cell: (data) => {
        const stakes = data.getValue();
        const expandButton = (data.row.getIsExpanded() && (
          <ChevronUpIcon />
        )) || <ChevronDownIcon />;
        return (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 flex-wrap grow">
              {stakes.length > 0 &&
                stakes
                  .filter((stake) => !stake.isDisabled)
                  .map((stake) => (
                  <StakeTag
                    key={stake.id}
                    stake={stake}
                    disableDetailModalOpening={true}
                    style={StakeTagStyle.COLORIZED}
                  />
                ))}
            </div>
            <div className={'flex items-center gap-3 text-gray-500'}>
              <a href={'#'} onClick={() => data.row.toggleExpanded()}>
                {expandButton}
              </a>
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t(
            'gap_analysis.chooseReportTopicModal.table.header.relatedStakes.label',
          )}
        </span>
      ),
    }),
  ] as Array<ColumnDef<ChooseReportDisclosureRequirementsRow>>;
};

/**
 * Prepare data for the table in the ChooseReportDisclosureRequirementsModal component by filtering out topics without disclosure requirements
 * @param topics
 * @param stakes
 */
export const prepareData = (
  topics: (GapAnalysisSummary_Topic_TreeFragment | null)[],
  stakes: GapAnalysisSummary_StakeFragment[],
): ChooseReportDisclosureRequirementsRow[] => {
  const filteredTopics = topics.filter(
    (topic): topic is GapAnalysisSummary_Topic_TreeFragment =>
      topic !== null &&
      topic !== undefined &&
      topic.disclosure_requirements.length > 0,
  );

  return filteredTopics.map((topic) => {
    const topicTreeDocumentIds = [
      topic.documentId,
      ...topic.children.map((child) => child?.documentId ?? ''),
      ...topic.children
        .map((child) => child?.children.map((c) => c?.documentId ?? ''))
        .flat(),
    ];

    // A stake is relevant if it is linked to the topic directly or one of its children
    const relevantStakes = stakes.filter((stake) => {
      return stake.topicIds?.some((topicId) => {
        return topicTreeDocumentIds.includes(topicId);
      });
    });

    return {
      topic,
      stakes: relevantStakes,
      disclosureRequirements:
        topic.disclosure_requirements.filter(
          (
            dr,
          ): dr is SummaryDetailDisclosureRequirementsRow_DisclosureRequirementFragment =>
            dr !== null && dr !== undefined,
        ) || [],
    };
  })
    .sort((a, b) => (b.topic.position || 0 ) - (a.topic.position || 0))
    .map((topic) => {console.log(topic.topic.position); return topic;});
};
