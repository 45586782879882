import React from 'react';
import { SurveyQuestionType } from '../../graphql/generated';
import clsx from 'clsx';
import {
  BoxIcon,
  CategoryIcon,
  CheckboxIcon,
  EmailIcon,
  EnpsIcon,
  InfoIcon,
  MaturityIcon,
  RankingIcon,
  ScaleIcon,
  StakesIcon,
  StarIcon,
  TextIcon,
} from '../icons';

export function SurveyQuestionIcon({
  questionType,
  color = 300,
}: {
  questionType: SurveyQuestionType;
  color?: 300 | 500 | 900;
}) {
  const textColor = `text-gray-${color}`;
  const borderColor = `border-gray-${color}`;
  switch (questionType) {
    case SurveyQuestionType.MultipleChoice:
    case SurveyQuestionType.Dropdown:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-blue-100 rounded-lg',
            textColor,
            borderColor,
          )}
        >
          <div
            className={clsx(
              'border-2 rounded-md w-4 h-4 flex items-center justify-center shrink-0 text-blue-500 border-blue-500',
              textColor,
              borderColor,
            )}
          >
            <CheckboxIcon />
          </div>
        </div>
      );
    case SurveyQuestionType.Matrix:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-red-100 text-red-500 rounded-lg p-2',
            textColor,
            borderColor,
          )}
        >
          <StakesIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.MaturityEvaluation:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-green-100 text-green-500 rounded-lg p-2',
            textColor,
            borderColor,
          )}
        >
          <MaturityIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.Statement:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-yellow-100 text-yellow-500 rounded-lg p-2',
            textColor,
          )}
        >
          <InfoIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.Email:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-gray-100 text-gray-500 rounded-lg',
            textColor,
          )}
        >
          <EmailIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.Enps:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center text-xs shrink-0 bg-purple-100 text-purple-500 rounded-lg',
            textColor,
          )}
        >
          <EnpsIcon className="w-5 h-5" />
        </div>
      );
    case SurveyQuestionType.Rating:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center text-xs shrink-0 bg-purple-100 text-purple-500 rounded-lg p-2',
            textColor,
          )}
        >
          <ScaleIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.StakesRanking:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-green-100 text-green-500 rounded-lg p-2',
            textColor,
            borderColor,
          )}
        >
          <RankingIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.StakeDoubleMateriality:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center text-xs shrink-0 bg-red-100 text-red-500 rounded-lg p-2',
            textColor,
          )}
        >
          <BoxIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center text-xs shrink-0 bg-red-100 rounded-lg p-2 relative',
            textColor,
          )}
        >
          <StarIcon className="w-4 h-4 absolute -top-2 -right-2 text-red-500" />
          <BoxIcon className="w-4 h-4 text-red-500" />
        </div>
      );
    case SurveyQuestionType.Group:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-yellow-100 text-yellow-500 rounded-lg p-2',
            textColor,
          )}
        >
          <CategoryIcon className="w-4 h-4" />
        </div>
      );
    case SurveyQuestionType.ShortText:
    case SurveyQuestionType.LongText:
      return (
        <div
          className={clsx(
            'w-8 h-8 flex items-center justify-center shrink-0 bg-blue-100 rounded-lg',
            textColor,
            borderColor,
          )}
        >
          <div
            className={clsx(
              'border-2 rounded w-4 h-4 flex items-center justify-center shrink-0 text-blue-500 border-blue-500',
              textColor,
              borderColor,
            )}
          >
            <TextIcon className="w-2 h-2" />
          </div>
        </div>
      );
    case SurveyQuestionType.Number:
      return (
        <div
          className={clsx(
            'rounded-lg w-8 h-8 flex items-center justify-center shrink-0 font-title font-extrabold text-lg bg-blue-100 text-blue-500',
            textColor,
            borderColor,
          )}
        >
          #
        </div>
      );
    default:
      return null;
  }
}
