import React, { useState } from 'react';
import { useCreateNotebookButton_NotebookCreateMutation } from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PlusIcon } from '../../icons';

export function CreateNotebookButton({ companyId }: { companyId: string }) {
  const projectContext = useProjectContext();

  const [createNotebookMutation] =
    useCreateNotebookButton_NotebookCreateMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createNotebook = () => {
    setIsLoading(true);
    createNotebookMutation({
      variables: {
        input: {
          projectId: companyId,
        },
      },
    })
      .then((res) => {
        if (res.data?.notebookCreate) {
          projectContext?.updateEnterprise(companyId, false);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      onClick={createNotebook}
      className="primary small purple"
      disabled={isLoading}
    >
      {isLoading ? <Loader /> : <PlusIcon />}
      <span>Créer une liste de notes</span>
    </button>
  );
}
