import { useDeleteSubsidiaryButtonMutation } from '../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../layout/Toast';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../layout/Modal';

export function DeleteSubsidiaryButton({ companyId }: { companyId: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();

  const [deleteSubsidiaryMutation] = useDeleteSubsidiaryButtonMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const preventAccidentalDeletion = () => {
    setShowDeleteConfirmation(true);
  };

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteSubsidiaryMutation({
      variables: {
        companyId: companyId,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('project.delete.success'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
        modal.closeModal();
      });
  };

  if (showDeleteConfirmation) {
    return (
      <div className="flex items-center gap-2">
        <p className="text-sm">{t('global:confirm_delete')}</p>
        <button
          className="primary danger small"
          onClick={deleteCompany}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : t('global:yes')}
        </button>
        <button
          className="tertiary text-gray-500 small"
          onClick={() => setShowDeleteConfirmation(false)}
        >
          Non
        </button>
      </div>
    );
  }

  return (
    <button
      className="primary danger"
      disabled={isDeleting}
      onClick={preventAccidentalDeletion}
    >
      {isDeleting && <Loader />}
      <div>{t('project.delete.cta_subsidiary')}</div>
    </button>
  );
}
