import React from 'react';
import clsx from 'clsx';

export enum ProgressBarLevel {
  None,
  Low,
  Medium,
  High,
}

export function ProgressBarWithScale({
  percentageValue,
  displayLevel,
}: {
  percentageValue: number;
  displayLevel: boolean;
}) {
  const level = getLevelFromValue(percentageValue);
  const color = getLevelColor(level);

  const lastBarWidth = getLastBarWidth(percentageValue);

  return (
    <div className="flex items-center justify-center gap-5 w-full">
      <div className="w-full flex items-center justify-between gap-4">
        <div className="flex items-center shadow-sm bg-white rounded-xl h-5 w-full">
          <div className="rounded-md bg-white w-full flex items-center relative">
            {/* Gray bars */}
            <div className={clsx('absolute h-5 w-full flex')}>
              {Array.from({ length: 4 }, (v, i) => i).map((i) => {
                return (
                  <div
                    key={i}
                    className="rounded-xl h-5 border-white border-2 items-center w-1/4 bg-gray-100"
                  ></div>
                );
              })}
            </div>

            {/* Colored bars */}
            <div className={clsx('absolute h-5 w-full flex')}>
              {Array.from({ length: 4 }, (v, i) => i).map((i) => {
                // Is bar full?
                if (i < Math.floor(percentageValue / 0.25)) {
                  return (
                    <div
                      key={i}
                      className={clsx(
                        'rounded-xl h-5 border-white border-2 items-center w-1/4',
                        color,
                      )}
                    ></div>
                  );
                }
                // Is bar empty?
                if (i > Math.floor(percentageValue / 0.25)) {
                  return null;
                }
                return (
                  <div
                    key={i}
                    className={clsx(
                      'rounded-xl h-5 border-white border-2 items-center w-1/4',
                      color,
                    )}
                    style={{ width: `${lastBarWidth}%` }}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
        {displayLevel && (
          <div className="font-bold w-6 text-right text-sm">
            {getLevelValueFromPercentageValue(percentageValue).toFixed(1)}
          </div>
        )}
      </div>
    </div>
  );
}

export function getLevelFromValue(value: number): ProgressBarLevel | null {
  if (value > 0.75) {
    return ProgressBarLevel.High;
  } else if (value > 0.5) {
    return ProgressBarLevel.Medium;
  } else if (value > 0.25) {
    return ProgressBarLevel.Low;
  } else {
    return ProgressBarLevel.None;
  }
}

export function getLevelValueFromPercentageValue(value: number): number {
  // Convert from percentage to  1 -> 4 scale
  return Math.round(value * 4 * 100) / 100;
}

function getLastBarWidth(value: number): number {
  // Each bar is 25% of the total width
  // Compute the width of the last colored bar
  return (value % 0.25) * 100;
}

function getLevelColor(level: ProgressBarLevel | null): string {
  switch (level) {
    case ProgressBarLevel.High:
      return 'bg-green-300';
    case ProgressBarLevel.Medium:
      return 'bg-green2-300';
    case ProgressBarLevel.Low:
      return 'bg-yellow-300';
    case ProgressBarLevel.None:
    case null:
      return 'bg-red-300';
    default:
      return 'bg-gray-100';
  }
}
