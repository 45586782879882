import { ProgressBarWithScale } from '../generic/ProgressBarWithScale';
import React from 'react';
import { StakeMaturityProgressBar_StakeFragment } from '../../graphql/generated';

export function StakeMaturityProgressBar({
  stake,
}: {
  stake: StakeMaturityProgressBar_StakeFragment;
}) {
  const sanitizedMaturity =
    stake.maturity?.score !== null &&
    stake.maturity?.score !== undefined &&
    stake.maturity.score >= 0
      ? stake.maturity.score
      : null;

  if (
    sanitizedMaturity !== null &&
    sanitizedMaturity !== undefined &&
    sanitizedMaturity >= 0
  ) {
    return (
      <ProgressBarWithScale
        percentageValue={sanitizedMaturity / 4}
        displayLevel={true}
      />
    );
  }
  return <div>-</div>;
}
