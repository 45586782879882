import React from 'react';
import {
  QualitativeQuestionAnswerThemesTags_AnswerThemeFragment,
  QualitativeQuestionAnswerThemesTags_QuestionFragment,
  QuestionAnswerThemesTags_SurveyQuestionFragment,
  SurveyQuestionType,
} from '../../../graphql/generated';
import AnalysisThemeTag from './AnalysisThemeTag';
import { useModal } from '../../layout/Modal';
import { AnalysisThemeModal } from './AnalysisThemeModal';

export function QuestionAnswerThemesTags({
  question,
}: {
  question: QuestionAnswerThemesTags_SurveyQuestionFragment;
}) {
  const isQualitativeQuestion = [
    SurveyQuestionType.LongText,
    SurveyQuestionType.ShortText,
    SurveyQuestionType.Email,
  ].includes(question.type);

  if (isQualitativeQuestion) {
    // Qualitative:
    // - themes are not editable
    // - we must display stats about the number of respondents who have answered each theme in this question
    return <QualitativeQuestionAnswerThemesTags question={question} />;
  } else {
    // Quantitative: no theme
    return null;
  }
}

function QualitativeQuestionAnswerThemesTags({
  question,
}: {
  question: QualitativeQuestionAnswerThemesTags_QuestionFragment;
}) {
  const modal = useModal();

  // FIXME: get respondents count
  // const respondentsCount = 0;

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {question.themes && question.themes.length > 0 ? (
        question.themes
          // Group by theme name and answerId
          .reduce(
            (acc, theme) => {
              const existingTheme = acc.find(
                (t) => t.name === theme.analysisTheme.name,
              );
              if (existingTheme) {
                const existingThemeGroupedByRespondentId =
                  existingTheme.themesGroupedByAnswerId.find(
                    (t) => t.answerId === theme.answer?.id,
                  );
                if (existingThemeGroupedByRespondentId) {
                  existingThemeGroupedByRespondentId.themes.push(theme);
                } else {
                  existingTheme.themesGroupedByAnswerId.push({
                    answerId: theme.answer?.id || '',
                    themes: [theme],
                  });
                }
              } else {
                acc.push({
                  name: theme.analysisTheme.name,
                  themesGroupedByAnswerId: [
                    {
                      answerId: theme.answer?.id || '',
                      themes: [theme],
                    },
                  ],
                });
              }
              return acc;
            },
            [] as {
              name: string;
              themesGroupedByAnswerId: {
                answerId: string;
                themes: QualitativeQuestionAnswerThemesTags_AnswerThemeFragment[];
              }[];
            }[],
          )
          // Sort
          .toSorted((a, b) => a.name.localeCompare(b.name))

          // Render
          .map((theme) => (
            <div
              key={theme.themesGroupedByAnswerId[0].themes[0].id}
              className="shrink-0"
              onClick={() =>
                modal.openModalWithComponent(
                  <AnalysisThemeModal
                    analysisThemeId={
                      theme.themesGroupedByAnswerId[0].themes[0].analysisTheme
                        .id
                    }
                  />,
                  'Thème',
                  true,
                  true,
                  'w-8/12',
                )
              }
            >
              <AnalysisThemeTag
                analysisTheme={
                  theme.themesGroupedByAnswerId[0].themes[0].analysisTheme
                }
                //FIXME: display respondents count
                // label={
                //   respondentsCount > 0
                //     ? Math.round(
                //         (100 * theme.themesGroupedByAnswerId.length) /
                //           respondentsCount,
                //       )
                //         .toString()
                //         .concat('%')
                //     : null
                // }
              />
            </div>
          ))
      ) : (
        <div className="text-gray-400">-</div>
      )}
    </div>
  );
}
