import {
  StakeTag_PillarFragment,
  StakeTag_StakeFragment,
} from '../../graphql/generated';
import React from 'react';
import clsx from 'clsx';
import { PillarIcon } from './PillarIcon';
import { getPillarColor } from '../../services/ReferentialService';
import { useModal } from '../layout/Modal';
import {
  openStakeDetailModal,
  STAKE_DETAILS_TABS,
} from './detail/StakeDetailModal';
import { useTranslation } from '@hooks/useTranslation';

export enum StakeTagStyle {
  COLORIZED = 'COLORIZED',
  GRAYED = 'GRAYED',
}

export function StakeTag({
  stake,
  pillar,
  children,
  disableDetailModalOpening,
  style = StakeTagStyle.COLORIZED,
}: {
  stake?: StakeTag_StakeFragment | null | undefined;
  pillar?: StakeTag_PillarFragment | null | undefined;
  children?: React.ReactNode;
  disableDetailModalOpening?: boolean;
  style?: StakeTagStyle;
}) {
  const { translateProperty } = useTranslation();
  const modal = useModal();
  const openStakeModal = () => {
    if (disableDetailModalOpening || !stake) return;
    openStakeDetailModal(modal, stake, STAKE_DETAILS_TABS.STAKE_OVERVIEW);
  };

  if (!stake && !pillar) return null;

  return (
    <div
      key={stake?.id || pillar?.id || ''}
      className={clsx(
        'font-semibold rounded-3xl py-1 px-2 flex items-center gap-2 shrink-0 w-fit',
        style === StakeTagStyle.COLORIZED &&
          'text-sm border-2 border-gray-900 py-1 px-2 ',
        style === StakeTagStyle.GRAYED &&
          'text-xs border bg-gray-50 border-gray-100 text-gray-500 p-1',
        style === StakeTagStyle.COLORIZED &&
          getPillarColor(stake?.pillar || pillar),
        !disableDetailModalOpening && 'cursor-pointer',
      )}
      onClick={openStakeModal}
    >
      <PillarIcon pillar={stake?.pillar || pillar} />
      {pillar && !stake && (
        <div className="flex-wrap" title={translateProperty(pillar, 'name')}>
          {translateProperty(pillar, 'name')}
        </div>
      )}
      {stake && (
        <div className="flex-wrap" title={translateProperty(stake, 'name')}>
          {translateProperty(stake, 'name')}
        </div>
      )}
      {children}
    </div>
  );
}
