import React from 'react';
import {
  ScoringChart_AnswerFragment,
  ScoringChart_QuestionFragment,
} from '../../../../graphql/generated';
import { VerticalScoringChart } from './VerticalScoringChart';

export function ScoringChart({
  question,
  answers,
  isThumbnail,
}: {
  question: ScoringChart_QuestionFragment;
  answers: ScoringChart_AnswerFragment[];
  isThumbnail?: boolean;
}) {
  // Build an array containing the count of all votes for each score
  const votes = getVotes(question, answers);
  return (
    <div className="p-4 rounded-2xl shadow-sm bg-white relative">
      <VerticalScoringChart
        scores={votes}
        title={question.title}
        isThumbnail={isThumbnail}
      />
    </div>
  );
}

function getVotes(
  question: ScoringChart_QuestionFragment,
  answers: ScoringChart_AnswerFragment[],
): number[] {
  return (answers || [])
    .flatMap((answer) => answer?.number)
    .reduce(
      (acc: number[], score) => {
        const index = (score || 0) - 1;
        acc[index] = acc[index] ? acc[index] + 1 : 1;
        return acc;
      },
      Array(question.valueMax).fill(0) as number[],
    );
}
