import {
  ChangeSurveyStatusButtons_SurveyFragment,
  SurveyStatus,
  useChangeSurveyStatusButtonsMutation,
} from '../../../graphql/generated';
import React, { Fragment, useState } from 'react';
import { useToast } from '../../layout/Toast';
import { Popover, Transition } from '@headlessui/react';
import { CheckIcon, SettingsIcon } from '../../icons';

export function ChangeSurveyStatusButtons({
  survey,
}: {
  survey: ChangeSurveyStatusButtons_SurveyFragment;
}) {
  const toast = useToast();
  const [surveyStatus, setSurveyStatus] = useState(survey.status);

  const isSurveyPublished =
    surveyStatus === SurveyStatus.Ready || surveyStatus === SurveyStatus.Closed;
  const isSurveyClosed = surveyStatus === SurveyStatus.Closed;

  const [updateSurveyMutation] = useChangeSurveyStatusButtonsMutation();

  const publishSurvey = () => {
    const newStatus = isSurveyPublished
      ? SurveyStatus.Created
      : SurveyStatus.Ready;
    setSurveyStatus(newStatus);
    updateSurveyMutation({
      variables: {
        input: {
          id: survey.id,
          status: newStatus,
        },
      },
    })
      .then(() => {
        if (newStatus === SurveyStatus.Ready) {
          toast.openToastWithMessage('Questionnaire publié');
        } else {
          toast.openToastWithMessage('Questionnaire dé-publié');
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="flex items-center gap-2">
      {!isSurveyPublished && (
        <button className="primary small purple" onClick={publishSurvey}>
          <div>Publier</div>
        </button>
      )}
      {isSurveyPublished && !isSurveyClosed && (
        <div className="tag green">
          <CheckIcon />
          <div>Questionnaire publié</div>
        </div>
      )}
      {isSurveyClosed && <div className="tag gray">Questionnaire fermé</div>}
      {isSurveyPublished && (
        <SurveyStatusMenu
          survey={survey}
          surveyStatus={surveyStatus}
          setSurveyStatus={setSurveyStatus}
        />
      )}
    </div>
  );
}

function SurveyStatusMenu({
  survey,
  surveyStatus,
  setSurveyStatus,
}: {
  survey: ChangeSurveyStatusButtons_SurveyFragment;
  surveyStatus: SurveyStatus;
  setSurveyStatus: (status: SurveyStatus) => void;
}) {
  const toast = useToast();

  const [updateSurveyMutation] = useChangeSurveyStatusButtonsMutation();

  const closeSurvey = () => {
    setSurveyStatus(SurveyStatus.Closed);
    updateSurveyMutation({
      variables: {
        input: {
          id: survey.id,
          status: SurveyStatus.Closed,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Questionnaire fermé');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const reopenSurvey = () => {
    const newStatus = SurveyStatus.Ready;
    setSurveyStatus(newStatus);
    updateSurveyMutation({
      variables: {
        input: {
          id: survey.id,
          status: newStatus,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Questionnaire ré-ouvert');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const unpublishSurvey = () => {
    const newStatus = SurveyStatus.Created;
    setSurveyStatus(newStatus);
    updateSurveyMutation({
      variables: {
        input: {
          id: survey.id,
          status: newStatus,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Questionnaire dé-publié');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="tertiary small">
            <SettingsIcon />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-50 absolute bg-white top-0 right-0 transform translate-y-10 w-52">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex flex-col items-stretch divide-y divide-gray-100">
                  {surveyStatus !== SurveyStatus.Closed && (
                    <div className="p-1">
                      <button
                        className="contextual-menu-item"
                        onClick={() => {
                          closeSurvey();
                          close();
                        }}
                      >
                        <span>Fermer le questionnaire</span>
                      </button>
                    </div>
                  )}
                  {surveyStatus !== SurveyStatus.Closed && (
                    <div className="p-1">
                      <button
                        className="contextual-menu-item"
                        onClick={() => {
                          unpublishSurvey();
                          close();
                        }}
                      >
                        <span>Dé-publier</span>
                      </button>
                    </div>
                  )}
                  {surveyStatus === SurveyStatus.Closed && (
                    <div className="p-1">
                      <button
                        className="contextual-menu-item"
                        onClick={() => {
                          reopenSurvey();
                          close();
                        }}
                      >
                        <span>Ré-ouvrir le questionnaire</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
