import { ComplexityLevel } from '../../graphql/generated';
import { NumberCircle, NumberCircleColor } from '../generic/NumberCircle';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';
import { getTooltipUniqueId } from '../../services/UtilsService';

export function ComplexityBadge({
  complexityLevel,
}: {
  complexityLevel: ComplexityLevel;
}) {
  const tooltipId = getTooltipUniqueId('ComplexityBadge');
  return (
    <div data-tooltip-id={tooltipId} className="cursor-help">
      <ComplexityBadgeTooltip
        complexityLevel={complexityLevel}
        id={tooltipId}
      />
      <NumberCircle
        number={complexityLevelToNumber(complexityLevel)}
        color={colorFromComplexityLevel(complexityLevel)}
        size={8}
      />
    </div>
  );
}

export function ComplexityBadgeTooltip({
  complexityLevel,
  id,
}: {
  complexityLevel: ComplexityLevel;
  id: string;
}) {
  return (
    <Tooltip id={id} className="tooltip">
      <p className="title-h6 mb-1">Complexité</p>
      <ol className="flex flex-col gap-1">
        <ComplexityItem
          index={1}
          complexityLevel={ComplexityLevel.Alone}
          isActive={complexityLevel === ComplexityLevel.Alone}
        />
        <ComplexityItem
          index={2}
          complexityLevel={ComplexityLevel.Max_10Collaborators}
          isActive={complexityLevel === ComplexityLevel.Max_10Collaborators}
        />
        <ComplexityItem
          index={3}
          complexityLevel={ComplexityLevel.TeamOrExternalHelp}
          isActive={complexityLevel === ComplexityLevel.TeamOrExternalHelp}
        />
        <ComplexityItem
          index={4}
          complexityLevel={ComplexityLevel.WholeCompany}
          isActive={complexityLevel === ComplexityLevel.WholeCompany}
        />
      </ol>
    </Tooltip>
  );
}

function ComplexityItem({
  complexityLevel,
  index,
  isActive,
}: {
  complexityLevel: ComplexityLevel;
  index: number;
  isActive: boolean;
}) {
  return (
    <li className={clsx('flex items-center gap-1', isActive || 'opacity-70')}>
      <NumberCircle
        number={index}
        size={8}
        color={
          isActive
            ? colorFromComplexityLevel(complexityLevel)
            : NumberCircleColor.Gray
        }
      />
      <span
        className={clsx('max-w-sm', isActive ? 'font-bold' : 'font-normal')}
      >
        {labelFromComplexityLevel(complexityLevel)}
      </span>
    </li>
  );
}

function labelFromComplexityLevel(complexityLevel: ComplexityLevel): string {
  switch (complexityLevel) {
    case ComplexityLevel.Alone:
      return '1 personne seule peut mettre en place l’action rapidement et sans coût financier.';
    case ComplexityLevel.Max_10Collaborators:
      return '1 référent·e unique peut mettre l’action en place, avec l’implication d’une dizaine de collaborateur·rices max, durant 2 heures max.';
    case ComplexityLevel.TeamOrExternalHelp:
      return '1 référent·e et/ou une équipe de travail peut mener l’action, avec l’implication d’une dizaine de collaborateur·rices min. durant plus de 2 heures et/ou un accompagnement externe. Divers métiers de l’entreprise doivent se coordonner.';
    case ComplexityLevel.WholeCompany:
      return 'Toute l’entreprise est concernée, requiert une conduite du changement (ex : formation, gestion de projet, gouvernance, co-construction, etc.).';
  }
}

export function titleFromComplexityLevel(
  complexityLevel: ComplexityLevel,
): string {
  switch (complexityLevel) {
    case ComplexityLevel.Alone:
      return '1 personne seule';
    case ComplexityLevel.Max_10Collaborators:
      return '1 référent·e, aidé';
    case ComplexityLevel.TeamOrExternalHelp:
      return '1 équipe de travail ou sous-traité';
    case ComplexityLevel.WholeCompany:
      return 'Toute l’entreprise';
  }
}

export function complexityLevelToNumber(
  complexityLevel: ComplexityLevel,
): number {
  switch (complexityLevel) {
    case ComplexityLevel.Alone:
      return 1;
    case ComplexityLevel.Max_10Collaborators:
      return 2;
    case ComplexityLevel.TeamOrExternalHelp:
      return 3;
    case ComplexityLevel.WholeCompany:
      return 4;
  }
}

export function colorFromComplexityLevel(
  complexityLevel: ComplexityLevel,
): NumberCircleColor {
  switch (complexityLevel) {
    case ComplexityLevel.Alone:
      return NumberCircleColor.Gray;
    case ComplexityLevel.Max_10Collaborators:
      return NumberCircleColor.Yellow;
    case ComplexityLevel.TeamOrExternalHelp:
      return NumberCircleColor.Purple;
    case ComplexityLevel.WholeCompany:
      return NumberCircleColor.Red;
  }
}
