import React, { DetailedHTMLProps, ReactNode, TdHTMLAttributes } from 'react';
import clsx from 'clsx';

export const Td = ({
  children,
  className,
  ...tdProps
}: { children: ReactNode } & DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>) => (
  <td className={clsx('border-r border-gray-100', className)} {...tdProps}>
    {children}
  </td>
);
