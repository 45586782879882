import React, { useMemo } from 'react';
import countryList from 'react-select-country-list';
import StringDropdown from './StringDropdown';
import { useTranslation } from '@hooks/useTranslation';

export const CountryDropdown = ({
  selectedCountry,
  chooseItem,
}: {
  selectedCountry?: string | null;
  chooseItem: (itemId: string | null) => void;
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);

  const countryItems = options
    .map((item) => ({
      id: item.value,
      label: t(`enum:country.${item.value}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedCountryItem =
    countryItems.find((item) => item.id === selectedCountry) || null;

  return (
    <StringDropdown
      filterable={true}
      allowEmpty={true}
      availableItems={countryItems}
      item={selectedCountryItem}
      setItem={(item) => chooseItem(item ? item.id : null)}
    />
  );
};
