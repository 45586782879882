import React from 'react';
import { InnerHtml } from '../../generic/InnerHtml';
import { useStakeOverviewDetailQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';

export function StakeOverviewDetail({ stakeId }: { stakeId: string }) {
  const { translateProperty } = useTranslation();
  const query = useStakeOverviewDetailQuery({
    variables: {
      id: stakeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.diagnosticStake) {
    return null;
  }

  const stake = query.data?.diagnosticStake;
  const description = translateProperty(stake, 'description');
  const financialMaterialityDescription = translateProperty(
    stake,
    'financialMaterialityDescription',
  );
  const impactMaterialityDescription = translateProperty(
    stake,
    'impactMaterialityDescription',
  );

  // No data, empty placeholder
  if (
    !description &&
    !financialMaterialityDescription &&
    !impactMaterialityDescription
  ) {
    return (
      <div className="italic text-gray-500 text-center py-4 text-sm">
        Retrouvez dans chaque onglet les données liées à cet enjeu
      </div>
    );
  }

  return (
    <div className="p-4 divide-y divide-gray-100 space-y-8">
      {description && (
        <div className="pt-8">
          <h6>Description</h6>
          <InnerHtml
            html={description}
            className="font-title font-extralight text-2xl"
          />
        </div>
      )}
      {financialMaterialityDescription && (
        <div className="pt-8">
          <h6>Matérialité financière</h6>
          <InnerHtml
            html={financialMaterialityDescription}
            className="font-title font-extralight text-2xl"
          />
        </div>
      )}
      {impactMaterialityDescription && (
        <div className="pt-8">
          <h6>Matérialité d&apos;impact</h6>
          <InnerHtml
            html={impactMaterialityDescription}
            className="font-title font-extralight text-2xl"
          />
        </div>
      )}
    </div>
  );
}
