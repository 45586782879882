import React from 'react';
import { NavLink } from 'react-router-dom';
import { CompanyAbstractFieldsFragment } from 'graphql/generated';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { useModal } from '../layout/Modal';
import { RegisterNewProjectModal } from './RegisterNewProjectModal';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { CompanyLogo } from './project/CompanyLogo';
import { getFrDateFromISOString } from '../../services/DateService';
import { ProjectContextualMenu } from './project/contextualMenu/ProjectContextualMenu';
import { PlusIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

import { AppRoutes } from '../../screens/AppRoutes';

export function ProjectList() {
  const currentUser = useCurrentUser();
  const contributesToCompany =
    currentUser?.contributions
      ?.map((contribution) => contribution.contributesTo)
      .filter((company) => !company.archivedDate && !company.parent) || [];

  const modal = useModal();
  const { t } = useTranslation();

  const openRegisterDiagnosticModal = () => {
    modal.openModalWithComponent(
      <RegisterNewProjectModal />,
      t('home.projects.create'),
    );
  };

  return (
    <div className="w-full">
      <h2 className="flex items-center gap-2 justify-between mb-4">
        <div className="flex gap-2 items-center">
          <h4>{t('home.projects.title')}</h4>
          <span className="tag purple">{contributesToCompany.length}</span>
        </div>
        <button
          className="primary small purple shrink-0"
          onClick={openRegisterDiagnosticModal}
        >
          <PlusIcon />
          <span>{t('home.projects.create')}</span>
        </button>
      </h2>
      {contributesToCompany.length > 0 ? (
        <CompaniesList companies={contributesToCompany} />
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          <p>{t('home.projects.empty.messageBox')}</p>
        </MessageBox>
      )}
    </div>
  );
}

function CompaniesList({
  companies,
}: {
  companies: Array<CompanyAbstractFieldsFragment>;
}) {
  const projects = companies
    // Only enterprises, no template
    .filter((company) => !company.isTemplate)
    // Sort by creation date
    .toSorted((a, b) => {
      if (a.createdAt && b.createdAt) {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      return 0;
    });
  const { t } = useTranslation();

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="w-8/12">{t('home.projects.table.project')}</th>
          <th className="w-2/12"></th>
        </tr>
      </thead>
      <tbody>
        {projects.map((company) => (
          <CompanyItem company={company} key={company.id} />
        ))}
      </tbody>
    </table>
  );
}

function CompanyItem({ company }: { company: CompanyAbstractFieldsFragment }) {
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const chooseItem = () => {
    if (projectContext) {
      projectContext.updateEnterprise(company.id, true);
    }
  };

  return (
    <tr key={company.id}>
      <td className="">
        <NavLink
          to={AppRoutes.Project}
          onClick={chooseItem}
          className="flex items-center gap-2"
        >
          <CompanyLogo companyId={company.id} size={'small'} />
          <div>
            <div className="font-bold">{company.name}</div>
            <div className="text-xs text-gray-500 italic font-light">
              {t('home.projects.table.createdAt', {
                createdAt: getFrDateFromISOString(company.createdAt),
                author: `${company.createdBy?.firstName} ${company.createdBy?.lastName}`,
                context: company.createdBy?.firstName ? 'by' : null,
              })}
            </div>
          </div>
        </NavLink>
      </td>
      <td>
        <div className="flex justify-end w-full">
          <ProjectContextualMenu company={company} />
        </div>
      </td>
    </tr>
  );
}
