import React from 'react';
import { useModal } from '../../layout/Modal';
import { MaturityEvaluationTemplateGalleryModal } from './MaturityEvaluationTemplateGalleryModal/MaturityEvaluationTemplateGalleryModal';
import { PlusIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function OpenMaturityEvaluationTemplateModalButton() {
  const modal = useModal();
  const { t } = useTranslation();

  return (
    <button
      onClick={() => {
        modal.openModalWithComponent(
          <MaturityEvaluationTemplateGalleryModal />,
          null,
          true,
          false,
          'w-10/12',
        );
      }}
      className="primary purple"
    >
      <PlusIcon className="w-4 h-4" />
      <span>{t('maturity.evaluation.noMaturityEvaluation.createButton')}</span>
    </button>
  );
}
