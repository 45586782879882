import {
  ChecklistDocument,
  CreateTaskButton_TaskGroupFragment,
  useCreateTaskButtonMutation,
} from '../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../layout/Modal';
import { UpdateTaskModal } from './UpdateTaskModal';
import { Loader } from '../generic/Loader';
import { PlusIcon } from '../icons';

export function CreateTaskButton({
  taskGroup,
}: {
  taskGroup: CreateTaskButton_TaskGroupFragment;
}) {
  const modal = useModal();
  const [createTaskMutation] = useCreateTaskButtonMutation({
    refetchQueries: [
      {
        query: ChecklistDocument,
        variables: {
          id: taskGroup.checklist.id,
        },
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(false);

  const createTask = () => {
    setIsLoading(true);
    createTaskMutation({
      variables: {
        input: {
          title: 'Nouvelle tâche',
          taskGroup: {
            id: taskGroup.id,
          },
        },
      },
    })
      .then((res) => {
        if (res.data?.taskCreate) {
          modal.openModalWithComponent(
            <UpdateTaskModal task={res.data?.taskCreate} />,
            'Ajouter une tâche',
          );
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button onClick={() => createTask()} className="tertiary bg-transparent">
      {isLoading ? <Loader /> : <PlusIcon />}
    </button>
  );
}
