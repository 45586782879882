import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  color?: 'purple' | 'blue' | 'green' | 'yellow' | 'red' | 'gray';
  small?: boolean;
}

export const Tag = ({ children, color = 'purple', small = false }: Props) => {
  return (
    <span className={clsx('tag', color, small && 'small')}>{children}</span>
  );
};
