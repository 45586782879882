import React from 'react';
import {
  SurveyQuestionType,
  SurveyStatus,
  useManageStakeholderListQuery,
} from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import { useRightDrawer } from '../../layout/RightDrawer';
import { SurveyEmailDrawer } from '../SurveyEmailDrawer';
import QRCode from 'react-qr-code';
import { getPublicLinkToSurvey } from '../../../services/SurveyService';
import { LoaderFullscreen } from '../../layout/Loader';
import { ExportableToImage } from '../../generic/ExportableToImage';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import clsx from 'clsx';
import { CopyableUrlInput } from '../../generic/CopyableUrlInput';
import { RecipientsForm } from './RecipientsForm';
import { EmailIcon, ExportIcon, QRCodeIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

export function ManageStakeholderList({ surveyId }: { surveyId: string }) {
  const { openDrawerWithComponent } = useRightDrawer();
  const modal = useModal();
  const { t } = useTranslation();

  const query = useManageStakeholderListQuery({
    variables: { id: surveyId },
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (!query.data) {
    return null;
  }

  const survey = query.data?.survey;

  const surveyHasIdentityQuestion = (survey.questions || [])
    .flatMap((question) =>
      question.type === SurveyQuestionType.Group ? question.children : question,
    )
    .some((question) => question?.type === SurveyQuestionType.Email);

  return (
    <div className="main-content py-8 w-full space-y-4">
      <div className="border border-gray-100 rounded-xl p-8 space-y-4 shadow-sm">
        <div className="flex items-center gap-4">
          <div className="bg-yellow-50 rounded-lg p-6">
            <ExportIcon className="w-6 h-6 text-yellow-900" />
          </div>
          <div className="space-y-2">
            <h4>{t('survey.stakeholderLists.shareLink.title')}</h4>
            <p className="text-gray-500 text-sm">
              {t('survey.stakeholderLists.shareLink.description')}
            </p>
          </div>
        </div>

        {survey.status === SurveyStatus.Created && (
          <MessageBox type={MessageBoxType.Info}>
            {t('survey.stakeholderLists.publishSurvey.message')}
          </MessageBox>
        )}

        <div
          className={clsx(
            'flex justify-between gap-4 items-center',
            survey.status === SurveyStatus.Created && 'opacity-40',
          )}
        >
          <CopyableUrlInput urlToCopy={getPublicLinkToSurvey(survey.id)} />
          <div className="flex items-center gap-2">
            <button
              className="secondary shrink-0 small purple"
              onClick={(event) => {
                event.preventDefault();
                openDrawerWithComponent(<SurveyEmailDrawer survey={survey} />);
              }}
            >
              <EmailIcon />
              <span>
                {t('survey.stakeholderLists.openEmailTemplate.button')}
              </span>
            </button>
            <button
              className="secondary shrink-0 small purple"
              onClick={() =>
                modal.openModalWithComponent(
                  <ExportableToImage name="QRCode">
                    <div className="flex items-center justify-center w-full p-8">
                      <QRCode value={getPublicLinkToSurvey(survey.id)} />
                    </div>
                  </ExportableToImage>,
                  `QRCode`,
                )
              }
            >
              <QRCodeIcon />
              <span>{t('survey.stakeholderLists.generateQRCode.button')}</span>
            </button>
          </div>
        </div>
      </div>

      <div className="border border-gray-100 rounded-xl p-8 space-y-4 shadow-sm">
        <div className="flex items-center gap-4">
          <div className="bg-purple-50 rounded-lg p-6">
            <EmailIcon className="w-6 h-6 text-purple-900" />
          </div>
          <div className="space-y-2">
            <h4 className="flex items-center gap-2">
              <span>{t('survey.stakeholderLists.enterContacts.title')}</span>
              <span className="tag purple">
                {t('survey.stakeholderLists.enterContacts.recommended')}
              </span>
            </h4>
            <p className="text-gray-500 text-sm">
              {t('survey.stakeholderLists.enterContacts.description')}
            </p>
          </div>
        </div>
        {!surveyHasIdentityQuestion && (
          <MessageBox type={MessageBoxType.Error}>
            {t('survey.stakeholderLists.noIdentityQuestion.message')}
          </MessageBox>
        )}
        <MessageBox type={MessageBoxType.Info}>
          {t('survey.stakeholderLists.noEmailSending.message')}
        </MessageBox>
        <RecipientsForm survey={survey} />
      </div>
    </div>
  );
}
