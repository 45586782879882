import { useTranslation } from '@hooks/useTranslation';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../nav/Tabs';
import React, { useState } from 'react';
import { SURVEY_TABS } from '../../project/survey/Survey';
import { SurveyResponses } from './responsesList/SurveyResponses';
import { useSurveyResponsesQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { SurveyWaitingStakeholder } from './waitingStakeholderList/SurveyWaitingStakeholder';
import { RespondentsHeader } from './RespondentsHeader';

export const SurveyTrackingResponsesTab = ({
  surveyId,
}: {
  surveyId: string;
}) => {
  const [responsesCounter, setResponsesCounter] = useState<number>();
  const [waitingStakeholderCounter, setWaitingStakeholderCounter] =
    useState<number>();

  const query = useSurveyResponsesQuery({
    variables: {
      surveyId: surveyId,
    },
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();
  let responsesTabLabel = t('survey.tabs.trackingResponses');
  if (responsesCounter) {
    responsesTabLabel += ` (${responsesCounter})`;
  }
  let waitingStakeholderTabLabel = t('survey.tabs.trackingWaitingStakeholders');
  if (waitingStakeholderCounter) {
    waitingStakeholderTabLabel += ` (${waitingStakeholderCounter})`;
  }
  const tabs: TabItem[] = [
    {
      key: SURVEY_TABS.SURVEY_TRACKING_RESPONSES,
      name: responsesTabLabel,
    },
    {
      key: SURVEY_TABS.SURVEY_TRACKING_WAITING_STAKEHOLDER,
      name: waitingStakeholderTabLabel,
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  if (query.loading) {
    return <LoaderFullscreen />;
  }
  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return null;
  }
  const survey = query.data?.survey;

  let content;
  switch (currentTab.key) {
    case SURVEY_TABS.SURVEY_TRACKING_WAITING_STAKEHOLDER:
      content = (
        <div className="w-full">
          <div className="border-b border-gray-100 px-8">
            <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
          </div>
          <SurveyWaitingStakeholder
            {...{ setWaitingStakeholderCounter, survey }}
          />
        </div>
      );
      break;
    case SURVEY_TABS.SURVEY_TRACKING_RESPONSES:
    default:
      content = (
        <div className="w-full">
          <div className="border-b border-gray-100 px-8">
            <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
          </div>
          <div className="w-full p-8">
            <SurveyResponses {...{ setResponsesCounter, survey }} />
          </div>
        </div>
      );
  }

  return (
    <>
      <RespondentsHeader survey={survey} />
      {content}
    </>
  );
};
