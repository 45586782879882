import {
  MaturityEvaluationsListScreen_MaturityEvaluationFragment,
  SurveyStatus,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

export const MaturityEvaluationStatusTag = ({
  maturityEvaluation,
  completionRate,
}: {
  maturityEvaluation: MaturityEvaluationsListScreen_MaturityEvaluationFragment;
  completionRate: number | null | undefined;
}) => {
  const { t } = useTranslation();
  if (maturityEvaluation.isTemplate) {
    return (
      <div className="tag purple">
        {t('maturity.evaluation.surveyStatus.tag.template')}
      </div>
    );
  }

  if (completionRate && completionRate >= 1) {
    return (
      <div className="tag green">
        {t('maturity.evaluation.surveyStatus.tag.completed')}
      </div>
    );
  }

  if (completionRate && completionRate >= 0) {
    return (
      <div className="tag blue">
        {t('maturity.evaluation.surveyStatus.tag.started')}
      </div>
    );
  }

  switch (maturityEvaluation.status) {
    case SurveyStatus.Created:
      return (
        <div className="tag gray">
          {t('maturity.evaluation.surveyStatus.tag.unassigned')}
        </div>
      );
    case SurveyStatus.Ready:
    case SurveyStatus.Sent:
      return (
        <div className="tag green">
          {t('maturity.evaluation.surveyStatus.tag.published')}
        </div>
      );
    case SurveyStatus.Closed:
      return (
        <div className="tag green">
          {t('maturity.evaluation.surveyStatus.tag.closed')}
        </div>
      );
    default:
      return null;
  }
};
