import { ActionCard_ActionFragment } from '../../../graphql/generated';
import { useRightDrawer } from '../../layout/RightDrawer';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { ActionIllustrationIcon } from './ActionIllustrationIcon';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { getActionLink } from '../../../screens/ScreensRouter';
import { ChevronRightIcon } from '../../icons';

export function ActionCard({ action }: { action: ActionCard_ActionFragment }) {
  const drawer = useRightDrawer();
  const projectContext = useProjectContext();
  return (
    <div className="flex flex-col items-center gap-4 w-full hover:border-gray-900 cursor-pointer rounded-xl bg-white border-2 border-transparent">
      <NavLink
        to={getActionLink(projectContext?.enterprise !== undefined, action.id)}
        className="w-full"
        onClick={() => drawer.closeDrawer()}
      >
        <div className="flex flex-col items-center w-full grow">
          <div className="flex items-center gap-2 w-full grow p-1 pr-2">
            <ActionIllustrationIcon action={action} />
            <div className="text-sm font-semibold grow">{action.title}</div>
            <ChevronRightIcon className="shrink-0" />
          </div>
        </div>
      </NavLink>
    </div>
  );
}
