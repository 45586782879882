import React from 'react';
import {
  MaterialityTypes,
  PriorityLevel,
  SimpleMaterialityQuestionsArray_StakeFragment,
  useSimpleMaterialityQuestionsArray_QuestionQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  MatrixArray,
  MatrixRowType,
  stakeAnswersToMatrixRows,
} from '../../survey/questionsDataviz/MatrixArray';

export function SimpleMaterialityQuestionsArray({
  questionIds,
  stakes,
}: {
  questionIds: string[];
  stakes: SimpleMaterialityQuestionsArray_StakeFragment[];
}) {
  // Fetch answers
  const questionsQuery = useSimpleMaterialityQuestionsArray_QuestionQuery({
    variables: {
      ids: questionIds,
    },
    fetchPolicy: 'cache-and-network',
  });

  // Consolidate all answers from all questions
  const answers =
    questionsQuery.data?.surveyQuestions
      .flatMap((question) => question.answers || [])
      // Keep only provided stakes
      .map((answer) => {
        return {
          ...answer,
          stakes: answer.stakes?.filter((stake) =>
            stakes.some((s) => s.id === stake.stakeId),
          ),
        };
      }) || [];

  // Columns to display
  const columns: Array<PriorityLevel> = [
    PriorityLevel.None,
    PriorityLevel.Low,
    PriorityLevel.Medium,
    PriorityLevel.High,
  ];

  const matrix: MatrixRowType[] = stakeAnswersToMatrixRows(
    answers || [],
    stakes,
    columns,
    MaterialityTypes.Financial,
  );

  if (questionsQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <MatrixArray
      title={'Importance pour les parties prenantes'}
      matrix={matrix}
      columns={columns}
    />
  );
}
