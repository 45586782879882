import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import loginIllustration from '../../../../assets/images/illustrations/login.png';
import { CreateNotebookButton } from '../../notebook/CreateNotebookButton';
import { Notebook } from '../../notebook/Notebook';
import React from 'react';

export const ProjectNotebookContent = () => {
  const projectContext = useProjectContext();

  if (!projectContext?.enterprise?.notebook?.id) {
    return (
      <div className="grow w-full py-8 px-16 max-w-6xl">
        <IllustratedExplanationMessage
          illustration={
            <img className="w-1/2" src={loginIllustration} alt="" />
          }
          title="Créez des notes pour ce projet"
          description="Ne perdez plus d'information en créant des notes !"
        >
          <CreateNotebookButton
            companyId={projectContext?.enterprise?.id || ''}
          />
        </IllustratedExplanationMessage>
      </div>
    );
  }

  return (
    <div className="grow bg-gray-50 w-full py-8 px-16">
      {projectContext?.enterprise?.notebook?.id && (
        <div className="max-w-4xl">
          <Notebook notebookId={projectContext.enterprise.notebook.id} />
        </div>
      )}
    </div>
  );
};
