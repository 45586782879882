import React, { useEffect, useState } from 'react';
import {
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';

export function QuestionNumber({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const [value, setValue] = useState<number | null>(answer?.number || null);

  const updateAnswer = (value: number) => {
    if (value !== null) {
      setIsAnswerValid(true);
      setAnswer({
        question: {
          id: question.id,
        },
        type: SurveyAnswerType.Text,
        number: value,
      });
    } else {
      setAnswer(null);
      setIsAnswerValid(false);
    }
  };

  const changeText = (newValue: string) => {
    const newValueAsNumber = Number(newValue);
    setValue(newValueAsNumber);
    updateAnswer(newValueAsNumber);
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(value !== null);
  }, [value, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <input
          type="number"
          className="form-input-text-survey w-44 h-14"
          value={value?.toString() || ''}
          onChange={(e) => changeText(e.target.value)}
        />
      </div>
    </div>
  );
}
