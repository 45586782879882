import React from 'react';
import { SSOLayout } from '../../components/layout/SSOLayout';
import { VisiativIntegrationLoginScreen } from '../public/integrations/visiativ/VisiativIntegrationLoginScreen';
import { SamiIntegrationLoginScreen } from '../public/integrations/sami/SamiIntegrationLoginScreen';

export const useIntegrationsRouteConfig = () => {
  return [
    {
      path: '/integrations',
      children: [
        // ====================
        // Sami integration routes
        // ====================
        {
          path: '/integrations/sami',
          element: <SSOLayout />,
          children: [
            {
              path: '/integrations/sami/login',
              element: <SamiIntegrationLoginScreen />,
            },
          ],
        },
        // ====================
        // Visiativ integration routes
        // ====================
        {
          path: '/integrations/visiativ',
          element: <SSOLayout />,
          children: [
            {
              path: '/integrations/visiativ/login',
              element: <VisiativIntegrationLoginScreen />,
            },
            {
              path: '/integrations/visiativ/login/:email',
              element: <VisiativIntegrationLoginScreen />,
            },
          ],
        },
      ],
    },
  ];
};
