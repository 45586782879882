import {
  ImpactLevel,
  MaterialityTypes,
  MaturityLevel,
  PriorityLevel,
  ProbabilityLevel,
  QualityLevel,
} from '../graphql/generated';

export function getMaturityLabel(
  maturity: MaturityLevel | null | undefined,
): string {
  switch (maturity) {
    case MaturityLevel.None:
      return 'Insuffisant';
    case MaturityLevel.Low:
      return 'À travailler';
    case MaturityLevel.Medium:
      return 'Mature';
    case MaturityLevel.High:
      return 'Inspirant';
    case MaturityLevel.Computed:
      return 'Calculé';
    default:
      return 'Non pertinent';
  }
}

export function getPriorityLabel(priority: PriorityLevel | null): string {
  switch (priority) {
    case PriorityLevel.None:
      return 'Aucune';
    case PriorityLevel.Low:
      return 'Peu important';
    case PriorityLevel.Medium:
      return 'Important';
    case PriorityLevel.High:
      return 'Très important';
    case PriorityLevel.Computed:
      return 'Calculé';
    default:
      return 'Non pertinent';
  }
}

export function getImpactLabel(priority: ImpactLevel | null): string {
  switch (priority) {
    case ImpactLevel.None:
      return 'Aucun';
    case ImpactLevel.Low:
      return 'Peu important';
    case ImpactLevel.Medium:
      return 'Important';
    case ImpactLevel.High:
      return 'Très important';
    case ImpactLevel.Computed:
      return 'Calculé';
    default:
      return 'Non pertinent';
  }
}

export function getQualityLabel(
  quality: QualityLevel | null,
  materialityType: MaterialityTypes,
): string {
  if (materialityType === MaterialityTypes.Financial) {
    switch (quality) {
      case QualityLevel.Negative:
        return 'Majoritairement un risque';
      case QualityLevel.Positive:
        return 'Majoritairement une opportunité';
      case QualityLevel.Neutral:
      default:
        return 'Autant un risque qu’une opportunité';
    }
  } else {
    switch (quality) {
      case QualityLevel.Negative:
        return 'Majoritairement négatif';
      case QualityLevel.Positive:
        return 'Majoritairement positif';
      case QualityLevel.Neutral:
      default:
        return 'Autant positif que négatif';
    }
  }
}

export function getProbabilityLabel(
  probability: ProbabilityLevel | null,
): string {
  switch (probability) {
    case ProbabilityLevel.Low:
      return 'Peu probable';
    case ProbabilityLevel.Medium:
      return 'Probable';
    case ProbabilityLevel.High:
      return 'Très probable';
    case ProbabilityLevel.Certain:
      return 'Certain';
    default:
      return 'Non pertinent';
  }
}
