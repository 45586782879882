import {
  EditLinkToQuoteFileFragment,
  useRoadmapUpdateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import expertsIllustration from '../../../assets/images/illustrations/experts.png';
import { useTranslation } from '@hooks/useTranslation';

export function EditLinkToQuoteFile({
  roadmap,
}: {
  roadmap: EditLinkToQuoteFileFragment;
}) {
  const { t } = useTranslation();
  const [linkToQuoteFile, setLinkToQuoteFile] = useState<string>(
    roadmap?.linkToQuoteFile || '',
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const [editRoadmap] = useRoadmapUpdateMutation();
  const handleLinkToRoadmapQuoteFileChange = (event: {
    target: { value: React.SetStateAction<string | null> };
  }) => {
    setLinkToQuoteFile(event?.target?.value?.toString() || '');
  };
  const newLinkToQuoteFile =
    linkToQuoteFile && linkToQuoteFile !== '' ? linkToQuoteFile : null;
  const saveLinkToQuoteFile = () => {
    setIsUpdating(true);
    editRoadmap({
      variables: {
        input: {
          id: roadmap.id,
          linkToQuoteFile: newLinkToQuoteFile,
        },
      },
    })
      .catch((err) => console.error(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="main-content w-full bg-blue-50 flex gap-8 items-center p-8 rounded-xl">
      <img src={expertsIllustration} alt="" className="w-40" />
      <div className="space-y-4">
        <h3>Allez encore plus loin grâce à nos expert·es</h3>
        <p className="text-sm">
          Découvrez notre proposition d'accompagnement pour aller plus loin dans
          vos objectifs grâce à nos experts qualifiés.
        </p>
        <div className="small card">
          <label className="title-h4" htmlFor="diagnostic-file">
            Lien vers une proposition commerciale
          </label>
          <input
            type="text"
            id="diagnostic-file"
            className="form-input-text"
            value={linkToQuoteFile}
            onChange={handleLinkToRoadmapQuoteFileChange}
          />
          <button
            className="primary self-end small purple"
            onClick={saveLinkToQuoteFile}
            disabled={linkToQuoteFile === roadmap.linkToQuoteFile}
          >
            {isUpdating && <Loader />}
            <span>{t('global:save')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
