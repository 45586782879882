import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';
import { XIcon } from '../icons';

type TPadding = 'p-0' | 'p-2' | 'p-4' | 'p-6' | 'p-8' | 'p-10';
export type ModalContextType = {
  title: string | null;
  visibility: boolean;
  isFullscreen: boolean;
  displayTitleBar: boolean;
  widthClass: string | null;
  padding?: TPadding;
  component: ReactNode | null;
  openModalWithComponent: (
    component: React.ReactNode,
    title?: string | null,
    isFullscreen?: boolean,
    displayTitleBar?: boolean,
    widthClass?: string | null,
    padding?: TPadding,
  ) => void;
  closeModal: () => void;
};

const initialContext: ModalContextType = {
  title: '',
  visibility: false,
  isFullscreen: false,
  displayTitleBar: true,
  widthClass: null,
  component: null,
  padding: 'p-8',
  openModalWithComponent: (
    component: ReactNode,
    title?: string | null,
    isFullscreen?: boolean,
    displayTitleBar?: boolean,
    widthClass?: string | null,
    padding?: TPadding,
  ) => {
    // do nothing
  },
  closeModal: () => {
    // do nothing
  },
};

const ModalContext = React.createContext(initialContext);

export function ModalProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState<string | null>(null);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [displayTitleBar, setDisplayTitleBar] = useState<boolean>(true);
  const [widthClass, setWidthClass] = useState<string | null>(null);
  const [padding, setPadding] = useState<TPadding | undefined>('p-8');
  const [component, setComponent] = useState<ReactNode | null>(null);

  function openModalWithComponent(
    component: ReactNode,
    title?: string | null,
    isFullscreen?: boolean,
    displayTitleBar?: boolean,
    widthClass?: string | null,
    padding: TPadding = 'p-8',
  ) {
    setIsFullscreen(isFullscreen || false);
    setDisplayTitleBar(displayTitleBar !== false);
    setWidthClass(widthClass || null);
    setComponent(component);
    setVisibility(true);
    setTitle(title || null);
    setPadding(padding);
  }

  function closeModal() {
    setVisibility(false);
    setComponent(null);
    setIsFullscreen(false);
    setDisplayTitleBar(true);
    setWidthClass(null);
    setTitle(null);
    setPadding(undefined);
  }

  return (
    <ModalContext.Provider
      value={{
        title,
        visibility,
        isFullscreen,
        displayTitleBar,
        widthClass,
        openModalWithComponent,
        closeModal,
        component,
        padding,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

export function Modal() {
  const {
    title,
    visibility,
    isFullscreen,
    displayTitleBar,
    widthClass,
    padding,
    closeModal,
    component,
  } = useContext(ModalContext);
  const content = visibility && component ? component : null;
  const { t } = useTranslation();
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    if (visibility) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    // Nettoyer en retirant l'événement lorsque le composant se démonte ou change d'état
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility, closeModal]);
  const preventFromClosingModalWithUnsavedChanges = () => {
    // Don't close the modal: use embedded buttons instead
    //  closeModal();
  };

  return (
    <Transition.Root show={visibility} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={preventFromClosingModalWithUnsavedChanges}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={clsx(
              isFullscreen
                ? 'h-full flex items-end justify-center p-4 text-center'
                : 'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all',
                  isFullscreen && 'h-full',
                  widthClass,
                )}
              >
                <div
                  className={clsx(
                    'flex flex-col',
                    isFullscreen ? 'w-full h-full' : 'sm:flex sm:items-start',
                  )}
                >
                  {displayTitleBar && (
                    <div className="flex gap-4 justify-between p-4 items-center w-full bg-gray-50 border-b border-gray-100">
                      <div className="w-12">
                        <button
                          className="tertiary bg-transparent text-gray-100"
                          onClick={() => closeModal()}
                          aria-label={t('global:close')}
                        >
                          <XIcon className="w-4 h-4" />
                        </button>
                      </div>
                      {title && <h4 className="grow text-center">{title}</h4>}
                      <div className="w-12"></div>
                    </div>
                  )}
                  <div
                    className={clsx(
                      isFullscreen
                        ? 'p-0 grow overflow-scroll'
                        : clsx(padding, 'w-full'),
                    )}
                  >
                    {content}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
