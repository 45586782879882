import React from 'react';
import clsx from 'clsx';
import { ThemeIcon } from '../icon/ThemeIcon';
import { ThemeIcon as ThemeIconEnum } from '../../../graphql/generated';

export function ThemeIconPicker({
  currentIcon,
  setCurrentIcon,
}: {
  currentIcon: ThemeIconEnum;
  setCurrentIcon: (icon: ThemeIconEnum) => void;
}) {
  const availableThemeIcons: ThemeIconEnum[] = Object.values(ThemeIconEnum);
  return (
    <div className="flex items-stretch gap-2 max-w-lg flex-wrap">
      {availableThemeIcons.map((themeIcon) => (
        <div
          key={themeIcon}
          className={clsx(
            'p-2 rounded-xl border w-12 h-12 flex items-center justify-center cursor-pointer',
            currentIcon === themeIcon
              ? 'border-gray-900 bg-gray-900 text-white'
              : 'border-gray-100 bg-white text-gray-900',
          )}
          onClick={() => setCurrentIcon(themeIcon)}
        >
          <ThemeIcon themeIcon={themeIcon} />
        </div>
      ))}
    </div>
  );
}
