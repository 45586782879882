import React, { useEffect, useMemo, useState } from 'react';
import {
  DiagnosticStakeFieldsFragment,
  ReferentialFieldsFragment,
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyWithCompanyReferentialQuery,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import { LoaderFullscreen } from '../../layout/Loader';
import { useParams } from 'react-router-dom';
import { NumberCircle } from '../../generic/NumberCircle';
import { PillarIcon } from '../../stake/PillarIcon';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteIcon, DownIcon, PlusIcon, UpIcon } from '../../icons';

export function QuestionStakesRanking({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  // Fetch stakes
  const { surveyId } = useParams();
  const surveyWithCompanyReferentialQuery =
    useSurveyWithCompanyReferentialQuery({
      variables: { id: surveyId || '' },
      skip: !surveyId,
      fetchPolicy: 'network-only',
    });

  if (surveyWithCompanyReferentialQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (surveyWithCompanyReferentialQuery.data?.survey?.company?.referential) {
    return (
      <QuestionStakesRankingInner
        referential={
          surveyWithCompanyReferentialQuery.data?.survey?.company?.referential
        }
        question={question}
        answer={answer}
        setAnswer={setAnswer}
        setIsAnswerValid={setIsAnswerValid}
      />
    );
  }

  return null;
}

function QuestionStakesRankingInner({
  referential,
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  referential: ReferentialFieldsFragment;
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t, translateProperty } = useTranslation();

  // All stakes or only the ones from the pillar
  const stakes = useMemo(() => {
    if (question.pillar?.id) {
      return (
        referential?.pillars
          .filter((pillar) => pillar.id === question.pillar?.id)
          .flatMap((pillar) => pillar.stakes) || []
      );
    } else {
      return referential?.pillars.flatMap((pillar) => pillar.stakes) || [];
    }
  }, [question.pillar?.id, referential?.pillars]);

  const getInitialChosenRankedStakes = (): DiagnosticStakeFieldsFragment[] => {
    if (answer?.stakes) {
      return answer.stakes
        .toSorted((a, b) => (a.rank || 0) - (b.rank || 0))
        .map((answerStake) =>
          stakes.find((stake) => stake.id === answerStake.stakeId),
        )
        .filter((stake) => stake) as DiagnosticStakeFieldsFragment[];
    } else {
      return [];
    }
  };

  const [chosenRankedStakes, setChosenRankedStakes] = useState<
    DiagnosticStakeFieldsFragment[]
  >(getInitialChosenRankedStakes());
  const [comment, setComment] = useState<string>(answer?.comment || '');

  const updateAnswerWithNewRankedStakes = (
    newChosenRankedStakes: DiagnosticStakeFieldsFragment[],
  ) => {
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Stakes,
      stakes: newChosenRankedStakes.map((stake) => ({
        stakeId: stake.id,
        rank: newChosenRankedStakes.findIndex((s) => s.id === stake.id),
      })),
      comment: comment,
    });
  };

  const updateAnswerWithComment = (comment: string) => {
    setIsAnswerValid(true);
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Stakes,
      stakes: chosenRankedStakes.map((stake) => ({
        stakeId: stake.id,
        rank: chosenRankedStakes.findIndex((s) => s.id === stake.id),
      })),
      comment,
    });
  };

  const addToChosenRankedStakes = (stake: DiagnosticStakeFieldsFragment) => {
    if (chosenRankedStakes.length < (question.valueMax || 5)) {
      const newChosenRankedStakes = [...chosenRankedStakes, stake];
      setChosenRankedStakes(newChosenRankedStakes);

      // Update answer
      updateAnswerWithNewRankedStakes(newChosenRankedStakes);

      // Is valid when we have minimum 1 stake
      setIsAnswerValid(newChosenRankedStakes.length > 0);
    }
  };

  const removeFromChosenRankedStakes = (
    stake: DiagnosticStakeFieldsFragment,
  ) => {
    const newChosenRankedStakes = chosenRankedStakes.filter(
      (s) => s.id !== stake.id,
    );
    setChosenRankedStakes(newChosenRankedStakes);

    // Update answer
    updateAnswerWithNewRankedStakes(newChosenRankedStakes);

    // Is valid when we have minimum 1 stake
    setIsAnswerValid(newChosenRankedStakes.length > 0);
  };

  const moveUp = (index: number) => {
    if (index > 0) {
      const newChosenRankedStakes = [...chosenRankedStakes];
      newChosenRankedStakes[index] = chosenRankedStakes[index - 1];
      newChosenRankedStakes[index - 1] = chosenRankedStakes[index];
      setChosenRankedStakes(newChosenRankedStakes);

      // Update answer
      updateAnswerWithNewRankedStakes(newChosenRankedStakes);
    }
  };

  const moveDown = (index: number) => {
    if (index < chosenRankedStakes.length - 1) {
      const newChosenRankedStakes = [...chosenRankedStakes];
      newChosenRankedStakes[index] = chosenRankedStakes[index + 1];
      newChosenRankedStakes[index + 1] = chosenRankedStakes[index];
      setChosenRankedStakes(newChosenRankedStakes);

      // Update answer
      updateAnswerWithNewRankedStakes(newChosenRankedStakes);
    }
  };

  const changeComment = (value: string) => {
    setComment(value);
    updateAnswerWithComment(value);
  };

  // List of stakes that are not ranked yet, grouped by pillarId
  const unrankedStakes = useMemo(() => {
    const rankedStakeIds = chosenRankedStakes.map((stake) => stake.id);
    return stakes
      .filter((stake) => !rankedStakeIds.includes(stake.id))
      .reduce(
        (acc, stake) => {
          if (stake.pillar?.id) {
            if (!acc[stake.pillar.id]) {
              acc[stake.pillar.id] = [];
            }
            acc[stake.pillar.id].push(stake);
          }
          return acc;
        },
        {} as { [pillarId: string]: DiagnosticStakeFieldsFragment[] },
      );
  }, [stakes, chosenRankedStakes]);

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(chosenRankedStakes.length > 0);
  }, [chosenRankedStakes, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div className="w-full flex justify-center items-center">
          <div className="w-full max-w-xl divide-y divide-gray-900 space-y-4">
            <div className="flex flex-col gap-2">
              {chosenRankedStakes.map((stake, index) => (
                <div className="flex items-center gap-2 w-full" key={index}>
                  <NumberCircle number={index + 1} size={8} />
                  <div className="bg-white rounded-lg border-2 border-b-4 border-gray-900 flex items-center gap-2 py-2 px-4 w-full">
                    {stake.pillar && <PillarIcon pillar={stake.pillar} />}
                    <div className="font-bold text-sm grow flex-wrap">
                      {translateProperty(stake, 'name')}
                    </div>
                    <div className="flex items-center gap-0.5 shrink-0">
                      <button
                        className="tertiary p-0"
                        onClick={() => moveUp(index)}
                        disabled={index === 0}
                      >
                        <UpIcon className="w-4 h-4 text-gray-900" />
                      </button>
                      <button
                        className="tertiary p-0"
                        onClick={() => moveDown(index)}
                        disabled={index === chosenRankedStakes.length - 1}
                      >
                        <DownIcon className="w-4 h-4 text-gray-900" />
                      </button>
                      <button
                        className="tertiary p-0"
                        onClick={() => removeFromChosenRankedStakes(stake)}
                      >
                        <DeleteIcon className="w-4 h-4 text-gray-900" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {
                // Fill in the gaps with empty rows
                Array.from({
                  length: (question.valueMax || 5) - chosenRankedStakes.length,
                })
                  .map((_, index) => index + chosenRankedStakes.length)
                  .map((index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <NumberCircle number={index + 1} size={8} />
                      <div
                        key={index}
                        className="bg-white rounded-lg h-10 w-full border-2 border-dashed border-gray-900"
                      ></div>
                    </div>
                  ))
              }
            </div>
            <div className="pt-4 space-y-4">
              <h6>
                {t('survey.question.stakes_ranking.choose_among_stakes', {
                  count: stakes.length,
                })}
              </h6>
              <div className="flex items-center flex-wrap gap-4">
                {
                  // Iterate over stakes that are not in the list yet, grouped by pillar (unrankedStakes)
                  Object.keys(unrankedStakes).map((pillarId) => {
                    const pillar = referential?.pillars.find(
                      (pillar) => pillar.id === pillarId,
                    );
                    return (
                      pillar && (
                        <div key={pillarId} className="flex flex-col gap-2">
                          <div className="font-bold text-sm flex items-center gap-2">
                            <PillarIcon pillar={pillar} />
                            {translateProperty(pillar, 'name')}
                          </div>
                          <div className="flex items-center flex-wrap gap-2">
                            {unrankedStakes[pillarId]
                              .toSorted((a, b) =>
                                translateProperty(a, 'name').localeCompare(
                                  translateProperty(b, 'name'),
                                ),
                              )
                              .map((stake) => (
                                <StakeToBeRanked
                                  key={stake.id}
                                  stake={stake}
                                  chosenRankedStakes={chosenRankedStakes}
                                  addToChosenRankedStakes={
                                    addToChosenRankedStakes
                                  }
                                  top={question.valueMax || 5}
                                />
                              ))}
                          </div>
                        </div>
                      )
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
        {question.canAddOtherChoice && (
          <textarea
            id="comment"
            name="comment"
            className="form-input-text-survey w-full h-24 max-w-xl"
            placeholder={t(
              'survey.question.stakes_ranking.comment_placeholder',
            )}
            value={comment}
            onChange={(e) => changeComment(e.target.value)}
          />
        )}
      </div>
    </div>
  );
}

function StakeToBeRanked({
  stake,
  chosenRankedStakes,
  addToChosenRankedStakes,
  top,
}: {
  stake: DiagnosticStakeFieldsFragment;
  chosenRankedStakes: DiagnosticStakeFieldsFragment[];
  addToChosenRankedStakes: (stake: DiagnosticStakeFieldsFragment) => void;
  top: number;
}) {
  const { translateProperty } = useTranslation();
  return (
    <div
      key={stake.id}
      className={clsx(
        'bg-white rounded-lg border-2 border-gray-900 flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-900 hover:text-white',
        // Greyed out if the list is full
        {
          'opacity-70': chosenRankedStakes.length >= top,
        },
      )}
      onClick={() => addToChosenRankedStakes(stake)}
    >
      {stake.pillar && <PillarIcon pillar={stake.pillar} />}
      <div className="font-bold text-sm flex-wrap">
        {translateProperty(stake, 'name')}
      </div>
      <PlusIcon className="w-4 h-4 shrink-0" />
    </div>
  );
}
