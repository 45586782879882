import React from 'react';
import { SurveyQuestionIcon } from '../../../../../survey/SurveyQuestionIcon';
import { TooltipWrapper } from '../../../../../generic/TooltipWrapper';
import { getQuestionTypeLabel } from '../../../../../../services/SurveyService';
import { InfoIcon } from '../../../../../icons';
import { QuestionTypeInput_QuestionFragment } from '../../../../../../graphql/generated';

export function QuestionTypeInput({
  question,
}: {
  question: QuestionTypeInput_QuestionFragment;
}) {
  return (
    <div className="w-1/2">
      <label htmlFor="title" className="form-input-label mb-2">
        Type de question
      </label>
      <TooltipWrapper
        label="Le type de question n'est pas modifiable"
        place="right"
      >
        <div className="form-input-text disabled flex items-center gap-2 grayscale">
          <div className="scale-90">
            <SurveyQuestionIcon questionType={question.type} />
          </div>
          <div className="grow">{getQuestionTypeLabel(question.type)}</div>
          <InfoIcon className="text-gray-500" />
        </div>
      </TooltipWrapper>
    </div>
  );
}
