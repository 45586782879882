import {
  SurveyQuestionUpdateInput,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { ToggleRequired } from './atoms/ToggleRequired';
import { useToast } from '../../../../layout/Toast';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';

export function QuestionNumberForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();

  const updateQuestion = (question: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: question,
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>
      <div className="pt-8">
        <QuestionPillarStake
          question={questionDraft}
          enablePillarPicker={true}
          enableStakePicker={true}
        />
      </div>

      <div className="pt-8 space-y-2">
        <h3>Contenu</h3>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            Intitulé de la question
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() =>
              updateQuestion({
                id: questionDraft.id,
                title: questionDraft.title,
              })
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
      </div>
    </div>
  );
}
