import React from 'react';
import {
  CopyNonRespondentEmailsButton_SurveyFragment,
  SurveyRespondentStatus,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { CopyIcon } from '../../../icons';

export function CopyNonRespondentEmailsButton({
  survey,
}: {
  survey: CopyNonRespondentEmailsButton_SurveyFragment;
}) {
  const toast = useToast();

  const copyNonRespondentEmailsToClipboard = () => {
    const emails = survey.respondents
      .filter((r) => r.status !== SurveyRespondentStatus.Completed)
      .map((r) => r.email)
      .join(', ');
    copyTextToClipboard(emails)
      .then(() =>
        toast.openToastWithMessage(
          'Les emails ont été copiés dans le presse-papier',
        ),
      )
      .catch((error) =>
        toast.openToastWithMessage(`Erreur : ${error as string}`),
      );
  };

  return (
    <button
      className="secondary small"
      onClick={() => copyNonRespondentEmailsToClipboard()}
    >
      <CopyIcon className="w-4 h-4" />
      <span>Copier les emails des non-répondants</span>
    </button>
  );
}

async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}
