import React, { useState } from 'react';
import {
  IroEvaluation_IroDocument,
  SurveyLanguage,
  UpdateIroModal_IroFragment,
  useUpdateIroModal_UpdateIroMutation,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import { useModal } from '../../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import LanguageDropdown from '../../../../../form/LanguageDropdown';
import { DeleteIroButton } from '../delete/DeleteIroButton';
import { Loader } from '../../../../../generic/Loader';
import { XIcon } from '../../../../../icons';

export function UpdateIroModal({ iro }: { iro: UpdateIroModal_IroFragment }) {
  const toast = useToast();
  const modal = useModal();
  const { t } = useTranslation();

  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const initializeI18nValues = () => {
    // Get i18n values from stake
    const i18nValues = iro.i18n || [];
    // Inject FR if not present
    if (!i18nValues.find((i18n) => i18n.language === SurveyLanguage.Fr)) {
      i18nValues.push({
        language: SurveyLanguage.Fr,
        name: iro.name,
      });
    }
    return i18nValues;
  };
  const [i18nValues, setI18nValues] = useState(initializeI18nValues());
  const translation = i18nValues?.find((i18n) => i18n.language === language);

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
    // Inject language if not present
    if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
      const newI18nValues = [...i18nValues];
      newI18nValues.push({
        language: newLanguage,
        name: '',
      });
      setI18nValues(newI18nValues);
    }
  };

  const [isLoading, setIsUpdating] = useState(false);
  const [updateIro] = useUpdateIroModal_UpdateIroMutation();
  const handleSubmit = () => {
    setIsUpdating(true);
    updateIro({
      variables: {
        input: {
          id: iro.id,
          i18n: i18nValues,
        },
      },
      refetchQueries: [
        {
          query: IroEvaluation_IroDocument,
          variables: {
            stakeId: iro?.stake?.id,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:iro.form.update.success'));
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithError(t('toast:iro.form.update.error'));
      })
      .finally(() => setIsUpdating(false));
  };

  const editField = (field: string, value: string) => {
    const newI18nValues = i18nValues.map((i18n) => {
      if (i18n.language === language) {
        return {
          ...i18n,
          [field]: value,
        };
      }
      return i18n;
    });
    setI18nValues(newI18nValues);
  };

  return (
    <div className="flex flex-col max-w-2xl h-full">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-100"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">{t('iro.update.modal.title')}</h4>
        <LanguageDropdown onUpdate={(language) => pickLanguage(language)} />
      </div>
      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('iro.form.name')}
            </label>
            <input
              type="text"
              id="title"
              className="form-input-text"
              placeholder={t('iro.form.name', {
                context: language,
              })}
              value={translation?.name || ''}
              onChange={(e) => editField('name', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-between px-6 py-4 border-t border-gray-100">
        <DeleteIroButton iro={iro} onDelete={() => modal.closeModal()} />
        <button
          className="primary"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : t('global:save')}
        </button>
      </div>
    </div>
  );
}
