import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { StakeholderList } from '../../../components/project/stakeholder/mapping/StakeholderList';
import { ScreenTopBar } from '../../../components/layout/ScreenTopBar';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { withProjectContext } from '../../../providers/withProjectContext';
import { Loader } from '../../../components/generic/Loader';

export const ProStakeholderScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();
    if (!projectContext?.enterprise?.id) {
      return <Loader />;
    }

    return (
      <>
        <PageTitle title={t('screen.stakeholder.stakeholder_mapping')} />
        <ScreenTopBar>
          {t('screen.stakeholder.stakeholder_mapping')}
        </ScreenTopBar>
        <StakeholderList companyId={projectContext.enterprise.id} />
      </>
    );
  },
  { requiredCompany: true },
);
