import { useParams } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useMaturityEvaluationScreenQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { MaturityEvaluation } from '../../../components/project/maturity/MaturityEvaluation';
import { withProjectContext } from '../../../providers/withProjectContext';

function MaturityEvaluationScreenInner() {
  const { surveyId } = useParams();

  const { data, loading } = useMaturityEvaluationScreenQuery({
    variables: {
      maturityEvaluationId: surveyId || '',
    },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.maturityEvaluation) {
    return (
      <>
        <PageTitle title="Évaluation de maturité" />
        <MaturityEvaluation survey={data.maturityEvaluation} />
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de l&apos;évaluation
          de maturité.
        </p>
      </MessageBox>
    );
  }
}

export const MaturityEvaluationScreen = withProjectContext(
  MaturityEvaluationScreenInner,
  {
    requiredCompany: true,
  },
);
