import { ApolloError } from '@apollo/client';

export function getGraphqlErrorStatus(err: ApolloError): number {
  return (err.graphQLErrors[0].extensions?.status as number) || 500;
}

export function isConflict(err: ApolloError): boolean {
  return getGraphqlErrorStatus(err) === 409;
}

export function isNotAcceptable(err: ApolloError): boolean {
  return getGraphqlErrorStatus(err) === 406;
}
