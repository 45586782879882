import { CompanyFieldsFragment, UserFieldsFragment } from 'graphql/generated';
import React from 'react';
import { CopyTextToClipboardButton } from '../../generic/CopyTextToClipboardButton';
import { getInvitationLink } from '../../../services/CompanyService';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { User } from './User';
import { useTranslation } from '@hooks/useTranslation';

export function Employees({ company }: { company: CompanyFieldsFragment }) {
  const { t } = useTranslation();
  const employees =
    company.employees?.toSorted((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    }) || [];

  const collaboratorInvitationLink = getInvitationLink(company.id, null);

  return (
    <div className="space-y-4 pt-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h2 className="title-h4">{t('project.home.internal_users.title')}</h2>
        </div>
        <CopyTextToClipboardButton
          buttonLabel={t('project.home.copy_invitation_link')}
          textToCopy={collaboratorInvitationLink}
          className="purple small"
        />
      </div>
      <EmployeesList employees={employees} companyId={company.id} />
    </div>
  );
}

function EmployeesList({
  employees,
  companyId,
}: {
  employees: UserFieldsFragment[];
  companyId: string;
}) {
  const { t } = useTranslation();
  if (employees.length > 0) {
    return (
      <table>
        <thead>
          <tr>
            <th>{t('project.home.internal_users.name')}</th>
            <th>{t('project.home.internal_users.email')}</th>
            <th>{t('project.home.internal_users.last_login_date')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {employees.map((user, index) => (
            <User user={user} key={index} companyId={companyId} />
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Info}>{t('global:none')}</MessageBox>
    );
  }
}
