import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../layout/Modal';
import React, { useState } from 'react';
import {
  DeleteStakeButtonFragment,
  useDeleteStakeButton_StakeDeleteMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';

export function DeleteStakeButton({
  stake,
}: {
  stake: DeleteStakeButtonFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteDiagnosticStakeMutation] =
    useDeleteStakeButton_StakeDeleteMutation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deleteStake = () => {
    setIsDeleting(true);
    deleteDiagnosticStakeMutation({ variables: { id: stake.id } })
      .then(() => {
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  if (confirmDelete) {
    return (
      <div className="flex items-center gap-1">
        <p className="text-sm">{t('global:confirm_delete')}</p>
        <button
          className="primary danger small"
          onClick={() => deleteStake()}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : t('global:yes')}
        </button>
        <button
          className="tertiary text-gray-500 small"
          onClick={() => setConfirmDelete(false)}
        >
          {t('global:no')}
        </button>
      </div>
    );
  }

  return (
    <button
      className="secondary danger"
      onClick={() => setConfirmDelete(true)}
      disabled={isDeleting}
    >
      {t('global:delete')}
    </button>
  );
}
