import { SurveyQuestionType, SurveyTarget } from '../graphql/generated';
import { ProjectContextType } from '../providers/ProjectContextProvider';
import { UserContextType } from '../providers/CurrentUserProvider';

// Local storage management
export function getSurveyAnswersFromLocalStorage(surveyId: string) {
  return localStorage.getItem(`survey-${surveyId}-answers`);
}
export function storeSurveyAnswersToLocalStorage(
  surveyId: string,
  stringifiedAnswers: string,
) {
  localStorage.setItem(`survey-${surveyId}-answers`, stringifiedAnswers);
}
export function deleteSurveyAnswersToLocalStorage(surveyId: string) {
  localStorage.removeItem(`survey-${surveyId}-answers`);
}

export function sanitizeTypeformString(
  text: string,
  projectContext: ProjectContextType | null,
  currentUser: UserContextType,
) {
  const getCompanyName = () => {
    if (projectContext?.enterprise) {
      return projectContext.enterprise.name;
    } else if (currentUser?.company?.name) {
      return currentUser.company.name;
    }
    return 'entreprise';
  };

  return text
    .replace(/\*/g, '')
    .replace(/_/g, '')
    .replace(/({{hidden:company}})/g, getCompanyName())
    .replace(/({{ENTREPRISE}})/g, getCompanyName());
}

export function getSurveyTargetLabel(type: SurveyTarget): string {
  switch (type) {
    case SurveyTarget.Collaborators:
      return 'Parties prenantes internes';
    case SurveyTarget.External:
      return 'Parties prenantes externes';
    case SurveyTarget.Ceo:
      return 'Direction / CODIR';
    default:
      return 'Consultation';
  }
}

export function getQuestionTypeLabel(type: SurveyQuestionType): string {
  switch (type) {
    case SurveyQuestionType.Group:
      return 'Groupe de questions';
    case SurveyQuestionType.MultipleChoice:
      return 'Choix (simple / multiple)';
    case SurveyQuestionType.Dropdown:
      return 'Liste de sélection';
    case SurveyQuestionType.Matrix:
      return 'Matérialité simple';
    case SurveyQuestionType.MaturityEvaluation:
      return 'Maturité d’un enjeu';
    case SurveyQuestionType.Statement:
      return 'Texte d’information';
    case SurveyQuestionType.LongText:
    case SurveyQuestionType.ShortText:
      return 'Texte simple';
    case SurveyQuestionType.Number:
      return 'Nombre / % / €';
    case SurveyQuestionType.Email:
      return 'Identité du répondant';
    case SurveyQuestionType.Enps:
      return 'eNPS';
    case SurveyQuestionType.Rating:
      return 'Score';
    case SurveyQuestionType.StakesRanking:
      return 'Classement d’enjeux';
    case SurveyQuestionType.StakeDoubleMateriality:
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return 'Double matérialité';
  }
  return '';
}

export function getPublicLinkToSurvey(surveyId: string): string {
  return `${window.location.origin}/survey/${surveyId}/answer`;
}
