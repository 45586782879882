import { CompanySector } from '../../../graphql/generated';
import StringDropdown from '../../generic/dropdown/StringDropdown';
import React from 'react';

export function CompanySectorDropdown({
  sector,
  setSector,
}: {
  sector: CompanySector | null | undefined;
  setSector: (sector: CompanySector) => void;
}) {
  const availableSectors = [
    { id: CompanySector.Agriculture, label: 'Agriculture' },
    {
      id: CompanySector.BanqueAssuranceFinanceImmobilier,
      label: 'Banque/Assurance/Finance/Immobilier',
    },
    {
      id: CompanySector.BtpMateriauxDeConstruction,
      label: 'BTP/Matériaux de construction',
    },
    {
      id: CompanySector.IndustrieAgroalimentaire,
      label: 'Industrie agroalimentaire ',
    },
    {
      id: CompanySector.IndustrieDuBoisPapierCartonImprimerie,
      label: 'Industrie du Bois/Papier/Carton/Imprimerie',
    },
    {
      id: CompanySector.IndustrieChimieParachimie,
      label: 'Industrie chimie/parachimie',
    },
    {
      id: CompanySector.IndustrieDuPlastiqueCaoutchouc,
      label: 'Industrie du plastique/Caoutchouc',
    },
    { id: CompanySector.IndustrieMetallurgie, label: 'Industrie Métallurgie' },
    {
      id: CompanySector.IndustriePharmaceutique,
      label: 'Industrie pharmaceutique',
    },
    {
      id: CompanySector.MachinesEtEquipementsAutomobile,
      label: 'Machines et équipements/Automobile',
    },
    {
      id: CompanySector.CommerceNegoceDistribution,
      label: 'Commerce/Négoce/Distribution',
    },
    {
      id: CompanySector.CommunicationMultimediaEdition,
      label: 'Communication/Multimédia/Édition',
    },
    {
      id: CompanySector.MaterielElectroniqueElectricite,
      label: 'Matériel Électronique/Électricité',
    },
    { id: CompanySector.InformatiqueTelecoms, label: 'Informatique/Télécoms' },
    { id: CompanySector.EtudesEtConseils, label: 'Études et conseils' },
    {
      id: CompanySector.ServicesAuxEntreprises,
      label: 'Services aux entreprises',
    },
    {
      id: CompanySector.TextileHabillementChaussure,
      label: 'Textile/Habillement/Chaussure',
    },
    { id: CompanySector.TransportsLogistique, label: 'Transports/Logistique' },
    {
      id: CompanySector.AdministrationPubliqueEnseignementSante,
      label: 'Administration publique/Enseignement/Santé',
    },
  ];

  return (
    <StringDropdown
      availableItems={availableSectors}
      filterable={true}
      item={availableSectors.find((item) => item.id === sector) || null}
      setItem={(item) => {
        if (item?.id) {
          const enumIndex = Object.keys(CompanySector).indexOf(item.id);
          setSector(Object.values(CompanySector)[enumIndex]);
        }
      }}
    />
  );
}
