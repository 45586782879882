import React, { useState } from 'react';
import { PreviewMaturityEvaluationTemplate } from './PreviewMaturityEvaluationTemplate';
import { MaturityEvaluationRowFragment } from '../../../../graphql/generated';
import { MaturityEvaluationTemplates } from './MaturityEvaluationTemplates';

export function MaturityEvaluationTemplateGalleryModal() {
  const [previewMaturityEvaluationTemplate, setPreviewMaturityEvaluationTemplate] =
    useState<MaturityEvaluationRowFragment | null>(null);

  return previewMaturityEvaluationTemplate ? (
    <PreviewMaturityEvaluationTemplate
      maturityEvaluationTemplate={previewMaturityEvaluationTemplate}
      setPreviewMaturityEvaluationTemplate={setPreviewMaturityEvaluationTemplate}
    />
  ) : (
    <MaturityEvaluationTemplates setPreviewMaturityEvaluationTemplate={setPreviewMaturityEvaluationTemplate} />
  );
}
