import { useModal } from '../../../layout/Modal';
import { CompanyUpdateInfo } from './CompanyUpdateInfo';
import React from 'react';
import { EditIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteCompanyButton } from './DeleteCompanyButton';

export function EditCompanyButton({ companyId }: { companyId: string }) {
  const modal = useModal();
  const { t } = useTranslation();

  const deleteAction = <DeleteCompanyButton companyId={companyId} />;

  function openEditCompanyModal(companyId: string) {
    if (companyId)
      modal.openModalWithComponent(
        <CompanyUpdateInfo
          companyId={companyId}
          deleteAction={deleteAction}
          callback={modal.closeModal}
        />,
        t('global:edit'),
      );
  }

  return (
    <button
      className="contextual-menu-item"
      onClick={() => openEditCompanyModal(companyId)}
    >
      <EditIcon />
      {t('global:edit')}
    </button>
  );
}
