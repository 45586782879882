import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

export enum ToggleColors {
  Purple = 'Purple',
  Blue = 'Blue',
  Gray = 'Gray',
  Green = 'Green',
  Red = 'Red',
}

const ColorScheme = {
  [ToggleColors.Purple]: {
    on: ['bg-purple-900 text-purple-900'],
    off: ['bg-gray-300 text-gray-300'],
  },
  [ToggleColors.Red]: {
    on: ['bg-red-500 text-red-500'],
    off: ['bg-gray-300 text-gray-300'],
  },
  [ToggleColors.Blue]: {
    on: ['bg-blue-600 text-blue-600'],
    off: ['bg-gray-300 text-gray-300'],
  },
  [ToggleColors.Gray]: {
    on: ['bg-gray-900 text-gray-900'],
    off: ['bg-gray-300 text-gray-300'],
  },
  [ToggleColors.Green]: {
    on: ['bg-green-500 text-green-500'],
    off: ['bg-gray-300 text-gray-300'],
  },
};

export type ToggleProps = {
  state: boolean | null | undefined;
  setState: (newState: boolean) => void;
  disabled?: boolean;
  onIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  color?: ToggleColors;
};

export function Toggle({
  state,
  setState,
  color = ToggleColors.Gray,
  disabled = false,
  onIcon,
  offIcon,
}: ToggleProps) {
  return (
    <Switch
      checked={state || false}
      disabled={disabled}
      onChange={(newState) => setState(newState)}
      className={clsx(
        state ? ColorScheme[color].on : ColorScheme[color].off,
        'm-0 p-0 place-content-start relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2',
      )}
    >
      <span
        aria-hidden="true"
        className={clsx(
          state ? 'translate-x-5' : 'translate-x-1',
          'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out flex items-center justify-center',
        )}
      >
        {state && onIcon}
        {!state && offIcon}
      </span>
    </Switch>
  );
}
