import { MaturityScreen_DiagnosticStakeFragment } from '../../../../graphql/generated';
import { StakeTag } from '../../../stake/StakeTag';
import { StakeMaturityProgressBar } from '../../../stake/StakeMaturityProgressBar';
import StakeMaturitySelect from '../../stakes/StakeMaturitySelect';
import React from 'react';

export const StakeMaturityRow = ({
  stake,
  isEditMode,
}: {
  stake: MaturityScreen_DiagnosticStakeFragment;
  isEditMode: boolean;
}) => {
  return (
    <tr>
      <td>
        <StakeTag stake={stake} />
      </td>
      <td>
        <StakeMaturityProgressBar stake={stake} />
      </td>
      <td>
        <StakeMaturitySelect stake={stake} disabled={!isEditMode} />
      </td>
    </tr>
  );
};
