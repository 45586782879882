import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../../AppRoutes';

export const enum DOUBLE_MATERIALITY_MATRIX_TABS {
  IRO = 'IRO',
  STAKEHOLDER = 'STAKEHOLDER',
}

export const useDoubleMaterialityMatrixTabs = () => {
  const { t } = useTranslation();

  return [
    {
      key: DOUBLE_MATERIALITY_MATRIX_TABS.IRO,
      name: t('screen.double_materiality.iro_matrix.tabTitle'),
      path: AppRoutes.PublicationDoubleMaterialityMatrixIro,
    },
    {
      key: DOUBLE_MATERIALITY_MATRIX_TABS.STAKEHOLDER,
      name: t('screen.double_materiality.stakeholder_matrix.tabTitle'),
      path: AppRoutes.PublicationDoubleMaterialityMatrixStakeholder,
    }
  ];
};
