import React from 'react';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';
import {
  MaterialityTypes,
  SurveyQuestionType,
  useDoubleMaterialityStakeholderMatrixQuery,
  useUtopiesStakesQualityQuery,
} from '../../../../../../graphql/generated';
import { StakeQuestionsQuality } from './StakeQuestionsQuality';

export function UtopiesStakesQuality({
  materialityType,
}: {
  materialityType: MaterialityTypes;
}) {
  const projectContext = useProjectContext();

  const surveysDocumentQuery = useUtopiesStakesQualityQuery({
    variables: {
      companyId: projectContext?.enterprise?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });
  const surveys = surveysDocumentQuery.data?.surveys || [];

  // Fetch stakes with materialities
  const computeMaterialityQuery = useDoubleMaterialityStakeholderMatrixQuery({
    variables: {
      referentialId: projectContext?.enterprise?.referential?.id || '',
      input: {
        companyId: projectContext?.enterprise?.id || '',
      },
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'no-cache', // Important because the value will always change
  });
  const stakes =
    computeMaterialityQuery?.data?.computeDoubleMaterialitiesStakeholder || [];

  // TODO query the questions directly
  // Keep questions
  const questionIds = surveys
    .flatMap((survey) => survey.questions)
    // Filter by type
    .filter(
      (question) =>
        question?.type === SurveyQuestionType.UtopiesStakeDoubleMateriality,
    )
    // Filter by materialityType
    .filter(
      (question) => question?.stakeOptions?.materialityType === materialityType,
    )
    .map((question) => question?.id || '');

  return (
    <StakeQuestionsQuality
      questionIds={questionIds}
      stakes={stakes}
      materialityType={materialityType}
    />
  );
}
