import React from 'react';
import { CommentList_AnswerFragment } from '../../../../graphql/generated';
import { DownloadIcon, QuoteIcon } from '../../../icons';
import { utils, writeFileXLSX } from 'xlsx';
import { TooltipWrapper } from '../../../generic/TooltipWrapper';

export function CommentList({
  answers,
}: {
  answers: CommentList_AnswerFragment[];
}) {
  const hasComment = (
    comment: string | null | undefined,
  ): comment is string => {
    return !!comment;
  };

  const comments = answers
    .map((answer) => answer.comment)
    .filter((comment) => hasComment(comment));

  if (comments.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-4">
        <h6>Commentaires</h6>
        <ExportCommentsToXlsButton answers={answers} />
      </div>
      <div className="space-y-2">
        {comments.map((comment, index) => (
          <div key={index} className="space-y-2">
            <div className="flex items-start gap-2">
              <QuoteIcon className="shrink-0 text-gray-300" />
              <div className="bg-white p-2 text-sm border-l-2 border-gray-100">
                {comment}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ExportCommentsToXlsButton({
  answers,
}: {
  answers: CommentList_AnswerFragment[];
}) {
  const exportToXls = () => {
    const worksheet = utils.json_to_sheet(
      answers
        .filter((answer) => answer.comment)
        .map((answer) => {
          return { Commentaire: answer.comment };
        }),
    );

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'commentaires');
    writeFileXLSX(workbook, 'Export commentaires.xlsx', {
      compression: true,
    });
  };

  return (
    <TooltipWrapper label="Exporter les commentaires en XLS">
      <button className="tertiary" onClick={exportToXls}>
        <DownloadIcon />
      </button>
    </TooltipWrapper>
  );
}
