import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../../../layout/Modal';
import { EditIcon } from '../../../../icons';
import { StakeholderGroupForm } from '../form/StakeholderGroupForm';
import {
  GroupedStakeholderSegmentDropdownDocument,
  StakeholderListDocument,
  UpdateStakeholderGroupInput,
  UpdateStakeholderGroupModal_StakeholderGroupFragment,
  useUpdateStakeholderGroupMutation,
} from '../../../../../graphql/generated';

type Props = {
  initialData: UpdateStakeholderGroupModal_StakeholderGroupFragment;
};

export const UpdateStakeholderGroupButton = ({ initialData }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const [updateStakeholderGroup] = useUpdateStakeholderGroupMutation();

  const openCreateIroModal = () => {
    modal.openModalWithComponent(
      <StakeholderGroupForm<UpdateStakeholderGroupInput>
        initialData={initialData}
        onSubmit={(input) =>
          updateStakeholderGroup({
            variables: { input },
            refetchQueries: () => [
              {
                query: StakeholderListDocument,
                variables: {
                  companyId: initialData.company?.id,
                },
              },
              {
                query: GroupedStakeholderSegmentDropdownDocument,
                variables: {
                  companyId: initialData.company?.id,
                },
              },
            ],
          })
        }
        onClose={modal.closeModal}
      />,
      t('stakeholder.group.update.modal.title'),
      false,
      false,
      undefined,
      'p-0',
    );
  };

  return (
    <button className="tertiary" onClick={openCreateIroModal}>
      <EditIcon className="w-4 h-4" />
    </button>
  );
};
