import React, { ElementType, ReactNode } from 'react';

export const IconTitle = ({
  icon,
  children,
  as: Component = 'div',
}: {
  icon?: ReactNode;
  children: ReactNode;
  as?: ElementType;
}) => {
  return (
    <div className="flex items-center gap-2 self-stretch">
      {icon && (
        <div className="flex justify-center items-center gap-2 w-10 h-10 rounded-lg bg-[#e4e6f2]">
          {icon}
        </div>
      )}
      <Component className="text-[#282a2c] font-['Bitter'] text-lg font-extrabold leading-[150%]">
        {children}
      </Component>
    </div>
  );
};
