import React from 'react';
import {
  DoubleMaterialityFinancialSeverityArray_AnswerFragment,
  DoubleMaterialityFinancialSeverityArray_StakeFragment,
  DoubleMaterialityImpactSeverityArray_AnswerFragment,
  DoubleMaterialityImpactSeverityArray_StakeFragment,
  DoubleMaterialitySeverityArrays_StakeFragment,
  ImpactLevel,
  MaterialityTypes,
  PriorityLevel,
  useDoubleMaterialitySeverityArrays_QuestionQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  MatrixArray,
  MatrixRowType,
  stakeAnswersToMatrixRows,
} from '../../survey/questionsDataviz/MatrixArray';
import { StakesDoubleMaterialityComments } from '../../survey/questionsDataviz/materiality/StakesDoubleMaterialityComments';
import { ChevronDownIcon } from '../../../icons';

export function DoubleMaterialitySeverityArrays({
  questionIds,
  stakes,
}: {
  questionIds: string[];
  stakes: DoubleMaterialitySeverityArrays_StakeFragment[];
}) {
  const [
    displayCommentsOnFinancialMateriality,
    setDisplayCommentsOnFinancialMateriality,
  ] = React.useState(false);
  const [
    displayCommentsOnImpactMateriality,
    setDisplayCommentsOnImpactMateriality,
  ] = React.useState(false);

  // Fetch answers
  const surveyQuestionsAnalysisQuery =
    useDoubleMaterialitySeverityArrays_QuestionQuery({
      variables: {
        ids: questionIds,
      },
      fetchPolicy: 'cache-and-network',
    });

  // Consolidate all answers from all questions: Financial materiality
  const financialMaterialityAnswers =
    surveyQuestionsAnalysisQuery.data?.surveyQuestions
      .filter(
        (question) =>
          question.stakeOptions?.materialityType === MaterialityTypes.Financial,
      )
      .flatMap((question) => question.answers || [])
      // Keep only provided stakes
      .map((answer) => {
        return {
          ...answer,
          stakes: answer.stakes?.filter((stake) =>
            stakes.some((s) => s.id === stake.stakeId),
          ),
        };
      }) || [];

  // Consolidate all answers from all questions: Impact materiality
  const impactMaterialityAnswers =
    surveyQuestionsAnalysisQuery.data?.surveyQuestions
      .filter(
        (question) =>
          question.stakeOptions?.materialityType === MaterialityTypes.Impact,
      )
      .flatMap((question) => question.answers || [])
      // Keep only provided stakes
      .map((answer) => {
        return {
          ...answer,
          stakes: answer.stakes?.filter((stake) =>
            stakes.some((s) => s.id === stake.stakeId),
          ),
        };
      }) || [];

  if (surveyQuestionsAnalysisQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-0.5">
        <DoubleMaterialityFinancialSeverityArray
          answers={financialMaterialityAnswers}
          stakes={stakes}
        />
        {displayCommentsOnFinancialMateriality ? (
          <StakesDoubleMaterialityComments
            answers={financialMaterialityAnswers}
            stakes={stakes}
          />
        ) : (
          <div className="flex justify-center">
            <button
              className="tertiary small bg-transparent"
              onClick={() => setDisplayCommentsOnFinancialMateriality(true)}
            >
              Afficher les commentaires libres <ChevronDownIcon />
            </button>
          </div>
        )}
      </div>
      <div className="space-y-0.5">
        <DoubleMaterialityImpactSeverityArray
          answers={impactMaterialityAnswers}
          stakes={stakes}
        />
        {displayCommentsOnImpactMateriality ? (
          <StakesDoubleMaterialityComments
            answers={impactMaterialityAnswers}
            stakes={stakes}
          />
        ) : (
          <div className="flex justify-center">
            <button
              className="tertiary small bg-transparent"
              onClick={() => setDisplayCommentsOnImpactMateriality(true)}
            >
              Afficher les commentaires libres <ChevronDownIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function DoubleMaterialityFinancialSeverityArray({
  answers,
  stakes,
}: {
  answers: DoubleMaterialityFinancialSeverityArray_AnswerFragment[];
  stakes: DoubleMaterialityFinancialSeverityArray_StakeFragment[];
}) {
  // Columns to display
  const columns: Array<PriorityLevel> = [
    PriorityLevel.None,
    PriorityLevel.Low,
    PriorityLevel.Medium,
    PriorityLevel.High,
  ];

  const matrix: MatrixRowType[] = stakeAnswersToMatrixRows(
    answers || [],
    stakes,
    columns,
    MaterialityTypes.Financial,
  );

  return (
    <MatrixArray
      title={"Conséquences financières sur l'entreprise"}
      matrix={matrix}
      columns={columns}
    />
  );
}

function DoubleMaterialityImpactSeverityArray({
  answers,
  stakes,
}: {
  answers: DoubleMaterialityImpactSeverityArray_AnswerFragment[];
  stakes: DoubleMaterialityImpactSeverityArray_StakeFragment[];
}) {
  // Columns to display
  const columns: Array<ImpactLevel> = [
    ImpactLevel.None,
    ImpactLevel.Low,
    ImpactLevel.Medium,
    ImpactLevel.High,
  ];

  const matrix: MatrixRowType[] = stakeAnswersToMatrixRows(
    answers || [],
    stakes,
    columns,
    MaterialityTypes.Impact,
  );

  return (
    <MatrixArray
      title={"Impact de l'entreprise sur son écosystème"}
      matrix={matrix}
      columns={columns}
    />
  );
}
