import React, { useEffect } from 'react';
import { XIcon } from '../icons';
import { useNavContext } from '../../providers/NavContextProvider';

export const FocusLayout = ({ children }: { children: React.ReactNode }) => {
  const { isNavCollapsed, setIsNavCollapsed, title, actions, onClose } =
    useNavContext();
  useEffect(() => {
    setIsNavCollapsed(true);
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <div className="px-8 py-4 border-b border-gray-100 shadow-sm flex items-center justify-between">
        <div className="flex items-center gap-1 w-2/3">
          {isNavCollapsed && onClose && (
            <button
              className="tertiary"
              onClick={() => {
                onClose();
                setIsNavCollapsed!(false);
              }}
            >
              <XIcon />
            </button>
          )}
          {title && <div className="title-h5 truncate">{title}</div>}
        </div>
        {actions && (
          <div className="w-1/3 flex items-center justify-end gap-2">
            {actions.map((action) => (
              <div key={action.key}>{action.component}</div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-1 overflow-hidden">{children}</div>
    </div>
  );
};
