import { useDeleteCompanyButtonMutation } from '../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../layout/Toast';
import { ConfirmationModal } from '../../../modals/ConfirmationModal';
import { useModal } from '../../../layout/Modal';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';

export function DeleteCompanyButton({ companyId }: { companyId: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();
  const navigate = useNavigate();

  const [deleteCompanyMutation] = useDeleteCompanyButtonMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: companyId,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('project.delete.success'));
        navigate(AppRoutes.Home);
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <button
      className="primary danger"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.delete.content')}
            callbackIfConfirmed={deleteCompany}
          />,
          t('project.delete.cta_project'),
        )
      }
    >
      {isDeleting && <Loader />}
      <div>{t('project.delete.cta_project')}</div>
    </button>
  );
}
