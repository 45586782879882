import {
  DeletePillarButtonFragment,
  useDeletePillarButton_PillarDeleteMutation,
} from '../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useModal } from '../../layout/Modal';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';

export function DeletePillarButton({
  pillar,
}: {
  pillar: DeletePillarButtonFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePillarMutation] = useDeletePillarButton_PillarDeleteMutation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deletePillar = () => {
    setIsDeleting(true);
    deletePillarMutation({ variables: { id: pillar.id } })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        modal.closeModal();
      })
      .finally(() => setIsDeleting(false))
      .catch((err) => {
        console.error(err);
      });
  };

  if (confirmDelete) {
    return (
      <div className="flex items-center gap-1">
        <p className="text-sm">{t('global:confirm_delete')}</p>
        <button
          className="primary danger small"
          onClick={() => deletePillar()}
          disabled={isDeleting}
        >
          {isDeleting ? <Loader /> : t('global:yes')}
        </button>
        <button
          className="tertiary text-gray-500 small"
          onClick={() => setConfirmDelete(false)}
        >
          {t('global:no')}
        </button>
      </div>
    );
  }

  return (
    <button
      className="secondary danger"
      onClick={() => setConfirmDelete(true)}
      disabled={isDeleting}
    >
      {t('global:delete')}
    </button>
  );
}
