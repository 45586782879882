import React from 'react';
import { DisclosureRequirement_DrLevel3Fragment } from '../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../utils';
import { BlockWithDatapointsRenderer } from '../../project/gapAnalysis/disclosureRequirement/BlockWithDatapointsRenderer';
import { BlocksRenderer } from '../renderers/BlocksRenderer';
import { Footnotes } from './Footnotes';

export const DisclosureRequirementLevel3Content = ({
  drLevel3,
}: {
  drLevel3: DisclosureRequirement_DrLevel3Fragment;
}) => {
  const footnotes = drLevel3.footnotes?.filter(filterEmptyDocuments) || [];
  const datapoints = drLevel3.datapoints.filter(filterEmptyDocuments);

  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2">
        {datapoints.length > 0 ? (
          <BlockWithDatapointsRenderer
            content={drLevel3.content}
            datapoints={datapoints.map((d) => d.slug || '')}
          />
        ) : (
          <BlocksRenderer content={drLevel3.content} />
        )}
        {footnotes.length > 0 && <Footnotes footnotes={footnotes} />}
      </div>
    </div>
  );
};
