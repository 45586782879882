import React from 'react';
import clsx from 'clsx';
import { IroScaleItem } from '../picker/IroScalePicker';
import {
  getBgColorFromColorStrength,
  getTextColorFromColorStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import { IroScopeItem } from '../picker/IroScopePicker';
import { IroLikelihoodItem } from '../picker/IroLikelihoodPicker';
import { IroIrremediabilityItem } from '../picker/IroIrremediabilityPicker';
import { useTranslation } from '@hooks/useTranslation';

export function CriteriaRatingTable({
  items,
}: {
  items:
    | IroScaleItem[]
    | IroScopeItem[]
    | IroLikelihoodItem[]
    | IroIrremediabilityItem[];
}) {
  const { t } = useTranslation();
  return (
    <table>
      <thead>
        <tr>
          <th>{t('iro.criteria.rating.header')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>
              <div className="space-y-2">
                <div
                  className={clsx(
                    'block truncate rounded-md p-1 font-bold w-full',
                    getBgColorFromColorStrength(item.strength),
                    getTextColorFromColorStrength(item.strength),
                  )}
                >
                  {item.name}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
