import React, { useState } from 'react';
import { ExportableToImage } from '../../../generic/ExportableToImage';
import { MaturityRadarChart } from './MaturityRadarChart';
import PillarDropdown from '../../../form/PillarDropdown';
import { MaturityScreen_DiagnosticStakeFragment } from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { StakeMaturityRow } from './StakeMaturityRow';

export function MaturityEvaluationResult({
  stakes,
  isEditMode,
}: {
  stakes: MaturityScreen_DiagnosticStakeFragment[];
  isEditMode: boolean;
}) {
  const [filterByPillar, setFilterByPillar] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-gray-50 shadow-inner">
        <div className="space-y-4 main-content py-16">
          <div className="flex gap-4 justify-between items-end">
            <div className="space-y-2">
              <h1>{t('maturity.evaluationResult.title')}</h1>
              <p>{t('maturity.evaluationResult.description')}</p>
            </div>
            <div className="flex flex-col items-start gap-4">
              <PillarDropdown
                currentPillarId={filterByPillar}
                setPillarId={setFilterByPillar}
                isFilter={true}
              />
            </div>
          </div>
          <div>
            <MaturityRadarChart
              stakes={stakes}
              filterByPillar={filterByPillar}
              setPillarId={setFilterByPillar}
            />
          </div>
        </div>
      </div>
      <div className="main-content pt-8">
        <ExportableToImage name="Évaluation de maturité">
          <div className="space-y-4 w-full">
            <h6>{t('maturity.evaluationResult.resultsByStake')}</h6>
            <table>
              <thead>
                <tr>
                  <th className="w-5/12">
                    {t('maturity.evaluationResult.table.stake')}
                  </th>
                  <th className="w-6/12">
                    {t('maturity.evaluationResult.table.maturityScore')}
                  </th>
                  <th className="w-1/12">
                    {t('maturity.evaluationResult.table.maturityLevel')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {stakes
                  .filter(
                    (stake) =>
                      !filterByPillar || stake.pillar.id === filterByPillar,
                  )
                  .toSorted(
                    (a, b) =>
                      (b?.maturity?.score || 0) - (a?.maturity?.score || 0),
                  )
                  .map((stake) => {
                    if (stake) {
                      return (
                        <StakeMaturityRow
                          key={stake.id}
                          stake={stake}
                          isEditMode={isEditMode}
                        />
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
          </div>
        </ExportableToImage>
      </div>
    </>
  );
}

