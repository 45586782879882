import { createContext, ReactNode, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const SSOContext = createContext<string | null>(null);

export const useSSO = () => useContext(SSOContext);

export function SSOProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const [loginHint] = useState<string | null>(searchParams.get('login_hint'));

  return (
    <SSOContext.Provider value={loginHint}>
      {children}
    </SSOContext.Provider>
  );
}
