import React, { useState } from 'react';
import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';
import {
  AddQuestionButton_SurveyFragment,
  MaterialityTypes,
  SurveyQuestionAddInput,
  SurveyQuestionType,
  useAddQuestionButton_AddMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useModal } from '../../../layout/Modal';
import clsx from 'clsx';
import { Loader } from '../../../generic/Loader';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PlusIcon, StarIcon } from '../../../icons';
import { isSurvey } from '../../../../types/survey.types';

export enum AddQuestionButtonStyle {
  BUTTON = 'BUTTON',
  PLUS_ICON = 'PLUS_ICON',
}

export function AddQuestionButton({
  survey,
  position,
  style,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  style: AddQuestionButtonStyle;
  callback?: (newQuestionId: string) => void;
  parentId?: string | null | undefined;
}) {
  const modal = useModal();

  const isSurveyConsultation = isSurvey(survey);

  return (
    <button
      className={clsx(
        style === AddQuestionButtonStyle.BUTTON && 'primary purple w-full',
        style === AddQuestionButtonStyle.PLUS_ICON && 'unstyled text-gray-500',
      )}
      onClick={() => {
        if (isSurveyConsultation) {
          modal.openModalWithComponent(
            <AddQuestionForStakeholderSurveyModal
              survey={survey}
              position={position}
              callback={callback}
              parentId={parentId ? parentId : undefined}
            />,
            'Quel type de question souhaitez-vous ajouter ?',
            true,
            true,
            'w-4/5',
          );
        } else {
          modal.openModalWithComponent(
            <AddQuestionForMaturityEvaluationSurveyModal
              survey={survey}
              position={position}
              callback={callback}
              parentId={parentId ? parentId : undefined}
            />,
            'Quel type de question souhaitez-vous ajouter ?',
            true,
            true,
            'w-4/5',
          );
        }
      }}
    >
      <PlusIcon className="" />
      {style === AddQuestionButtonStyle.BUTTON && (
        <div>Ajouter une question</div>
      )}
    </button>
  );
}

function AddQuestionForMaturityEvaluationSurveyModal({
  survey,
  position,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  callback?: (newQuestionId: string) => void;
  parentId?: string;
}) {
  const defaultQuestionValues: SurveyQuestionAddInput = {
    survey: {
      id: survey.id,
    },
    position,
    parent: parentId
      ? {
          id: parentId,
        }
      : undefined,
    required: true,
    title: 'Nouvelle question',
    description: '<p class="ql-align-center"></p>',
    type: SurveyQuestionType.ShortText,
  };

  return (
    <div className="grid grid-cols-2 gap-8 2xl:gap-16 w-full p-6 2xl:p-8">
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Structure</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Statement}
            label="Texte d'information"
            description=""
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStatementQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Group}
            label="Groupe"
            description="Pour organiser votre questionnaire. Invisible pour les répondants."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getGroupQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Questions simples</h5>
          <QuestionItem
            questionType={SurveyQuestionType.ShortText}
            label="Champs texte"
            description="Questions ouvertes, le répondant devra écrire sa réponse"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getShortTextQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MultipleChoice}
            label="Avec choix"
            description="Choix simple ou multiples, Oui/Non"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMultipleChoiceQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Number}
            label="Nombre"
            description="Pourcentages, monétaire, etc..."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getNumberQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">Scores</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Rating}
            label="Notation"
            description="Pour donner une note (ex: de 1 à 10)"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getRatingQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Enps}
            label="eNPS"
            description="Indicateur qui sert à mesurer la probabilité que les employés recommandent leur entreprise comme lieu de travail."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEnpsQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
        </div>
      </div>
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">Identification</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Email}
            label="Identité du répondant"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEmailQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Évaluation des enjeux</h5>
          <QuestionItem
            questionType={SurveyQuestionType.StakesRanking}
            label="Classement d'enjeux"
            description="Pour établir un classement d'enjeux"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakesRankingQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MaturityEvaluation}
            label="Maturité d'un enjeu"
            description="Score d'un enjeu en fonction du niveau de maturité de l'entreprise"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMaturityEvaluationQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">Matérialité</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Matrix}
            label="Matérialité simple"
            description="Pour évaluer l'importance des enjeux d'un pilier"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMatrixQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label="Double matérialité : matérialité financière"
            description="Évaluer l'impact financier des enjeux d'un pilier"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityFinancialQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label="Double matérialité : matérialité d'impact"
            description="Évaluer les impacts (négatifs ou positifs) de l’entreprise sur son environnement économique, social et naturel."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityImpactQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}

function AddQuestionForStakeholderSurveyModal({
  survey,
  position,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  callback?: (newQuestionId: string) => void;
  parentId?: string;
}) {
  const projectContext = useProjectContext();

  const clientSpecificQuestionTypes =
    projectContext?.enterprise?.metadata?.customQuestionTypes || [];

  const defaultQuestionValues: SurveyQuestionAddInput = {
    survey: {
      id: survey.id,
    },
    position,
    parent: parentId
      ? {
          id: parentId,
        }
      : undefined,
    required: true,
    title: 'Nouvelle question',
    description: '<p class="ql-align-center"></p>',
    type: SurveyQuestionType.ShortText,
  };

  return (
    <div className="grid grid-cols-2 gap-8 2xl:gap-16 w-full p-4 2xl:p-8">
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Structure</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Statement}
            label="Texte d'information"
            description=""
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStatementQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Group}
            label="Groupe"
            description="Pour organiser votre questionnaire. Invisible pour les répondants."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getGroupQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Questions simples</h5>
          <QuestionItem
            questionType={SurveyQuestionType.ShortText}
            label="Champs texte"
            description="Questions ouvertes, le répondant devra écrire sa réponse"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getShortTextQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MultipleChoice}
            label="Avec choix"
            description="Choix simple ou multiples, Oui/Non"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMultipleChoiceQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Number}
            label="Nombre"
            description="Pourcentages, monétaire, etc..."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getNumberQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Scores</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Rating}
            label="Notation"
            description="Pour donner une note (ex: de 1 à 10)"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getRatingQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Enps}
            label="eNPS"
            description="Indicateur qui sert à mesurer la probabilité que les employés recommandent leur entreprise comme lieu de travail."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEnpsQuestionTemplate()}
            callback={callback}
          />
        </div>
      </div>
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Identification</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Email}
            label="Identité du répondant"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEmailQuestionTemplate()}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Évaluation des enjeux</h5>
          <QuestionItem
            questionType={SurveyQuestionType.StakesRanking}
            label="Classement d'enjeux"
            description="Pour établir un classement d'enjeux"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakesRankingQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MaturityEvaluation}
            label="Maturité d'un enjeu"
            description="Score d'un enjeu en fonction du niveau de maturité de l'entreprise"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMaturityEvaluationQuestionTemplate()}
            callback={callback}
            disabled={true}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">Matérialité</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Matrix}
            label="Matérialité simple"
            description="Pour évaluer l'importance des enjeux d'un pilier"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMatrixQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label="Double matérialité : matérialité financière"
            description="Évaluer l'impact financier des enjeux d'un pilier"
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityFinancialQuestionTemplate()}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label="Double matérialité : matérialité d'impact"
            description="Évaluer les impacts (négatifs ou positifs) de l’entreprise sur son environnement économique, social et naturel."
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityImpactQuestionTemplate()}
            callback={callback}
          />
        </div>
        {clientSpecificQuestionTypes.length > 0 && (
          <div className="space-y-1 2xl:space-y-2">
            <h5 className="title-h6 2xl:title-h5 flex items-center gap-2">
              <StarIcon className="shrink-0 text-gray-900" />
              <div>Personnalisé</div>
            </h5>
            {clientSpecificQuestionTypes.includes(
              SurveyQuestionType.UtopiesStakeDoubleMateriality,
            ) && (
              <>
                <QuestionItem
                  questionType={
                    SurveyQuestionType.UtopiesStakeDoubleMateriality
                  }
                  label="Utopies : matérialité financière"
                  description="Évaluer l'impact financier des enjeux d'un pilier"
                  defaultQuestionValues={defaultQuestionValues}
                  questionTemplate={getUtopiesStakeDoubleMaterialityFinancialQuestionTemplate()}
                  callback={callback}
                />
                <QuestionItem
                  questionType={
                    SurveyQuestionType.UtopiesStakeDoubleMateriality
                  }
                  label="Utopies : matérialité d'impact"
                  description="Évaluer les impacts (négatifs ou positifs) de l’entreprise sur son environnement économique, social et naturel."
                  defaultQuestionValues={defaultQuestionValues}
                  questionTemplate={getUtopiesStakeDoubleMaterialityImpactQuestionTemplate()}
                  callback={callback}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function QuestionItem({
  questionType,
  label,
  description,
  defaultQuestionValues,
  questionTemplate,
  callback,
  disabled,
}: {
  questionType: SurveyQuestionType;
  label: string;
  description?: string;
  defaultQuestionValues: SurveyQuestionAddInput;
  questionTemplate?: Partial<SurveyQuestionAddInput>;
  callback?: (newQuestionId: string) => void;
  disabled?: boolean;
}) {
  const toast = useToast();
  const modal = useModal();

  const [isAdding, setIsAdding] = useState(false);

  const [addSurveyQuestionMutation] = useAddQuestionButton_AddMutation();
  const addQuestion = () => {
    if (disabled) {
      return;
    }
    setIsAdding(true);
    const newQuestion: SurveyQuestionAddInput = {
      ...defaultQuestionValues,
      ...questionTemplate,
    };
    addSurveyQuestionMutation({
      variables: {
        input: newQuestion,
      },
    })
      .then((newQuestion) => {
        toast.openToastWithMessage('Question ajoutée');
        modal.closeModal();
        if (callback && newQuestion.data?.surveyQuestionAdd) {
          callback(newQuestion.data?.surveyQuestionAdd.id);
        }
      })
      .catch(() => {
        toast.openToastWithError('Une erreur est survenue');
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  return (
    <div
      className={clsx(
        'group flex items-center gap-4 border border-gray-100 rounded-xl px-2 2xl:px-4 py-1.5 2xl:py-3 shadow-sm',
        disabled
          ? 'bg-gray-50'
          : 'bg-white cursor-pointer hover:bg-gray-50 hover:border-gray-900',
      )}
      onClick={addQuestion}
    >
      <SurveyQuestionIcon questionType={questionType} color={900} />
      <div className={clsx('space-y-1 grow', disabled && 'opacity-40')}>
        <div className="font-semibold text-sm 2xl:text-base">{label}</div>
        {description && (
          <div className="text-gray-500 text-sm">{description}</div>
        )}
      </div>
      {isAdding ? (
        <Loader />
      ) : !disabled ? (
        <PlusIcon className="shrink-0 text-gray-300 group-hover:text-gray-900" />
      ) : null}
    </div>
  );
}

function getStatementQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Statement,
    title: "Texte d'information",
    description: '<p class="ql-align-center">Description (optionnelle)</p>',
  };
}

function getGroupQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Group,
    title: 'Groupe de questions',
  };
}

function getShortTextQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.ShortText,
    title: 'Nouvelle question',
  };
}

function getNumberQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Number,
    title: 'Nouvelle question',
  };
}

function getMultipleChoiceQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.MultipleChoice,
    title: 'Nouvelle question à choix',
    choices: [
      {
        label: 'Choix 1',
        position: 1,
      },
      {
        label: 'Choix 2',
        position: 2,
      },
    ],
    canSelectMultipleChoices: false,
  };
}

function getRatingQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Rating,
    title: 'Nouvelle notation de 1 à 10',
    valueMin: 1,
    valueMax: 10,
  };
}

function getEnpsQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Enps,
    title:
      'Sur une échelle de 0 à 10, quelle est la probabilité pour que vous recommandiez à votre entourage de venir travailler dans notre entreprise ?',
  };
}

function getEmailQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Email,
    title: 'Votre identité et votre fonction',
    description:
      '<p class="ql-align-center">Pour le suivi, mais vos réponses resteront bien anonymes !</p>',
  };
}

function getMaturityEvaluationQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.MaturityEvaluation,
    title: "Évaluation de la maturité de l'enjeu",
    description:
      '<p class="ql-align-center">Chaque choix est associé à un score de maturité</p>',
    choices: [
      {
        label: 'Maturité nulle',
        position: 1,
        score: 0,
      },
      {
        label: 'Maturité faible',
        position: 2,
        score: 1,
      },
      {
        label: 'Maturité moyenne',
        position: 3,
        score: 2,
      },
      {
        label: 'Maturité forte',
        position: 4,
        score: 3,
      },
      {
        label: 'Maturité excellente',
        position: 5,
        score: 4,
      },
    ],
  };
}

function getStakesRankingQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.StakesRanking,
    title:
      'Parmi cette liste, quels sont selon vous les 5 enjeux les plus importants ?',
    valueMin: 1,
    valueMax: 5,
  };
}

function getMatrixQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.Matrix,
    title:
      "Quelle est selon-vous l'importance de chacun de ces enjeux pour l'entreprise ?",
  };
}

function getStakeDoubleMaterialityImpactQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.StakeDoubleMateriality,
    title: "Impact de ces enjeux sur l'entreprise",
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Impact,
      materialityOptions: {
        impactLabel:
          "Quel pourrait être l'impact de l'entreprise sur cet enjeu ?",
        impactNoneLabel: 'Faible',
        impactLowLabel: 'Modéré',
        impactMediumLabel: 'Fort',
        impactHighLabel: 'Majeur',
        probabilityLabel:
          'Quelle est la probabilité que ces conséquences se matérialisent ?',
        probabilityLowLabel: 'Faible (0-25%)',
        probabilityMediumLabel: 'Modérée (25-50%)',
        probabilityHighLabel: 'Forte (50-75%)',
        probabilityCertainLabel: 'Certaine (75-100%)',
      },
    },
  };
}

function getStakeDoubleMaterialityFinancialQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.StakeDoubleMateriality,
    title: "Impact de l'entreprise sur ces enjeux",
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Financial,
      materialityOptions: {
        impactLabel:
          "Quelles pourraient être les conséquences financières de cet enjeu sur l'entreprise ?",
        impactNoneLabel: 'Faibles',
        impactLowLabel: 'Modérées',
        impactMediumLabel: 'Fortes',
        impactHighLabel: 'Majeures',
        probabilityLabel:
          'Quelle est la probabilité que ces conséquences se matérialisent ?',
        probabilityLowLabel: 'Faible (0-25%)',
        probabilityMediumLabel: 'Modérée (25-50%)',
        probabilityHighLabel: 'Forte (50-75%)',
        probabilityCertainLabel: 'Certaine (75-100%)',
      },
    },
  };
}

// Client specific questions

function getUtopiesStakeDoubleMaterialityImpactQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.UtopiesStakeDoubleMateriality,
    title: 'Impact à moyen terme',
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Impact,
      materialityOptions: {
        qualityLabel:
          "Selon vous, dans les 3 à 5 ans à venir, l’impact de l'entreprise sur cet enjeu sera-t-il :",
        qualityNegativeChoiceLabel:
          "Majoritairement négatif : au global, l'entreprise est plutôt susceptible d’avoir un impact négatif sur cet enjeu et contribue à l'aggravation de la situation plutôt qu'à son amélioration.",
        qualityNeutralChoiceLabel:
          "Autant positif que négatif : l'entreprise est susceptible d’avoir un impact autant positif que négatif sur cet enjeu et contribue autant à une amélioration de la situation qu’à une aggravation.",
        qualityPositiveChoiceLabel:
          "Majoritairement positif : au global, l'entreprise est plutôt susceptible d’avoir un impact positif sur cet enjeu et contribue à une amélioration de la situation plutôt qu'à une aggravation.",
        impactLabel:
          "Quel pourrait être l'impact de l'entreprise sur cet enjeu dans les 3 à 5 ans ?",
        impactNoneLabel:
          "Faible : l'entreprise aura un impact faible ou nul et/ou facilement réversible et/ou sur un périmètre très restreint.",
        impactLowLabel:
          "Modéré : l'entreprise aura un impact modéré et/ou réversible et/ou sur un périmètre limité.",
        impactMediumLabel:
          "Fort : l'entreprise aura un impact significatif et/ou difficilement réversible et/ou sur un large périmètre.",
        impactHighLabel:
          "Majeur : l'entreprise aura un impact extrêmement fort et/ou irréversible et/ou sur un périmètre très étendu.",
        probabilityLabel:
          'Quelle est la probabilité que cet impact se matérialise ou perdure dans les 3 à 5 ans ?',
        probabilityLowLabel:
          "Faible (0-25%) : il est peu probable que l'entreprise ait un impact sur cet enjeu.",
        probabilityMediumLabel:
          "Modérée (25-50%) : il est moyennement probable que l'entreprise ait un impact sur cet enjeu.",
        probabilityHighLabel:
          "Forte (50-75%) : il est fortement probable que l'entreprise ait un impact sur cet enjeu.",
        probabilityCertainLabel:
          "Certaine (75-100%) : il est certain ou presque, ou déjà avéré, que l'entreprise aura un impact sur cet enjeu.",
      },
    },
  };
}

function getUtopiesStakeDoubleMaterialityFinancialQuestionTemplate(): Partial<SurveyQuestionAddInput> {
  return {
    type: SurveyQuestionType.UtopiesStakeDoubleMateriality,
    title: "Impact financier sur l'entreprise à moyen terme",
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Financial,
      materialityOptions: {
        qualityLabel:
          'Selon vous, dans les 3 à 5 ans à venir, l’impact de cet enjeu sur les résultats financiers d’Unither Pharmaceuticals sera-t-il :',
        qualityNegativeChoiceLabel:
          "Majoritairement un risque : au global, l'enjeu est plutôt susceptible d’avoir un impact négatif sur les résultats financiers d'Unither Pharmaceuticals Exemples : réduction significative de la demande pour les produits ou services d'Unither Pharmaceuticals, enjeu de confiance ou de réputation, poursuites judiciaires majeures, coûts massifs liés à la conformité réglementaire…",
        qualityNeutralChoiceLabel:
          "Autant un risque qu’une opportunité : l'enjeu est susceptible d’avoir des impacts tant positifs que négatifs sur les résultats financiers d'Unither Pharmaceuticals. Exemples : opportunités de croissance à l’issue incertaine, changements technologiques ou sectoriels qui pourraient présenter à la fois des risques et des opportunités…",
        qualityPositiveChoiceLabel:
          "Majoritairement une opportunité : au global, l'enjeu est plutôt susceptible d’avoir un impact positif sur les résultats financiers d'Unither Pharmaceuticals. Exemples : enjeux porteurs de nouveaux relais de croissance, changements réglementaires facilitant l'entrée sur de nouveaux marchés, innovations technologiques permettant de se différencier des concurrents…",
        impactLabel:
          'Quelles pourraient être les conséquences financières de cet enjeu sur Unither Pharmaceuticals dans les 3 à 5 ans ?',
        impactNoneLabel:
          'Faibles : l’enjeu aura des conséquences faibles ou nulles sur les résultats financiers de l’entreprise.',
        impactLowLabel:
          'Modérées : l’enjeu aura des conséquences modérées sur les résultats financiers de l’entreprise.',
        impactMediumLabel:
          'Fortes : l’enjeu aura des conséquences significatives sur les résultats financiers de l’entreprise.',
        impactHighLabel:
          'Majeures : l’enjeu aura des conséquences déterminantes sur les résultats financiers de l’entreprise.',
        probabilityLabel:
          'Quelle est la probabilité que ces conséquences se matérialisent ou perdurent dans les 3 à 5 ans ?',
        probabilityLowLabel:
          'Faible (0-25%) : il est peu probable que cet enjeu ait un impact financier sur Unither Pharmaceuticals.',
        probabilityMediumLabel:
          'Modérée (25-50%) : il est possible que cet enjeu ait un impact financier (positif ou négatif) sur Unither Pharmaceuticals.',
        probabilityHighLabel:
          'Forte (50-75%) : il est fort probable que cet enjeu ait un impact financier (positif ou négatif) sur Unither Pharmaceuticals.',
        probabilityCertainLabel:
          'Certaine (75-100%) : il est certain ou presque que cet enjeu aura un impact financier (positif ou négatif) sur Unither Pharmaceuticals.',
      },
    },
  };
}
