import React, { useState } from 'react';
import Tabs, {
  TabItem,
  TabItemManager,
  TabsStyles,
} from '../../../../components/nav/Tabs';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { OwnActionTemplateList } from '../../../../components/project/templates/OwnActionTemplateList';
import { ProjectTemplatesList } from '../../../../components/project/templates/ProjectTemplatesList';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';

const enum TEMPLATES_TABS {
  TEMPLATES_ACTIONS = 'TEMPLATES_ACTIONS',
  TEMPLATES_PROJECTS = 'TEMPLATES_PROJECTS',
}

export function TemplatesSettingsScreen() {
  const tabs: TabItem[] = [
    { key: TEMPLATES_TABS.TEMPLATES_PROJECTS, name: 'Modèles de projets' },
    { key: TEMPLATES_TABS.TEMPLATES_ACTIONS, name: "Bibliothèque d'actions" },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <>
      <PageTitle title="Gérer les modèles" />
      <Breadcrumb />
      <div className="p-4">
        <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
      </div>
      <div className="main-content max-w-5xl">
        <TemplatesInnerTab currentTab={currentTab} />
      </div>
    </>
  );
}

function TemplatesInnerTab({ currentTab }: { currentTab: TabItem }) {
  switch (currentTab.key) {
    case TEMPLATES_TABS.TEMPLATES_ACTIONS:
      return <OwnActionTemplateList />;
    case TEMPLATES_TABS.TEMPLATES_PROJECTS:
    default:
      return <ProjectTemplatesList />;
  }
}
