import {
  AnalysisThemeSummaryFieldsFragment,
  AnswerThemeCreateInput,
  LinkAnalysisThemeToAnswerInput,
  ThemeColor,
  useAnalysisThemesQuery,
  useAnswerThemeCreateMutation,
  useLinkAnswerThemeMutation,
} from '../../../graphql/generated';
import { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../layout/Loader';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import AnalysisThemeTag from './AnalysisThemeTag';
import { PlusIcon, SearchIcon } from '../../icons';

export type ThemeSelectorProps = {
  selectionStartAt?: number;
  selectionEndAt?: number;
  selectedText?: string;
  cursorXPosition?: number;
  cursorYPosition?: number;
  surveyId: string;
  questionId: string;
  answerId?: string;
  setThemeSelectorProps: (props: ThemeSelectorProps | null) => void;
};

export default function ThemeSelector({
  props,
}: {
  props: ThemeSelectorProps;
}) {
  const [searchInputValue, setSearchInputValue] = useState('');

  const elRef = useRef<HTMLParagraphElement>(null);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setHeight(elRef?.current?.clientHeight || 0);
  }, []);
  setTimeout(() => {
    setHeight(elRef?.current?.clientHeight || 0);
  }, 100);

  // Avoid the theme selector to be out of the screen
  const yPosition = props.cursorYPosition
    ? props.cursorYPosition + height + 240 > window.innerHeight
      ? window.innerHeight - (height + 240)
      : props.cursorYPosition
    : null;

  return (
    <div className="bg-white">
      <div
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-100 opacity-25"
        onClick={() => props.setThemeSelectorProps(null)}
      ></div>
      <div
        className="fixed z-50 border border-gray-100 rounded-xl"
        ref={elRef}
        style={{
          left: `${props.cursorXPosition}px`,
          top: `${yPosition}px`,
        }}
      >
        <div className="py-1 px-4 flex items-center gap-2 bg-gray-50 rounded-t-xl">
          <SearchIcon className="w-4 h-4 text-gray-300" />
          <input
            type="text"
            autoFocus={true}
            value={searchInputValue || ''}
            onChange={(e) => setSearchInputValue(e.target.value)}
            className="form-input-text-inline placeholder-gray-300 px-1 placeholder:text-xs border-0"
            placeholder="Rechercher ou créer un thème"
          />
        </div>
        <CreateNewTheme searchInputValue={searchInputValue} props={props} />
        <ExistingThemesSelector
          searchInputValue={searchInputValue}
          props={props}
        />
      </div>
    </div>
  );
}

function CreateNewTheme({
  searchInputValue,
  props,
}: {
  searchInputValue: string;
  props: ThemeSelectorProps;
}) {
  const toast = useToast();
  const projectContext = useProjectContext();
  const companyId = projectContext?.enterprise?.id;
  const [answerThemeCreateMutation] = useAnswerThemeCreateMutation();
  const [isCreatingNewTheme, setIsCreatingNewTheme] = useState(false);
  const [color, setColor] = useState<ThemeColor>(ThemeColor.Green);

  const createAnalysisSurveyThemeInput = (
    props: ThemeSelectorProps,
    searchInputValue: string,
    color: ThemeColor,
    companyId: string,
  ) => {
    const input: AnswerThemeCreateInput = {
      survey: {
        id: props.surveyId,
      },
      question: {
        id: props.questionId,
      },
      analysisTheme: {
        companyId: companyId,
        name: searchInputValue,
        color: color,
      },
    };
    if (
      props.selectedText &&
      props.selectionStartAt !== null &&
      props.selectionStartAt !== undefined &&
      props.selectionEndAt
    ) {
      input.chunk = {
        content: props.selectedText,
        startAt: props.selectionStartAt,
        finishAt: props.selectionEndAt,
      };
    }
    if (props.answerId) {
      input.answer = {
        id: props.answerId,
      };
    }
    return input;
  };

  const createNewAnalysisTheme = () => {
    setIsCreatingNewTheme(true);
    answerThemeCreateMutation({
      variables: {
        input: createAnalysisSurveyThemeInput(
          props,
          searchInputValue,
          color,
          companyId || '',
        ),
      },
    })
      .then(() => {
        setIsCreatingNewTheme(false);
        props.setThemeSelectorProps(null);
      })
      .catch((err) => {
        setIsCreatingNewTheme(false);
        toast.openToastWithError(err.message);
        console.error(err);
      });
  };

  return (
    <>
      {searchInputValue && (
        <div className="bg-white flex flex-col px-2 py-4 gap-2 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <p className="px-1 text-gray-500 text-left w-full text-xs">
              Créer le nouveau thème
            </p>
            <div className="flex items-center gap-0.5">
              <div
                className={clsx(
                  'rounded-full w-3 h-3 border border-transparent bg-green-300 cursor-pointer',
                  color === ThemeColor.Green && 'border-gray-900',
                )}
                onClick={() => setColor(ThemeColor.Green)}
              ></div>
              <div
                className={clsx(
                  'rounded-full w-3 h-3 border border-transparent bg-gray-300 cursor-pointer',
                  color === ThemeColor.Gray && 'border-gray-900',
                )}
                onClick={() => setColor(ThemeColor.Gray)}
              ></div>
              <div
                className={clsx(
                  'rounded-full w-3 h-3 border border-transparent bg-blue-300 cursor-pointer',
                  color === ThemeColor.Blue && 'border-gray-900',
                )}
                onClick={() => setColor(ThemeColor.Blue)}
              ></div>
              <div
                className={clsx(
                  'rounded-full w-3 h-3 border border-transparent bg-yellow-300 cursor-pointer',
                  color === ThemeColor.Yellow && 'border-gray-900',
                )}
                onClick={() => setColor(ThemeColor.Yellow)}
              ></div>
              <div
                className={clsx(
                  'rounded-full w-3 h-3 border border-transparent bg-red-300 cursor-pointer',
                  color === ThemeColor.Red && 'border-gray-900',
                )}
                onClick={() => setColor(ThemeColor.Red)}
              ></div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {isCreatingNewTheme ? (
              <Loader />
            ) : (
              <div
                className={clsx('tag cursor-pointer', color)}
                onClick={() => createNewAnalysisTheme()}
              >
                <PlusIcon className="w-3 h-3" />
                <span className="font-semibold">{searchInputValue}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function ExistingThemesSelector({
  searchInputValue,
  props,
}: {
  searchInputValue: string;
  props: ThemeSelectorProps;
}) {
  const [isLinkingTheme, setIsLinkingTheme] = useState(false);
  const toast = useToast();

  // Fetch all available themes
  const projectContext = useProjectContext();
  const companyId = projectContext?.enterprise?.id;
  const { data, loading } = useAnalysisThemesQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const [linkAnswerThemeMutation] = useLinkAnswerThemeMutation();

  const linkAnalysisSurveyThemeInput = (
    props: ThemeSelectorProps,
    themeId: string,
  ) => {
    const input: LinkAnalysisThemeToAnswerInput = {
      survey: {
        id: props.surveyId,
      },
      question: {
        id: props.questionId,
      },
      analysisTheme: {
        id: themeId,
      },
    };

    if (
      props.selectedText &&
      props.selectionStartAt !== null &&
      props.selectionStartAt !== undefined &&
      props.selectionEndAt
    ) {
      input.chunk = {
        content: props.selectedText,
        startAt: props.selectionStartAt,
        finishAt: props.selectionEndAt,
      };
    }
    if (props.answerId) {
      input.answer = {
        id: props.answerId,
      };
    }
    return input;
  };

  const selectTheme = (theme: AnalysisThemeSummaryFieldsFragment) => {
    setIsLinkingTheme(true);
    linkAnswerThemeMutation({
      variables: {
        input: linkAnalysisSurveyThemeInput(props, theme.id),
      },
    })
      .then(() => {
        setIsLinkingTheme(false);
        props.setThemeSelectorProps(null);
      })
      .catch((err) => {
        setIsLinkingTheme(false);
        toast.openToastWithError(err.message);
        console.error(err);
      });
  };

  const filterAndSortAnalysisThemes = (
    themes: AnalysisThemeSummaryFieldsFragment[],
    searchedText: string,
  ) => {
    return themes
      .filter((theme) =>
        theme.name.toLowerCase().includes(searchedText.toLowerCase()),
      )
      .toSorted((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
  };

  const themes = data?.analysisThemes
    ? filterAndSortAnalysisThemes(data.analysisThemes, searchInputValue)
    : [];

  return (
    <div className="bg-white rounded-b-xl">
      {loading ? (
        <div className="p-4">
          <LoaderFullscreen />
        </div>
      ) : isLinkingTheme ? (
        <div className="p-4">
          <LoaderFullscreen />
        </div>
      ) : themes.length === 0 ? (
        <div className="bg-white flex flex-col px-2 py-4 gap-2 rounded-b-xl max-h-56 overflow-scroll">
          {searchInputValue ? (
            <p className="px-1 text-gray-500 text-left w-full text-xs">
              Aucun thème trouvé
            </p>
          ) : (
            <p className="px-1 text-gray-500 text-left w-full text-xs">
              Aucun thème existant
            </p>
          )}
        </div>
      ) : (
        themes.length > 0 && (
          <div className="bg-white flex flex-col px-2 py-4 gap-2 rounded-b-xl max-h-56 overflow-scroll">
            <p className="px-1 text-gray-500 text-left w-full text-xs">
              Tous les thèmes
            </p>
            {themes.map((theme) => (
              <div
                key={theme.id}
                onClick={() => selectTheme(theme)}
                className="cursor-pointer"
              >
                <AnalysisThemeTag analysisTheme={theme} />
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
}
