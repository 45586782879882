import clsx from 'clsx';
import { PriceLevel } from '../../graphql/generated';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { NumberCircle, NumberCircleColor } from '../generic/NumberCircle';
import { getTooltipUniqueId } from '../../services/UtilsService';
import { EuroIcon } from '../icons';

export function PriceBadge({
  priceLevel,
  className,
}: {
  priceLevel: PriceLevel;
  className?: string;
}) {
  const id = getTooltipUniqueId('PriceBadge');
  const price = priceLevelToNumber(priceLevel);
  return (
    <div
      className={clsx('flex items-center cursor-help', className)}
      data-tooltip-id={id}
    >
      <PriceBadgeTooltip priceLevel={priceLevel} id={id} />
      <EuroIcon
        className={clsx(
          '-mx-0.5',
          price.valueOf() > 0 ? 'text-gray-900' : 'text-gray-300',
        )}
      />
      <EuroIcon
        className={clsx(
          '-mx-0.5',
          price.valueOf() > 1 ? 'text-gray-900' : 'text-gray-300',
        )}
      />
      <EuroIcon
        className={clsx(
          '-mx-0.5',
          price.valueOf() > 2 ? 'text-gray-900' : 'text-gray-300',
        )}
      />
      <EuroIcon
        className={clsx(
          '-mx-0.5',
          price.valueOf() > 3 ? 'text-gray-900' : 'text-gray-300',
        )}
      />
    </div>
  );
}

export function priceLevelToNumber(priceLevel: PriceLevel): number {
  switch (priceLevel) {
    case PriceLevel.LessThan_1k:
      return 1;
    case PriceLevel.LessThan_5k:
      return 2;
    case PriceLevel.LessThan_15k:
      return 3;
    case PriceLevel.MoreThan_15k:
      return 4;
  }
}

export function PriceBadgeTooltip({
  priceLevel,
  id,
}: {
  priceLevel: PriceLevel;
  id: string;
}) {
  return (
    <Tooltip id={id} className="tooltip">
      <p className="title-h6 mb-1">Coût</p>
      <ol className="flex flex-col gap-1">
        <PriceItem
          index={1}
          priceLevel={PriceLevel.LessThan_1k}
          isActive={priceLevel === PriceLevel.LessThan_1k}
        />
        <PriceItem
          index={2}
          priceLevel={PriceLevel.LessThan_5k}
          isActive={priceLevel === PriceLevel.LessThan_5k}
        />
        <PriceItem
          index={3}
          priceLevel={PriceLevel.LessThan_15k}
          isActive={priceLevel === PriceLevel.LessThan_15k}
        />
        <PriceItem
          index={4}
          priceLevel={PriceLevel.MoreThan_15k}
          isActive={priceLevel === PriceLevel.MoreThan_15k}
        />
      </ol>
    </Tooltip>
  );
}

function PriceItem({
  priceLevel,
  index,
  isActive,
}: {
  priceLevel: PriceLevel;
  index: number;
  isActive: boolean;
}) {
  return (
    <li className={clsx('flex items-center gap-1', isActive || 'opacity-70')}>
      <NumberCircle
        number={index}
        size={8}
        color={
          isActive ? colorFromPriceLevel(priceLevel) : NumberCircleColor.Gray
        }
      />
      <span className={clsx(isActive ? 'font-bold' : 'font-normal')}>
        {labelFromPriceLevel(priceLevel)}
      </span>
    </li>
  );
}

export function labelFromPriceLevel(priceLevel: PriceLevel): string {
  switch (priceLevel) {
    case PriceLevel.LessThan_1k:
      return '< 1 000€';
    case PriceLevel.LessThan_5k:
      return '1 000 à 5 000€';
    case PriceLevel.LessThan_15k:
      return '5 000 à 15 000€';
    case PriceLevel.MoreThan_15k:
      return '> 15 000€';
  }
}

export function colorFromPriceLevel(priceLevel: PriceLevel): NumberCircleColor {
  switch (priceLevel) {
    case PriceLevel.LessThan_1k:
      return NumberCircleColor.Gray;
    case PriceLevel.LessThan_5k:
      return NumberCircleColor.Yellow;
    case PriceLevel.LessThan_15k:
      return NumberCircleColor.Purple;
    case PriceLevel.MoreThan_15k:
      return NumberCircleColor.Red;
  }
}
