import {
  SurveyResponsesDocument,
  useAddContactToStakeholdersButton_StakeholderCreateMutation,
} from '../../../../graphql/generated';
import React from 'react';
import { Loader } from '../../../generic/Loader';
import { PlusIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function AddContactToStakeholdersButton({
  surveyId,
  respondent,
}: {
  surveyId: string;
  respondent: {
    email: string;
    name?: string | null;
  };
}) {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = React.useState(false);
  const [createStakeholder] =
    useAddContactToStakeholdersButton_StakeholderCreateMutation();
  const addToRecipients = () => {
    setIsAdding(true);
    createStakeholder({
      variables: {
        input: {
          stakeholderSurvey: {
            survey: {
              id: surveyId,
            },
            email: respondent.email,
            firstName: respondent.name || respondent.email,
            lastName: '',
          },
        },
      },
      refetchQueries: [
        {
          query: SurveyResponsesDocument,
          variables: {
            surveyId,
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsAdding(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isAdding}
      onClick={addToRecipients}
    >
      {isAdding ? <Loader /> : <PlusIcon />}
      <div>{t('survey.responses.contextualMenu.addToStakeholders')}</div>
    </button>
  );
}
