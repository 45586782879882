import React, { useCallback, useRef } from 'react';
import { useToast } from '../../../layout/Toast';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { CompanyLogoInput } from '../CompanyLogoInput';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  CompanySector,
  CompanyUpdateInfo_CompanyDocument,
  EnterpriseStructureType,
  useCompanyUpdateInfo_CompanyQuery,
  useCompanyUpdateInfo_CompanyUpdateMutation,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown';
import { EnterpriseInformationForm } from './EnterpriseForm';

export function CompanyUpdateInfo({
  companyId,
  deleteAction,
  callback,
}: {
  companyId: string;
  deleteAction?: React.ReactNode;
  callback?: () => void;
}) {
  const { t } = useTranslation();
  const formRef = useRef<{ submit: () => void } | null>(null);
  const companyQuery = useCompanyUpdateInfo_CompanyQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateCompanyMutation, { loading }] =
    useCompanyUpdateInfo_CompanyUpdateMutation();
  const toast = useToast();
  const projectContext = useProjectContext();

  const handleSave = useCallback(
    (data: {
      name: string;
      sector?: string;
      size?: number;
      annualTurnover?: string;
      country?: string;
      identificationNumber?: string;
      structureType?: StringDropdownItem | null;
    }) => {
      if (!companyQuery.data?.enterprise) {
        throw new Error('enterprise id is required');
      }
      updateCompanyMutation({
        variables: {
          input: {
            id: companyQuery.data.enterprise.id,
            name: data.name,
            structureType: data.structureType?.id as EnterpriseStructureType,
            country: data.country,
            metadata: {
              sector: data.sector as CompanySector,
              size: data.size,
              annualTurnover: data.annualTurnover,
              identificationNumber: data.identificationNumber,
            },
          },
        },
        refetchQueries: [
          {
            query: CompanyUpdateInfo_CompanyDocument,
            variables: {
              id: companyId,
            },
          },
        ],
      })
        .then(() => {
          toast.openToastWithMessage(
            t('toast:company.settings.updated.success'),
          );
          projectContext?.refreshEnterprise();
          if (callback) {
            callback();
          }
        })
        .catch((err: { message: string }) => {
          toast.openToastWithError(err.message);
        });
    },
    [companyQuery],
  );

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-8 divide-y divide-gray-100">
        <div className="grid grid-cols-2 gap-8 divide-x divide-gray-100">
          {companyQuery.data?.enterprise && (
            <EnterpriseInformationForm
              ref={formRef}
              initialData={{
                name: companyQuery.data.enterprise.name,
                country: companyQuery.data.enterprise.country ?? undefined,
                sector:
                  companyQuery.data.enterprise.metadata?.sector ?? undefined,
                size: companyQuery.data.enterprise.metadata?.size ?? undefined,
                identificationNumber:
                  companyQuery.data.enterprise.metadata?.identificationNumber ??
                  undefined,
                annualTurnover:
                  companyQuery.data.enterprise.metadata?.annualTurnover ??
                  undefined,
                structureType:
                  companyQuery.data.enterprise.structureType ?? undefined,
              }}
              onSave={handleSave}
              isLoading={companyQuery.loading || loading}
            />
          )}
          <div className="pl-4">
            {companyQuery.data?.enterprise && (
              <CompanyLogoInput company={companyQuery.data.enterprise} />
            )}
          </div>
        </div>
        <div className="py-8 flex justify-between flex-row-reverse">
          <button className="primary" onClick={() => formRef.current?.submit()}>
            {t('global:save')}
          </button>
          {deleteAction && deleteAction}
        </div>
      </div>
    </div>
  );
}
