import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Theme } from '../../graphql/generated';
import { CheckIcon, ChevronDownIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';
import { ThemeIcon } from '../badges/ThemeIcon';
import { Float } from '@headlessui-float/react';

const allThemes: Theme[] = [
  Theme.Environment,
  Theme.Collaborators,
  Theme.Governance,
  Theme.Ecosystem,
];

export default function ThemeFilterDropdown({
  selectedTheme,
  setFilterByTheme,
  displayAllThemes = true,
  inlined = false,
}: {
  selectedTheme: Theme | null;
  setFilterByTheme: (filterByTheme: Theme | null) => void;
  displayAllThemes?: boolean;
  inlined?: boolean;
}) {
  const { t } = useTranslation();

  const availableThemes = displayAllThemes ? [null, ...allThemes] : allThemes;

  const getTranslatedThemeLabel = (theme: Theme | null) => {
    if (theme) {
      return t(`enum:theme.${theme?.toLowerCase()}`);
    }
    return t('global:all');
  };

  return (
    <Listbox value={selectedTheme} onChange={setFilterByTheme}>
      {({ open }) => (
        <Float>
          <ListboxButton
            className={clsx(
              'form-input-select ',
              inlined
                ? 'border-0 shadow-none flex items-center justify-center'
                : 'form-input-text py-2 pl-3 pr-10 ',
            )}
          >
            <span
              className={clsx(
                'flex items-center w-full',
                inlined && 'bg-gray-900 text-white py-2 px-2 rounded-md ',
              )}
            >
              {selectedTheme && (
                <ThemeIcon theme={selectedTheme} className="w-5 h-5" />
              )}
              {inlined || (
                <span className="ml-3 block truncate">
                  {getTranslatedThemeLabel(selectedTheme)}
                </span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {availableThemes.map((availableTheme) => (
                <ListboxOption
                  key={availableTheme}
                  className={clsx(
                    'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-[focus]:text-white data-[focus]:bg-blue-600',
                  )}
                  value={availableTheme}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        {availableTheme && (
                          <ThemeIcon
                            theme={availableTheme}
                            className="w-5 h-5"
                          />
                        )}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block',
                          )}
                        >
                          {getTranslatedThemeLabel(availableTheme)}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </Float>
      )}
    </Listbox>
  );
}
