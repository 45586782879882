import React, { useState } from 'react';
import {
  FinancialOpportunityRow_OpportunityIroFragment,
  FinancialRiskRow_RiskIroFragment,
  IroEvaluationFragment,
  IroType,
  NegativeImpactRow_NegativeImpactIroFragment,
  PositiveImpactRow_PositiveImpactIroFragment,
  useIroEvaluation_IroQuery,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { ImpactMaterialityScore } from './scores/ImpactMaterialityScore';
import { IroEvaluationHeader } from './IroEvaluationHeader';
import { tidyIros } from '@hooks/tidyIros';
import { FinancialMaterialityScore } from './scores/FinancialMaterialityScore';
import { LoaderFullscreen } from '../../../layout/Loader';
import { withRouter } from '../../../../screens/withRouter';
import { IroRow } from './row/IroRow';

export type IroEvaluationProps = {
  referential: IroEvaluationFragment;
  params?: { stakeId: string; pillarId: string };
};

export const IroEvaluation = withRouter<IroEvaluationProps>(
  ({ referential, params: { pillarId, stakeId } }) => {
    const { t } = useTranslation();

    const pillars = referential.pillars;
    const firstPillar = pillars[0] || null;
    const [currentPillarId, setCurrentPillarId] = useState<string | null>(
      pillarId ?? firstPillar.id,
    );
    const pillar =
      pillars.find((pillar) => pillar.id === currentPillarId) || null;
    const changePillar = (id: string | null) => {
      setCurrentPillarId(id);
      // Try to fetch first stake of this pillar
      const firstPillarStakes = pillars.find(
        (pillar) => pillar.id === id,
      )?.stakes;
      const firstStake = firstPillarStakes ? firstPillarStakes[0] : null;
      setCurrentStakeId(firstStake?.id ?? null);
    };

    const stakes = pillars.flatMap((pillar) => pillar.stakes);
    const firstStake = stakes[0] || null;
    const [currentStakeId, setCurrentStakeId] = useState<string | null>(
      stakeId ?? firstStake.id,
    );
    const stake = stakes.find((stake) => stake.id === currentStakeId) || null;

    const query = useIroEvaluation_IroQuery({
      variables: {
        stakeId: currentStakeId ?? '',
      },
      fetchPolicy: 'cache-and-network',
    });

    const { positiveImpacts, negativeImpacts, opportunities, risks } = tidyIros<
      FinancialRiskRow_RiskIroFragment,
      FinancialOpportunityRow_OpportunityIroFragment,
      PositiveImpactRow_PositiveImpactIroFragment,
      NegativeImpactRow_NegativeImpactIroFragment
    >(query.data?.iros ?? []);

    return (
      <>
        <IroEvaluationHeader
          stake={stake}
          setStakeId={setCurrentStakeId}
          pillar={pillar}
          setPillarId={changePillar}
        ></IroEvaluationHeader>
        {query.loading && <LoaderFullscreen />}
        {!query.loading && stake && (
          <div className="space-y-4 p-8">
            <div className="flex items-center justify-between">
              <h2 className="title-h4">{t('screen.iro.company_impact')}</h2>
              <ImpactMaterialityScore stake={stake} />
            </div>
            <IroRow
              stakeId={stake.id}
              iros={negativeImpacts ?? []}
              iroType={IroType.NegativeImpact}
            />
            <IroRow
              stakeId={stake.id}
              iros={positiveImpacts ?? []}
              iroType={IroType.PositiveImpact}
            />
            <hr />
            <div className="flex items-center justify-between">
              <h2 className="title-h4">
                {t('screen.iro.company_financial_impact')}
              </h2>
              <FinancialMaterialityScore stake={stake} />
            </div>
            <IroRow
              stakeId={stake.id}
              iros={risks ?? []}
              iroType={IroType.Risk}
            />
            <IroRow
              stakeId={stake.id}
              iros={opportunities ?? []}
              iroType={IroType.Opportunity}
              lastSection={true}
            />
          </div>
        )}
      </>
    );
  },
);
