import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDownIcon } from '../../../icons';
import { Float } from '@headlessui-float/react';
import SecondaryButton from './SecondaryButton';

interface SplitButtonProps {
  onClick: () => void;
  secondaryActions: {
    title: ReactNode;
    onClick: () => void;
    icon?: ReactNode;
    description?: ReactNode;
  }[];
  children: ReactNode;
  className?: string;
}

const SplitButton: React.FC<SplitButtonProps> = ({
  onClick,
  secondaryActions,
  children,
  className = 'primary purple py-2 px-4',
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuStyles, setMenuStyles] = useState({});

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      const nextSibling = buttonRef.current
        .nextElementSibling as HTMLElement | null;
      const nextSiblingWidth = nextSibling ? nextSibling.clientWidth : 0;
      setMenuStyles({
        left: `-${buttonWidth}px`,
        width: `${buttonWidth + nextSiblingWidth}px`,
        transform: `translateX(${nextSiblingWidth}px)`,
      });
    }
  }, []);

  return (
    <div className="relative inline-flex text-left">
      <button
        ref={buttonRef}
        onClick={onClick}
        className={clsx(
          className,
          'flex-grow rounded-none rounded-l-md focus:outline-none border-r-2 border-r-solid !border-r-purple-900',
        )}
      >
        {children}
      </button>
      <Menu as="div">
        <Float portal>
          <MenuButton
            className={clsx(
              'rounded-none rounded-r-md focus:outline-none flex items-center justify-center min-h-full px-4',
              className,
            )}
          >
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </MenuButton>
          <MenuItems
            className="absolute -ml-14 mt-1 flex flex-col gap-1"
            style={menuStyles}
          >
            {secondaryActions.map((action, index) => (
              <MenuItem key={index}>
                <SecondaryButton
                  onClick={action.onClick}
                  icon={action.icon}
                  title={action.title}
                  description={action.description}
                />
              </MenuItem>
            ))}
          </MenuItems>
        </Float>
      </Menu>
    </div>
  );
};

export default SplitButton;
