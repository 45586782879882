import { useState } from 'react';
import {
  DiagnosticStakeUpdateInput,
  StakeMaterialityToggle_StakeFragment,
  useStakeMaterialityToggle_StakeUpdateMutation,
} from '../../../graphql/generated';
import { Toggle } from '../../generic/form/Toggle';
import { Loader } from '../../generic/Loader';
import { TooltipWrapper } from '../../generic/TooltipWrapper';

export default function StakeMaterialityToggle({
  stake,
  disabled = false,
}: {
  stake: StakeMaterialityToggle_StakeFragment;
  disabled?: boolean;
}) {
  const [isStakeEnabled, setIsStakeEnabled] = useState(!stake.isDisabled);
  const [isUpdating, setIsUpdating] = useState(false);

  const [editDiagnosticStakes] =
    useStakeMaterialityToggle_StakeUpdateMutation();
  const editDiagnosticStake = (input: DiagnosticStakeUpdateInput) => {
    setIsUpdating(true);
    editDiagnosticStakes({
      variables: {
        input,
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const toggleStakeStatus = (newState: boolean) => {
    setIsStakeEnabled(newState);
    if (newState) {
      editDiagnosticStake({
        id: stake.id,
        isDisabled: false,
      });
    } else {
      editDiagnosticStake({
        id: stake.id,
        isDisabled: true,
      });
    }
  };

  if (isUpdating) {
    return (
      <div className="w-11 flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (disabled) {
    return (
      <Toggle
        state={isStakeEnabled}
        disabled={disabled}
        setState={toggleStakeStatus}
      />
    );
  }

  return (
    <TooltipWrapper
      place={'bottom'}
      label={
        isStakeEnabled
          ? 'Déclarer cet enjeu non matériel'
          : 'Déclarer cet enjeu matériel'
      }
    >
      <Toggle
        state={isStakeEnabled}
        disabled={disabled}
        setState={toggleStakeStatus}
      />
    </TooltipWrapper>
  );
}
