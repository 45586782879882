import {
  AnalysisTheme,
  AnalysisThemeFieldsFragment,
  HighlightableSurveyAnswer_AnalysisThemeFragment,
  HighlightableSurveyAnswer_AnswerFragment,
  SurveyResponseChunkFieldsFragment,
  ThemeColor,
} from '../../../graphql/generated';
import React, { useRef, useState } from 'react';
import ThemeSelector, { ThemeSelectorProps } from './ThemeSelector';
import { getThemeBgColor } from './AnalysisThemeTag';
import clsx from 'clsx';

// A chunk is a part of a text that is associated with a theme (which has a color)
type HtmlChunk = {
  startAt: number;
  endAt: number;
  text: string;
  themes?: AnalysisTheme[];
};

export function HighlightableSurveyAnswer({
  answer,
  highlightedThemeInParentComponent,
}: {
  answer: HighlightableSurveyAnswer_AnswerFragment;
  highlightedThemeInParentComponent?: HighlightableSurveyAnswer_AnalysisThemeFragment | null;
}) {
  const text = answer.text || answer.number?.toString() || '';

  // Remove end of line characters
  // Replace also double spaces by single spaces
  const sanitizedText = text
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/ +(?= )/g, '');

  const myRef = useRef<HTMLDivElement>(null);

  const [
    highlightedThemeInCurrentComponent,
    setHighlightedThemeInCurrentComponent,
  ] = useState<AnalysisThemeFieldsFragment | null>(null);
  const highlightedTheme =
    highlightedThemeInCurrentComponent ||
    highlightedThemeInParentComponent ||
    null;

  // Also used to trigger ThemeSelector component rendering
  const [themeSelectorProps, setThemeSelectorProps] =
    useState<ThemeSelectorProps | null>(null);

  const answerThemeChunks: SurveyResponseChunkFieldsFragment[] = answer.themes
    ? (answer.themes
        // Remove null chunk values
        .filter((answerTheme) => answerTheme.chunk)
        .map((answerTheme) => {
          return answerTheme.chunk;
        }) as SurveyResponseChunkFieldsFragment[])
    : [];

  // Get list of start and end from ranges
  const rangesStartEndList = answerThemeChunks
    // Remove null or undefined values
    .filter((range) => range)
    .flatMap((range) => [range.startAt, range.finishAt]);
  // Don't forget to include beginning and end of the text, sort & dedup
  const startEndList = [0, ...rangesStartEndList, sanitizedText.length]
    .toSorted((a, b) => a - b)
    .filter((value, index, array) => array.indexOf(value) === index);

  // Split text into chunks, based on indexes from startEndList
  const htmlChunks: HtmlChunk[] = startEndList.reduce(
    (acc: HtmlChunk[], startEnd, index) => {
      const nextStartEnd = startEndList[index + 1];
      const themes = answer.themes
        ? (answer.themes
            .filter((range) => {
              return (
                range.chunk &&
                range.chunk.startAt <= startEnd &&
                range.chunk.finishAt > startEnd
              );
            })
            .map((range) => range.analysisTheme) as AnalysisTheme[])
        : [];
      const htmlChunk = {
        startAt: startEnd,
        endAt: nextStartEnd,
        text: sanitizedText.substring(startEnd, nextStartEnd),
        themes,
      };
      return [...acc, htmlChunk];
    },
    [],
  );

  // Text to be displayed is the concatenation of all chunks
  return (
    <div className="relative">
      {themeSelectorProps && <ThemeSelector props={themeSelectorProps} />}
      <div
        ref={myRef}
        className="shadow-sm rounded-2xl bg-white p-4 border border-transparent hover:border-gray-900"
        onMouseUp={(e) => {
          const selection = window.getSelection();
          if (selection && selection.anchorOffset !== selection.focusOffset) {
            const selectedText = selection.toString();

            // Search for selected text index in the whole text
            const selectionStartAt: number =
              sanitizedText.indexOf(selectedText);

            const cursorXPosition = myRef.current
              ? e.clientX - myRef.current.getBoundingClientRect().left
              : e.clientX;
            const cursorYPosition = e.clientY;

            // Warning: here we assume that the selected text is matching exactly the searched text
            setThemeSelectorProps({
              selectionStartAt: selectionStartAt,
              selectionEndAt: selectionStartAt + selectedText.length,
              selectedText: sanitizedText.substring(
                selectionStartAt,
                selectionStartAt + selectedText.length,
              ),
              answerId: answer.id,
              questionId: answer.question.id,
              cursorXPosition,
              cursorYPosition,
              surveyId: answer.question.survey?.id || '',
              setThemeSelectorProps: setThemeSelectorProps,
            });
          }
        }}
      >
        {htmlChunks.map((chunk) => {
          const mainColor =
            chunk.themes && chunk.themes.length > 0
              ? chunk.themes[chunk.themes.length - 1].color
              : '';
          const isHighlighted =
            chunk.themes &&
            chunk.themes.length > 0 &&
            chunk.themes
              .map((theme) => theme.id)
              .includes(highlightedTheme?.id || '');
          const classColors = getThemeBgColor(
            mainColor,
            isHighlighted,
            highlightedTheme,
          );
          const highlightedColors = getThemeHighlightedColor(highlightedTheme);
          return (
            <span
              key={chunk.startAt}
              className={clsx(
                mainColor && `border-b-2 border-transparent`,
                isHighlighted ? highlightedColors : mainColor && classColors,
              )}
              onMouseOver={() => {
                if (
                  chunk.themes &&
                  chunk.themes.length > 0 &&
                  chunk.themes[0]
                ) {
                  setHighlightedThemeInCurrentComponent(chunk.themes[0]);
                }
              }}
              onMouseLeave={() => {
                setHighlightedThemeInCurrentComponent(null);
              }}
            >
              {chunk.text}
            </span>
          );
        })}
      </div>
    </div>
  );
}

function getThemeHighlightedColor(
  theme: HighlightableSurveyAnswer_AnalysisThemeFragment | null,
) {
  switch (theme?.color) {
    case ThemeColor.Blue:
      return 'bg-blue-100 border-blue-300';
    case ThemeColor.Red:
      return 'bg-red-100 border-red-300';
    case ThemeColor.Green:
      return 'bg-green-100 border-green-300';
    case ThemeColor.Purple:
      return 'bg-purple-100 border-purple-300';
    case ThemeColor.Yellow:
      return 'bg-yellow-100 border-yellow-300';
    case ThemeColor.Gray:
    default:
      return 'bg-gray-100 border-gray-300';
  }
}
