import React from 'react';
import {
  ExportCommentsToXlsButton_AnswerFragment,
  ExportCommentsToXlsButton_StakeFragment,
  StakesDoubleMaterialityComments_AnswerFragment,
  StakesDoubleMaterialityComments_StakeFragment,
} from '../../../../../graphql/generated';
import { StakeTag } from '../../../../stake/StakeTag';
import { utils, writeFileXLSX } from 'xlsx';
import { TooltipWrapper } from '../../../../generic/TooltipWrapper';
import { DownloadIcon, QuoteIcon } from '../../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function StakesDoubleMaterialityComments({
  stakes,
  answers,
}: {
  stakes: StakesDoubleMaterialityComments_StakeFragment[];
  answers: StakesDoubleMaterialityComments_AnswerFragment[];
}) {
  // Simple list of answer.stakes.comment (no matrix)
  // Grouped by stake (stake -> array of comments)
  const comments: {
    stake: StakesDoubleMaterialityComments_StakeFragment;
    comments: string[];
  }[] = answers
    .flatMap((answer) => answer.stakes)
    .filter((stakeAnswer) => stakeAnswer?.comment)
    .reduce(
      (
        acc: {
          stake: StakesDoubleMaterialityComments_StakeFragment;
          comments: string[];
        }[],
        stakeAnswer,
      ) => {
        const stake = stakes.find((stake) => stake.id === stakeAnswer?.stakeId);
        if (stake) {
          const stakeComments = acc.find(
            (stakeComment) => stakeComment.stake.id === stake.id,
          );
          if (stakeComments) {
            stakeComments.comments.push(stakeAnswer?.comment || '');
          } else {
            acc.push({ stake, comments: [stakeAnswer?.comment || ''] });
          }
        }
        return acc;
      },
      [],
    );

  if (comments.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-4">
        <h6>Commentaires libres</h6>
        <ExportCommentsToXlsButton answers={answers} stakes={stakes} />
      </div>

      <div className="space-y-4">
        {comments.map((comment, index) => (
          <div key={index} className="space-y-2">
            <div className="flex">
              <StakeTag
                stake={comment.stake}
                disableDetailModalOpening={true}
              />
            </div>
            <div className="space-y-4">
              {comment.comments.map((comment, index) => (
                <div className="flex items-start gap-2" key={index}>
                  <QuoteIcon className="shrink-0 text-gray-300" />
                  <div className="bg-white p-2 text-sm border-l-2 border-gray-100">
                    {comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function ExportCommentsToXlsButton({
  stakes,
  answers,
}: {
  stakes: ExportCommentsToXlsButton_StakeFragment[];
  answers: ExportCommentsToXlsButton_AnswerFragment[];
}) {
  const { translateProperty } = useTranslation();
  const exportToXls = () => {
    const worksheet = utils.json_to_sheet(
      answers
        .flatMap((answer) => answer.stakes)
        .filter((stakeAnswer) => stakeAnswer?.comment)
        .map((stakeAnswer) => {
          const stake = stakes.find(
            (stake) => stake.id === stakeAnswer?.stakeId,
          );
          const stakeName = stake ? translateProperty(stake, 'name') : '';
          return {
            Stake: stakeName,
            Commentaire: stakeAnswer?.comment,
          };
        }),
    );

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'commentaires');
    writeFileXLSX(workbook, 'Export commentaires.xlsx', {
      compression: true,
    });
  };

  return (
    <TooltipWrapper label="Exporter les commentaires en XLS">
      <button className="tertiary" onClick={exportToXls}>
        <DownloadIcon />
      </button>
    </TooltipWrapper>
  );
}
