import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import { useToast } from '../../layout/Toast';
import RichTextEditorTextarea from '../../generic/form/RichTextEditorTextarea';
import {
  UpdateNoteModal_NoteFragment,
  useUpdateNoteModal_NoteUpdateMutation,
} from '../../../graphql/generated';

export function UpdateNoteModal({
  note,
}: {
  note: UpdateNoteModal_NoteFragment;
}) {
  const toast = useToast();
  const modal = useModal();

  const [title, setTitle] = useState(note.title);
  const [content, setContent] = useState(note.content || '');

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateNoteMutation] = useUpdateNoteModal_NoteUpdateMutation();
  const updateNote = () => {
    setIsUpdating(true);
    updateNoteMutation({
      variables: {
        input: {
          id: note.id,
          title: title,
          content: content,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Note mise à jour');
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-2xl min-w-[50rem] h-full">
      <div className="grow overflow-y-scroll space-y-2 p-4">
        <input
          type="text"
          id="title"
          placeholder="Nom de la note"
          className="form-input-text-inline font-extrabold font-title text-xl px-4"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <RichTextEditorTextarea
          value={content}
          onChange={(value) => setContent(value)}
          placeholder="Votre note..."
          inlined={true}
        />
      </div>

      <div className="flex gap-2 justify-between p-4 border-t border-gray-100">
        <button className="secondary purple" onClick={() => modal.closeModal()}>
          Annuler
        </button>
        <button
          className="primary purple"
          onClick={() => updateNote()}
          disabled={isUpdating || !title}
        >
          {isUpdating ? <Loader /> : 'Modifier'}
        </button>
      </div>
    </div>
  );
}
