import React from 'react';
import { ChartData } from 'chart.js';
import { utils, writeFileXLSX } from 'xlsx';
import { BubbleDataPoint } from 'chart.js/auto';
import { convertArrayToObject } from '../../services/FileService';
import { DownloadIcon } from '../icons';

export function ExportChartJsToXlsButton({
  xAxisLabel,
  yAxisLabel,
  data,
}: {
  xAxisLabel: string;
  yAxisLabel: string;
  data: ChartData;
}) {
  const exportToXls = () => {
    const header = ['Enjeu', xAxisLabel, yAxisLabel, 'Classement'];

    const rows: (string | number | undefined)[][] = data.datasets.map(
      (dataset) => {
        // Ignore "Ligne de consensus" dataset
        if (dataset.label?.toLowerCase() === 'ligne de consensus') {
          return [];
        }

        return dataset.data.flatMap((datasetData) => {
          const bubbleData = datasetData as BubbleDataPoint;
          return [dataset.label, bubbleData.x, bubbleData.y, bubbleData.r];
        });
      },
    );

    // Convert array to object to be able to use xlsx library
    const asAnObject = convertArrayToObject(rows, header);

    const worksheet = utils.json_to_sheet(asAnObject, {
      skipHeader: false,
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'matrice');
    writeFileXLSX(workbook, 'Export matrice.xlsx', {
      compression: true,
    });
  };

  return (
    <button className="contextual-menu-item" onClick={exportToXls}>
      <DownloadIcon />
      <div>Fichier XLS</div>
    </button>
  );
}
