import React from 'react';
import { StakeTag } from '../../../../stake/StakeTag';
import { ListItemRank } from '../../../../generic/ListItemRank';
import clsx from 'clsx';
import { TStakeMateriality } from './StakesMaterialityChart';
import { useTranslation } from '@hooks/useTranslation';

export function StakesMaterialityLegend({
  stakesMaterialities,
  filteredStakeIds,
  isExportToImageMode,
  stakesIdsToExport,
  setStakesIdsToExport,
}: {
  stakesMaterialities: TStakeMateriality[];
  filteredStakeIds: string[];
  isExportToImageMode: boolean;
  stakesIdsToExport: string[];
  setStakesIdsToExport: (stakesIds: string[]) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 w-full h-full space-y-4">
      <div className="flex items-center justify-between gap-1 sticky top-0 bg-white">
        <div
          className={clsx(
            'flex items-center gap-1',
            isExportToImageMode ? 'w-8/12' : 'w-9/12',
          )}
        >
          {isExportToImageMode ? (
            <h6>
              {t('charts.materiality_matrix.legend.select_stakes_to_export')}
            </h6>
          ) : (
            <h6>{t('charts.materiality_matrix.legend.stakes')}</h6>
          )}
        </div>
        <div className="text-gray-500 text-xs font-bold w-3/12 text-center">
          {t('charts.materiality_matrix.legend.materiality')}
        </div>
        {isExportToImageMode && (
          <div className="text-gray-500 text-xs font-bold w-1/12">
            {t('charts.materiality_matrix.legend.export')}
          </div>
        )}
      </div>
      <div className="w-full space-y-1 overflow-y-scroll">
        {stakesMaterialities.map((stakeValue, index) => {
          return (
            <div
              key={stakeValue.stake.id}
              className={clsx(
                'flex items-center gap-0.5 shrink-0 w-full transition duration-200 ease-in-out',
                filteredStakeIds.includes(stakeValue.stake.id)
                  ? 'opacity-100'
                  : 'opacity-20',
              )}
            >
              <div className="flex items-center gap-0.5 shrink-0 w-8/12">
                <ListItemRank
                  rank={index + 1}
                  size={8}
                  color={stakeValue.stake.pillar.color}
                />
                <StakeTag stake={stakeValue.stake} />
              </div>
              <div className="text-xs text-gray-500 font-semibold shrink-0 grow text-center w-3/12 shrink-0">
                {(Math.round(stakeValue.materialityScore * 10) / 10).toFixed(1)}
              </div>
              {isExportToImageMode && (
                <div className="w-1/12 flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="form-input-checkbox"
                    checked={stakesIdsToExport.includes(stakeValue.stake.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStakesIdsToExport([
                          ...stakesIdsToExport,
                          stakeValue.stake.id,
                        ]);
                      } else {
                        setStakesIdsToExport(
                          stakesIdsToExport.filter(
                            (id) => id !== stakeValue.stake.id,
                          ),
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
