import {
  AddMeAsRecipientButton_MaturityEvaluationFragment,
  useAddMeAsRecipientButton_StakeholderCreateMutation,
} from '../../../../graphql/generated';
import React, { useState } from 'react';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { Loader } from '../../../generic/Loader';
import { PlusIcon, UserIcon } from '../../../icons';

export function AddMeAsRecipientButton({
  survey,
}: {
  survey: AddMeAsRecipientButton_MaturityEvaluationFragment;
}) {
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);

  const [createStakeholder] =
    useAddMeAsRecipientButton_StakeholderCreateMutation();
  const addMeAsStakeholder = () => {
    setIsLoading(true);
    createStakeholder({
      variables: {
        input: {
          stakeholderMaturityEvaluation: {
            maturityEvaluation: {
              id: survey.id,
            },
            email: currentUser?.auth?.email || '',
            firstName: currentUser?.firstName || '',
            lastName: currentUser?.lastName || '',
          },
        },
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button className="secondary purple" onClick={addMeAsStakeholder}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex items-center">
          <PlusIcon className="w-4 h-4" />
          <UserIcon className="w-4 h-4" />
        </div>
      )}
      <div>Vous ajouter</div>
    </button>
  );
}
