import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useTranslation } from '@hooks/useTranslation';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { NavItem } from './NavItem';
import {
  CalendarIcon,
  MaturityIcon,
  StakesIcon,
  SurveyIcon,
  UserIcon,
  XIcon,
} from '../../icons';
import { RequiredBadge } from '../../badges/RequiredBadge';
import React from 'react';
import { AppRoutes } from '../../../screens/AppRoutes';

export const TemplateNavItems = () => {
  const projectContext = useProjectContext();
  const hasReferential = projectContext?.enterprise?.referential !== null;
  const { t } = useTranslation();

  const closeTemplate = () => {
    projectContext?.updateEnterprise(null, true);
  };

  return (
    <div className="flex flex-col">
      {projectContext?.enterprise && (
        <>
          <MessageBox type={MessageBoxType.Warning}>
            <div>
              <div className="text-xs">
                {t('nav:leftNav.templates.you_are_editing_the_template')}
              </div>
              <div className="font-bold text-yellow-900">
                {projectContext?.enterprise?.name}
              </div>
            </div>
          </MessageBox>

          <div className="mt-4">
            <NavItem
              name={t('nav:leftNav.templates.template_management')}
              Icon={CalendarIcon}
              href={AppRoutes.Project}
            />
            <NavItem
              name={t('nav:page.referential')}
              Icon={StakesIcon}
              href={AppRoutes.Stakes}
              badge={!hasReferential && <RequiredBadge />}
            />
            <NavItem
              name={t('nav:page.maturity_evaluation')}
              Icon={MaturityIcon}
              href={AppRoutes.MaturityEvaluations}
            />
            <NavItem
              name={t('nav:page.surveys')}
              Icon={SurveyIcon}
              href={AppRoutes.Survey}
            />
            <NavItem
              name={t('nav:page.stakeholder_mapping')}
              Icon={UserIcon}
              href={AppRoutes.StakeholderMapping}
            />
            <div className="px-3 py-2">
              <button
                className="unstyled text-gray-500 w-full flex items-center justify-start text-sm font-normal"
                onClick={closeTemplate}
              >
                <XIcon className="h-3.5 w-3.5" />
                <div>{t('nav:leftNav.templates.close_template')}</div>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
