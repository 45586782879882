import React, { useState } from 'react';
import {
  DisplayLuccaStakeholders_StakeholderFragment,
  ImportLuccaStakeholdersModal_SurveyFragment,
  StakeholderSurveyCreateInput,
  useImportLuccaStakeholdersModal_CompanyUpdateMutation,
  useImportLuccaStakeholdersModal_StakeholderImportMutation,
  useImportLuccaStakeholdersModal_StakeholdersQuery,
} from '../../../graphql/generated';
import { StakeholderTable } from './StakeholdersTable';
import luccaLogoIcon from '../../../assets/images/lucca-logo.png';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import { LoaderFullscreen } from '../../layout/Loader';
import { Loader } from '../../generic/Loader';

export function ImportLuccaStakeholdersModal({
  survey,
}: {
  survey: ImportLuccaStakeholdersModal_SurveyFragment;
}) {
  const [luccaUrl, setLuccaUrl] = useState(
    survey.company?.metadata?.luccaConfiguration?.url || '',
  );
  const [luccaApiKey, setLuccaApiKey] = useState(
    survey.company?.metadata?.luccaConfiguration?.apiKey || '',
  );

  const [displayLuccaConfigurator, setDisplayLuccaConfigurator] =
    useState(false);

  const isLuccaConfigured = luccaUrl && luccaApiKey;

  const refreshLuccaConfiguration = (luccaUrl: string, luccaApiKey: string) => {
    setLuccaUrl(luccaUrl);
    setLuccaApiKey(luccaApiKey);
    setDisplayLuccaConfigurator(false);
  };

  return (
    <div className="flex flex-col gap-8">
      {!isLuccaConfigured ? (
        <>
          <div className="flex items-center gap-4">
            <img src={luccaLogoIcon} className="h-10 w-10" alt="" />
            <p className="max-w-md text-gray-500 text-sm">
              Importez rapidement l&apos;ensemble de vos collaborateurs grâce à
              notre intégration avec Lucca !<br />
              <a
                href="https://www.lucca.fr/"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                En savoir plus
              </a>
            </p>
          </div>
          <MessageBox type={MessageBoxType.Info}>
            <div>
              <p className="mb-2">
                Veuillez configurer votre connecteur Lucca afin d&apos;activer
                l&apos;import
              </p>
              {displayLuccaConfigurator && survey.company?.id ? (
                <ConfigureLuccaIntegration
                  companyId={survey.company.id}
                  storedLuccaUrl={luccaUrl}
                  storedLuccaApiKey={luccaApiKey}
                  onSubmit={refreshLuccaConfiguration}
                />
              ) : (
                <button
                  className="primary small blue"
                  onClick={() => setDisplayLuccaConfigurator(true)}
                >
                  Configurer Lucca
                </button>
              )}
            </div>
          </MessageBox>
        </>
      ) : (
        <>
          <div className="flex items-center gap-4">
            <img src={luccaLogoIcon} className="h-10 w-10" alt="" />
            <p className="max-w-md text-gray-500 text-sm">
              Importez rapidement l&apos;ensemble de vos collaborateurs grâce à
              notre intégration avec Lucca !<br />
              <button
                className="unstyled small"
                onClick={() => setDisplayLuccaConfigurator(true)}
              >
                Modifier la configuration
              </button>
            </p>
          </div>
          {displayLuccaConfigurator && survey.company?.id ? (
            <ConfigureLuccaIntegration
              companyId={survey.company.id}
              storedLuccaUrl={luccaUrl}
              storedLuccaApiKey={luccaApiKey}
              onSubmit={refreshLuccaConfiguration}
            />
          ) : (
            <DisplayLuccaStakeholders
              luccaUrl={luccaUrl}
              luccaApiKey={luccaApiKey}
              survey={survey}
            />
          )}
        </>
      )}
    </div>
  );
}

function DisplayLuccaStakeholders({
  luccaUrl,
  luccaApiKey,
  survey,
}: {
  luccaUrl: string;
  luccaApiKey: string;
  survey: ImportLuccaStakeholdersModal_SurveyFragment;
}) {
  const toast = useToast();
  const modal = useModal();

  const [importStakeholder] =
    useImportLuccaStakeholdersModal_StakeholderImportMutation();
  const [isImporting, setIsImporting] = useState(false);

  // Fetch stakeholders from Lucca API
  const query = useImportLuccaStakeholdersModal_StakeholdersQuery({
    variables: {
      luccaUrl,
      luccaApiKey,
    },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return (
      <div className="flex flex-col gap-4 items-center">
        <LoaderFullscreen />
        <h6>Récupération des collaborateurs</h6>
      </div>
    );
  }

  if (isImporting) {
    return (
      <div className="flex flex-col gap-4 items-center">
        <LoaderFullscreen />
        <h6>Import des collaborateurs</h6>
      </div>
    );
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>{query.error.message}</p>
      </MessageBox>
    );
  }

  const getCreateStakeholderInput = (
    stakeholder: DisplayLuccaStakeholders_StakeholderFragment,
  ): StakeholderSurveyCreateInput => {
    return {
      survey: {
        id: survey.id,
      },
      email: stakeholder.email,
      firstName: stakeholder.firstName,
      lastName: stakeholder.lastName,
    };
  };

  const stakeholders = query.data?.getLuccaCollaboratorsAsStakeholders || [];
  const stakeholdersCount: number = stakeholders.length;

  const submit = () => {
    setIsImporting(true);
    void importStakeholder({
      variables: {
        input: stakeholders.map((stakeholder) => {
          return getCreateStakeholderInput(stakeholder);
        }),
      },
    })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage(
          `${stakeholdersCount.toString()} contacts importés avec succès`,
        );
      })
      .finally(() => setIsImporting(false));
  };

  return (
    <div className="flex flex-col gap-8">
      {stakeholders.length > 0 && (
        <>
          <h6 className="flex items-center gap-2">
            <span>Collaborateurs prêts à être importés</span>
            <span className="tag gray">{stakeholders.length}</span>
          </h6>
          <StakeholderTable stakeholders={stakeholders} />
          <div className="flex justify-end">
            <button className="primary" onClick={submit}>
              Importer les collaborateurs
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function ConfigureLuccaIntegration({
  companyId,
  storedLuccaUrl,
  storedLuccaApiKey,
  onSubmit,
}: {
  companyId: string;
  storedLuccaUrl: string;
  storedLuccaApiKey: string;
  onSubmit: (luccaUrl: string, luccaApiKey: string) => void;
}) {
  const [luccaUrl, setLuccaUrl] = useState(storedLuccaUrl);
  const [luccaApiKey, setLuccaApiKey] = useState(storedLuccaApiKey);

  const [updateCompany] =
    useImportLuccaStakeholdersModal_CompanyUpdateMutation();
  const [isLoading, setIsLoading] = useState(false);
  const submit = () => {
    setIsLoading(true);
    updateCompany({
      variables: {
        input: {
          id: companyId,
          metadata: {
            luccaConfiguration: {
              url: luccaUrl,
              apiKey: luccaApiKey,
            },
          },
        },
      },
    })
      .then(() => {
        onSubmit(luccaUrl, luccaApiKey);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="flex flex-col gap-2 py-4">
      <label className="form-input-label text-sm" htmlFor="luccaUrl">
        URL Lucca
      </label>
      <input
        type="text"
        id="luccaUrl"
        className="form-input-text"
        placeholder="https://cheese-and-wine.ilucca.net"
        value={luccaUrl}
        onChange={(event) => {
          setLuccaUrl(event.target.value);
        }}
      />
      <label className="form-input-label text-sm" htmlFor="luccaApiKey">
        Clé d&apos;API
      </label>
      <input
        type="text"
        id="luccaApiKey"
        className="form-input-text"
        placeholder="aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee"
        value={luccaApiKey}
        onChange={(event) => {
          setLuccaApiKey(event.target.value);
        }}
      />
      <button
        className="self-end primary small blue"
        disabled={isLoading}
        onClick={submit}
      >
        {isLoading && <Loader />}
        <span>Sauvegarder</span>
      </button>
    </div>
  );
}
