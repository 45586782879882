import { UpdateCatalystModal } from './UpdateCatalystModal';
import React from 'react';
import { useModal } from '../../layout/Modal';
import { EditIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { UpdateCatalystModalFragment } from '../../../graphql/generated';

export function UpdateCatalystButton({
  catalyst,
}: {
  catalyst: UpdateCatalystModalFragment;
}) {
  const modal = useModal();
  const { t } = useTranslation();
  return (
    <button
      className="primary purple"
      onClick={() =>
        modal.openModalWithComponent(
          <UpdateCatalystModal catalyst={catalyst} />,
          null,
          true,
          false,
          'w-1/2',
        )
      }
    >
      <EditIcon className="w-4 h-4" />
      <span>{t('global:edit')}</span>
    </button>
  );
}
