import { TranslatableObject, useTranslation } from '@hooks/useTranslation';
import { Scalars, SurveyLanguage } from '../../graphql/generated';
import { Tooltip } from '../tooltip/Tooltip';
import clsx from 'clsx';
import { WarningIcon } from '../icons';
import React from 'react';

type TranslatableEntityWithId = TranslatableObject & {
  id: Scalars['ID']['output'];
};

export function MissingTranslationsWarningIcon<
  T extends TranslatableEntityWithId,
>({
  entity,
  properties,
  languages,
}: {
  entity: T;
  properties: string[];
  languages: SurveyLanguage[];
}) {
  const { getMissingTranslations, t } = useTranslation();
  const missingTranslations = getMissingTranslations(
    entity,
    properties,
    languages,
  );

  if (missingTranslations.length > 0) {
    return (
      <Tooltip
        id={`MissingTranslationsWarningIcon-${entity.id}-tooltip`}
        content={t('global:missingTrans', {
          languages: missingTranslations
            .map((language) => t(`global:lang.${language}`))
            .join(', '),
        })}
      >
        <div className={clsx('text-red-700')}>
          <WarningIcon />
        </div>
      </Tooltip>
    );
  }

  return null;
}
