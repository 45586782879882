import React from 'react';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';
import { SurveyResponsesTable } from './SurveyResponsesTable';
import { SurveyResponses_SurveyFragment } from '../../../../graphql/generated';

export function SurveyResponses({
  survey,
  setResponsesCounter,
}: {
  survey: SurveyResponses_SurveyFragment;
  setResponsesCounter?: (responsesCounter: number) => void;
}) {
  const { t } = useTranslation();

  const hasRecipients = (survey.recipients?.length ?? 0) > 0;
  const unknownRecipients = survey.respondents.filter(
    (r) => r.stakeholderId === null,
  );

  return (
    <div className="space-y-4 pb-16">
      {!hasRecipients && (
        <MessageBox type={MessageBoxType.Info}>
          <div className="flex items-center justify-between">
            <p>{t('translation:survey.respondents.emptyState.content')}</p>
          </div>
        </MessageBox>
      )}

      {hasRecipients && unknownRecipients.length > 0 && (
        <div className="my-4">
          <MessageBox type={MessageBoxType.Info}>
            {t('translation:survey.respondents.unknownRecipients', {
              count: unknownRecipients.length,
              context: unknownRecipients.length > 1 ? 'plural' : 'singular',
            })}
          </MessageBox>
        </div>
      )}

      <SurveyResponsesTable
        survey={survey}
        setResponsesCounter={setResponsesCounter}
        hasRecipients={hasRecipients}
      />
    </div>
  );
}
