import React, { useEffect, useMemo } from 'react';
import {
  DiagnosticStakeFieldsFragment,
  PriorityLevel,
  SurveyAnswerType,
  SurveySubmitAnswerInput,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyWithCompanyReferentialQuery,
} from '../../../graphql/generated';
import { QuestionDescription } from './QuestionDescription';
import { LoaderFullscreen } from '../../layout/Loader';
import { useParams } from 'react-router-dom';
import { HelpModal } from '../../generic/HelpModal';
import { CheckboxSurveyInput } from '../../generic/form/CheckboxSurveyInput';
import { useTranslation } from '@hooks/useTranslation';
import { MinusIcon, PlusIcon } from '../../icons';
import { InnerHtml } from '../../generic/InnerHtml';

export function QuestionStakesSimpleMateriality({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t, translateProperty } = useTranslation();

  // Fetch stakes
  // TODO: refactor to avoid querying referential: we should write a graphql query containing question + stakes
  const { surveyId } = useParams();
  const surveyWithCompanyReferentialQuery =
    useSurveyWithCompanyReferentialQuery({
      variables: { id: surveyId || '' },
      skip: !surveyId,
      fetchPolicy: 'network-only',
    });
  const referential =
    surveyWithCompanyReferentialQuery.data?.survey?.company?.referential;
  const pillar = referential?.pillars.find(
    (pillar) => pillar.id === question.pillar?.id,
  );
  const stakes = useMemo(() => {
    return (
      (pillar?.stakes || []).toSorted((a, b) =>
        translateProperty(a, 'name').localeCompare(
          translateProperty(b, 'name'),
        ),
      ) || []
    );
  }, [pillar, translateProperty]);

  const updateStakePriority = (
    stakeId: string,
    priority: PriorityLevel | undefined,
  ) => {
    const answerStakes = answer?.stakes || [];
    const stakeIndex = answerStakes.findIndex((s) => s.stakeId === stakeId);
    if (stakeIndex === -1) {
      answerStakes.push({
        stakeId,
        priority,
      });
    } else {
      answerStakes[stakeIndex].priority = priority;
    }
    if (answerStakes.length === stakes.length) {
      setIsAnswerValid(true);
    }
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Stakes,
      stakes: answerStakes,
    });
  };

  // Validate answer
  useEffect(() => {
    setIsAnswerValid(
      (answer?.stakes || []).filter((stakeAnswer) => stakeAnswer.priority)
        .length === stakes.length,
    );
  }, [answer, stakes, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div className="w-full flex justify-center items-center">
          {surveyWithCompanyReferentialQuery.loading ? (
            <LoaderFullscreen />
          ) : (
            <div className="flex flex-col gap-1">
              <div className="hidden sm:block">
                {stakes.length > 0 && (
                  <RowHeader
                    label={t(
                      'survey.question.stakes_simple_materiality.importance',
                    )}
                  />
                )}
              </div>
              {stakes.map((stake) => (
                <StakeRow
                  question={question}
                  key={stake.id}
                  stake={stake}
                  currentPriority={
                    answer?.stakes?.find((s) => s.stakeId === stake.id)
                      ?.priority
                  }
                  updateStakePriority={(priority) =>
                    updateStakePriority(stake.id, priority)
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function StakeRow({
  question,
  stake,
  currentPriority,
  updateStakePriority,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  stake: DiagnosticStakeFieldsFragment;
  currentPriority?: PriorityLevel | undefined | null;
  updateStakePriority: (priority: PriorityLevel | undefined) => void;
}) {
  const { translateProperty } = useTranslation();
  const [priority, setPriority] = React.useState<
    PriorityLevel | null | undefined
  >(currentPriority || null);

  const checkStakeLevel = (priority: PriorityLevel) => {
    setPriority(priority);
    updateStakePriority(priority);
  };

  const uncheckStakeLevel = () => {
    setPriority(undefined);
    updateStakePriority(undefined);
  };

  const description = translateProperty(stake, 'description');

  return (
    <div className="flex flex-col sm:flex-row items-center justify-start gap-4 w-full hover:bg-yellow-100 pl-4 p-1 rounded-3xl">
      <div className="w-80 shrink-0 flex items-center justify-center sm:justify-start gap-2">
        {translateProperty(stake, 'name')}
        {description && (
          <HelpModal modalTitle="">
            <InnerHtml html={description} className="text-gray-700 text-sm" />
          </HelpModal>
        )}
      </div>
      <div className="w-56 flex items-center justify-evenly gap-2">
        <div className="block sm:hidden">
          <MinusIcon />
        </div>
        <CheckboxSurveyInput
          checked={priority === PriorityLevel.None}
          check={() => checkStakeLevel(PriorityLevel.None)}
          uncheck={uncheckStakeLevel}
          placeholder={'1'}
          tooltip={question.stakeOptions?.materialityOptions?.impactNoneLabel}
        />
        <CheckboxSurveyInput
          checked={priority === PriorityLevel.Low}
          check={() => checkStakeLevel(PriorityLevel.Low)}
          uncheck={uncheckStakeLevel}
          placeholder={'2'}
          tooltip={question.stakeOptions?.materialityOptions?.impactLowLabel}
        />
        <CheckboxSurveyInput
          checked={priority === PriorityLevel.Medium}
          check={() => checkStakeLevel(PriorityLevel.Medium)}
          uncheck={uncheckStakeLevel}
          placeholder={'3'}
          tooltip={question.stakeOptions?.materialityOptions?.impactMediumLabel}
        />
        <CheckboxSurveyInput
          checked={priority === PriorityLevel.High}
          check={() => checkStakeLevel(PriorityLevel.High)}
          uncheck={uncheckStakeLevel}
          placeholder={'4'}
          tooltip={question.stakeOptions?.materialityOptions?.impactHighLabel}
        />
        <div className="block sm:hidden">
          <PlusIcon />
        </div>
      </div>
    </div>
  );
}

function RowHeader({ label }: { label?: string }) {
  return (
    <div className="flex items-center justify-end gap-4 w-full">
      <div className="w-56 flex items-center justify-evenly gap-2 py-4 pr-4 pl-2">
        <div className="rounded-full border-2 border-gray-900">
          <MinusIcon />
        </div>
        <hr className="border border-gray-900 w-8" />
        <div className="font-semibold">{label}</div>
        <hr className="border border-gray-900 w-8" />
        <div className="rounded-full border-2 border-gray-900">
          <PlusIcon />
        </div>
      </div>
    </div>
  );
}
