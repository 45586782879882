import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useNavContext } from '../../../providers/NavContextProvider';
import { useLeftDrawer } from '../../layout/LeftDrawer';
import { NavLink } from 'react-router-dom';
import { CompanyLogo } from '../../project/project/CompanyLogo';
import { ChevronRightIcon } from '../../icons';
import { TemplateNavItems } from './TemplateNavItems';
import { NavItems } from './NavItems';
import { UserProfileButton } from '../../user/UserProfileButton';
import { ConsultingCompanyMaxProjectsCountProgressBar } from '../../project/project/ConsultingCompanyMaxProjectsCountProgressBar';
import React from 'react';
import { CoachIndicator } from '../../user/CoachIndicator';
import { isCoachUser } from '../../../services/CompanyService';
import { AppRoutes } from '../../../screens/AppRoutes';
import { SkeletonLoader } from '../../generic/loader/SkeletonLoader';

function LeftNavSkeleton() {
  const skeletons = Array.from({ length: 10 }, (_, i) => ({
    key: `leftnav-skeleton-${i}`,
    component: (
      <SkeletonLoader
        size={{
          height: 'h-10',
        }}
        randomSizes={{ width: ['w-24', 'w-28', 'w-32', 'w-36', 'w-2/3'] }}
      />
    ),
  }));

  return (
    <>
      <SkeletonLoader size={{ height: 'h-16', width: 'w-52' }} />
      <br />
      <div className={'flex flex-col gap-4'}>
        {skeletons.map(({ key, component }) => (
          <div key={key}>{component}</div>
        ))}
      </div>
    </>
  );
}

export const LeftNavInner = () => {
  const currentUser = useCurrentUser();
  const projectContext = useProjectContext();
  const navContext = useNavContext();
  const leftDrawer = useLeftDrawer();
  const isCoach = isCoachUser(currentUser);
  if (projectContext?.isLoadingEnterprise || !currentUser)
    return <LeftNavSkeleton/>;

  const companyName = currentUser?.company
    ? currentUser.company.name
    : 'Good Steps';

  return (
    <div className="text-sm flex flex-col h-full">
      <div className="flex-1 flex flex-col overflow-y-auto gap-5">
        <div className="flex items-center pl-1 gap-3">
          <div className="flex items-center grow bg-gray-50 px-4 py-3 rounded-xl">
            <div className="grow">
              <NavLink to={AppRoutes.Home}>
                <div className="flex items-center gap-3">
                  {currentUser?.company && (
                    <CompanyLogo
                      companyId={currentUser?.company.id}
                      size={'small'}
                    />
                  )}
                  <span className="grow font-bold">{companyName}</span>
                </div>
              </NavLink>
            </div>
          </div>
          <button
            className="unstyled"
            onClick={() => {
              if (navContext?.isNavCollapsed) {
                leftDrawer.closeDrawer();
                navContext?.setIsNavCollapsed(false);
              } else {
                navContext?.setIsNavCollapsed(true);
              }
            }}
          >
            {navContext?.isNavCollapsed ? (
              <>
                <ChevronRightIcon />
                <ChevronRightIcon className="-ml-4" />
              </>
            ) : (
              <>
                <ChevronRightIcon className="text-gray-500 rotate-180" />
                <ChevronRightIcon className="text-gray-500 rotate-180 -ml-4" />
              </>
            )}
          </button>
        </div>
        <nav
          className="flex flex-col gap-2 mt-2 text-gray-500"
          aria-label="Sidebar"
        >
          {projectContext?.enterprise?.isTemplate ? (
            <TemplateNavItems />
          ) : (
            <NavItems />
          )}
        </nav>
      </div>
      <div className="flex flex-col space-y-2">
        {!isCoach && <CoachIndicator />}
        <UserProfileButton />
        {currentUser?.company && isCoach && (
          <div className="border border-gray-100 px-4 py-3 shadow-sm rounded-md">
            <ConsultingCompanyMaxProjectsCountProgressBar
              companyId={currentUser.company.id}
            />
          </div>
        )}
      </div>
    </div>
  );
};
