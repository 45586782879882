import React from 'react';
import { ScoreRangeSlider } from '../../../../generic/form/ScoreRangeSlider';
import {
  FinancialScoreRangeSliderFragment,
  StakeIroDetailDocument,
  useFinancialScoreRangeSliderMutation,
} from '../../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../layout/Loader';

export function FinancialScoreRangeSlider({
  stake,
}: {
  stake: FinancialScoreRangeSliderFragment;
}) {
  const [updateStakeMutation] = useFinancialScoreRangeSliderMutation();
  const [loading, setLoading] = React.useState(false);

  const setScore = (newScore: number | null) => {
    setLoading(true);
    void updateStakeMutation({
      variables: {
        input: {
          id: stake.id,
          financialMaterialityScore: newScore,
        },
      },
      refetchQueries: [
        { query: StakeIroDetailDocument, variables: { stakeId: stake.id } },
      ],
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute w-full h-full flex items-center bg-white z-50 border border-gray-100 rounded-lg shadow-sm">
          <LoaderFullscreen />
        </div>
      )}
      <div className={loading ? 'invisible' : 'visible'}>
        <ScoreRangeSlider
          score={stake.persistedFinancialMaterialityScore ?? null}
          setScore={setScore}
          referenceScore={stake.computedFinancialMaterialityScore}
        />
      </div>
    </div>
  );
}
