import React from 'react';
import {
  MaterialityTypes,
  QualityLevel,
  StakesDoubleMaterialityQualityArray_AnswerFragment,
  StakesDoubleMaterialityQualityArray_QuestionFragment,
  StakesDoubleMaterialityQualityArray_StakeFragment,
} from '../../../../../graphql/generated';
import { getQualityLabel } from '../../../../../services/DiagnosticService';
import { MatrixArray, MatrixRowType } from '../MatrixArray';
import { useTranslation } from '@hooks/useTranslation';

export function StakesDoubleMaterialityQualityArray({
  stakes,
  question,
  answers,
}: {
  stakes: StakesDoubleMaterialityQualityArray_StakeFragment[];
  question: StakesDoubleMaterialityQualityArray_QuestionFragment;
  answers: StakesDoubleMaterialityQualityArray_AnswerFragment[];
}) {
  const { translateProperty } = useTranslation();
  // columns = list of impacts or priorities, depending on materiality type
  const columns: Array<QualityLevel> = [
    QualityLevel.Negative,
    QualityLevel.Neutral,
    QualityLevel.Positive,
  ];

  // Matrix = list of rows, each row is a stake
  const matrix: MatrixRowType[] =
    (answers || [])
      .flatMap((answer) => answer.stakes)
      .reduce((acc: MatrixRowType[], stakeAnswer) => {
        if (acc.find((row) => row.stakeId === stakeAnswer?.stakeId)) {
          // Increment value in the right cell
          const stakeId = stakeAnswer?.stakeId || '';
          const level = stakeAnswer?.quality;
          const row = acc.find((row) => row.stakeId === stakeId);
          const cell = row?.values.find((cell) => cell.key === level);
          if (cell) {
            cell.value++;
          }
          return acc;
        } else {
          const stake = stakes.find(
            (stake) => stake.id === stakeAnswer?.stakeId,
          );

          return [
            ...acc,
            {
              stakeId: stakeAnswer?.stakeId || '',
              label: translateProperty(stake, 'name'),
              values: columns.map((column) => {
                const label = getQualityLabel(
                  column,
                  question.stakeOptions?.materialityType ||
                    MaterialityTypes.Financial,
                );
                const value = stakeAnswer?.quality === column ? 1 : 0;
                return {
                  key: column,
                  label,
                  value,
                };
              }),
            },
          ];
        }
      }, [])
      .toSorted((a, b) => a.label.localeCompare(b.label)) || [];

  return (
    <MatrixArray
      title={question.stakeOptions?.materialityOptions?.qualityLabel}
      matrix={matrix}
      columns={columns}
    />
  );
}
