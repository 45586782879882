import {
  SurveyQuestionUpdateChoiceInput,
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useEffect, useState } from 'react';
import { ToggleRequired } from './atoms/ToggleRequired';
import { useToast } from '../../../../layout/Toast';
import { NumberCircle } from '../../../../generic/NumberCircle';
import { Toggle } from '../../../../generic/form/Toggle';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { DeleteIcon, DownIcon, PlusIcon, UpIcon } from '../../../../icons';

export function QuestionMultipleChoiceForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  const [choices, setChoices] = useState<SurveyQuestionUpdateChoiceInput[]>([
    ...(questionDraft.choices || []),
  ]);
  const [canSelectMultipleChoices, setCanSelectMultipleChoices] = useState(
    questionDraft.canSelectMultipleChoices,
  );
  const [canAddOtherChoice, setCanAddOtherChoice] = useState(
    questionDraft.canAddOtherChoice,
  );

  // Update choices and other props when selected question changes
  useEffect(() => {
    setChoices([...(questionDraft.choices || [])]);
    setCanSelectMultipleChoices(questionDraft.canSelectMultipleChoices);
    setCanAddOtherChoice(questionDraft.canAddOtherChoice);
  }, [questionDraft]);

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();

  const updateQuestion = () => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          title: questionDraft.title,
          canSelectMultipleChoices,
          canAddOtherChoice,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const toggleCanSelectMultipleChoices = (newState: boolean): void => {
    setCanSelectMultipleChoices(newState);

    setQuestionDraft({
      ...questionDraft,
      canSelectMultipleChoices: newState,
    });

    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          canSelectMultipleChoices: newState,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const toggleCanAddOtherChoice = (newState: boolean): void => {
    setCanAddOtherChoice(newState);

    setQuestionDraft({
      ...questionDraft,
      canAddOtherChoice: newState,
    });

    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          canAddOtherChoice: newState,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const updateQuestionChoices = (
    newChoices: SurveyQuestionUpdateChoiceInput[],
  ) => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          choices: newChoices.map((choice) => ({
            id: choice.id,
            label: choice.label,
            position: choice.position,
          })),
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Question mise à jour');
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const addNewChoice = () => {
    const newChoices = [...choices];
    // Get maximum position
    const maxPosition = Math.max(
      ...newChoices.map((choice) => choice.position),
    );
    newChoices.push({
      label: `Choix ${choices.length + 1}`,
      position: maxPosition + 1,
    });
    setChoices(newChoices);

    setQuestionDraft({
      ...questionDraft,
      choices: newChoices.map((choice) => ({
        id: choice.id || '',
        label: choice.label,
        position: choice.position,
      })),
    });

    updateQuestionChoices(newChoices);
  };

  const removeChoice = (index: number) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);

    setQuestionDraft({
      ...questionDraft,
      choices: newChoices.map((choice) => ({
        id: choice.id || '',
        label: choice.label,
        position: choice.position,
      })),
    });

    updateQuestionChoices(newChoices);
  };

  const moveChoiceUp = (id: string) => {
    const newChoices = choices.toSorted((a, b) => a.position - b.position);
    const index = newChoices.findIndex((choice) => choice.id === id);
    if (index === 0) return;
    const choiceToMove = { ...newChoices[index] };
    const choiceToSwap = { ...newChoices[index - 1] };
    choiceToMove.position = choiceToSwap.position;
    choiceToSwap.position = choiceToMove.position + 1;
    newChoices[index] = choiceToSwap;
    newChoices[index - 1] = choiceToMove;
    setChoices(newChoices);

    setQuestionDraft({
      ...questionDraft,
      choices: newChoices.map((choice) => ({
        id: choice.id || '',
        label: choice.label,
        position: choice.position,
      })),
    });

    updateQuestionChoices(newChoices);
  };

  const moveChoiceDown = (index: number) => {
    const newChoices = choices.toSorted((a, b) => a.position - b.position);
    if (index === newChoices.length - 1) return;
    const choiceToMove = { ...newChoices[index] };
    const choiceToSwap = { ...newChoices[index + 1] };
    choiceToMove.position = choiceToSwap.position;
    choiceToSwap.position = choiceToMove.position - 1;
    newChoices[index] = choiceToSwap;
    newChoices[index + 1] = choiceToMove;
    setChoices(newChoices);

    setQuestionDraft({
      ...questionDraft,
      choices: newChoices.map((choice) => ({
        id: choice.id || '',
        label: choice.label,
        position: choice.position,
      })),
    });

    updateQuestionChoices(newChoices);
  };

  const updateChoiceLabel = (index: number, newLabel: string) => {
    const newChoices = [...choices];
    const updatedChoice = { ...newChoices[index] };
    updatedChoice.label = newLabel;
    newChoices[index] = updatedChoice;
    setChoices(newChoices);

    setQuestionDraft({
      ...questionDraft,
      choices: newChoices.map((choice) => ({
        id: choice.id || '',
        label: choice.label,
        position: choice.position,
      })),
    });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>
      <div className="pt-8">
        <QuestionPillarStake
          question={questionDraft}
          enableStakePicker={true}
          enablePillarPicker={true}
        />
      </div>

      <div className="pt-8 space-y-2">
        <h3>Contenu</h3>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            Intitulé de la question
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() => updateQuestion()}
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
        <div className="space-y-2 bg-gray-50 rounded-xl p-4 border border-gray-100">
          <label className="form-input-label mb-2">Choix</label>
          <div className="flex flex-col gap-2">
            {choices
              ?.toSorted((a, b) => a.position - b.position)
              .map((choice, index) => (
                <div className="flex items-center gap-2" key={index}>
                  <NumberCircle number={index} size={6} mode={'alpha'} />
                  <input
                    type="text"
                    name="choice"
                    id="choice"
                    className="form-input-text"
                    value={choice.label}
                    onChange={(e) => {
                      updateChoiceLabel(index, e.target.value);
                    }}
                    onBlur={() => updateQuestionChoices(choices)}
                  />
                  <button
                    className="unstyled text-gray-500"
                    onClick={() => moveChoiceUp(choice.id || '')}
                    disabled={index === 0}
                  >
                    <UpIcon className="w-4 h-4" />
                  </button>
                  <button
                    className="unstyled text-gray-500"
                    onClick={() => moveChoiceDown(index)}
                    disabled={index === choices.length - 1}
                  >
                    <DownIcon className="w-4 h-4" />
                  </button>
                  <button
                    className="unstyled text-gray-500"
                    onClick={() => removeChoice(index)}
                  >
                    <DeleteIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
            <div className="flex items-center gap-4">
              <button className="primary small" onClick={addNewChoice}>
                <PlusIcon />
                <span>Ajouter un choix</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8 space-y-4">
        <h3>Options</h3>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-sm">
            <Toggle
              state={canSelectMultipleChoices}
              setState={toggleCanSelectMultipleChoices}
            />
            <label className="form-input-label">
              Autoriser la sélection multiple
            </label>
          </div>
          <div className="flex items-center gap-2 text-sm">
            <Toggle
              state={canAddOtherChoice}
              setState={toggleCanAddOtherChoice}
            />
            <label className="form-input-label">Choix &quot;autre&quot;</label>
          </div>
        </div>
      </div>
    </div>
  );
}
