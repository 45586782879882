import { LoaderFullscreen } from '../../../components/layout/Loader';
import React, { useState } from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { MaturityEvaluationResult } from '../../../components/project/publications/maturityScores/MaturityEvaluationResult';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { EditIcon, XIcon } from '../../../components/icons';
import { useMaturityScreenQuery } from '../../../graphql/generated';
import { useNavContext } from '../../../providers/NavContextProvider';
import { withProjectContext } from '../../../providers/withProjectContext';
import { useTranslation } from '@hooks/useTranslation';

export const MaturityScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const [isEditMode, setIsEditMode] = useState(false);

    // Fetch maturity through referential directly
    const stakesWithMaturityQuery = useMaturityScreenQuery({
      variables: {
        input: {
          referentialId: projectContext?.enterprise?.referential?.id || '',
        },
      },
      skip: !projectContext?.enterprise?.id,
      fetchPolicy: 'network-only',
    });

    if (stakesWithMaturityQuery.loading) {
      return <LoaderFullscreen />;
    }

    if (stakesWithMaturityQuery.error) {
      return (
        <MessageBox type={MessageBoxType.Error}>
          {stakesWithMaturityQuery.error.message}
        </MessageBox>
      );
    }

    if (stakesWithMaturityQuery.data?.computeMaturityScores) {
      return (
        <>
          <PageTitle title="Score de maturité" />
          <ScreenTopBar isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
          <div className="w-full">
            <MaturityEvaluationResult
              isEditMode={isEditMode}
              stakes={stakesWithMaturityQuery.data.computeMaturityScores}
            />
          </div>
        </>
      );
    }

    return null;
  },
  { requiredCompany: true },
);

function ScreenTopBar({
  isEditMode,
  setIsEditMode,
}: {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const navContext = useNavContext();
  const { t } = useTranslation();

  const editMode = () => {
    setIsEditMode(true);
    navContext?.setIsNavCollapsed(true);
  };

  const closeEditMode = () => {
    setIsEditMode(false);
    navContext?.setIsNavCollapsed(false);
  };

  if (isEditMode) {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <button className="tertiary" onClick={closeEditMode}>
          <XIcon />
        </button>
        <h1 className="title-h5 grow text-center">
          {t('maturity.evaluationResult.editTitle')}
        </h1>
      </div>
    );
  } else {
    return (
      <div className="sticky top-0 bg-white z-40 py-4 px-8 flex items-center gap-4 justify-between">
        <h1 className="title-h5">
          {t('maturity.evaluationResult.title')}</h1>
        <div className="flex items-center gap-4">
          <button className="primary purple" onClick={editMode}>
            <EditIcon className="w-4 h-4" />
            <span>{t('global:edit')}</span>
          </button>
        </div>
      </div>
    );
  }
}
