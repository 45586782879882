import { useParams } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Survey } from '../../../components/project/survey/Survey';
import { useSurveyScreenQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { withProjectContext } from '../../../providers/withProjectContext';

export function SurveyScreenInner() {
  const { surveyId } = useParams();

  const { data, loading } = useSurveyScreenQuery({
    variables: { surveyId: surveyId || '' },
    skip: !surveyId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.survey) {
    return (
      <>
        <PageTitle title="Consultations" />
        <Survey survey={data.survey} />
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>
          Une erreur est survenue lors de la récupération de la consultation.
        </p>
      </MessageBox>
    );
  }
}

export const SurveyScreen = withProjectContext(SurveyScreenInner, {
  requiredCompany: true,
});
