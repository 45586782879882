import React from 'react';
import { DisclosureRequirement_MdrLevel3Fragment } from '../../../graphql/cms/generated';
import { filterEmptyDocuments } from '../utils';
import { BlockWithDatapointsRenderer } from '../../project/gapAnalysis/disclosureRequirement/BlockWithDatapointsRenderer';
import { BlocksRenderer } from '../renderers/BlocksRenderer';

export const DisclosureRequirementMdrLevel3 = ({
  mdrLevel3,
}: {
  mdrLevel3: DisclosureRequirement_MdrLevel3Fragment;
}) => {
  const datapoints = mdrLevel3.datapoints.filter(filterEmptyDocuments);

  return (
    <div className="flex items-stretch gap-2 w-full divide-x divide-gray-100 hover:bg-gray-50">
      <div className="space-y-2">
        {datapoints.length > 0 ? (
          <BlockWithDatapointsRenderer
            content={mdrLevel3.content}
            datapoints={datapoints.map((d) => d.slug || '')}
          />
        ) : (
          <BlocksRenderer content={mdrLevel3.content} />
        )}
      </div>
    </div>
  );
};
