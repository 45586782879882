import { useDeleteSurveyResponseButton_DeleteMutation } from '../../../../graphql/generated';
import React from 'react';
import { Loader } from '../../../generic/Loader';
import { useModal } from '../../../layout/Modal';
import { ConfirmationModal } from '../../../modals/ConfirmationModal';
import { XIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function DeleteSurveyResponseButton({
  responseId,
}: {
  responseId: string;
}) {
  const modal = useModal();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteResponseMutation] =
    useDeleteSurveyResponseButton_DeleteMutation();

  const deleteResponse = () => {
    setIsDeleting(true);
    deleteResponseMutation({
      variables: {
        id: responseId,
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const preventDeletingResponseData = () => {
    modal.openModalWithComponent(
      <ConfirmationModal
        callbackIfConfirmed={deleteResponse}
        message={t('survey.responses.delete.confirm.message')}
      />,
      t('survey.responses.delete.confirm.title'),
    );
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDeleting}
      onClick={preventDeletingResponseData}
    >
      {isDeleting ? <Loader /> : <XIcon />}
      <div>{t('survey.responses.contextualMenu.delete')}</div>
    </button>
  );
}
