import React, { useState } from 'react';
import {
  AdminCompaniesScreenDocument,
  useRegisterNewProModalMutation,
} from '../../../graphql/generated';
import { Loader } from '../../../components/generic/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../components/layout/MessageBox';
import { useModal } from '../../../components/layout/Modal';
import { useToast } from '../../../components/layout/Toast';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { LogoUploadForm } from '../../../components/generic/LogoUploadForm';

export function RegisterNewProModal() {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const modal = useModal();

  const [companyName, setCompanyName] = useState('');
  const [logo, setLogo] = useState<string | null | undefined>(null);

  const [createConsultingCompany] = useRegisterNewProModalMutation();

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const create = () => {
    setIsCreating(true);
    createConsultingCompany({
      variables: {
        input: {
          name: companyName,
          logo,
          metadata: {
            customQuestionTypes:
              currentUser?.company?.metadata?.customQuestionTypes || [],
          },
        },
      },
      refetchQueries: [
        {
          query: AdminCompaniesScreenDocument,
        },
      ],
    })
      .then((result) => {
        if (result.data?.createConsultingCompany?.id) {
          toast.openToastWithMessage('Pro créé');
          modal.closeModal();
        } else {
          setError('Erreur lors de la création du pro');
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label htmlFor="companyName" className="form-input-label">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            id="companyName"
            className="form-input-text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="logo" className="form-input-label">
            Logo de l'entreprise
          </label>

          <div className="flex gap-4 items-start">
            <div className="shrink-0 w-24 h-24 border border-gray-100 rounded-xl flex items-center justify-center p-2">
              {logo ? (
                <img src={logo} className="rounded-xl w-56" alt="logo" />
              ) : (
                <div className="italic text-sm text-gray-500">Aucun logo</div>
              )}
            </div>

            <LogoUploadForm logo={logo} setLogo={setLogo} />
          </div>
        </div>
      </div>

      {error && <MessageBox type={MessageBoxType.Error}>{error}</MessageBox>}

      <div className="flex gap-2 justify-between pt-4">
        <button
          className="secondary"
          onClick={() => modal.closeModal()}
          disabled={isCreating}
        >
          {isCreating ? <Loader /> : 'Annuler'}
        </button>
        <button
          className="primary purple"
          onClick={create}
          disabled={isCreating || !companyName}
        >
          {isCreating ? <Loader /> : 'Créer'}
        </button>
      </div>
    </div>
  );
}
