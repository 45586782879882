import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  StakeholderList__StakeholderGroupFragment,
  StakeholderList__StakeholderSegmentFragment,
  SurveyLanguage,
  useStakeholderListQuery,
} from '../../../../graphql/generated';
import { ThemeIcon } from '../../../generic/icon/ThemeIcon';
import { AddStakeholderGroupButton } from './create/AddStakeholderGroupButton';
import { UpdateStakeholderGroupButton } from './update/UpdateStakeholderGroupButton';
import LanguageDropdown from '../../../form/LanguageDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { LoaderFullscreen } from '../../../layout/Loader';
import { GradientScore } from '../../../generic/GradientScore';
import { MissingTranslationsWarningIcon } from '../../../generic/MissingTranslationsWarningIcon';

export const StakeholderList = ({
  companyId,
  gridClassname,
  readonly = false,
}: {
  companyId: string;
  readonly?: boolean;
  gridClassname?: string;
}) => {
  const { t, i18n, translateProperty } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(
    i18n.language as SurveyLanguage,
  );

  const { data, loading } = useStakeholderListQuery({
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setLanguage(i18n.language as SurveyLanguage);
  }, [i18n.language]);

  if (loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="flex flex-col gap-8 bg-gray-50 px-8 py-16 border-t border-gray-100 shadow-inner grow">
      <div className="flex items-center justify-between gap-3">
        <h1>{t('screen.stakeholder.stakeholder_mapping')}</h1>
        <LanguageDropdown onUpdate={(lang) => setLanguage(lang)} />
        {!readonly && (
          <>
            <div className={'flex-grow'} />
            <div className="flex gap-4 items-center">
              <AddStakeholderGroupButton companyId={companyId} />
            </div>
          </>
        )}
      </div>
      {data?.stakeholderGroups && data.stakeholderGroups.length > 0 ? (
        <div className={gridClassname || 'columns-2 xl:columns-3 gap-4'}>
          {data?.stakeholderGroups
            .toSorted((a, b) =>
              translateProperty(a, 'name', language).localeCompare(
                translateProperty(b, 'name', language),
              ),
            )
            .map((stakeholderGroup) => {
              if (!stakeholderGroup) return null;

              return (
                <StakeholderGroupCard
                  key={stakeholderGroup.id}
                  stakeholderGroup={stakeholderGroup}
                  language={language}
                  readonly={readonly}
                />
              );
            })}
        </div>
      ) : (
        <div className="border rounded-xl border-gray-100 flex flex-col items-center gap-4 p-5 bg-white text-center">
          <div className="grow p-4">{t('stakeholder.group.mapping.empty')}</div>
          <AddStakeholderGroupButton companyId={companyId} />
        </div>
      )}
    </div>
  );
};

export function StakeholderGroupCard({
  stakeholderGroup,
  language,
  readonly = false,
}: {
  stakeholderGroup: StakeholderList__StakeholderGroupFragment;
  language: SurveyLanguage;
  readonly?: boolean;
}) {
  const { translateProperty, t } = useTranslation();
  return (
    <div className="rounded-2xl mb-4 break-inside-avoid">
      <div className="bg-white shadow-sm p-6 rounded-2xl space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ThemeIcon themeIcon={stakeholderGroup.icon} />
            <h2 className="title-h5 flex items-center gap-2">
              {translateProperty(stakeholderGroup, 'name', language)}
              <MissingTranslationsWarningIcon
                entity={stakeholderGroup}
                properties={['name']}
                languages={[language]}
              />
            </h2>
          </div>
          {!readonly && (
            <UpdateStakeholderGroupButton initialData={stakeholderGroup} />
          )}
        </div>
        <div className="space-y-2">
          <div className="text-gray-500 text-xs">
            {t('stakeholder.group.mapping.stakeholder_segments_and_weight')}
          </div>
          {stakeholderGroup.segments?.map((segment) => (
            <StakeholderSegmentCard
              key={segment.id}
              segment={segment}
              language={language}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function StakeholderSegmentCard({
  segment,
  language,
}: {
  segment: StakeholderList__StakeholderSegmentFragment;
  language: SurveyLanguage;
}) {
  const { translateProperty } = useTranslation();
  return (
    <div className={clsx('pl-4 pr-1 py-1 rounded-xl bg-gray-50')}>
      <div className="flex items-center justify-between">
        <div className="font-semibold text-sm flex items-center gap-2">
          {translateProperty(segment, 'name', language)}
          <MissingTranslationsWarningIcon
            entity={segment}
            properties={['name']}
            languages={[language]}
          />
        </div>
        <GradientScore score={segment.weight} />
      </div>
    </div>
  );
}
