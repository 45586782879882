import { useActionCreateMutation } from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useToast } from '../../layout/Toast';
import { PlusIcon } from '../../icons';

export function AddActionTemplateButton({
  companyId,
  callback,
}: {
  companyId: string;
  callback: () => void;
}) {
  const toast = useToast();
  const [addAction] = useActionCreateMutation();
  const [isAdding, setIsAdding] = useState(false);
  const add = () => {
    setIsAdding(true);
    addAction({
      variables: {
        input: {
          companyId,
          isTemplate: true,
        },
      },
    })
      .then(() => {
        callback();
        toast.openToastWithMessage('Modèle ajouté');
      })
      .catch((err) => console.error(err))
      .finally(() => setIsAdding(false));
  };

  return (
    <button onClick={add} disabled={isAdding} className="purple primary small">
      {isAdding ? <Loader /> : <PlusIcon className="w-4 h-4" />}
      Ajouter un modèle d&apos;action
    </button>
  );
}
